import { deleteApiCall, getApiCall, postApiCall, putApiCall } from "../../utils/requests";
import { returnErrors } from '../ActionCreators/errorAction';
import {
    GET_ALL_PARTNERS, GET_ALL_PRODUCTS, GET_ALL_COMPANIES, GET_ALL_LEADS, ADD_LEAD, EDIT_LEAD, DELETE_LEAD
} from "../Types/types";

// This function was an get api call for All Partner Details in leads page on partner portal 

export const getAllPartners = () => async (dispatch) => {
    try {
        const response = await getApiCall(`utility/partners`);
        if (response.status === 200) {
            return dispatch({ type: GET_ALL_PARTNERS, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call for All Products Details in leads page on partner portal 

export const getAllProducts = () => async (dispatch) => {
    try {
        const response = await getApiCall(`utility/products`);
        if (response.status === 200) {
            return dispatch({ type: GET_ALL_PRODUCTS, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call for All Companies Details in leads page on partner portal 

export const getAllCompanies = () => async (dispatch) => {
    try {
        const response = await getApiCall(`utility/companies`);
        if (response.status === 200) {
            return dispatch({ type: GET_ALL_COMPANIES, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call for All Leads export Details in leads page on partner portal 

export const getLeadExportData = () => async (dispatch) => {
    try {
        const response = await getApiCall(`leads/export`);
        if (response.status === 200) {
            return dispatch({ type: 'LEAD_EXPORT_DATA', payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call gor All leads in leads page on partner portal 

export const getLeads = (page, size, query, partnerId, productId, companyId, status) => async (dispatch) => {
    try {
        const response = await getApiCall(`leads?size=${size}&page=${page}&search=${query}&partner_id=${partnerId ? partnerId : ''}&product_id=${productId ? productId : ''}&company_id=${companyId ? companyId : ''}&status=${status ? status : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_ALL_LEADS, payload: response.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call for Adding New Leads in leads page on partner portal 

export const addLead = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`leads`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Lead has been added', response.status);
            return dispatch({ type: ADD_LEAD, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call for updating Leads Details in leads page on partner portal 

export const updateLead = (obj) => async (dispatch) => {

    try {
        const response = await putApiCall(`leads`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Lead has been updated', response.status);
            return dispatch({ type: EDIT_LEAD, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call for Deleting leads in leads page on partner portal 

export const deletedLead = (id) => async (dispatch) => {
    let obj = {
        _id: id
    }
    try {
        const response = await deleteApiCall(`leads`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Lead has been deleted', response.status)
            return dispatch({ type: DELETE_LEAD, payload: id });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}