import { GET_ALL_DEALS, ADD_DEAL, DELETE_DEAL } from "../Types/types";

let intialState = {
    deals: [],
    isLoading: true
}

// This function was to initially store deals details

export default function dealReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_ALL_DEALS:
            stateCopy.deals = action.payload.data;
            stateCopy.totalPages = action.payload.pages;
            stateCopy.isLoading = false;
            return stateCopy;
        case ADD_DEAL:
            stateCopy.deals = [...stateCopy.deals, action.payload];
            return stateCopy;
        case DELETE_DEAL:
            stateCopy.deals = stateCopy.deals.filter(el => el._id !== action.payload);
            return stateCopy;
        default:
            return stateCopy;
    }
}