import { Fragment } from 'react'
import ContentLoader from 'react-content-loader'
import './loader.scss'


export const BallBeat = (props) => {
    return (
        <div className="ball-loader" >
            <div className="ball-loader-ball ball1" style={{ backgroundColor: props.color }}></div>
            <div className="ball-loader-ball ball2" style={{ backgroundColor: props.color }}></div>
            <div className="ball-loader-ball ball3" style={{ backgroundColor: props.color }}></div>
        </div>
    )

}

export const FeedsLoader = () => (
    <ContentLoader
        speed={1}
        width={window.innerWidth > 767 ? 600 : 300}
        height={window.innerWidth > 767 ? 485 : 200}
        viewBox="0 0 300 300"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <circle cx="31" cy="31" r="15" />
        <rect x="58" y="18" rx="2" ry="2" width="140" height="5" />
        <rect x="58" y="34" rx="2" ry="2" width="100" height="5" />
        <rect x="0" y="60" rx="2" ry="2" width="300" height="200" />
    </ContentLoader>
)

export const TopicLoader = props => (
    <ContentLoader
        width='100%'
        height='100%'
        viewBox="0 0 1000 320"
        backgroundColor="#f5f5f5"
        foregroundColor="#dbdbdb"
        {...props}
    >
        {
            window.innerWidth > 767 ?
                <Fragment>
                    <rect x="0" y="10" rx="6" ry="6" width="1000" height="40" />
                    <rect x="0" y="60" rx="6" ry="6" width="1000" height="40" />
                    <rect x="0" y="110" rx="6" ry="6" width="1000" height="40" />
                    <rect x="0" y="160" rx="6" ry="6" width="1000" height="40" />
                    <rect x="0" y="210" rx="6" ry="6" width="1000" height="40" />
                </Fragment>
                : <Fragment>
                    <rect x="0" y="10" rx="6" ry="6" width="1000" height="85" />
                    <rect x="0" y="120" rx="6" ry="6" width="1000" height="85" />
                    <rect x="0" y="230" rx="6" ry="6" width="1000" height="85" />
                    <rect x="0" y="350" rx="6" ry="6" width="1000" height="85" />
                    <rect x="0" y="400" rx="6" ry="6" width="1000" height="85" />
                </Fragment>
        }

    </ContentLoader>
)