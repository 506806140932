/* eslint-disable no-unused-vars */
import { getApiCall, postApiCall } from '../../utils/requests';
import { returnErrors } from './errorAction';
import { GET_MENUS } from '../Types/types';


// This function was an post api call to Track login in Side bar in Partner portal

export const trackLogin = () => async (dispatch) => {
    try {
        await postApiCall(`utility/login`);

    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

// This function was an get api call to get menu items in Side bar in Partner portal

export const getMenus = () => async (dispatch) => {
    try {
        const response = await getApiCall(`utility/menu`);
        if (response.status === 200) {
            dispatch({ type: GET_MENUS, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

// This function was an post api call to handle user logout in Partner portal

export const logOutUser = () => async (dispatch) => {
    try {
        const response = await postApiCall(`users/logout`);
        if (response.status === 200) {
            return dispatch({ type: 'Logout_user', payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}