import { deleteApiCall, getApiCall, postApiCall, putApiCall } from "../../utils/requests";
import { returnErrors } from '../ActionCreators/errorAction';
import {
    RESET_LISTS, GET_ROLES, GET_SEQUERTEK_USERS, ADD_USER, CHANGE_STATUS, EDIT_USER, DELETE_USER, GET_PARTNERS, GET_COUNTRIES, ADD_PARTNER,
    GET_PARTNER_USERS
} from "../Types/types";

// This function was to reset the list of users in the users page in partner portal

export const resetLists = () => async (dispatch) => {
    return dispatch({
        type: RESET_LISTS
    })
}

// This function was a get api call to get roles of the users in users page in Partner portal

export const getRoles = () => async (dispatch) => {
    try {
        const response = await getApiCall(`utility/roles`);
        if (response.status === 200) {
            return dispatch({ type: GET_ROLES, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a get api call to get users details in users page in Partner portal

export const getSequertekUsers = (page, size, query, role, status) => async (dispatch) => {
    try {
        const response = await getApiCall(`users?size=${size}&page=${page}&search=${query}&role_id=${role ? role : ''}&status=${status ? status : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_SEQUERTEK_USERS, payload: response.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a post api call to Add new user in users page in Partner portal

export const addUser = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`users`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'User has been added', response.status);
            return dispatch({ type: ADD_USER, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const resendInvite = (id) => async (dispatch) => {
    let obj = {
        _id: id
    }
    try {
        const response = await postApiCall(`users/re-invite`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Invite has been sent', response.status);
            return dispatch({ type: 'RESEND_INVITES', payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a put api call to make status toggle changes to a user in users page in Partner portal

export const handleStatusChange = (id, checked, type) => async (dispatch) => {
    let obj = {
        _id: id
    }
    try {
        const response = type === 'sequretek' ? await putApiCall(`users/toggle`, obj) : await putApiCall(`partners/toggle`, obj);
        if (response.status === 200) {
            return dispatch({ type: CHANGE_STATUS, payload: { ...obj, checked: checked, type: type } });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a put api call to make changes in user details in users page in Partner portal

export const updateUser = (obj) => async (dispatch) => {

    try {
        const response = await putApiCall(`users/edit`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'User has been updated', response.status);
            return dispatch({ type: EDIT_USER, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a delete api call to Delete user details in users page in Partner portal

export const deletedUser = (id, type) => async (dispatch) => {
    let obj = {
        _id: id
    }
    try {
        const response = type === 'partner' ? await deleteApiCall(`partners`, obj) : await deleteApiCall(`users`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, type === 'partner' ? 'Partner has been deleted' : 'User has been deleted', response.status)
            return dispatch({ type: DELETE_USER, payload: { id, type } });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a gey api call to get partner details in users page in Partner portal

export const getPartnerList = (page, size, query, country, status) => async (dispatch) => {
    try {
        const response = await getApiCall(`partners?size=${size}&page=${page}&search=${query}&country_id=${country ? country : ''}&status=${status ? status : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_PARTNERS, payload: response.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a get api call to get Countries details in users page in Partner portal

export const getCountries = (obj) => async (dispatch) => {
    try {
        const response = await getApiCall(`utility/countries`);
        if (response.status === 200) {
            return dispatch({ type: GET_COUNTRIES, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a post api call to Add New Partner details in users page in Partner portal

export const addPartner = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`partners`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Partner has been added', response.status);
            return dispatch({ type: ADD_PARTNER, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a put api call to make changes in Partner details in users page in Partner portal

export const updatePartner = (obj) => async (dispatch) => {

    try {
        const response = await putApiCall(`partners/edit`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Partner has been updated', response.status);
            return dispatch({ type: 'EDIT_PARTNER', payload: obj });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a get api call to get Partner details in users page in Partner portal

export const getPartnerUsers = (page, size, query, role, status, partnerId) => async (dispatch) => {
    try {
        const response = await getApiCall(`partners/users?size=${size}&page=${page}&search=${query}&role_id=${role ? role : ''}&status=${status ? status : ''}&partner_id=${partnerId}`);
        if (response.status === 200) {
            return dispatch({ type: GET_PARTNER_USERS, payload: response.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a get api call to ger user export details in users page in Partner portal

export const getUserExportData = () => async (dispatch) => {
    try {
        const response = await getApiCall(`users/export`);
        if (response.status === 200) {
            return dispatch({ type: 'USER_EXPORT_DATA', payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a get api call to get partner export details in users page in Partner portal

export const getPartnerExportData = () => async (dispatch) => {
    try {
        const response = await getApiCall(`partners/export`);
        if (response.status === 200) {
            return dispatch({ type: 'PARTNER_EXPORT_DATA', payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a get api call to get partner's user export details in users page in Partner portal

export const getPartnerUserExportData = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`partners/users/export?partner_id=${id}`);
        if (response.status === 200) {
            return dispatch({ type: 'PARTNER_USER_EXPORT_DATA', payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response?.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

