// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';




export default function Blogs18() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek Blog-Technical Analysis of DarkBit Ransomware</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Technical Analysis of DarkBit Ransomware</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Technical Analysis of DarkBit Ransomware</h3>
                        <p>20-MAR-2023 | Sanket Wagh </p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample62.jpg' width={'100%'} height={318} alt='' />
                            <h4>Overview</h4>
                            <p>Israel’s leading academic college Technion- Israel Institute of Technology has been hit with a new ransomware attack, dubbed by Blackberry Research & Intelligence Team as “Darkbit”.</p>
                            <br />
                            <p>The threat actor behind this Golang-compiled ransomware appears to have geopolitical reasons; the ransom message contains anti-Israeli and anti-government sentiments, as well as references to the current rash of layoffs in the IT industry.</p>
                            <p>Command-line parameters and data encryption optimization for big files are supported by the primary portable executable (PE) module.</p>
                            <p>Upon execution, activity of the malware is as follows: –</p>
                            <ul>
                                <li><p>Creates a global mutex</p></li>
                                <li><p>Removes volume shadow copies</p></li>
                                <li><p>Encrypts several files by utilizing multithreading</p></li>
                                <li><p>Adds extension to encrypted files “*. Darkbit”</p></li>
                            </ul>
                            <h4>File Details</h4>
                            <Table responsive>
                                <tr>
                                    <th><p>MD5</p></th>
                                    <th><p>9880fae6551d1e9ee921f39751a6f3c0</p></th>
                                </tr>
                                <tr>
                                    <th><p>SHA-1</p></th>
                                    <th><p>30466ccd4ec7bcafb370510855da2cd631f74b7a</p></th>
                                </tr>
                                <tr>
                                    <th><p>SHA-256</p></th>
                                    <th><p>9107be160f7b639d68fe3670de58ed254d81de6aec9a41ad58d91aa814a247ff</p></th>
                                </tr>
                                <tr>
                                    <th><p>File type</p></th>
                                    <th><p>Win32 EXE</p></th>
                                </tr>
                                <tr>
                                    <th><p>Target Machine</p></th>
                                    <th><p>x64</p></th>
                                </tr>
                                <tr>
                                    <th><p>File size</p></th>
                                    <th><p>5.14 MB (5385216 bytes)</p></th>
                                </tr>
                                <tr>
                                    <th><p>Compiler</p></th>
                                    <th><p>Golang (MinGW (GCC: (tdm64-1) 4.9.2))</p></th>
                                </tr>
                                <tr>
                                    <th><p>Signature</p></th>
                                    <th><p>Not signed</p></th>
                                </tr>
                            </Table>
                            <h4>Static Analysis</h4>
                            <p>Sequretek Malware Analysis Team analysed a sample of the malware to determine the infection methodology.</p>
                            <p>We discovered that the malware excludes certain files and file types that are hard coded.  DarkBit specifies the following exclusions: –</p>
                            <ul>
                                <li><p>File extensions (Figure1)</p></li>
                                <li><p>File names and directories (Figure2)</p></li>
                                <li><p>Size Limit Configuration (Figure3)</p></li>
                            </ul>
                            <p><u>File Extensions</u></p>
                            <p>The ransomware excludes specific file extensions: –</p>
                            <img src='/images/blog-images/sample158.png' alt='' width={'100%'} />
                            <p>Figure1: – Excluded file extensions</p>
                            <p><u>File Names and Directories</u></p>
                            <p>The ransomware also excludes specific filenames and directories: –</p>
                            <img src='/images/blog-images/sample159.png' alt='' width={'100%'} />
                            <p>Figure2: – Excluded file names and directories</p>
                            <p><u>File Size Limit Configuration</u></p>
                            <p>To limit the size of the encrypted files, DarkBit divides larger files into segments as per the size limit given in the configuration file. It means that files between 25 MB and 1000 MB will be divided into two parts, these two parts will have a maximum size of 12000 bytes. (Figure 3)</p>
                            <img src='/images/blog-images/sample160.png' alt='' width={'100%'} />
                            <p>Figure3: – File Size Limit Configuration</p>
                            <h4>Dynamic Analysis</h4>
                            <p>We executed the payload in our sandbox environment to observe its behaviour.  We observed the following activities: –</p>
                            <ul>
                                <li><p>DarkBit created a Global Mutex value with the name “Global//dbdbdbdb” by calling the CreateMutexW API.</p></li>
                                <img src='/images/blog-images/sample161.png' alt='' width={'100%'} />
                                <p>Figure 4: –Create Mutex</p>
                                <li><p>DarkBit Ransomware uses multithreading encryption process sequence, using several APIs such as</p></li>
                                <ul>
                                    <li><p>NtDeviceIoControlFile</p></li>
                                    <li><p>GetQueuedCompletionStatusEx</p></li>
                                    <li><p>PostQueuedCompletionStatus</p></li>
                                    <li><p>ResumeThread</p></li>
                                </ul>
                                <li><p>DarkBit’s multithreading process uses worker threads to encrypt files on the victim’s machine. A worker thread is commonly used to handle background tasks so that the user should not have to wait for to continue using your application.</p></li>
                                <img src='/images/blog-images/sample162.png' alt='' width={'100%'} />
                                <p>Figure: Two worker multithreading</p>
                            </ul>
                            <p>The following steps are carried by worker threads at execution time: –</p>
                            <ul>
                                <li><h4>Step 1</h4></li>
                                <p>Collect Drive Information.  To determine the drive type of each drive on the victim’s machine, the ransomware calls the GetLogicalDrives and GetDriveType APIs.</p>
                                <img src='/images/blog-images/sample163.png' alt='' width={'100%'} />
                                <p>Figure6: – API calls for drive & drive type information</p>
                                <li><h4>Step2</h4></li>
                                <p>Delete Volume Shadow Copy of a victim machine to prevent system recovery.  Ransomware uses the CreateProcessW API to execute vssadmin.exe and delete shadow copies.</p>
                                <img src='/images/blog-images/sample164.png' alt='' width={'100%'} />
                                <p>Figure 7:–Delete backup shadow copies using vssadmin.exe</p>
                                <li><h4>Step 3</h4></li>
                                <p>The payload encrypts each data file individually.  Post encryption process, it modifies all encrypted files’ extension with “. Darkbit”.</p>
                                <img src='/images/blog-images/sample165.png' alt='' width={'100%'} />
                                <p>Figure8: – Encrypted files and Ransom Note</p>
                                <br />
                            </ul>
                            <p>The ransomware also creates a text file (.txt) on the victim system during the encryption process. The text file “RECOVERY_DARKBIT.txt” contains instructions for decryption.</p>
                            <p>DarkBit provides instructions on how the victims can obtain their ransom payments in its ransom note.  According to the DarkBit ransom note, the victim is offered “support” by the company to recover the encrypted files. Ransom notes also included a link to a TOR website where victims can use their TOX IDs (redacted) to communicate with the attackers and attempt to retrieve their data.</p>
                            <h4><u>Ransom Warning</u></h4>
                            <p>As is usual with other ransomware incidents, DakBit’s ransom note also indicates that the victim’s refusal to comply with their demands will result in an additional 30% penalty to the already large ransom demanded.  Furthermore, the attackers explicitly state their intention to sell any data that they have stolen after a period of five days if the ransom is not paid.</p>
                            <img src='/images/blog-images/sample167.png' alt='' width={'100%'} />
                            <p>Figure 9: – DarkBit ransom note</p>
                            <br />
                            <p>In addition to encrypting files, the ransomware includes a marker “DARKBIT_ENCRYPTED_FILES” and encryption details, such as the encryption key at the end of the encrypted file.</p>
                            <img src='/images/blog-images/sample168.png' alt='' width={'100%'} />
                            <p>Figure 10: – Marker added at the end of the encrypted file</p>
                            <br />
                            <h4><u>Detection Indicators</u></h4>
                            <ul>
                                <li><p>Ransomware payload includes TOX ID and Tor browser onion link to communicate with the attackers.</p></li>
                                <li><p>The payload encrypted system files and created a ransom note for payment instructions.</p></li>
                            </ul>
                            <h4><u>Best Practices for Risk Reduction against Ransomware Attacks</u></h4>
                            <ul>
                                <li><p><strong>Backup your data:</strong> Ensure that your organization’s data is regularly backed up and the backups are stored in a separate location. This will help you recover your data if you fall victim to any ransomware attack.</p></li>
                                <li><p><strong>Keep software up to date:</strong>Ensure that your organization’s operating systems, software applications and other devices are updated with the latest security patches and updates. Regularly updating your software will help prevent known vulnerabilities from being exploited.</p></li>
                                <li><p><strong>Implement a strong password policy at your organization:</strong>Ensure that your employees use strong passwords and two-factor authentication to access organization’s systems and data. Weak passwords can be easily targeted by ransomware attackers.</p></li>
                                <li><p><strong>Regularly train employees on security best practices:</strong> Regularly train employees on security best practices:</p></li>
                                <li><p><strong>Limit access:</strong></p> Restrict access to sensitive organization’s data to only those who need it. This will help prevent ransomware from spreading through your organization’s network.</li>
                                <li><p><strong>Implement network segmentation:</strong> Segment your organization’s network into smaller subnetworks to prevent the spread of ransomware in the event of an attack.</p></li>
                                <li><p><strong>Use security tools:</strong> Implement security tools such as firewalls, antivirus / EDR, intrusion detection and prevention systems, identity governance and administration (IGA) and security monitoring / extended detection & response (XDR) solutions to monitor, protect, respond and remediate against malicious activities / cyber incidents.</p></li>
                                <li><p><strong>Have a plan:</strong> Develop a plan for responding to ransomware attacks. This should include identifying key personnel and responsibilities, having a backup and recovery plan, and having a communication plan in place to inform stakeholders of the situation.</p></li>
                            </ul>
                            <p>Reach out on info@sequretek.com / to know how we can help your organization defend against advanced and unknown cyber attacks through our cutting-edge cybersecurity & cloud security products as well as services.</p>
                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'Sequretek Blog-Technical Analysis of DarkBit Ransomware' , url: 'https://www.sequretek.com/resources/blog/technical-analysis-of-darkbit-ransomware'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
