import { postApiCall } from "../../utils/requests";
import { returnErrors } from "./errorAction";

// This function was an api post for the Contact us form submittion in website

export const submitContactus = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`external/form/contact-us`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, `Thanks for getting in touch. We'll come back to you shortly`, response.status);
            return dispatch({ type: "CONTACTUS_SUBMIT" });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, 'Something went wrong try after sometime', error?.response?.status)
    }
}

// This function was an api post for the Experiencing a Breach form submittion in website

export const submitBreachForm = (obj) => async (dispatch) => {

    try {
        const response = await postApiCall(`external/form/breach`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, `Thanks for getting in touch. We'll come back to you shortly`, response.status);
            return dispatch({ type: "BREACH_SUBMIT" });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, 'Something went wrong try after sometime', error?.response?.status)
    }
}

// This function was an api post for the Schedule a Demo form submittion in website

export const submitScheduleDemoForm = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`external/form/demo`, obj);
        if (response.status === 200) {
            // returnErrors(dispatch, `Thanks for getting in touch. We'll come back to you shortly`, response.status);
            return dispatch({ type: "DEMO_SUBMIT" });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, 'Something went wrong try after sometime', error?.response?.status)
    }
}

// This function was an api post for the Email Subscribe form submittion in website

export const emailSubscribe = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`external/form/subscribe`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Form has been submitted.', response.status);
            return dispatch({ type: "SUBSCRIBE" });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, 'Something went wrong try after sometime', error?.response?.status)
    }
}