// ############################ Require packages here ############################
import React, { Fragment, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FileUploader } from 'react-drag-drop-files';


// ############################ Require files here ###############################

import MultiSelect from '../../../utils/customSelect';
import { Spinner } from 'react-bootstrap';


export default function AddTicket({ setIsAdd, supportDetails, categories, handleSubmit }) {
    const fileTypes = ["JPEG", "PNG", "PDF"];
    const [err, setErr] = useState('');

    const validationSchema = Yup.object({
        category: Yup.string().required('Category is required'),
        question: Yup.string()
            .trim('Remove extra space')
            .strict(true)
            .matches(/^[a-zA-Z0-9\s,'.]+$/, "Question should only contain alphabets, numbers, spaces, commas, and apostrophes")
            .required('Question is required'),
        attachment: Yup.mixed()
            .notRequired()
            .test('fileSize', 'File size too large', (value) => {
                if (typeof value !== 'object') {
                    return true
                }
                else if (value) {
                    return value.size <= 2097152; // 2 MB
                }
                return true;
            })
    });

    const onSizeError = (err) => {
        setErr(err);
    }


    return (
        <Fragment>
            {/* ADD TICKET FORM */}
            <div className='add-screen'  >
                <Formik initialValues={supportDetails} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ setFieldValue, values, isSubmitting }) => (
                        <Form className="users_form">
                            <div>
                                <h2 className="d-flex align-items-center justify-content-between">
                                    Add Ticket
                                    <div className='leads-btn'>
                                        <button onClick={() => setIsAdd(false)}>Cancel </button>
                                        <button type='submit' style={{ width: isSubmitting ? 109 : '' }} disabled={isSubmitting} >{isSubmitting ? <Spinner animation="border" variant="light" /> : 'Save Ticket'}</button>
                                    </div>
                                </h2>
                            </div>
                            <div className='row mt-2' style={{ width: '70%' }}>
                                <div className='col-md-6'>
                                    <div className='form-group mt-2'>
                                        <label>Category</label>
                                        <div className="has-search">
                                            <span className="form-control-feedback">
                                                <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                            </span>
                                            <Field
                                                name="category"
                                                id="singleSelectCustom"
                                                placeholder="Select Category"
                                                isMulti={false}
                                                component={MultiSelect}
                                                options={categories}
                                            />
                                            <ErrorMessage name="category" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 col-12'>
                                    <div className='form-group mt-2'>
                                        <label>Question</label>
                                        <Field
                                            as="textarea"
                                            type='text'
                                            name="question"
                                            placeholder="Enter Question"
                                            className='form-control'
                                            rows='4'
                                        />
                                        <ErrorMessage name="question" component="div" className="error-message" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 mt-2">
                                    <div className='form-group'>
                                        <div className="form-check form-check-inline">
                                            <Field
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkbox"
                                                name='doc_shared'
                                            // value={false}

                                            />
                                            <label className="form-check-label" htmlFor="checkbox">
                                                I wish to share a screenshot/document related to the question
                                            </label>
                                        </div>
                                    </div>
                                    {values?.doc_shared ?
                                        <Fragment>
                                            In case of any support related documents, please email them to <strong> partnersupport@sequretek.com</strong>
                                        </Fragment>
                                        : null}
                                </div>
                                {/* <div className='col-md-12 col-12'>
                                    <div className='form-group mt-2'>
                                        <label>Upload Attachment</label>
                                        <br /><br />
                                        <div>
                                            <FileUploader
                                                multiple={false}
                                                name="attachment"
                                                types={fileTypes}
                                                label={'Upload a file or Drag and Drop file'}
                                                maxSize={2}
                                                handleChange={(file) => {
                                                    setFieldValue("attachment", file);
                                                    setErr('')
                                                }}
                                                onSizeError={onSizeError}
                                            />
                                            <p>{values?.attachment ? `File name: ${values?.attachment?.name}` : "No file uploaded yet"}</p>
                                            <span style={{ color: 'red' }}>{err}</span>
                                            <ErrorMessage name="attachment" component="div" className="error-message" />
                                        </div>

                                    </div>
                                </div> */}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div >
        </Fragment>
    )
}
