// ############################ Require packages here ############################
import React, { lazy, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
// import TestimonialSlider from '../testimonialSlider';
import RelatedResources from '../relatedResources';
import { getOneResource } from '../../../Redux/ActionCreators/resourcesAction';
const TestimonialSlider = lazy(() => import("../testimonialSlider"));



const challenges = [{ image: '/images/house-lock-color.svg', title: 'Infrastructure & Technology', description: 'Complex IT infrastructure (on-premises and cloud) exposes IT/ITES firms to a vast attack surface and potential disruptions.' },
{ image: '/images/information.svg', title: 'Data Handling', description: `Vast amounts of sensitive data (customer information and proprietary business data) make IT/ITES firms attractive to cybercriminals.` },
{ image: '/images/cloud.svg', title: 'Complex Supply Chains', description: 'Interconnected supply chains mean sharing a network with potentially vulnerable vendors and partners, increasing the chances for attacks.' },
{ image: '/images/person.svg', title: 'Insider Threats', description: `Employees and contractors with access to critical systems pose a risk of intentional or unintentional security breaches.` },
{ image: '/images/user-key-blue.svg', title: 'Access Control Management', description: 'A large resource base and increased user churn pose stale and dormant access challenges, increasing security threats and compliance violations.' },
{ image: '/images/team-check.svg', title: 'Content Collaboration', description: `Increased licensing, content creation outsourcing, and social media usage expose IT/ITES firms to information security risks.` },
{ image: '/images/paper-sheet.svg', title: 'Intellectual Property (IP) Theft', description: `Inadequate real-time protection, exfiltration detection, and incident response allow unethical competitors to steal your IP and bypass R&D costs.` },
{ image: '/images/globe.svg', title: 'Compliance Conundrum', description: `Non-compliance with global industry regulations and data protection laws can trigger legal and financial repercussions.` },
{ image: '/images/laptop-mobile.svg', title: 'Hybrid Work', description: `Hybrid models introduce personal devices, unsecure Wi-Fi, weak cloud service accesses, and open communication channels, creating security risks.` },
]

const helpList = [{ image: '/images/pulse.svg', title: 'Enhanced Security Monitoring', description: `Sequretek's Percept Continuous Threat Exposure Management (PCTEM) continuously monitors IT infrastructure for threats, and mitigates potential breaches.` },
{ image: '/images/alert.svg', title: 'Threat Detection and Response', description: 'Advanced cyber risk management with our EDR, XDR & NG SIEM products and services helps monitor, detect, and respond to cyberattacks in real time. ' },
{ image: '/images/user-key.svg', title: 'User Access Management', description: 'Our identity products and services prevent data breaches by continuously monitoring user activity and limiting access to authorized personnel only.' },
{ image: '/images/shield.svg', title: 'Conformance to Compliance Mandates', description: 'Regulations like FTC, SEC, Cert-In, DPDPA, etc. ensnare the corporate sector. Sequretek untangles the complexity with compliance reporting.' },
{ image: '/images/house-lock.svg', title: 'Vulnerability Management', description: 'Sequretek strengthens defenses, fixes vulnerabilities, and ensures system stability with regular audits, patching, and critical infrastructure update deployment.' },
{ image: '/images/customer-care-white.svg', title: 'Managed Security Services', description: `Sequretek MSS offers 24/7 onsite/remote services, including audit and advisory, risk and governance, incident response, server, network and perimeter security.` },
]




export default function Technology() {

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setPage = (val) => {
        window.scrollTo(0, 0);
        if (val === 'contact') {
            window.scrollTo(0, 5300);
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }

    const downloadFactsheet = async () => {
        const res = await dispatch(getOneResource(['66dad1f71e5c52d1577f91ae']));
        if (res?.type === 'GET_ONE_RESOURCES') {
            navigate(`/resources/factsheet/${encodeURIComponent(res.payload?.title)}`, { state: { data: res?.payload } })
        }
    }

    return (
        <div className='seq-content-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sequretek | Tackle cyber threats in IT</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Protect your IT environment with Sequretek's expert  IT Cybersecurity Solutions. Our comprehensive solutions safeguard your data and systems from cyber threats." />
                <meta name="keywords" content="Cloud security in IT, IT Cybersecurity Solutions, cyber threats in IT" />
                <meta property="og:title" content="Sequretek | Tackle cyber threats in IT" />
                <meta property="og:description" content="Protect your IT environment with Sequretek's expert  IT Cybersecurity Solutions. Our comprehensive solutions safeguard your data and systems from cyber threats." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-content-heading' style={{ backgroundImage: `linear-gradient(4.09deg, rgba(6, 6, 93, 0.7) 4%, rgba(4, 12, 48, 0.9) 18.36%, rgba(4, 12, 48, 0.9) 55.89%, rgba(6, 6, 93, 0.7) 96.66%),url('/images/services-bgimg.png')` }}>
                <div className='seq-description seq-des-service'>
                    <h5 data-aos="fade-up">Industries {'>'} Technology </h5>
                    <h2 data-aos="fade-up">Patch Up Weaknesses, Lock Out Threats, Code Your Successes</h2>
                    <div data-aos="fade-up" className='text-center'>
                        <button onClick={() => downloadFactsheet()}>Download Factsheet</button>
                    </div>
                </div>
            </div>
            {/* section 2 INFO */}
            <div className='seq-content-1'>
                <div className='container pe-0'>
                    <div className='row me-0'>
                        <div className='col-md-5 col-sm-12 col-12'>
                            <h4>The IT Security Tightrope: Exponential Growth Meets Evolving Threats</h4>
                        </div>
                        <div className='col-md-6 col-sm-12 offset-lg-1 col-12 seq-info-text'>&nbsp;
                            <p>The technology industry transforms businesses and our world. But the boom comes at a cost: cyberthreats in IT. Rich data in IT firms attracts cybercriminals who threaten data safety, operations, and access to critical systems. From phishing scams to malware infiltration and denial-of-service attacks, cybercriminals have a vast arsenal. Robust IT security measures are the only defense.</p><br />
                            <p>Particularly for maintaining the integrity of unique client systems on the cloud, tailored cloud security in IT is an absolute essential.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 3 COMPANIES */}
            <div className='seq-content-2' data-aos="fade-up" style={{ backgroundImage: `linear-gradient(225.34deg, #040C30 21.83%, rgba(4, 12, 48, 0.7) 41.46%, #050D31 56.88%),url('/images/bg-img2.png')` }}>
                <div className='container'>
                    <h3>The Compliance Imperative:<br />
                        <p> Regulations governing IT/ITES companies</p>
                    </h3>
                    {window.innerWidth > 767 ?
                        <div className='seq-table-content'>
                            <Table responsive>
                                <thead>
                                    <th><img src='/images/usa-flag.svg' alt='' loading="lazy" />&nbsp;USA</th>
                                    <th><img src='/images/ind-flag.svg' alt='' loading="lazy" />&nbsp;India</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Regulators and Acts: Federal Trade Commission (FTC), Securities and Exchange Commission (SEC), Department of Health and Human Services (HHS)</td>
                                        <td>Regulators and Acts: The Indian Computer Emergency Response Team (CERT-In), The Information Technology Act, 2000 (IT Act), the Securities and Exchange Board of India (SEBI), Digital Personal Data Protection Act, 2023.</td>
                                    </tr>
                                    <tr>
                                        <td>Penalties: Heavy fines, reputational damage, even civil lawsuits. </td>
                                        <td>Penalties: Imprisonment, fines, and directives for corrective measures,  depending on the nature and severity of the violation.</td>
                                    </tr>
                                    <br />
                                </tbody>
                            </Table>
                        </div> :
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <img src='/images/usa-flag.svg' alt='' loading="lazy" />&nbsp;&nbsp;USA
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <ul>
                                            <li style={{ marginTop: '20px', marginBottom: '20px' }}><span className='ms-3'>Regulators and Acts: Federal Trade Commission (FTC), Securities and Exchange Commission (SEC), Department of Health and Human Services (HHS)</span></li>
                                            <li style={{ marginTop: '20px', marginBottom: '20px' }}><span className='ms-3'>Penalties: Heavy fines, reputational damage, even civil lawsuits. </span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <img src='/images/ind-flag.svg' alt='' loading="lazy" />&nbsp;&nbsp;India
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <ul>
                                            <li style={{ marginTop: '20px', marginBottom: '20px' }}><span className='ms-3'>Regulators and Acts: The Indian Computer Emergency Response Team (CERT-In), The Information Technology Act, 2000 (IT Act), the Securities and Exchange Board of India (SEBI), Digital Personal Data Protection Act, 2023.</span></li>
                                            <li style={{ marginTop: '20px', marginBottom: '20px' }}><span className='ms-3'>Penalties: Imprisonment, fines, and directives for corrective measures,  depending on the nature and severity of the violation.</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {/* section 4 METRICS */}
            <div className='seq-content-2security' data-aos="fade-up" style={{ backgroundImage: `linear-gradient(169.17deg, #040C30 32.48%, rgba(4, 12, 48, 0.9) 56.94%, #050D31 76.15%)` }}>
                <div className='container px-0'>
                    <div className='seq-cybersecurity '>
                        <div className='seq-cybersecurity-head'>
                            <h4>Services Cybersecurity Insights</h4>
                        </div>
                        <div className='row seq-cybersecurity-body'>
                            <div className='col-md-3 col-12 seq-cybersecurity-content'>
                                <img src='/images/folder-tree.svg' alt='' style={{ width: "48px", height: "48px" }} loading="lazy" />
                                <div>
                                    <h5 style={{ color: "#FFFFFF" }}>4-5 million</h5>
                                    <p style={{ color: "#B6BCBF" }}>Records Breached Monthly</p>
                                    <p style={{ color: "#B6BCBF", fontWeight: '200', fontSize: '12' }}>in 2023</p>
                                </div>
                            </div>
                            <div className='col-md-3 col-12 seq-cybersecurity-content'>
                                <img src='/images/usd-circle.svg' alt='' style={{ width: "48px", height: "48px" }} loading="lazy" />
                                <div>
                                    <h5 style={{ color: "#FFFFFF" }}>$4.66 million</h5>
                                    <p style={{ color: "#B6BCBF" }}>Average Data Breach Cost</p>
                                    <p style={{ color: "#B6BCBF", fontWeight: '200', fontSize: '12' }}>in 2023</p>
                                </div>
                            </div>
                            <div className='col-md-3 col-12 seq-cybersecurity-content'>
                                <img src='/images/user.svg' alt='' style={{ width: "48px", height: "48px" }} loading="lazy" />
                                <div>
                                    <h5 style={{ color: "#FFFFFF" }}>155 million</h5>
                                    <p style={{ color: "#B6BCBF" }}>Compromised Sensitive Records</p>
                                    <p style={{ color: "#B6BCBF", fontWeight: '200', fontSize: '12' }}>in USA, 2020</p>
                                </div>
                            </div>
                            <div className='col-md-3 col-12 seq-cybersecurity-content'>
                                <img src='/images/paper-sheet.svg' alt='' style={{ width: "48px", height: "48px" }} loading="lazy" />
                                <div>
                                    <h5 style={{ color: "#FFFFFF" }}>Over 1,000</h5>
                                    <p style={{ color: "#B6BCBF" }}>Data Breaches in USA</p>
                                    <p style={{ color: "#B6BCBF", fontWeight: '200', fontSize: '12' }}>in 2020</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 5 CHALLENGES */}
            <div className='seq-challenge'>
                <p>Challenges faced</p>
                <h3>Security Chills
                    <br />
                    <span style={{ color: '#B6BCBF' }}>Top Cybersecurity Risks in IT/ITES</span> </h3>
                <div className='container pe-0'>
                    {
                        window.innerWidth > 767 ?
                            <div className='row me-0 seq-card-items-row'>
                                {
                                    challenges?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-card-items' style={{ flexGrow: 'unset' }}>
                                                <div className='seq-card' style={{ height: 330 }}>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <p>{el.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className='d-flex overflow-auto flex-nowrap'>
                                {
                                    challenges?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='seq-card'>
                                                <img src={el.image} alt='' loading="lazy" />
                                                <h4>{el.title}</h4>
                                                <p>{el.description}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
            {/* section 6 HOW SEQURETEK HELPS */}
            <div className='seq-how-to-helps'>
                <div className='container'>
                    <div className='seq-help-bg' style={{ backgroundImage: `url('/images/bg-img65.svg')` }}>
                        <div className='row '>
                            <div data-aos="fade-up" className=' col-md-5 col-12 seq-help-left'>
                                <p>How Sequretek Helps</p>
                                <h3>Advanced Protection From Today's Threats: Sequretek’s Security Has You Covered</h3>
                                <button onClick={() => setPage('contact')}>Get started</button>
                            </div>
                            {window.innerWidth > 767 ?
                                <div className='col-md-4 offset-md-1 seq-ind-qimg'>
                                    <img src='/images/q-icon1.svg' alt='' height={300} loading="lazy" />
                                </div> :
                                null
                            }
                        </div>
                    </div>
                    <div className='seq-help-list'>
                        <div className='row'>
                            {
                                helpList?.map(el => {
                                    return (
                                        <div data-aos="fade-up" className='col-md-4 col-sm-12'>
                                            {window.innerWidth > 767 ?
                                                <div className='seq-card'>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div> :
                                                <div className='seq-card'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex justify-content-center align-items-center'><img src={el.image} alt='' loading="lazy" /></div>
                                                        <h4>{el.title}</h4>
                                                    </div>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* section 7 TESTIMONIAL SLIDER */}
            <div className="seq-quotes">
                <div className='gredient'> </div>
                <TestimonialSlider
                    page={'technology'}
                />
            </div>
            {/* section 8 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container ps-4 pe-4 '>
                    <div className="insurance-partseven" data-aos="fade-up">
                        <ScheduleForm
                            text={'The security of your data and infrastructure is more critical than ever. Secure your sensitive data with Sequretek’s enhanced security monitoring and threat detection and ensure that your critical information remains confidential and protected.'}
                            bg={'/images/bg-img66.svg'}
                            mobilebg={'/images/bg-img67.svg'}
                            page='banking'
                        />
                    </div>
                </div>
            </div>
            {/* section 9 RELATED RESOURCES */}
            <div className='seq-resources'>
                <div className='container'>
                    <h4>Related resources</h4>
                    <div className='row ms-0 me-0'>
                        <div className='col-md-12 ps-0'>
                            <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto'}>
                                <RelatedResources
                                    page='services'
                                    type=''
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Resource Center</button></div>}
        </div >
    )
}
