// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';


export default function Blogs12() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek Blog-Analysis of Malicious Android App Targeting Financial Information</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Analysis of Malicious Android App Targeting Financial Information</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Analysis of Malicious Android App Targeting Financial Information</h3>
                        <p>17-AUG-2023 | Sanket Wagh </p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample62.jpg' width={'100%'} height={318} alt='' />
                            <h4>Overview</h4>
                            <p>Sequretek Malware Analysis Team recently came across an Android app forwarded by one of our global customers.  On analysis, we determined that this is a malicious app masquerading as Android Update Setting app and targets European banking users. Its method of distribution is via SMS message which contains a phishing link, and this link requires the user to download an app displayed on the link. After downloading and installing the app records filled in financial details like bank account number, mobile number, username, password, etc. as well as audio conversations.  These details are then sent to a server in plain text.</p>
                            <h4>Technical Analysis</h4>
                            <p>The MD5 of the app that is analyzed in this post is 9e185dd6d7137357b61941525e935124 and its package name is ‘glasgow.pl.dimensional’.</p>
                            <Table responsive>
                                <tr>
                                    <th><p>MD5</p></th>
                                    <th><p>9e185dd6d7137357b61941525e935124</p></th>
                                </tr>
                                <tr>
                                    <th><p>SHA-1</p></th>
                                    <th><p>9a029b5769f031bd69bd4f17dbe87c066ba106de</p></th>
                                </tr>
                                <tr>
                                    <th><p>SHA-256</p></th>
                                    <th><p>bad77dca600dc7569db4de97806a66fa969b55b77c24e3a7eb2c49e009c1f216</p></th>
                                </tr>
                                <tr>
                                    <th><p>File Type</p></th>
                                    <th><p>APK</p></th>
                                </tr>
                                <tr>
                                    <th><p>Target SDK Required</p></th>
                                    <th><p>29</p></th>
                                </tr>
                                <tr>
                                    <th><p>File Size</p></th>
                                    <th><p>3.93 MB (4122533 bytes)</p></th>
                                </tr>
                                <tr>
                                    <th><p>App Name</p></th>
                                    <th><p>CERTIFCATO</p></th>
                                </tr>
                                <tr>
                                    <th><p>Package Name</p></th>
                                    <th><p>glasgow.pl.dimensional</p></th>
                                </tr>
                                <tr>
                                    <th><p>App Icon</p></th>
                                    <th><p><img src='/images/blog-images/sample97.png' alt='' /></p></th>
                                </tr>
                            </Table>
                            <h4>Permissions used by the App</h4>
                            <p>In this app some sensitive permissions are used – READ_SMS, SEND_SMS, READ_CALL_LOG, RECORD_AUDIO, REQUEST_INSTALL_PACKAGES, READ_CONTACTS, etc.</p>
                            <img src='/images/blog-images/sample99.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 1: Permissions used</p>
                            <br />
                            <br />
                            <p>Main class is declared in the manifest file “glasgow.pl.MainActive” which loads at runtime (Figure 2).</p>
                            <img src='/images/blog-images/sample100.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 2: Main Activity Class</p>
                            <br />
                            <p>It abuses the Accessibility services granted by the victim at the time of installation, it is used to enable sensitive permissions, so that the app can record victim’s keystrokes (Figure 3).</p>
                            <img src='/images/blog-images/sample101.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 3: Accessibility service</p>
                            <br />
                            <p>It enables accessibility service in code (Figure 4).</p>
                            <img src='/images/blog-images/sample102.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 4: Enabled accessibility service</p>
                            <br />
                            <p>It creates a directory “Config/sys/apps/log “, within the devices’ external storage and the logs are saved to the document “log-yyyy-mm-dd.log”. This format “yyyy-mm-dd” is used to save date when the keystrokes were captured in the created directory (Figure 5).</p>
                            <br />
                            <img src='/images/blog-images/sample103.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 5: Creating Log files to capture keystrokes</p>
                            <br />
                            <p>It creates a directory “/Config/sys/apps/rc“, within the device’s external storage and the recording are saved to the audio file “log-yyyy-mm-dd.wvc”. This format “yyyy-mm-dd” is used to save the date when the audio conversation was captured in the created directory (Figure 6).</p>
                            <img src='/images/blog-images/sample104.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 6: Creating Audio files to capture recording  </p>
                            <br />
                            <p>C2 connection encoded with Base64 (Figure 7).</p>
                            <br />
                            <img src='/images/blog-images/sample105.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 7: Encoded C2 with Base 64 technique</p>
                            <br />
                            <h4>IOCs:</h4>
                            <p>MD5 Hash:</p>
                            <ul>
                                <li><p>9e185dd6d7137357b61941525e935124</p></li>
                                <li><p>291c24d9b3f4a5793a2600610671eb42</p></li>
                            </ul>
                            <p>C2 Server:</p>
                            <ul>
                                <li><p>37[.]120[.]141[.]140:7775</p></li>
                                <li><p>37[.]120[.]141[.]144:7771</p></li>
                            </ul>
                            <br />
                            <h4>MITRE ATT&CK Tactics and Techniques</h4>
                            <br />
                            <h4>Command and Control TA0011</h4>
                            <ul>
                                <li><p>Application Layer Protocol – T1071</p></li>
                                <li><p>Non-Standard Port – T1571</p></li>
                                <li><p>Encrypted Channel – T1573</p></li>
                            </ul>
                            <h4>Defense Evasion TA0030</h4>
                            <ul>
                                <li><p>Obfuscated Files or Information – T1406</p></li>
                            </ul>
                            <h4>Discovery TA0032</h4>
                            <ul>
                                <li><p>System Network Connections Discovery – T1421</p></li>
                                <li><p>Location Tracking – T1430</p></li>
                            </ul>
                            <h4>Impact TA0034</h4>
                            <ul>
                                <li><p>Carrier Billing Fraud – T1448</p></li>
                            </ul>
                            <h4>Collection TA0035</h4>
                            <ul>
                                <li><p>Audio Capture – T1429</p></li>
                                <li><p>Location Tracking – T1430</p></li>
                                <li><p>Access Call Log – T1433</p></li>
                                <li><p>Screen Capture – T1513</p></li>
                            </ul>
                            <h4>Network Effects TA0038</h4>
                            <ul>
                                <li><p>Exploit SS7 to Redirect Phone Calls/SMS – T1449</p></li>
                            </ul>
                            <h4>Conclusion</h4>
                            <p>This is a fake app related to “Android update setting” targets European financial bank. The app steals vital information from the users like credit card information, or other essential data. It also captures keystrokes, audio conversations, save these “text”, “wvc” format, and then send the information to its C2 server.</p>
                            <h4>Recommendations to Prevent Infection</h4>
                            <ul>
                                <li><p>Check if the SMS messages and links are received from a legitimate source.</p></li>
                                <li><p>Install an antivirus to be alerted of fake apps.</p></li>
                                <li><p>Install applications from known source only, like Google Play Store.</p></li>
                            </ul>

                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'Sequretek Blog-Analysis of Malicious Android App Targeting Financial Information<'  , url: 'https://www.sequretek.com/resources/blog/analysis-of-malicious-android-app-targeting-financial-information'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
