// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';

// ############################ Require files here ###############################

import './resources.scss';
import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';

export default function Blogs6() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>VPNs Under Siege: Cyberattacks Exploiting Vulnerabilities</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Learn how increased VPN vulnerabilities are putting your business at risk. Discover essential steps to secure your VPN infrastructure and protect sensitive data." />
                <meta name="keywords" content="VPN vulnerabilities, cyberattacks, VPN security, data protection, remote work, cyber threats" />
                <meta property="og:title" content="VPNs Under Siege: Cyberattacks Exploiting Vulnerabilities" />
                <meta property="og:description" content="Learn how increased VPN vulnerabilities are putting your business at risk. Discover essential steps to secure your VPN infrastructure and protect sensitive data." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Attackers Exploit VPN Vulnerabilities in a Frenzy</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Attackers Exploit VPN Vulnerabilities in a Frenzy</h3>
                        <p>25-JUNE-2024</p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample8.jpg' width={'100%'} height={318} alt='' loading="lazy" />
                            <h4>Attackers Exploit VPN Vulnerabilities in a Frenzy</h4>
                            <p>Public Wi-Fi risks in the mid-2000s sparked individual VPN use, and in the 2020 COVID-19 outbreak, remote work boom fueled the need for secure access, driving VPN growth. As VPN adoption soared, attackers saw their chance to step up to the plate. They weren’t fazed by this unexpected curveball – instead, they swung into action, exploiting vulnerabilities in these increasingly popular tools.</p>
                            <h4>What is VPN?</h4>
                            <p>A virtual private network (VPN) is a tool that creates a secure connection between a device and a network, or between two networks, using the public Internet. It allows users to access a private network, like an office network, securely from a remote location over the Internet.[i]</p>
                            <img src='/images/blog-images/sample12.jpg' className='mt-3' width={'100%'} height={318} alt='' loading="lazy" />
                            <p>Figure 1: VPN creating encryption tunnel for data transfer</p>
                            <h4>What are the reasons behind these attacks?</h4>
                            <p>Reports predict a rise in VPN vulnerabilities in 2024, but it’s part of a larger increase observed over the past few years. Here’s some information based on available data:</p>
                            <ul>
                                <li><p>A survey by Zscaler-ThreatLabz[ii] reveals that 56% of organizations experienced cyberattacks in the last year that took advantage of VPN vulnerabilities, a significant increase from 45% the year before.</p></li>
                                <li><p>Predictions based on this trend suggest a further 22% increase in 2024, reaching 163 vulnerabilities.</p></li>
                            </ul>
                            <p>While the reasons behind this specific trend aren’t definitively established, some general factors might contribute to increased vulnerabilities in software:</p>
                            <ul>
                                <li><p>attacker focus leading to heightened security scrutiny.</p></li>
                                <li><p>Increased popularity of VPNs makes them a bigger target.</p></li>
                                <li><p>Attackers are constantly adapting their techniques.</p></li>
                                <li><p>Zero-day exploits pose a serious threat.</p></li>
                            </ul>
                            <h4>One man’s tool is another man’s weapon</h4>
                            <p>VPNs (Virtual Private Networks) are essential tools for securing data by protecting all network traffic. However, when not properly understood and managed, they can inadvertently become perfect tools for attackers. Here’s how VPNs can become easy targets and reverse their intended purpose:</p>
                            <ul>
                                <li><p><strong>VPNs are designed to protect all network traffic.</strong> However, VPNs do not inherently differentiate between legitimate and malicious traffic. If a device connects to a network or host that is already infected, the VPN will tunnel this traffic, potentially spreading the infection throughout the network. The encrypted nature of VPN traffic can bypass security measures like firewalls, making it difficult to detect and stop malware or other threats.</p></li>
                                <li><p><strong>The level of security provided by a VPN depends on the OSI layer it operates on.</strong> TLS (Transport Layer Security) operates at layers 4 through 7 (transport layer), while IPsec (Internet Protocol Security) operates at layer 3 (network layer). The lower the layer of operation, the higher the security. For example, IPsec is generally considered more secure because it operates at a lower layer, providing more comprehensive protection.</p></li>
                                <li><p><strong>Making an informed decision on the type of VPN is crucial. </strong> Different VPN technologies offer varying levels of security and protection. Choosing the wrong type of VPN or misconfiguring it can leave your network vulnerable to attacks. It’s essential to understand the specific needs of your network and select a VPN solution that aligns with those requirements to ensure maximum protection.[iii]</p></li>
                            </ul>
                            <h4>Recent Major VPN Vulnerabilities</h4>
                            <p>A recent surge of high-severity vulnerabilities is giving attackers new avenues to exploit these critical tools. While no software is entirely immune, these VPN weaknesses pose a unique challenge for enterprises. Each vulnerability can represent a single point of failure, potentially allowing attackers to breach a VPN, establish a persistent presence within the network, steal sensitive data, and inflict significant financial losses. As new VPN CVEs continue to emerge, enterprises reliant on VPNs for remote access must prioritize robust security measures to mitigate these persistent risks. CISA has added these vulnerabilities to its Known Exploited Vulnerabilities Catalog.[iv]</p>
                            <img src='/images/blog-images/sample13.jpg' className='mt-3' width={'100%'} height={318} alt='' loading="lazy" />
                            <p>Figure 2: Timeline of VPN vulnerabilities exploited in 2024</p>
                            <h4>How Understanding Chronology Can Better Prepare Organizations</h4>
                            <p>Understanding the chronology of vulnerabilities helps organizations recognize patterns and anticipate potential future threats. By studying the timeline of past attacks, security teams can:</p>
                            <ul>
                                <li><p><strong>Identify Trends:</strong> Recognize recurring vulnerabilities and the types of exploits used.</p></li>
                                <li><p><strong>Predict Future Threats: </strong> Anticipate which components of their infrastructure might be targeted next based on historical data.</p></li>
                                <li><p><strong>Enhance Incident Response:</strong> Improve their incident response plans by understanding how previous attacks unfolded and what measures were effective in mitigating them.</p></li>
                            </ul>
                            <p>This table summarizes the key details for each vulnerability, including technical descriptions, associated attack groups, prominent victims, the dates of the attacks and the malware or exploits used:</p>
                            <Table responsive>
                                <tr>
                                    <th><p>Vendor</p></th>
                                    <th><p>CVE IDs</p></th>
                                    <th><p>Exploit Description</p></th>
                                    <th><p>Known Campaign Use</p></th>
                                    <th><p>Prominent Victims</p></th>
                                    <th><p>Malware/Exploits Used</p></th>
                                </tr>
                                <tr>
                                    <th><p>Ivanti</p></th>
                                    <th><p>CVE-2023-46805,</p><p>CVE-2024-21887</p></th>
                                    <th><p>These vulnerabilities allowed the attackers to bypass security controls and execute arbitrary commands, leading to unauthorized access to the victim’s networks. The attack involved sophisticated tactics to exploit these vulnerabilities, demonstrating targeted and active exploitation efforts by the APT5 group (Manganese).[v]</p></th>
                                    <th><p>APT5 (Manganese)</p></th>
                                    <th><p>U.S. and European defense contractor, tech firms</p></th>
                                    <th><p>Custom backdoors, web shells</p></th>
                                </tr>
                                <tr>
                                    <th><p>Citrix</p></th>
                                    <th><p>CVE-2023-6548,</p><p>CVE-2023-6549</p></th>
                                    <th><p>Code injection and buffer overflow vulnerabilities in Citrix Netscaler devices leading to remote code execution and DoS attacks.[vi]</p></th>
                                    <th><p>Unknown</p></th>
                                    <th><p>Financial institution, healthcare providers</p></th>
                                    <th><p>Mimikatz, Cobalt Strike</p></th>
                                </tr>
                                <tr>
                                    <th><p>Cisco</p></th>
                                    <th><p>CVE-2020-3259</p></th>
                                    <th><p>Exploitation of Cisco Anyconnect vulnerability (CVE-2020-3259). Attackers accessed sensitive memory contents, including usernames and passwords, through an unauthenticated, remote attack. This vulnerability allowed them to compromise multiple accounts without recent phishing campaigns or password attacks.[vii]</p></th>
                                    <th><p>Akira ransomware group</p></th>
                                    <th><p>Government agencies, critical infrastructure</p></th>
                                    <th><p>Akira ransomware</p></th>
                                </tr>
                                <tr>
                                    <th><p>Ivanti</p></th>
                                    <th><p>CVE-2024-21893</p></th>
                                    <th><p>SSRF vulnerability in the SAML component in Ivanti devices allowing crafted requests to internal servers.[viii]</p></th>
                                    <th><p>Unknown</p></th>
                                    <th><p>Financial services firms in Southeast Asia</p></th>
                                    <th><p>Unknown</p></th>
                                </tr>
                                <tr>
                                    <th><p>Fortinet</p></th>
                                    <th><p>CVE-2022-42475[ix],</p><p>CVE-2023-27997[x],</p><p>CVE-2024-21762[xi]</p></th>
                                    <th><p>Buffer overflow and out-of-bounds write vulnerabilities in SSL VPN code allowing arbitrary code execution. An unauthenticated, remote attacker can send specially crafted HTTP requests to a vulnerable device, leading to remote code execution or command execution on the device.</p></th>
                                    <th><p>APT41 (suspected nexus to China), Volt Typhoon</p></th>
                                    <th><p>Communications, Energy, Transportation Systems, and Water and Wastewater Systems in the United States.</p></th>
                                    <th><p>Cobalt Strike, PlugX</p></th>
                                </tr>
                                <tr>
                                    <th><p>Ivanti</p></th>
                                    <th><p>CVE-2024-22024[xii]</p></th>
                                    <th><p>Authentication bypass in the SAML component allowing unauthorized access to restricted resources.</p></th>
                                    <th><p>Chinese nation-state actors</p></th>
                                    <th><p>Government and enterprises</p></th>
                                    <th><p>Not specified</p></th>
                                </tr>
                                <tr>
                                    <th><p>Palo Alto Networks</p></th>
                                    <th><p>CVE-2024-3400[xiii]</p></th>
                                    <th><p>Exploiting firewall devices with custom Python backdoor (UPSTYLE), using specially crafted network requests. Testing exploitability with zero-byte files initially, followed by successful deployment of malicious payloads.</p></th>
                                    <th><p>UTA0218 (China)</p></th>
                                    <th><p>	Multiple organizations</p></th>
                                    <th><p>UPSTYLE backdoor</p></th>
                                </tr>
                                <tr>
                                    <th><p>Cisco</p></th>
                                    <th><p>CVE-2024-20353[xiv], </p><p>CVE-2024-20359[xv]</p></th>
                                    <th><p>The attack on Cisco ASA appliances was carried out by exploiting incomplete error checking in HTTP header parsing. This vulnerability led to denial-of-service (DoS) and arbitrary code execution with root privileges, allowing attackers to take full control of the affected systems. The Sandworm group leveraged this vulnerability to disrupt operations and execute malicious payloads such as NotPetya and Industroyer, significantly impacting Ukraine’s critical infrastructure.</p></th>
                                    <th><p>Sandworm (Russia)</p></th>
                                    <th><p>Critical infrastructure in Ukraine</p></th>
                                    <th><p>NotPetya, Industroyer</p></th>
                                </tr>
                                <tr>
                                    <th><p>Check Point</p></th>
                                    <th><p>CVE-2024-24919[xvi]</p></th>
                                    <th><p>Exploitation of CVE-2024-24919, a path traversal vulnerability, to extract ntds.dit and other user information. Attackers leverage this data for lateral movement within networks, potentially using tools like Visual Studio Code for traffic tunneling.</p></th>
                                    <th><p>Not specified</p></th>
                                    <th><p>Finance, healthcare, government, etc.</p></th>
                                    <th><p>ShadowPad, Winnti</p></th>
                                </tr>
                            </Table>
                            <p>By understanding the chronology and details of these vulnerabilities, organizations can better prepare for and defend against sophisticated attacks on their VPN infrastructure.</p>
                            <h4>If VPN security isn’t getting through, what is?</h4>
                            <p>Given the continuous evolution of cyber threats, the probability of exploitation activity remains high. Organizations implement security measures like VPNs to protect their networks. However, various factors can prevent VPNs from effectively securing a network:</p>
                            <ul>
                                <li><p><strong>Existing Vulnerabilities:</strong> Existing vulnerabilities within the network or VPN software can allow attackers to bypass security measures.</p></li>
                                <li><p><strong>Malicious Traffic:</strong> VPNs do not inherently differentiate between legitimate and malicious traffic, potentially allowing threats to infiltrate the network undetected.</p></li>
                                <li><p><strong>Configuration Issues:</strong> Misconfigurations and improper implementations of VPNs can hinder their effectiveness in protecting the network.</p></li>
                            </ul>
                            <h4>Securing Your VPN Infrastructure</h4>
                            <p>Beyond understanding past exploits, here are some steps organizations can take to secure their VPNs. Sequretek offers a comprehensive suite of services to keep your VPN infrastructure secure and your data protected:</p>
                            <ul>
                                <li><p><strong>Regular Vulnerability Assessments:</strong> We conduct regular vulnerability assessments using advanced security tools to identify potential weaknesses in your VPN systems. Our team prioritizes critical vulnerabilities and ensures prompt patching to minimize attack risks.</p></li>
                                <li><p><strong>Multi-Factor Authentication (MFA): </strong>  Sequretek strengthens your VPN access points with MFA, adding an extra layer of defense beyond simple passwords. This advanced security measure helps prevent unauthorized access attempts.</p></li>
                                <li><p><strong>Least Privilege Access:</strong> We ensure that users have only the minimum level of access needed within the VPN. This principle minimizes potential damage in case of a security breach.</p></li>
                                <li><p><strong>Restrict Administrative Access:</strong> Sequretek implements strict controls on administrative access to VPN configurations. Only authorized personnel with proper training can make changes, minimizing the risk of configuration errors or malicious activity.</p></li>
                                <li><p><strong>Threat Intelligence at Your Fingertips:</strong> Stay informed! We subscribe to and regularly review threat advisories from leading security vendors and government agencies. This ensures you have access to the latest information on vulnerabilities, mitigation strategies, and recommended security practices.</p></li>
                            </ul>
                            <h4>Conclusion</h4>
                            <p>In today’s digital age, a secure VPN is no longer a luxury, but a necessity. By implementing comprehensive security measures, organizations can protect their valuable data and assets, ensuring business continuity and promoting a culture of cybersecurity awareness.  Start your journey to a more secure VPN environment today!</p>
                            <p>References</p>
                            <p>[i] <a target='_blank' rel="noreferrer" href='https://en.wikipedia.org/wiki/Virtual_private_network'>https://en.wikipedia.org/wiki/Virtual_private_network</a></p>
                            <p>[ii] <a target='_blank' rel="noreferrer" href='https://www.cybersecurity-insiders.com/zcaler-threatlabz-2024-vpn-risk-report/'>https://www.cybersecurity-insiders.com/zcaler-threatlabz-2024-vpn-risk-report/</a></p>
                            <p>[iii] <a target='_blank' rel="noreferrer" href='https://www.cyber.gc.ca/en/guidance/virtual-private-networks-itsap80101'>https://www.cyber.gc.ca/en/guidance/virtual-private-networks-itsap80101</a></p>
                            <p>[iv] <a target='_blank' rel="noreferrer" href='https://www.cisa.gov/known-exploited-vulnerabilities-catalog'>https://www.cisa.gov/known-exploited-vulnerabilities-catalog</a></p>
                            <p>[v] <a target='_blank' rel="noreferrer" href='https://atos.net/en/lp/securitydive/analysis-of-ivanti-0-days-cve-2023-46805-and-cve-2024-21887'>https://atos.net/en/lp/securitydive/analysis-of-ivanti-0-days-cve-2023-46805-and-cve-2024-21887</a></p>
                            <p>[vi] <a target='_blank' rel="noreferrer" href='https://threatprotect.qualys.com/2024/01/17/citrix-netscaler-adc-and-netscaler-gateway-vulnerabilities-exploited-in-the-wild-cve-2023-6548-and-cve-2023-6549/'>https://threatprotect.qualys.com/2024/01/17/citrix-netscaler-adc-and-netscaler-gateway-vulnerabilities-exploited-in-the-wild-cve-2023-6548-and-cve-2023-6549/</a></p>
                            <p>[vii] <a target='_blank' rel="noreferrer" href='https://www.truesec.com/hub/blog/akira-ransomware-and-exploitation-of-cisco-anyconnect-vulnerability-cve-2020-3259#:~:text=During%20the%20past%20weeks%2C%20the,as%20CVE%2D2020%2D3259'>https://www.truesec.com/hub/blog/akira-ransomware-and-exploitation-of-cisco-anyconnect-vulnerability-cve-2020-3259#:~:text=During%20the%20past%20weeks%2C%20the,as%20CVE%2D2020%2D3259</a></p>
                            <p>[viii] <a target='_blank' rel="noreferrer" href='https://www.ncsc.gov.uk/news/exploitation-ivanti-vulnerabilities'>https://www.ncsc.gov.uk/news/exploitation-ivanti-vulnerabilities</a></p>
                            <p>[ix] <a target='_blank' rel="noreferrer" href='https://www.fortinet.com/blog/psirt-blogs/analysis-of-fg-ir-22-398-fortios-heap-based-buffer-overflow-in-sslvpnd'>https://www.fortinet.com/blog/psirt-blogs/analysis-of-fg-ir-22-398-fortios-heap-based-buffer-overflow-in-sslvpnd</a></p>
                            <p>[x] <a target='_blank' rel="noreferrer" href='https://www.tenable.com/blog/cve-2024-21762-critical-fortinet-fortios-out-of-bound-write-ssl-vpn-vulnerability#:~:text=On%20February%207%2C%20researchers%20at,which%20has%20a%20%E2%80%9Csuspected%20nexus'>https://www.tenable.com/blog/cve-2024-21762-critical-fortinet-fortios-out-of-bound-write-ssl-vpn-vulnerability#:~:text=On%20February%207%2C%20researchers%20at,which%20has%20a%20%E2%80%9Csuspected%20nexus</a></p>
                            <p>[xi] <a target='_blank' rel="noreferrer" href='https://www.tenable.com/blog/cve-2024-21762-critical-fortinet-fortios-out-of-bound-write-ssl-vpn-vulnerability'>https://www.tenable.com/blog/cve-2024-21762-critical-fortinet-fortios-out-of-bound-write-ssl-vpn-vulnerability</a></p>
                            <p>[xii] <a target='_blank' rel="noreferrer" href='https://www.bleepingcomputer.com/news/security/over-13-000-ivanti-gateways-vulnerable-to-actively-exploited-bugs/'>https://www.bleepingcomputer.com/news/security/over-13-000-ivanti-gateways-vulnerable-to-actively-exploited-bugs/</a></p>
                            <p>[xiii] <a target='_blank' rel="noreferrer" href='https://www.volexity.com/blog/2024/04/12/zero-day-exploitation-of-unauthenticated-remote-code-execution-vulnerability-in-globalprotect-cve-2024-3400/'>https://www.volexity.com/blog/2024/04/12/zero-day-exploitation-of-unauthenticated-remote-code-execution-vulnerability-in-globalprotect-cve-2024-3400/</a></p>
                            <p>[xiv] <a target='_blank' rel="noreferrer" href='https://phoenix.security/cisco-asa-cve-2024-20353/'>https://phoenix.security/cisco-asa-cve-2024-20353/</a></p>
                            <p>[xv] <a target='_blank' rel="noreferrer" href='https://www.ncsc.gov.uk/news/exploitation-vulnerabilities-affecting-cisco-firewall-platforms'>https://www.ncsc.gov.uk/news/exploitation-vulnerabilities-affecting-cisco-firewall-platforms</a></p>
                            <p>[xvi] <a target='_blank' rel="noreferrer" href='https://www.mnemonic.io/resources/blog/advisory-check-point-remote-access-vpn-vulnerability-cve-2024-24919/'>https://www.mnemonic.io/resources/blog/advisory-check-point-remote-access-vpn-vulnerability-cve-2024-24919/</a></p>
                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'VPNs Under Siege: Cyberattacks Exploiting Vulnerabilities'  , url: 'https://www.sequretek.com/resources/blog/prevent-vpn-vulnerabilities-secure-business'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                        title='Attackers Exploit VPN Vulnerabilities in a Frenzy'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}
