/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import $ from 'jquery';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';


export default function PdfReader(props) {
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [showBtn, setShowBtn] = useState(false);
    const [scale, setScale] = useState(1.1);
    const [fs, setfs] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', resize);
        resize();

        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [])

    // Adapt the size of the scale according to the screen
    const resize = () => {
        if (window.innerWidth <= 767) {
            setScale(0.5);
        }
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
        setShowBtn(true);
    }

    const changePage = (offset) => {
        setPageNumber(pageNumber + offset)
    }


    const previousPage = () => {
        window.scrollTo(0, 0);
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    // const closeAll = () => {
    //     props.closedeatils1();
    //     setPageNumber(1);
    //     setNumPages(null);
    //     setShowBtn(false);
    // }

    const zoomIn = () => {
        scale <= 2 ?
            setScale(scale + 0.2)
            : null
    };

    const zoomOut = () => {
        (window.innerWidth > 767 ? scale >= 1 : scale >= 0.5) ?
            setScale(scale - 0.2)
            : null
    };

    const fullscreen = () => {
        setfs(true);
        setShowBtn(false);
        // $("body").css("overflow", "hidden");

    }

    const closeQuizpopup = () => {
        setfs(false);
        setShowBtn(true)
        $("body").css("overflow", "visible");

    }





    return (
        <div>
            {
                fs ?
                    <div className='pdf-fullscreen'>
                        <div onClick={() => closeQuizpopup()} className='close-quiz'>
                            <img src='/images/close1.svg' alt='' />
                        </div>
                        <div className='fullscreen-container'>
                            <Document
                                file={props.url}
                                onLoadSuccess={onDocumentLoadSuccess}
                            // options={{ workerSrc: '/pdf.worker.js' }}
                            >
                                {<div className='zoom-btn' style={{ top: 5 }}>
                                    <button id="zoomInButton" onClick={zoomIn} ><img src='/images/zoom-out.png' alt='' /></button>
                                    <button id="zoomOutButton" onClick={zoomOut}><img src='/images/zoom-in.png' alt='' /></button>
                                    {/* <button id="fullscreenButton" onClick={fullscreen}><img src='/images/fullscreen.png' alt='' /></button> */}
                                </div>}
                                <Page
                                    pageNumber={pageNumber} onDoubleClick={zoomIn} style={{ width: '100%' }} scale={1.5}>
                                </Page>
                            </Document>
                            {
                                showBtn && numPages !== 1 ?
                                    <div className="text-center">

                                        <p style={{ color: '#fff' }}>
                                            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                                        </p>
                                        <button
                                            className='nextPage'
                                            type="button"
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}
                                        >
                                            <img src='/images/Orange-left.svg' width='12px' alt='' />
                                        </button>&nbsp;&nbsp;
                                        <button
                                            className='nextPage'
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            <img src='/images/Orange-right.svg' width='12px' alt='' />                        </button>
                                    </div> : null
                            }
                        </div>

                    </div> : null}


            <Document
                file={props.url}
                onLoadSuccess={onDocumentLoadSuccess}
            // options={{ workerSrc: '/pdf.worker.js' }}
            >
                {<div className='zoom-btn'>
                    <button id="zoomInButton" onClick={zoomIn} ><img src='/images/zoom-out.png' alt='' /></button>
                    <button id="zoomOutButton" onClick={zoomOut}><img src='/images/zoom-in.png' alt='' /></button>
                    {window.innerWidth > 767 ? <button id="fullscreenButton" onClick={fullscreen}><img src='/images/fullscreen.png' alt='' /></button> : null}
                </div>}
                <Page
                    pageNumber={pageNumber} onDoubleClick={zoomIn} style={{ width: '100%' }} scale={scale}>
                </Page>
            </Document>
            {
                showBtn && numPages !== 1 ?
                    <div className="text-center">

                        <p>
                            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                        </p>
                        <button
                            className='nextPage'
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                        >
                            <img src='/images/Orange-left.svg' width='12px' alt='' />
                        </button>&nbsp;&nbsp;
                        <button
                            className='nextPage'
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                        >
                            <img src='/images/Orange-right.svg' width='12px' alt='' />                        </button>
                    </div> : null
            }

        </div>
    )
}

