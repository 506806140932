import React from 'react';
import { useField, useFormikContext } from 'formik';
import CreatableSelect from 'react-select/creatable';

const CreatableSelects = ({
    name,
    options,
    isMulti = false,
    placeholder = 'Select...',
    isCreatable = true,
}) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field] = useField(name);

    // Handle change and notify Formik
    const handleChange = (selectedOption) => {
        if ((name === 'company') && selectedOption?.length > 1) {

        } else {
            setFieldValue(name, selectedOption);
        }
    };

    // Handle blur and notify Formik
    const handleBlur = () => {
        setFieldTouched(name, true);
    };

    // Determine the value to display in the select component
    const getValue = () => {
        if (isMulti) {
            return field.value || [];
        }
        return field.value || null;
    };

    return (
        <div>
            {name === 'company' ?
                <CreatableSelect
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name={name}
                    value={getValue()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={options}
                    isMulti={isMulti}
                    isCreatable={isCreatable}
                    isSearchable
                    placeholder={placeholder}
                />
                : <CreatableSelect
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name={name}
                    value={getValue()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={options}
                    isMulti={isMulti}
                    isCreatable={isCreatable}
                    isSearchable
                    placeholder={placeholder}
                />}
            {/* {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null} */}
        </div>
    );
};

export default CreatableSelects;
