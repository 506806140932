// ############################ Require packages here ############################
import React, { Fragment, useState, useRef } from 'react'
import { Modal, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha,
} from "react-simple-captcha";

// ############################ Require files here ###############################

import { submitBreachForm } from '../../Redux/ActionCreators/contactusAction';
import MultiSelect from '../../utils/customSelect';
import DialCodes from '../../utils/dialCodes.json';


export default function Breach() {
    const [loading, setLoading] = useState(false);
    const [captchaError, setCaptchaError] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [data, setData] = useState(null);
    const initialValues = { fname: '', lname: '', email: '', dial_code: "", mobile: '', job: "", message: "" }

    const active = 'breach';
    const formikRef = useRef();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const setActive = (val) => {
        if (val === 'contact-us') {
            navigate(`/contactus`)
        }
    }

    const validationSchema =
        Yup.object({
            fname: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true)
                .matches(/^[a-zA-Z0-9\s]+$/, 'First Name should only contain alphabets, numbers, and spaces')
                .required('First Name is required'),
            lname: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true)
                .matches(/^[a-zA-Z0-9\s]+$/, 'Last Name should only contain alphabets, numbers, and spaces')
                .required('Last Name is required'),
            email: Yup.string()
                .trim('No extra space allowed')
                .strict(true)
                .required('Email is required')
                .matches(/^([\w.-]+)@(?!hotmail\.com|gmail\.com|googlemail\.com|yahoo\.com|gmx\.com|ymail\.com|outlook\.com|bluewin\.ch|protonmail\.com|t-online\.de|web\.de|online\.de|aol\.com|live\.com)([a-zA-Z\d-]+\.)+([a-zA-Z]{2,63})$/, 'Enter Only Business email address'),
            mobile: Yup.string()
                .trim('Only Numbers allowed')
                .strict(true)
                .matches(/^\d{10}$/, 'Phone number must be 10 digits')
                .required('Phone is required'),
            job: Yup.string()
                .trim('No extra space allowed')
                .strict(true)
                .required('Job title  is required'),
            message: Yup.string()
                .trim('No extra space allowed')
                .strict(true)
                .max(1500, "Maximum 1500 characters allowed")
                .matches(/^[a-zA-Z0-9\s]+$/, 'Message should only contain alphabets, numbers, and spaces')
                .notRequired(''),
        });

    const handleSubmit = async (values, { setSubmitting }) => {
        let obj = {
            "fname": values?.fname,
            "lname": values?.lname,
            "email": values?.email,
            "mobile": `${values?.dial_code}${values?.mobile}`,
            "job": values?.job,
            "message": values?.message

        }
        setData(obj)
        setIsConfirm(true);
        setSubmitting(false);
        setTimeout(() => {
            loadCaptchaEnginge(6);
        }, 500);
    }
    const handleCaptchaSubmit = async () => {
        // < ! --let's assume there is an HTML input text box with id 'user_captcha_input' to get user input -->    
        let user_captcha_value = document.getElementById('user_captcha_input').value;
        await setLoading(true);
        if (validateCaptcha(user_captcha_value, false) === true) {
            setCaptchaError('');
            const res = await dispatch(submitBreachForm(data));
            if (res?.type === 'BREACH_SUBMIT') {
                if (formikRef.current) {
                    formikRef.current.resetForm();
                    formikRef.current.setFieldValue('newsletter', false);
                    setIsConfirm(false);

                }
            }
        }
        else {
            setCaptchaError('Captcha Does Not Match');
        }
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#040c30',
            zIndex: 3
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'lightblue' : '#040c30', // Change background color on selection
            color: '#b6bcbf', // Ensure text is visible
            ':hover': {
                color: '#000',
                backgroundColor: '#b6bcbf' // Change background color on hover
            }
        })
    };

    return (
        <div className='seq-contact-us'>
            <div className='reverse-grad'>
            </div>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Experiencing a Breach</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Data breach? Cybersecurity experts at Sequretek are here to help with rapid response, remediation, and operations restoration." />
                <meta property="og:title" content="Sequretek | Experiencing a Breach" />
                <meta property="og:description" content="Data breach? Cybersecurity experts at Sequretek are here to help with rapid response, remediation, and operations restoration." />
            </Helmet>
            {/* section 1 BREACH FORM */}
            <div className='container'>
                <div className='row justify-content-center me-0'>
                    <div className='col-md-10 col-sm-12 col-12'>
                        <div className='contact-tabs'>
                            <div onClick={() => setActive('experiencing-breach')} className={active === 'breach' ? 'seq-tab active' : 'seq-tab'}>
                                Experience a breach ?
                            </div>
                            <div onClick={() => setActive('contact-us')} className={active === 'contact' ? 'seq-tab active' : 'seq-tab'}>
                                Contact us
                            </div>
                        </div>
                        <Fragment>
                            <div className='breach-container'>
                                <div className='row me-0'>
                                    <div className='col-md-6 col-sm-12 col-12'>
                                        <div className='seq-user-attack' style={{
                                            backgroundImage: `url('/images/bg-img16.svg')`
                                        }}>
                                            <img src='/images/attack.svg' alt='' />
                                            <h6>Under Attack ?</h6>
                                            <p>We understand the urgency and gravity of a cybersecurity breach that threatens your business operations and sensitive data. Leave us a message and our expert team will get back to you and support you in mitigating the impact of the breach.</p>
                                        </div>
                                    </div>
                                    <div className={window.innerWidth > 767 ? 'col-md-6 col-sm-12 ' : 'col-12 pe-0'}>
                                        <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                            {({ setFieldValue, isSubmitting }) => (
                                                <Form className="users_form">
                                                    <div className='row me-0 seq-breach-form'>
                                                        <h5><img src='/images/comment-icon.svg' alt='' /> &nbsp; Leave us a message</h5>
                                                        <div className='col-md-6 col-12 mt-3'>
                                                            <div className='form-group mt-2'>
                                                                <Field
                                                                    type="text"
                                                                    name="fname"
                                                                    placeholder="First Name*"
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                />
                                                                <ErrorMessage name="fname" component="div" className="error-message" />
                                                            </div>
                                                        </div>
                                                        <div className={window.innerWidth > 767 ? 'col-md-6 mt-3' : 'col-md-6'}>
                                                            <div className='form-group mt-2' >
                                                                <Field
                                                                    type="text"
                                                                    name="lname"
                                                                    placeholder="Last Name*"
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                />
                                                                <ErrorMessage name="lname" component="div" className="error-message" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12 col-12'>
                                                            <div className='form-group mt-2' >
                                                                <Field
                                                                    type="text"
                                                                    name="job"
                                                                    placeholder="Job title*"
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                />
                                                                <ErrorMessage name="job" component="div" className="error-message" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12 col-12'>
                                                            <div className='form-group mt-2' >
                                                                <Field
                                                                    type="text"
                                                                    name="email"
                                                                    placeholder="Business Email*"
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                />
                                                                <ErrorMessage name="email" component="div" className="error-message" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12 col-12'>
                                                            <div className='form-group mt-2 d-flex' >
                                                                <Field
                                                                    className='select-cur'
                                                                    style={customStyles}
                                                                    name="dial_code"
                                                                    placeholder=""
                                                                    isMulti={false}
                                                                    isCreatable={false}
                                                                    component={MultiSelect}
                                                                    options={DialCodes?.map(el => [{ label: el.code, value: el.code }][0])}
                                                                />
                                                                <Field
                                                                    type="text"
                                                                    name="mobile"
                                                                    placeholder="Phone*"
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                />
                                                            </div>
                                                            <ErrorMessage name="mobile" component="div" className="error-message" />
                                                        </div>
                                                        <div className='col-md-12 col-12'>
                                                            <div className='form-group mt-2' >
                                                                <Field
                                                                    type="text"
                                                                    as='textarea'
                                                                    rows={4}
                                                                    name="message"
                                                                    placeholder='Information about the breach you are experiencing'
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                />
                                                                <ErrorMessage name="message" component="div" className="error-message" />
                                                            </div>
                                                        </div>
                                                        <div className='submit-btn'>
                                                            {loading ?
                                                                <button type='submit' style={{ backgroundColor: '#1BA431' }} ><img src='/images/tick-white.svg' alt='' /></button> :
                                                                <button type='submit' disabled={isSubmitting}>{isSubmitting ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>
                                                            }
                                                        </div>
                                                        {window.innerWidth < 767 ? <p className='privacy-text'>Your privacy matters. By filling out this form, you consent to the collection, storage, and use of your PII as per our privacy policy. We will protect your data and not share it with third parties without your consent, except as required by law.</p> : null}
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                            {window.innerWidth > 767 ? <p className='privacy-text'>Your privacy matters. By filling out this form, you consent to the collection, storage, and use of your PII as per our privacy policy. We will protect your data and not share it with third parties without your consent, except as required by law.</p> : null}
                        </Fragment>
                    </div>
                </div>
            </div>
            <Modal
                show={isConfirm}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={() => setIsConfirm(false)}
                dialogClassName={"confirm-modal"}
            >
                {
                    loading ?
                        null :
                        <div className='close-sticky'>
                            <img src='/images/close.svg' alt='' onClick={() => setIsConfirm(false)} />
                        </div>
                }
                <div className="p-4" >
                    <div>
                        <LoadCanvasTemplate reloadText="Reload" />
                    </div>
                    <div className='form-group mt-3'>
                        <input className='form-control' placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text" />
                    </div>
                    <span style={{ color: 'red' }}>{captchaError}</span>
                    <div className="save-btn1">
                        <button onClick={() => handleCaptchaSubmit()} disabled={loading}>{loading ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>&nbsp;&nbsp;
                    </div>
                </div>
            </Modal>
        </div >
    )
}
