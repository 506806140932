import React, { useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';

const Paginations = ({ totalPages, onPageChange, className }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        onPageChange(pageNumber);
    };

    const renderPaginationItems = () => {
        const items = [];
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handlePageClick(currentPage - 1)}
                disabled={currentPage === 1}
            >
                <img src={className === 'custom-pagination1' ? '/images/arrow-left.svg' : '/images/arrow-left-grey.svg'} alt='' />
            </Pagination.Prev>
        );

        for (let number = 1; number <= totalPages; number++) {
            if (
                number === 1 ||
                number === totalPages ||
                (number >= currentPage - 1 && number <= currentPage + 1)
            ) {
                items.push(
                    <Pagination.Item
                        key={number}
                        active={number === currentPage}
                        onClick={() => handlePageClick(number)}
                    >
                        {number}
                    </Pagination.Item>
                );
            } else if (
                number === currentPage - 2 ||
                number === currentPage + 2
            ) {

                items.push(window.innerWidth > 767 ? <Pagination.Ellipsis key={`ellipsis-${number}`} /> : null);
            }
        }

        items.push(
            <Pagination.Next
                key="next"
                onClick={() => handlePageClick(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                <img src={className === 'custom-pagination1' ? '/images/arrow-right.svg' : '/images/arrow-right-grey.svg'} alt='' />
            </Pagination.Next>
        );

        return items;
    };

    return <Pagination className={className}>{renderPaginationItems()}</Pagination>;
};

export default Paginations;