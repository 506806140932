// ############################ Require packages here ############################
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import moment from 'moment';
import { Dialog } from '@mui/material';

// ############################ Require files here ###############################

import { getAllProducts } from '../../../../Redux/ActionCreators/leadsAction';
import { getIndustries, getTopics, getServices, addResource, editResource } from '../../../../Redux/ActionCreators/resourcesAction';
import { Spinner } from 'react-bootstrap';
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/arrow-down.svg' alt='' width={18} />
        </components.DropdownIndicator>
    );
};

export default function AddResource(props) {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [file, setFile] = useState('');
    const [image, setImage] = useState('');
    const [imageUrl, setImageUrl] = useState("/images/empty-image.svg");
    const [imageName, setImageName] = useState('');
    const [url, setUrl] = useState('');
    const [industry, setIndustry] = useState(null);
    const [topic, setTopic] = useState(null);
    const [product, setProduct] = useState(null);
    const [service, setService] = useState(null);
    const [website, setWebsite] = useState(false);
    const [titleError, setTitleError] = useState('');
    const [descError, setDescError] = useState('');
    const [typeError, setTypeError] = useState('');
    const [fileError, setFileError] = useState('');
    const [urlError, setUrlError] = useState('');
    const [industryError, setIndustryError] = useState('');
    const [topicError, setTopicError] = useState('');
    const [productError, setProductError] = useState('');
    const [serviceError, setServiceError] = useState('');
    const [update, setUpdate] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [gated, setGated] = useState(false);
    const [publishDate, setPublishDate] = useState(new Date());
    const [id, setId] = useState('');
    const [fileType, setFileType] = useState(null);
    const [fileTypeError, setFileTypeError] = useState('');
    const fileTypes = [{ label: 'File Upload', value: 'file' }, { label: 'Url', value: 'url' }]
    const dispatch = useDispatch();

    const { resourceTypes, industries, topics, services } = useSelector(state => state.resources);

    const { products } = useSelector(state => state.leads);


    useEffect(() => {
        dispatch(getAllProducts());
        dispatch(getIndustries());
        dispatch(getTopics());
        dispatch(getServices());
        const { resourceDetails } = props
        if (resourceDetails) {
            setTitle(resourceDetails?.title);
            setDescription(resourceDetails?.description)
            setType({ value: resourceDetails?.type, label: resourceTypes.filter(el => el.value === resourceDetails?.type)[0]?.label })
            setUrl(resourceDetails?.url);
            setFileType(resourceDetails?.url?.includes('amazonaws.com') ? { label: 'File Upload', value: 'file' } : { label: 'Url', value: 'url' })
            setName(resourceDetails?.url);
            setFile(resourceDetails?.url)
            setImage(resourceDetails?.image);
            setImageName(resourceDetails?.image);
            setIndustry(resourceDetails.industries?.map(el => [{ label: el.name, value: el._id }][0]));
            setTopic(resourceDetails.topics?.map(el => [{ label: el.name, value: el._id }][0]));
            setProduct(resourceDetails.products?.map(el => [{ label: el.name, value: el._id }][0]));
            setService(resourceDetails.services?.map(el => [{ label: el.name, value: el._id }][0]));
            setWebsite(resourceDetails?.website);
            setGated(resourceDetails?.gated);
            setId(resourceDetails?._id);
            setPublishDate(resourceDetails?.publish ? moment(resourceDetails?.publish).format('YYYY-MM-DD') : null)
        }
    }, [])

    const selectType = (e) => {
        setType(e)
        setTypeError('');
        if (e?.value === 'blog' || e?.value === 'video' || e?.value === 'podcast' || e?.value === 'demo' || e?.value === 'event') {
            setFileType({ label: 'Url', value: 'url' })
        } else if ((e?.value === "factsheet") || (e?.value === "datasheet") || (e?.value === "whitepaper") || (e?.value === "case-study") || (e?.value === 'report') || (e?.value === 'infographic') || (e?.value === 'advisory') || (e?.value === 'internal')) {
            setFileType({ label: 'File Upload', value: 'file' })
        }
        else {
            setFileType(null);
        }
    }

    const selectFileType = (e) => {
        setFileType(e)
        setFileTypeError('');
    }

    const changeVideo = (e) => {
        console.log(e.target.files[0])
        var Extension = e.target.value
            .substring(e.target.value.lastIndexOf(".") + 1)
            .toLowerCase();
        if (e.target.files[0].size > 10242880) {
            setSuccessMsg(true);
            setSuccessMessageText("The file size cannot exceed 10MB");
        }
        else {
            if (type?.value === "internal" && (Extension === "ppt" || Extension === "doc" || Extension === "docx" || Extension === "pdf" || Extension === "pptx")) {
                const file = e.target.files[0];
                const reader = new FileReader();
                if (file) {
                    reader.readAsDataURL(file);
                    setUpdate(true);
                    setName(file.name);
                    setFile(file);
                    setFileError('');
                } else {
                    setImageUrl('');
                    setName('');
                    setUpdate(false);
                }
            }
            else if (((type?.value === "report") || (type?.value === "press") || (type?.value === "media") || (type?.value === "factsheet") || (type?.value === "infographic") || (type?.value === "advisory") || (type?.value === "case-study") || (type?.value === "datasheet")
                || (type?.value === "whitepaper")) && (Extension === "png" || Extension === "jpeg" || Extension === "jpg" || Extension === "pdf")) {
                const file = e.target.files[0];
                const reader = new FileReader();

                if (file) {
                    reader.readAsDataURL(file);
                    setUpdate(true);
                    setName(file.name);
                    setFile(file);
                    setFileError('');
                } else {
                    setImageUrl('');
                    setName('');
                    setUpdate(false);
                }
            } else {
                setSuccessMsg(false);
                setSuccessMessageText("The file Format not allow");
            }
        }
    };

    const changeImage = (e) => {
        var Extension = e.target.value
            .substring(e.target.value.lastIndexOf(".") + 1)
            .toLowerCase();
        if (e.target.files[0].size > 2097152) {
            setSuccessMsg(true);
            setSuccessMessageText("The file size cannot exceed 2MB");
        }
        else {
            if (
                Extension === "png" ||
                Extension === "jpeg" ||
                Extension === "jpg"
            ) {
                const file = e.target.files[0];
                const reader = new FileReader();

                reader.onloadend = () => {
                    setImageUrl(reader.result);
                };
                if (file) {
                    reader.readAsDataURL(file);
                    setImageUrl(reader.result);
                    setImage(file);
                    setImageName(file.name);
                } else {
                    setImageUrl('');
                    setImageName('');
                }
            } else {
                setSuccessMsg(true);
                setSuccessMessageText("File Format not allowed");
            }
        }

    }

    const selectIndustry = (e) => {
        setIndustry(e);
        setIndustryError('');
    }

    const selectTopic = (e) => {
        setTopic(e);
        setTopicError('');
    }

    const selectProduct = (e) => {
        setProduct(e);
        setProductError('');
    }

    const selectService = (e) => {
        setService(e);
        setServiceError('');
    }

    const submitResource = async () => {
        await setLoading(true);
        const regex = /^[a-zA-Z0-9\s]+$/;
        const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
        if (title.trim('') === '') {
            setTitleError(`Title can't empty`);
            setLoading(false);
        } else if (!(regex.test(title))) {
            setTitleError(`Only alphanumericals and space allowed`);
            setLoading(false);
        }
        else if (description.trim('') === '') {
            setDescError(`Description can't empty`);
            setLoading(false);
        } else if (!(regex.test(description))) {
            setDescError(`Only alphanumericals and space allowed`);
            setLoading(false);
        }
        else if (type === null) {
            setTypeError('Select Type');
            setLoading(false);
        } else if (fileType === null) {
            setTypeError('Select File Type');
            setLoading(false);
        }
        else if ((fileType?.value === 'url') && (url === '')) {
            setUrlError('Enter Url');
            setLoading(false);
        } else if ((fileType?.value === 'file') && (file === '')) {
            setFileError('Select File');
            setLoading(false);
        }
        // else if (((type?.value === "datasheet") || (type?.value === "factsheet") || (type?.value === "whitepaper") || (type?.value === "case-study") || (type?.value === "report") || (type?.value === "infographic") || (type?.value === "advisory")) && (file === '')) {
        //     setFileError('Select File');
        //     setLoading(false);
        // } else if (((type?.value === "blog") || (type?.value === "press") || (type?.value === "media") || (type?.value === "video") || (type?.value === "event")) && (url === '')) {
        //     setUrlError('Enter Url');
        //     setLoading(false);
        // }
        else if (url && !urlRegex.test(url)) {
            setUrlError('Enter Valid Url');
            setLoading(false);
        }
        else if (industry === null) {
            setIndustryError('Select or create at least one industry');
        }
        else if (topic === null) {
            setTopicError('Select or create at least one topic');
        }
        else if (product == null) {
            setProductError('Select or create at least one product');
            setLoading(false);
        }
        else if (service === null) {
            setServiceError('Select or create at least one service');
            setLoading(false);
        }
        else {
            const data = new FormData();
            props.resourceDetails?._id && data.append('_id', id);
            data.append('title', title);
            data.append('description', description);
            data.append('type', type?.value);
            (fileType?.value === 'file' ? data.append('payload', file) : data.append('url', url));
            data.append('image', image);
            data.append('products', JSON.stringify(product?.map(el => el.__isNew__ ? [{ name: el.label }][0] : [{ name: el.label, _id: el.value }][0])));
            data.append('industries', JSON.stringify(industry?.map(el => el.__isNew__ ? [{ name: el.label }][0] : [{ name: el.label, _id: el.value }][0])));
            data.append('topics', JSON.stringify(topic?.map(el => el.__isNew__ ? [{ name: el.label }][0] : [{ name: el.label, _id: el.value }][0])));
            data.append('services', JSON.stringify(service?.map(el => el.__isNew__ ? [{ name: el.label }][0] : [{ name: el.label, _id: el.value }][0])));
            data.append('website', website);
            data.append('gated', gated);
            data.append('publish', publishDate);
            if (!props.isEdit) {
                const res = await dispatch(addResource(data, props?.isAddFromCategory));

                if (props?.isAddFromCategory && (res?.type === 'ADD_CATEGORY_RESOURCE')) {
                    await props.backToMain();
                    setTitle('');
                    setDescription('');
                    setImage('');
                    setUrl('');
                    setType(null);
                    setIndustry(null);
                    setTopic(null);
                    setProduct(null);
                    setService(null);
                    setWebsite(false)
                    setGated(false);
                } else if (!props?.isAddFromCategory && (res?.type === 'ADD_RESOURCE')) {
                    await props.backToMain();
                    setTitle('');
                    setDescription('');
                    setImage('');
                    setUrl('');
                    setType(null);
                    setIndustry(null);
                    setTopic(null);
                    setProduct(null);
                    setService(null);
                    setWebsite(false)
                    setGated(false);
                }
            } else {
                const res = await dispatch(editResource(data, props?.isAddFromCategory));

                if ((res?.type === 'UPDATE_RESOURCE')) {
                    await props.backToMain();
                    setTitle('');
                    setDescription('');
                    setImage('');
                    setUrl('');
                    setType(null);
                    setIndustry(null);
                    setTopic(null);
                    setProduct(null);
                    setService(null);
                    setWebsite(false)
                    setGated(false);
                }
            }
        }
        await setLoading(false);
    }
    const snackClosed = () => {
        setSuccessMsg(false);
        setSuccessMessageText('');
    }

    const back = () => {
        props.backToMain();
        setTitle('');
        setDescription('');
        setImage('');
        setUrl('');
        setType(null);
        setIndustry(null);
        setTopic(null);
        setProduct(null);
        setService(null);
        setWebsite(false)
        setGated(false);
        setPublishDate('');
    }

    return (
        <div className='add-resource'>
            {/* ADD RESOURCE HEADING */}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={successMsg}
                autoHideDuration={3000}
                onClose={snackClosed}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>
            {
                loading ?
                    <Dialog
                        className='screen-loader'
                        open={loading}
                        onClose={() => { }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        Saving...
                    </Dialog> :
                    null
            }

            <h3><img src='/images/back.svg' alt='' onClick={() => back()} /> &nbsp;&nbsp;{props.isEdit ? 'Edit Resource' : 'Add Resource'}</h3>
            <div style={{ width: '70%', marginTop: 30 }}>
                {((localStorage.getItem('role') === 'admin') && props.isEdit) &&
                    <div className='form-group mt-2'>
                        <label>Resource Id</label>
                        <input type='text' className='form-control mt-2' value={id} placeholder='Enter Title' readOnly disabled />
                        <aside>{titleError}</aside>
                    </div>}
                <div className='form-group mt-2'>
                    <label>Title</label>
                    <input type='text' className='form-control mt-2' value={title} placeholder='Enter Title' onChange={(e) => {
                        setTitle(e.target.value)
                        setTitleError('')
                    }} />
                    <aside>{titleError}</aside>
                </div>
                <div className='form-group mt-2'>
                    <label>Description</label>
                    <textarea rows={4} className='form-control mt-2' placeholder='Enter Description' value={description} onChange={(e) => {
                        setDescription(e.target.value)
                        setDescError('')
                    }} />
                    <aside>{descError}</aside>
                </div>
                <div>
                    {/* ADD RESOURCE FORM */}
                    <div className='form-group'>
                        <label >Resource Type</label>
                        <div className='mt-2'>
                            <Select
                                components={{ DropdownIndicator }}
                                options={resourceTypes}
                                onChange={selectType}
                                placeholder='Select Type'
                                value={type}
                                isDisabled={props.isEdit}
                            />
                        </div>
                        <aside>{typeError}</aside>
                    </div>
                </div>
                {type &&
                    (((type?.value === 'press') || (type?.value === 'media')) &&
                        <div className='form-group'>
                            <label >File Type</label>
                            <div className='mt-2'>
                                <Select
                                    components={{ DropdownIndicator }}
                                    options={fileTypes}
                                    onChange={selectFileType}
                                    placeholder='Select Filetype'
                                    value={fileType}
                                />
                            </div>
                            <aside>{fileTypeError}</aside>
                        </div>)
                }
                {fileType &&
                    (fileType?.value === 'url' ?
                        <div className='form-group mt-2'>
                            <label>Url</label>
                            <input type='url' className='form-control mt-2' placeholder='Enter Url' value={url} onChange={(e) => {
                                setUrl(e.target.value)
                                setUrlError('')
                            }} />
                            <aside>{urlError}</aside>
                        </div>
                        :
                        <div className="mt-4">
                            <label>
                                File
                            </label>
                            {type === "Video" ? null : (
                                <p>{type?.value === 'internal' ? 'Only PDF/PPT/Doc accepted' : 'Only PDF/png/jpeg accepted'}</p>
                            )}
                            <div className="d-flex align-items-center ">
                                <div className="d-flex flex-column mt-3 position-relative ">
                                    <span>
                                        <img src="/images/upload-icon.svg" alt="" />{" "}
                                        &nbsp;Upload file from computer &nbsp;
                                        {props.isEdit ? null : <small>{name}</small>}
                                        <input
                                            type="file"
                                            className="file-upload_question"
                                            name="selectedFile"
                                            accept={
                                                type?.value === 'internal' ? ".pdf,.ppt,.pptx,.doc,.docx" : ".pdf,png,jpeg"
                                            }
                                            onChange={changeVideo}
                                        />
                                    </span>
                                    {props.isEdit && <a href={name} rel="noreferrer" target='_blank'>{name}</a>}
                                </div>
                            </div>
                            <aside>{fileError}</aside>
                        </div>)

                }

                <div className="mt-3">
                    <label>Image</label>
                    <div className="d-flex align-items-center ">
                        {image ? (
                            <div className="program-image">
                                <img
                                    src={imageUrl}
                                    alt=""
                                    width="139px"
                                    height="78px"
                                />
                            </div>
                        ) : (
                            <div className="program-image">
                                <img src={imageUrl} alt="" />
                            </div>
                        )}

                        <div className="d-flex flex-column mt-3 position-relative upload-img">
                            <span>
                                {" "}
                                <img src="/images/upload-icon.svg" alt="" />{" "}
                                &nbsp;Upload Image &nbsp;{" "}
                                <small>{imageName}</small>
                                <input
                                    type="file"
                                    // ref="uploadImg"
                                    className="file-upload"
                                    style={{ left: 0 }}
                                    name="selectedFile"
                                    accept="image/png, image/jpeg,.doc"
                                    onChange={changeImage}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div className='form-group mt-2'>
                    <label>Industries</label>
                    <div className='mt-2'>
                        <CreatableSelect
                            components={{ DropdownIndicator }}
                            isSearchable
                            isMulti
                            placeholder='Industries'
                            value={industry}
                            onChange={selectIndustry}
                            options={industries}
                        />
                    </div>
                    <aside>{industryError}</aside>
                </div>
                <div className='form-group mt-2'>
                    <label>Topics</label>
                    <div className='mt-2'>
                        <CreatableSelect
                            components={{ DropdownIndicator }}
                            isSearchable
                            isMulti
                            placeholder='Topics'
                            value={topic}
                            onChange={selectTopic}
                            options={topics}
                        />
                    </div>
                    <aside>{topicError}</aside>
                </div>
                <div className='form-group mt-2'>
                    <label>Products</label>
                    <div className='mt-2'>
                        <CreatableSelect
                            components={{ DropdownIndicator }}
                            isSearchable
                            isMulti
                            placeholder='Products'
                            value={product}
                            onChange={selectProduct}
                            options={products}
                        />
                    </div>
                    <aside>{productError}</aside>
                </div>
                <div className='form-group mt-2'>
                    <label>Services</label>
                    <div className='mt-2'>
                        <CreatableSelect
                            components={{ DropdownIndicator }}
                            isSearchable
                            isMulti
                            placeholder='Services'
                            value={service}
                            onChange={selectService}
                            options={services}
                        />
                    </div>
                    <aside>{serviceError}</aside>
                </div>
                <div className='form-group'>
                    <label >Published Date</label>
                    <input type="date" name="joined" placeholder="" value={publishDate} className='picker form-control mt-2' onChange={(e) => {
                        setPublishDate(e.target.value)
                    }} />
                </div>
                <div className='website-checkbox'>
                    <label><input type="checkbox" name='website' onChange={(e) => setWebsite(e.target.checked)} checked={website} /> Display on Website</label>
                </div>
                {website ?
                    <div className='website-checkbox'>
                        <label><input type="checkbox" name='gated' onChange={(e) => setGated(e.target.checked)} checked={gated} /> Gated</label>
                    </div> :
                    null
                }
                <div className='mt-4'>
                    <button className='submit-btn' disabled={loading} onClick={() => submitResource()}>{loading ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>
                </div>
            </div>
        </div>
    )
}
