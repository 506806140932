// ############################ Require packages here ############################
import React, { Fragment, useState, useRef } from 'react'
import { Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import Select from 'react-select';
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha,
} from "react-simple-captcha";

// ############################ Require files here ###############################

import countries from '../../utils/countries.json';
import { submitContactus } from '../../Redux/ActionCreators/contactusAction';


export default function Contactus() {
    const [loading, setLoading] = useState(false);
    const [captchaError, setCaptchaError] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [data, setData] = useState(null);
    const initialValues = {
        fname: '', lname: '', email: '', dial_code: "", mobile: '', organization: "", job: "", country: "", message: ""
    };

    const active = 'contact';
    const formikRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setActive = (val) => {
        if (val === 'experiencing-breach') {
            navigate(`/experiencing-breach`)
        }
    }

    const validationSchema =
        Yup.object({
            fname: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true)
                .matches(/^[a-zA-Z0-9\s]+$/, 'First Name should only contain alphabets, numbers, and spaces')
                .required('First Name is required'),
            lname: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true)
                .matches(/^[a-zA-Z0-9\s]+$/, 'Last Name should only contain alphabets, numbers, and spaces')
                .required('Last Name is required'),
            email: Yup.string()
                .trim('No extra space allowed')
                .strict(true)
                .required('Email is required')
                .matches(/^([\w.-]+)@(?!hotmail\.com|gmail\.com|googlemail\.com|yahoo\.com|gmx\.com|ymail\.com|outlook\.com|bluewin\.ch|protonmail\.com|t-online\.de|web\.de|online\.de|aol\.com|live\.com)([a-zA-Z\d-]+\.)+([a-zA-Z]{2,63})$/, 'Enter Only Business email address'),
            mobile: Yup.string()
                .trim('Only Numbers allowed')
                .strict(true)
                .matches(/^\d{10}$/, 'Phone number must be 10 digits')
                .required('Phone is required'),
            country: Yup.object()
                .shape({
                    value: Yup.string().required('You must select an option'),
                })
                .nullable() // allow null if you want to allow resetting to null
                .required('Country is Required'),
            organization: Yup.string()
                .trim('No extra space allowed')
                .strict(true)
                .matches(/^[a-zA-Z0-9\s]+$/, 'Organization should only contain alphabets, numbers, and spaces')
                .required('Organization is required'),
            job: Yup.string()
                .trim('No extra space allowed')
                .strict(true)
                .required('Job title  is required'),
            message: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true)
                .max(1500, "Maximum 1500 characters allowed")
                .matches(/^[a-zA-Z0-9\s]+$/, 'Message should only contain alphabets, numbers, and spaces')
                .notRequired(''),
        });

    const handleCountryChange = (event, setFieldValue) => {
        setFieldValue('country', event ? event : '');
        setFieldValue('dial_code', event ? event.dial_code : '');
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        let obj = {
            "fname": values?.fname,
            "lname": values?.lname,
            "email": values?.email,
            "mobile": `${values?.dial_code}${values?.mobile}`,
            "country": values?.country?.value,
            "organization": values?.organization,
            "job": values?.job,
            "message": values?.message
        }
        setData(obj);
        setIsConfirm(true);
        setSubmitting(false);
        setTimeout(() => {
            loadCaptchaEnginge(6);
        }, 500);
    }
    const handleCaptchaSubmit = async () => {
        // < ! --let's assume there is an HTML input text box with id 'user_captcha_input' to get user input -->    
        let user_captcha_value = document.getElementById('user_captcha_input').value;
        await setLoading(true);
        if (validateCaptcha(user_captcha_value, false) === true) {
            setCaptchaError('');
            const res = await dispatch(submitContactus(data));
            if (res?.type === 'CONTACTUS_SUBMIT') {
                if (formikRef.current) {
                    formikRef.current.resetForm();
                    formikRef.current.setFieldValue('country', null);
                    formikRef.current.setFieldValue('newsletter', false);
                    setIsConfirm(false);

                }
            }
        }
        else {
            setCaptchaError('Captcha Does Not Match');
        }
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#040c30',
            zIndex: 3
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'lightblue' : '#040c30', // Change background color on selection
            color: '#b6bcbf', // Ensure text is visible
            ':hover': {
                color: '#000',
                backgroundColor: '#b6bcbf' // Change background color on hover
            }
        })
    };

    return (
        <div className='seq-contact-us'>
            <div className='reverse-grad'>
            </div>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Contact us </title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Get in touch with Sequretek for top-notch cybersecurity products & solutions and real-time protection." />
                <meta property="og:title" content="Sequretek | Contact us " />
                <meta property="og:description" content="Get in touch with Sequretek for top-notch cybersecurity products & solutions and real-time protection." />
            </Helmet>
            {/* section 1 CONTACT FORM */}
            <div className='container'>
                <div className='row justify-content-center me-0'>
                    <div className='col-md-10 col-sm-12 col-12'>
                        <div className='contact-tabs'>
                            <div onClick={() => setActive('experiencing-breach')} className={active === 'breach' ? 'seq-tab active' : 'seq-tab'}>
                                Experience a breach ?
                            </div>
                            <div onClick={() => setActive('contact-us')} className={active === 'contact' ? 'seq-tab active' : 'seq-tab'}>
                                Contact us
                            </div>
                        </div>

                        <div className={window.innerWidth > 767 ? 'mt-4' : 'mt-4 py-2 ready-text'}>
                            <h6>Ready to protect your business?</h6>
                            <p className='privacy-text mt-1'>In today’s digital landscape, a cybersecurity breach can happen at any time. It results in the loss of critical information, leading to severe financial and reputational damage. It is crucial for you to keep your company safe from breaches.</p>
                        </div>
                        <div className='breach-container mt-4'>
                            <div className='row me-0'>
                                <div className='col-md-6 col-sm-12 col-12 pe-0'>
                                    <div className='seq-user-attack' style={{
                                        backgroundImage: `url('/images/bg-img17.svg')`
                                    }}>
                                        <div>
                                            <h6>Reach us directly</h6>
                                            <p>We are here to help. Reach out to Sequretek to safeguard your business's future and ensure resilience in the face of cyber incidents.</p>
                                            <div style={{ marginTop: 15, borderBottom: '2px solid #2076CD' }}></div>
                                        </div>
                                        <div className='mt-4'>
                                            <h6>Sales</h6>
                                            {/* <p className='mt-2'>Phone: +1 732 392 7470<br />
                                                    </p> */}
                                            <p >Email: sales@sequretek.com</p>
                                        </div>
                                        <div className='mt-5'>
                                            <h6>Partner/Resellers</h6>
                                            {/* <p className='mt-2'>Phone: +1 732 392 7470<br />
                                                    </p> */}
                                            <p >Email: partners@sequretek.com</p>
                                        </div>
                                        <div className='mt-5'>
                                            <h6>Existing Customers</h6>
                                            {/* <p className='mt-2'>Phone: +1 732 392 7470<br />
                                                    </p> */}
                                            <p >Email: support@sequretek.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={window.innerWidth > 767 ? 'col-md-6 col-sm-12 ' : 'col-12 pe-0'}>
                                    <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                        {({ setFieldValue, errors, values, touched, isSubmitting }) => (
                                            <Form className="users_form">
                                                <div className='row me-0 seq-breach-form'>
                                                    <h5><img src='/images/comment-icon.svg' alt='' /> &nbsp; Leave us a message</h5>
                                                    <div className='col-md-6 mt-3'>
                                                        <div className='form-group mt-2'>
                                                            <Field
                                                                type="text"
                                                                name="fname"
                                                                placeholder="First Name*"
                                                                className='form-control'
                                                                autoComplete='off'
                                                            />
                                                            <ErrorMessage name="fname" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className={window.innerWidth > 767 ? 'col-md-6 mt-3' : 'col-md-6'}>
                                                        <div className='form-group mt-2'>
                                                            <Field
                                                                type="text"
                                                                name="lname"
                                                                placeholder="Last Name*"
                                                                className='form-control'
                                                                autoComplete='off'
                                                            />
                                                            <ErrorMessage name="lname" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='form-group mt-2' >
                                                            <Field
                                                                type="text"
                                                                name="organization"
                                                                placeholder="Organization*"
                                                                className='form-control'
                                                                autoComplete='off'
                                                            />
                                                            <ErrorMessage name="organization" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='form-group mt-2' >
                                                            <Field
                                                                type="text"
                                                                name="job"
                                                                placeholder="Job title*"
                                                                className='form-control'
                                                                autoComplete='off'
                                                            />
                                                            <ErrorMessage name="job" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='form-group mt-2' >
                                                            <Field
                                                                type="text"
                                                                name="email"
                                                                placeholder="Business Email*"
                                                                className='form-control'
                                                                autoComplete='off'
                                                            />
                                                            <ErrorMessage name="email" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='form-group mt-2' >
                                                            <Select
                                                                name="country"
                                                                id="singleSelectCustom"
                                                                placeholder="Select Country"
                                                                isMulti={false}
                                                                // isClearable
                                                                isSearchable
                                                                styles={customStyles}
                                                                onChange={(event) => handleCountryChange(event, setFieldValue)}
                                                                options={countries?.map(el => [{ ...el, label: el.name, value: el.name }][0])}
                                                            />
                                                            {errors.country?.value && touched.country ? (
                                                                <div className="error-message">{errors.country.value}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='form-group mt-2 d-flex' >
                                                            <Field
                                                                style={{ width: '80px' }}
                                                                type="dial_code"
                                                                name="dial_code"
                                                                placeholder=""
                                                                className='form-control'
                                                                autoComplete='off'
                                                                disabled
                                                            />
                                                            <Field
                                                                type="tel"
                                                                name="mobile"
                                                                maxLength={10}
                                                                placeholder="Phone*"
                                                                className='form-control'
                                                                autoComplete='off'
                                                            />
                                                        </div>
                                                        <ErrorMessage name="mobile" component="div" className="error-message" />
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='form-group mt-2' >
                                                            <Field
                                                                type="text"
                                                                as='textarea'
                                                                rows={4}
                                                                name="message"
                                                                placeholder="How can we help?"
                                                                className='form-control'
                                                                autoComplete='off'
                                                            />
                                                            <ErrorMessage name="message" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className='submit-btn'>
                                                        {loading ?
                                                            <button type='submit' style={{ backgroundColor: '#1BA431' }} ><img src='/images/tick-white.svg' alt='' /></button> :
                                                            <button type='submit' disabled={isSubmitting} >{isSubmitting ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>
                                                        }
                                                    </div>

                                                    {window.innerWidth < 767 ? <p className='privacy-text'>Your privacy matters. By filling out this form, you consent to the collection, storage, and use of your PII as per our privacy policy. We will protect your data and not share it with third parties without your consent, except as required by law.</p> : null}

                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>

                            </div>
                        </div>
                        {window.innerWidth > 767 ? <p className='privacy-text'>Your privacy matters. By filling out this form, you consent to the collection, storage, and use of your PII as per our privacy policy. We will protect your data and not share it with third parties without your consent, except as required by law.</p> : null}
                    </div>
                </div>
            </div>
            <div style={{ backgroundImage: `linear-gradient(179.04deg, rgb(4, 12, 48) 24.36%, rgba(4, 12, 48, 0.7) 50.37%, rgb(5, 13, 49) 70.81%),url('/images/bg-img2.png')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>
                <div className='container'>
                    <div className='row justify-content-center me-0'>
                        <div className='col-md-10 col-sm-12 col-12'>
                            <div className='seq-loactions' >
                                <h4><img src='/images/visit.svg' alt='' />&nbsp;&nbsp;Our Offices</h4>
                                {window.innerWidth > 767 ?
                                    // <Table responsive>
                                    //     <tbody>
                                    //         <tr>
                                    //             <td style={{ width: '12%' }}><img src='/images/usa-flag-big.svg' alt='' /></td>
                                    //             <td style={{ width: '23%' }}>
                                    //                 <h6>Arkansas</h6>
                                    //                 <p>417 Main Street</p>
                                    //                 <p>Ste 501-2<br />Little Rock, AR 72201</p>
                                    //             </td>
                                    //             <td style={{ width: '21%' }}>
                                    //                 <h6>New Jersey</h6>
                                    //                 <p>100 Overlook Center</p>
                                    //                 <p>2nd Floor 2109</p>
                                    //                 <p>Princeton, NJ 08540</p>
                                    //             </td>
                                    //             <td>

                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td style={{ width: '12%' }}><img src='/images/ind-flag-big.svg' alt='' /></td>
                                    //             <td style={{ width: '25%' }}>
                                    //                 <h6>Mumbai</h6>
                                    //                 <p>504, Satellite Silver</p>
                                    //                 <p>Andheri Kurla Road,</p>
                                    //                 <p> Andheri</p>
                                    //                 <p>Mumbai 400059</p>
                                    //             </td>
                                    //             <td style={{ width: '31%' }}>
                                    //                 <h6>Bengaluru</h6>
                                    //                 <p>1st Floor, Lewis Workspace</p>
                                    //                 <p>157, Brigade Road</p>
                                    //                 <p>Shanthala Nagar, Ashok Nagar</p>
                                    //                 <p>Bengaluru 560025</p>
                                    //             </td>
                                    //             <td >
                                    //                 <h6>New Delhi</h6>
                                    //                 <p>3rd Floor</p>
                                    //                 <p>Landmark Cyber Park</p>
                                    //                 <p>Sector 67</p>
                                    //                 <p>Gurgaon 122102</p>
                                    //             </td>


                                    //         </tr>
                                    //         <tr>
                                    //             <td style={{ width: '12%' }}></td>
                                    //             <td style={{ width: '35%' }} >
                                    //                 <h6>Hyderabad</h6>
                                    //                 <p>C/o T Hub Foundation, Plot 1/C,83/1 </p>
                                    //                 <p>Raidurg, Panmaktha</p>
                                    //                 <p>Hyderabad Knowledge City, Serilingampally </p>
                                    //                 <p>Hyderabad 500081</p>
                                    //             </td>

                                    //         </tr>
                                    //     </tbody>
                                    // </Table>
                                    <div style={{ paddingTop: '20px' }}>
                                        <div className='d-flex seq-contact-address-section'>
                                            <div className='seq-contact-address-flag'><img src='/images/usa-flag-big.svg' alt='' /></div>
                                            <div className='row' style={{ width: '100%' }}>
                                                <div className='col-6 seq-contact-address'>
                                                    <h6>Arkansas</h6>
                                                    <p>417 Main Street</p>
                                                    <p>Ste 501-2<br />Little Rock, AR 72201</p>
                                                </div>
                                                <div className='col-6 seq-contact-address'>
                                                    <h6>New Jersey</h6>
                                                    <p>100 Overlook Center</p>
                                                    <p>2nd Floor 2109</p>
                                                    <p>Princeton, NJ 08540</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex seq-contact-address-section'>
                                            <div className='seq-contact-address-flag'><img src='/images/ind-flag-big.svg' alt='' /></div>
                                            <div className='row'>
                                                <div className='col-6 seq-contact-address'>
                                                    <h6>Mumbai</h6>
                                                    <p>504, Satellite Silver</p>
                                                    <p>Andheri Kurla Road,</p>
                                                    <p> Andheri</p>
                                                    <p>Mumbai 400059</p>
                                                </div>
                                                <div className='col-6 seq-contact-address'>
                                                    <h6>Bengaluru</h6>
                                                    <p>1st Floor, Lewis Workspace</p>
                                                    <p>157, Brigade Road</p>
                                                    <p>Shanthala Nagar, Ashok Nagar</p>
                                                    <p>Bengaluru 560025</p>
                                                </div>
                                                <div className='col-6 seq-contact-address'>
                                                    <h6>New Delhi</h6>
                                                    <p>3rd Floor</p>
                                                    <p>Landmark Cyber Park</p>
                                                    <p>Sector 67</p>
                                                    <p>Gurgaon 122102</p>
                                                </div>
                                                <div className='col-6 seq-contact-address'>
                                                    <h6>Hyderabad</h6>
                                                    <p className='seq-address-long-text'>C/o T Hub Foundation, Plot 1/C,83/1 </p>
                                                    <p>Raidurg, Panmaktha</p>
                                                    <p>Hyderabad Knowledge City, </p>
                                                    <p className='seq-address-long-text'>Serilingampally, Hyderabad 500081</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    :
                                    <Fragment>
                                        <div style={{ marginTop: 20 }}>
                                            <img src='/images/usa-flag-big.svg' alt='' />
                                            <div className='mt-3 mb-4'>
                                                <h6>417 Main Street</h6>
                                                <p>Ste 501-2<br />Little Rock, AR 72201</p>
                                            </div>
                                            <div className='mb-4'>
                                                <h6>New Jersey</h6>
                                                <p>100 Overlook Center</p>
                                                <p>2nd Floor 2109</p>
                                                <p>Princeton, NJ 08540</p>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 30 }}>
                                            <img src='/images/ind-flag-big.svg' alt='' />
                                            <div className='mt-3 mb-4'>
                                                <h6>Mumbai</h6>
                                                <p>504, Satellite Silver</p>
                                                <p>Andheri Kurla Road, Andheri</p>
                                                <p>Mumbai 400059</p>
                                            </div>
                                            <div className=' mb-4'>
                                                <h6>Bengaluru</h6>
                                                <p>1st Floor, Lewis Workspace</p>
                                                <p>157, Brigade Road</p>
                                                <p>Shanthala Nagar, Ashok Nagar</p>
                                                <p>Bengaluru 560025</p>
                                            </div>
                                            <div className='mb-4'>
                                                <h6>New Delhi</h6>
                                                <p>3rd Floor</p>
                                                <p>Landmark Cyber Park</p>
                                                <p>Sector 67</p>
                                                <p>Gurgaon 122102</p>
                                            </div>
                                            <div className='mb-4'>
                                                <h6>Hyderabad</h6>
                                                <p>C/o T Hub Foundation, Plot 1/C,83/1 </p>
                                                <p>Raidurg, Panmaktha</p>
                                                <p>Hyderabad Knowledge City, Serilingampally </p>
                                                <p>Hyderabad 500081</p>
                                            </div>

                                        </div>
                                    </Fragment>


                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={isConfirm}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={() => setIsConfirm(false)}
                dialogClassName={"confirm-modal"}
            >
                {
                    loading ?
                        null :
                        <div className='close-sticky'>
                            <img src='/images/close.svg' alt='' onClick={() => setIsConfirm(false)} />
                        </div>
                }
                <div className="p-4" >
                    <div>
                        <LoadCanvasTemplate reloadText="Reload" />
                    </div>
                    <div className='form-group mt-3'>
                        <input className='form-control' placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text" />
                    </div>
                    <span style={{ color: 'red' }}>{captchaError}</span>
                    <div className="save-btn1">
                        <button disabled={loading} onClick={() => handleCaptchaSubmit()}>{loading ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>&nbsp;&nbsp;
                    </div>
                </div>
            </Modal>
        </div >
    )
}
