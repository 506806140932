// ############################ Require packages here ############################
import React, { lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// ############################ Require files here ###############################

import './style.scss';
import ScheduleForm from '../scheduleForm';
// import TestimonialSlider from '../testimonialSlider';
import RelatedResources from '../relatedResources';
import { getOneResource } from '../../../Redux/ActionCreators/resourcesAction';
const TestimonialSlider = lazy(() => import("../testimonialSlider"));



const challenges = [{ image: '/images/industry.svg', title: 'Industrial Cybersecurity Breaches', description: 'Cyberattacks are a nightmare as they cripple operations resulting in exposed data, halted operations, financial losses, damaged reputation, and customer migrations. ' },
{ image: '/images/microchip.svg', title: 'New Attack Vectors', description: `Manufacturing's dependence on operational technology (OT) and cost-prioritized IoT devices makes it a prime target for cybercriminals.` },
{ image: '/images/data-transfer.svg', title: 'Remote Access Vulnerabilities', description: 'Complex supply chains and remote vendors create blind spots, rendering internal security inadequate, and adding to cybersecurity challenges.' },
{ image: '/images/brake-warning.svg', title: 'Cyber Sabotage', description: 'Persistent attackers apply AI-based reconnaissance and infiltrate systems, disrupting production by equipment stoppage or undesirable output.' },
{ image: '/images/compliance.svg', title: 'Intellectual Property (IP) Theft', description: 'Cybercriminals steal formulations, patents, and designs to make cheap imitations, costing the industry billions (estimated $600 billion annually).' },
{ image: '/images/globe.svg', title: 'Regulatory Issues', description: `While frameworks and guidelines exist, there are few global regulatory acts to enforce cybersecurity in the manufacturing sector.` },
{ image: '/images/broken-chain-link-wrong.svg', title: 'Inadequate Security', description: `Regardless of company size, disruption due to inadequate cybersecurity measures not only impacts them but also affects the entire supply chain's operations.` },
{ image: '/images/block-star.svg', title: 'Integration Complexity', description: `The integration of legacy and state-of-the-art IT, OT, and Internet of Things (IoT) devices creates a vast attack surface to manage.` },
{ image: '/images/queue.svg', title: 'Unaware Workforce', description: `Companies with under-trained, unaware, or overly curious employees are vulnerable to exploitation by cyber adversaries.` },
{ image: '/images/shield-virus.svg', title: 'Prioritizing Security', description: `Owner-driven companies often fail to prioritize cybersecurity due to budget constraints, apathy, hubris, or lack of security awareness, leaving them vulnerable.` },
]

const helpList = [{ image: '/images/pulse.svg', title: 'Enhanced Security Monitoring', description: `Our Percept Continuous Threat Exposure Management (CTEM) solution proactively defends the IT landscape by integrating with existing legacy and next-gen technologies.` },
{ image: '/images/alert.svg', title: 'Threat Detection and Response', description: 'Advanced cyber risk management with our EDR, XDR & NG SIEM products and services helps monitor, detect, and respond to cyberattacks in real time. ' },
{ image: '/images/user-key.svg', title: 'User Access Management', description: 'Our identity products and services prevent data breaches by continuously monitoring user activity and limiting access to authorized personnel only.' },
{ image: '/images/shield.svg', title: 'Conformance to Compliance Mandates', description: 'Sequretek’s CTEM platform helps generate compliance reports and align with frameworks, standards and guidelines meant for manufacturers.' },
{ image: '/images/house-lock.svg', title: 'Vulnerability Management', description: 'Sequretek strengthens defenses, fixes vulnerabilities, and ensures system stability with regular audits, patching, and critical infrastructure update deployment.' },
{ image: '/images/customer-care-white.svg', title: 'Managed Security Services', description: `Sequretek MSS offers 24/7 onsite/remote services, including audit and advisory, risk and governance, incident response, server, network and perimeter security.` },
]




export default function Manufacturing() {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setPage = (val) => {
        window.scrollTo(0, 0);
        if (val === 'contact') {
            window.scrollTo(0, 4900);
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }

    const downloadFactsheet = async () => {
        const res = await dispatch(getOneResource(['66dad0db1e5c52d1577f917f']))
        if (res?.type === 'GET_ONE_RESOURCES') {
            navigate(`/resources/factsheet/${encodeURIComponent(res.payload?.title)}`, { state: { data: res?.payload } })
        }
    }

    return (
        <div className='seq-content-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sequretek | Build a Resilient Manufacturing Business</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Ensure the resilience of your manufacturing enterprise with real-time cybersecurity. Protect against IT, OT, and IoT vulnerabilities and secure your supply chain." />
                <meta name="keywords" content="industrial cybersecurity, cybersecurity for manufacturing" />
                <meta property="og:title" content="Sequretek | Build a Resilient Manufacturing Business" />
                <meta property="og:description" content="Ensure the resilience of your manufacturing enterprise with real-time cybersecurity. Protect against IT, OT, and IoT vulnerabilities and secure your supply chain." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-content-heading' style={{ backgroundImage: `linear-gradient(4.09deg, rgba(6, 6, 93, 0.7) 4%, rgba(4, 12, 48, 0.9) 18.36%, rgba(4, 12, 48, 0.9) 55.89%, rgba(6, 6, 93, 0.7) 96.66%),url('/images/bg-img8.png')` }}>
                <div className='seq-description seq-des-man'>
                    <h5 data-aos="fade-up">Industries {'>'} Manufacturing </h5>
                    <h2 data-aos="fade-up">Manufacturing the Future?
                        <p>Protect Operations, Build Trust With Every Product</p>
                    </h2>
                    <div data-aos="fade-up" className='text-center'>
                        <button onClick={() => downloadFactsheet()}>Download Factsheet</button>
                    </div>
                </div>
            </div>
            {/* section 2 INFO */}
            <div className='seq-content-1'>
                <div className='container pe-0'>
                    <div className='row  me-0'>
                        <div className='col-md-5 col-sm-12 col-12'>
                            <h4>Manufacturing at the Crossroads: When Efficiency Increases Cyber Risk</h4>
                        </div>
                        <div className='col-md-6 col-sm-12 offset-lg-1 col-12 seq-info-text'>
                            <p>The path to complete digitization in manufacturing is strewn with vulnerability and exploits. While this will lead to incredible efficiency gains, the exposed vulnerabilities in the IT, OT, and IoT environments open a pandora's box of attack vectors. A single attack on anyone in the vast network of suppliers of an Original Equipment Manufacturer (OEM) can cripple the chain bringing services to a grinding halt.</p><br />
                            <p>
                                Cybersecurity for manufacturing is therefore a necessity. Proactive and real-time industrial cybersecurity initiatives to identify, respond, and mitigate threats ensure resilience of the enterprise and ecosystem.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 3 METRICS */}
            <div className='seq-content-2security' data-aos="fade-up" style={{ backgroundImage: `linear-gradient(169.17deg, #040C30 32.48%, rgba(4, 12, 48, 0.9) 56.94%, #050D31 76.15%), url('/images/bg-img9.jpeg')` }}>
                <div className='container' style={{ padding: '0' }}>
                    <div className='seq-cybersecurity'>
                        <div className='seq-cybersecurity-head'>
                            <h4>Manufacturing Cybersecurity Insights</h4>
                        </div>
                        <div className='row seq-cybersecurity-body'>
                            <div className='col-md-4 col-12 seq-cybersecurity-content'>
                                <img src='/images/usd-circle.svg' alt='' style={{ width: "48px", height: "48px" }} loading="lazy" />
                                <div>
                                    <h5 style={{ color: "#FFFFFF" }}>$4.47 million</h5>
                                    <p style={{ color: "#B6BCBF" }}>Value of data breaches</p>
                                </div>
                            </div>
                            <div className='col-md-4 col-12 seq-cybersecurity-content'>
                                <img src='/images/shield-symbol.svg' alt='' style={{ width: "48px", height: "48px" }} loading="lazy" />
                                <div>
                                    <h5 style={{ color: "#FFFFFF" }}>10.9 million</h5>
                                    <p style={{ color: "#B6BCBF" }}>Cyberattacks</p>
                                </div>
                            </div>
                            <div className='col-md-4 col-12 seq-cybersecurity-content'>
                                <img src='/images/up-arr.svg' alt='' style={{ width: "48px", height: "48px" }} loading="lazy" />
                                <div>
                                    <h5 style={{ color: "#FFFFFF" }}>83%</h5>
                                    <p style={{ color: "#B6BCBF" }}>Surge in ransomware victims
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='seq-cybersecurity seq-cybersecurity-man'>
                        {window.innerWidth > 767 ?
                            <div>
                                <div className='seq-cybersecurity-head'>
                                    <h4>Gatekeeping Manufacturing's Defenses</h4>
                                    <p className='d-flex justify-content-center'>Frameworks and standards governing the manufacturing sector:</p>
                                </div>
                                <div className='row seq-cybersecurity-body'>
                                    <div className='col seq-cybersecurity-defences'>
                                        <h5 style={{ color: "#FFFFFF" }}>NIST Cybersecurity Framework</h5>
                                    </div>
                                    <div className='col seq-cybersecurity-defences'>
                                        <h5 style={{ color: "#FFFFFF" }}>IEC 62443 </h5>
                                    </div>
                                    <div className='col seq-cybersecurity-defences'>
                                        <h5 style={{ color: "#FFFFFF" }}>Cyber Resilience Act</h5>
                                    </div>
                                    <div className='col seq-cybersecurity-defences'>
                                        <h5 style={{ color: "#FFFFFF" }}>TISAX</h5>
                                    </div>
                                    <div className='col seq-cybersecurity-defences'>
                                        <h5 style={{ color: "#FFFFFF" }}>ISO 27001</h5>
                                    </div>
                                </div>
                            </div> :
                            <div>
                                <div className='seq-cybersecurity-head'>
                                    <h4>Gatekeeping Manufacturing's Defenses</h4>
                                    <p>Frameworks and standards governing the manufacturing sector:</p>
                                </div>
                                <div className=' seq-cybersecurity-body'>
                                    <div className=' seq-cybersecurity-defences'>
                                        <h5 style={{ color: "#FFFFFF" }}>NIST Cybersecurity Framework</h5>
                                    </div>
                                    <div className=' seq-cybersecurity-defences'>
                                        <h5 style={{ color: "#FFFFFF" }}>IEC 62443 </h5>
                                    </div>
                                    <div className=' seq-cybersecurity-defences'>
                                        <h5 style={{ color: "#FFFFFF" }}>Cyber Resilience Act</h5>
                                    </div>
                                    <div className=' seq-cybersecurity-defences'>
                                        <h5 style={{ color: "#FFFFFF" }}>TISAX</h5>
                                    </div>
                                    <div className=' seq-cybersecurity-defences'>
                                        <h5 style={{ color: "#FFFFFF" }}>ISO 27001</h5>
                                    </div>
                                </div>
                            </div>

                        }

                    </div>
                </div>
            </div>
            {/* section 4 CHALLENGES */}
            <div className='seq-challenge'>
                <h3>Cracks in the Manufacturing Armor</h3>
                <div className='container pe-0'>
                    {
                        window.innerWidth > 767 ?
                            <div className='row me-0 seq-card-items-row'>
                                {
                                    challenges?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-card-items' style={{ flexGrow: 'unset' }}>
                                                <div className='seq-card' style={{ height: 360 }}>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <p>{el.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className='d-flex overflow-auto flex-nowrap'>
                                {
                                    challenges?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='seq-card'>
                                                <img src={el.image} alt='' loading="lazy" />
                                                <h4>{el.title}</h4>
                                                <p>{el.description}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
            {/* section 5 HOW SEQURETEK HELPS */}
            <div className='seq-how-to-helps'>
                <div className='container'>
                    <div className='seq-help-bg ' style={{ backgroundImage: `url('/images/bg-img49.svg')` }}>
                        <div className='row '>
                            <div data-aos="fade-up" className=' col-md-5 col-12 seq-help-left'>
                                <p>How Sequretek Helps</p>
                                <h3>How Sequretek Helps in Fortifying the Factory Floor</h3>
                                <button onClick={() => setPage('contact')}>Get started</button>
                            </div>
                            {window.innerWidth > 767 ?
                                <div className='col-md-4 offset-md-1 seq-ind-qimg'>
                                    <img src='/images/q-icon1.svg' alt='' height={300} loading="lazy" />
                                </div> :
                                null
                            }
                        </div>
                    </div>
                    <div className='seq-help-list '>
                        <div className='row'>
                            {
                                helpList?.map(el => {
                                    return (
                                        <div data-aos="fade-up" className='col-md-4 col-sm-12'>
                                            {window.innerWidth > 767 ?
                                                <div className='seq-card'>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div> :
                                                <div className='seq-card'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex justify-content-center align-items-center'><img src={el.image} alt='' loading="lazy" /></div>
                                                        <h4>{el.title}</h4>
                                                    </div>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* section 6 TESTIMONIAL SLIDER */}
            <div className="seq-quotes">
                <div className='gredient'> </div>
                <TestimonialSlider
                    page={'manufacturing'}
                />
            </div>
            {/* section 7 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container ps-4 pe-4 '>
                    <div className="insurance-partseven" data-aos="fade-up">
                        <ScheduleForm
                            text={'The security of your data and infrastructure is more critical than ever. Secure your sensitive data with Sequretek’s enhanced security monitoring and threat detection and ensure that your critical information remains confidential and protected.'}
                            bg={'/images/bg-img51.svg'}
                            mobilebg={'/images/bg-img50.svg'}
                            page='banking'
                        />
                    </div>
                </div>
            </div>
            {/* section 8 RELATED RESOURCES */}
            <div className='seq-resources'>
                <div className='container'>
                    <h4>Related resources</h4>
                    <div className='row ms-0 me-0'>
                        <div className='col-md-12 ps-0'>
                            <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto'}>
                                <RelatedResources
                                    page='manufacturing'
                                    type=''
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Resource Center</button></div>}
        </div >
    )
}
