// ############################ Require packages here ############################
import React, { useRef, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha,
} from "react-simple-captcha";
// ############################ Require files here ###############################

import countries from '../../utils/countries.json';
import { submitScheduleDemoForm } from '../../Redux/ActionCreators/contactusAction';


export default function ScheduleForm({ bg, mobilebg, page, text }) {
    const [loading, setLoading] = useState(false);
    const [captchaError, setCaptchaError] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [data, setData] = useState(null);
    const initialValues = {
        fname: '', lname: '', organization: '', email: '', dial_code: "", mobile: '', products: [], services: [], country: "", message: "", newsletter: false
    };
    const formikRef = useRef();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const validationSchema =
        Yup.object({
            fname: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true)
                .matches(/^[a-zA-Z0-9\s]+$/, 'First Name should only contain alphabets, numbers, and spaces')
                .required('First Name is required'),
            lname: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true)
                .matches(/^[a-zA-Z0-9\s]+$/, 'Last Name should only contain alphabets, numbers, and spaces')
                .required('Last Name is required'),
            organization: Yup.string()
                .trim('No extra space allowed')
                .strict(true)
                .matches(/^[a-zA-Z0-9\s]+$/, 'Organization should only contain alphabets, numbers, and spaces')
                .required('Organization is required'),
            email: Yup.string()
                .trim('No extra space allowed')
                .strict(true)
                .required('Email is required')
                .matches(/^([\w.-]+)@(?!hotmail\.com|gmail\.com|googlemail\.com|yahoo\.com|gmx\.com|ymail\.com|outlook\.com|bluewin\.ch|protonmail\.com|t-online\.de|web\.de|online\.de|aol\.com|live\.com)([a-zA-Z\d-]+\.)+([a-zA-Z]{2,63})$/, 'Enter Only Business email address'),
            mobile: Yup.string()
                .trim('Only Numbers allowed')
                .strict(true)
                .matches(/^\d{10}$/, 'Phone number must be 10 digits')
                .required('Phone is required'),
            country: Yup.object()
                .shape({
                    value: Yup.string().required('You must select an option'),
                })
                .nullable() // allow null if you want to allow resetting to null
                .required('Country is Required'),
            products: Yup.array()
                .min(1, 'You must select at least one products')
                .required('Required'),
            services: Yup.array()
                .min(1, 'You must select at least one services')
                .required('Required'),
            message: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true)
                .max(2000, "Maximum 2000 characters allowed")
                .matches(/^[a-zA-Z0-9\s]+$/, 'Message should only contain alphabets, numbers, and spaces')
                .notRequired(''),
        });

    const serviceSchema =
        Yup.object({
            fname: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true,)
                .matches(/^[a-zA-Z0-9\s]+$/, 'First Name should only contain alphabets, numbers, and spaces')
                .required('First Name is required'),
            lname: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true)
                .matches(/^[a-zA-Z0-9\s]+$/, 'Last Name should only contain alphabets, numbers, and spaces')
                .required('Last Name is required'),
            organization: Yup.string()
                .trim('No extra space allowed')
                .strict(true)
                .matches(/^[a-zA-Z0-9\s]+$/, 'Organization should only contain alphabets, numbers, and spaces')
                .required('Organization is required'),
            email: Yup.string()
                .trim('No extra space allowed')
                .strict(true)
                .required('Email is required')
                .matches(/^([\w.-]+)@(?!hotmail\.com|gmail\.com|googlemail\.com|yahoo\.com|gmx\.com|ymail\.com|outlook\.com|bluewin\.ch|protonmail\.com|t-online\.de|web\.de|online\.de|aol\.com|live\.com)([a-zA-Z\d-]+\.)+([a-zA-Z]{2,63})$/, 'Enter Only Business email address'),
            mobile: Yup.string()
                .trim('Only Numbers allowed')
                .strict(true)
                .matches(/^\d{10}$/, 'Phone number must be 10 digits')
                .required('Phone is required'),
            country: Yup.object()
                .shape({
                    value: Yup.string().required('You must select an option'),
                })
                .nullable() // allow null if you want to allow resetting to null
                .required('Country is Required'),
            services: Yup.array()
                .min(1, 'You must select at least one services')
                .required('Services is Required'),
            message: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true)
                .max(1500, "Maximum 1500 characters allowed")
                .matches(/^[a-zA-Z0-9\s]+$/, 'Message should only contain alphabets, numbers, and spaces')
                .notRequired(''),
        });


    const handleCountryChange = (event, setFieldValue) => {
        setFieldValue('country', event ? event : '');
        setFieldValue('dial_code', event ? event.dial_code : '');
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        let obj = {
            "fname": values?.fname,
            "lname": values?.lname,
            "organization": values.organization,
            "email": values?.email,
            "mobile": `${values?.dial_code}${values?.mobile}`,
            "country": values?.country?.value,
            "products": values?.products,
            "services": values?.services,
            "message": values?.message,
            "newsletter": values?.newsletter
        }
        setData(obj);
        setIsConfirm(true);
        setSubmitting(false);
        setTimeout(() => {
            loadCaptchaEnginge(6);
        }, 500);
    }
    const handleCaptchaSubmit = async () => {
        let user_captcha_value = document.getElementById('user_captcha_input').value;
        if (validateCaptcha(user_captcha_value, false) === true) {
            console.log('matched')
            setCaptchaError('');
            await setLoading(true);
            const res = await dispatch(submitScheduleDemoForm(data));
            if (res?.type === 'DEMO_SUBMIT') {
                if (formikRef.current) {
                    formikRef.current.resetForm();
                    formikRef.current.setFieldValue('country', null);
                    formikRef.current.setFieldValue('newsletter', false);
                    setIsConfirm(false);
                }
            }
            setTimeout(() => {
                setLoading(false)
            }, 3000);
        }

        else {
            setCaptchaError('Captcha Does Not Match');
        }
    }

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#040c30',
            zIndex: 3
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'lightblue' : '#040c30', // Change background color on selection
            color: '#b6bcbf', // Ensure text is visible
            ':hover': {
                color: '#000',
                backgroundColor: '#b6bcbf' // Change background color on hover
            }
        })
    };



    const navigateTo = () => {
        window.scrollTo(0, 0);
        navigate('/schedule-a-demo', { state: { type: page === 'Sequretek Services' ? 'service' : '' } })
    }

    // SCHEDULE FORM  WAS BEEN USED IN Insurance,banking,Manufacturing,Financial Services,Government,Healthcare,Pharmaceuticals,Retail,Services,Small & Medium Businesses,PLATFORM,Percept Identity,Percept XDR and NGSIEM,Percept EDR,Percept Compliance ManageResources,Home Page, ALL BLOG PAGES REPORTS , VIDEO, SERVICES pages,Why Sequretek

    return (
        <div className=" seq-form  row ">
            {/* section 2 LEFT SECTION */}
            <div className="col-lg-3 col-sm-12 left-section" style={{ backgroundImage: window.innerWidth > 767 ? `linear-gradient(180deg, #0e49b5 0.24%, rgba(0, 0, 0, 0) 100%),url(${bg})` : `url(${mobilebg})` }}>
                <div className='left-section-text'>
                    <img className='justify-content-start' src='/images/lightbulb-on.svg' alt='' />
                    <h2>Ready to Protect Your Enterprise?</h2>
                </div>
                <p className='justify-content-between'>{text}</p>
                {window.innerWidth > 767 ? null : <div className='insu-btn' onClick={() => navigateTo()} ><Button className='insu-demo-btn' >{page === 'Sequretek Services' ? 'Get Started' : 'Schedule a demo'}</Button> </div>}
            </div>
            {window.innerWidth > 767 ?
                <div className="col-lg-9 col-sm-12 form-section">
                    {/* section 2-2 RIGHT SECTION */}
                    <div className='right-section' >
                        <h3>Request a discussion or schedule a demo</h3>
                        <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={window.location.pathname === '/services' ? serviceSchema : validationSchema} onSubmit={handleSubmit}>
                            {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                                <Form className='input-form'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <div className='form-group mt-2'>
                                                    <Field
                                                        type="text"
                                                        name="fname"
                                                        placeholder="First Name*"
                                                        className='form-control'
                                                        autoComplete='off'
                                                    />
                                                    <ErrorMessage name="fname" component="div" className="error-message" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group mt-2'>
                                                <Field
                                                    type="text"
                                                    name="lname"
                                                    placeholder="Last Name*"
                                                    className='form-control'
                                                    autoComplete='off'
                                                />
                                                <ErrorMessage name="lname" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group mt-2'>
                                                <Field
                                                    type="text"
                                                    name="organization"
                                                    placeholder="Organization*"
                                                    className='form-control'
                                                    autoComplete='off'
                                                />
                                                <ErrorMessage name="organization" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group mt-2' >
                                                <Field
                                                    type="email"
                                                    name="email"
                                                    placeholder="Business Email*"
                                                    className='form-control'
                                                    autoComplete='off'
                                                />
                                                <ErrorMessage name="email" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group mt-2' >
                                                <Select
                                                    name="country"
                                                    id="singleSelectCustom"
                                                    placeholder="Select Country"
                                                    isMulti={false}
                                                    isClearable
                                                    isSearchable
                                                    value={values.country}
                                                    styles={customStyles}
                                                    onChange={(event) => handleCountryChange(event, setFieldValue)}
                                                    options={countries?.map(el => [{ ...el, label: el.name, value: el.name }][0])}
                                                />
                                                {errors.country?.value && touched.country ? (
                                                    <div className="error-message">{errors.country.value}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group mt-2 d-flex' >
                                                <Field
                                                    style={{ width: '80px' }}
                                                    name="dial_code"
                                                    placeholder=""
                                                    className='form-control'
                                                    autoComplete='off'
                                                    disabled
                                                />
                                                <Field
                                                    type="tel"
                                                    name="mobile"
                                                    maxLength={10}
                                                    placeholder="Phone*"
                                                    className='form-control'
                                                    autoComplete='off'
                                                />
                                            </div>
                                            <ErrorMessage name="mobile" component="div" className="error-message" />
                                        </div>
                                    </div>
                                    <div className='row prods-serves'>
                                        {window.location.pathname === '/services' ? null :
                                            <div className=" products-list col-sm-6">
                                                <label className='label-head'>Products Interested In</label>
                                                <div className='prods-input'>
                                                    <div className="form-check label-select">
                                                        <Field className="form-check-input " type="checkbox" id="products" value='Percept XDR and NG SIEM' name='products' />
                                                        <label className="form-check-label" htmlFor="products">
                                                            Percept XDR & NG SIEM
                                                        </label>
                                                    </div>
                                                    <div className="form-check label-select">
                                                        <Field className="form-check-input " type="checkbox" id="products" name='products' value={'Percept EDR'} />
                                                        <label className="form-check-label" htmlFor="products">
                                                            Percept EDR
                                                        </label>
                                                    </div>
                                                    <div className="form-check label-select">
                                                        <Field className="form-check-input " type="checkbox" id="products" name='products' value={'Percept Identity'} />
                                                        <label className="form-check-label" htmlFor="products">
                                                            Percept Identity
                                                        </label>
                                                    </div>
                                                    <div className="form-check label-select">
                                                        <Field className="form-check-input " type="checkbox" id="products" name='products' value={'Percept Compliance Manager'} />
                                                        <label className="form-check-label" htmlFor="products">
                                                            Percept Compliance Manager
                                                        </label>
                                                    </div>
                                                    <ErrorMessage name="products" component="div" className="error-message" />
                                                </div>
                                            </div>}
                                        <div className="col-md-4 col-sm-6 products-list service-list">
                                            <label className='label-head'>Services Interested In</label>
                                            <div className='prods-input service-list-item'>
                                                <div className="form-check label-select">
                                                    <Field className="form-check-input" type="checkbox" id="services" name='services' value={'Governance Risk and Compliance'} />
                                                    <label className="form-check-label" htmlFor="services">
                                                        Governance Risk and Compliance
                                                    </label>
                                                </div>
                                                <div className="form-check label-select">
                                                    <Field className="form-check-input" type="checkbox" id="services" name='services' value={'Security Operations and Management'} />
                                                    <label className="form-check-label" htmlFor="services">
                                                        Security Operations and Management
                                                    </label>
                                                </div>
                                                <div className="form-check label-select">
                                                    <Field className="form-check-input" type="checkbox" value="Incident Response and Management" id="services" name='services' />
                                                    <label className="form-check-label" htmlFor="services">
                                                        Incident Response and Management
                                                    </label>
                                                </div>
                                                <div className="form-check label-select">
                                                    <Field className="form-check-input" type="checkbox" value="Patch Management as a Service" id="services" name='services' />
                                                    <label className="form-check-label" htmlFor="services">
                                                        Patch Management as a Service
                                                    </label>
                                                </div>
                                                <ErrorMessage name="services" component="div" className="error-message" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='msg-feld'>
                                        <div className="mb-3">
                                            <label className='message-text d-flex'>Your Message &nbsp; </label>
                                            <p className='text-restrictions'>Only alphanumerical characters are allowed</p>
                                            <div className='form-group' >
                                                <Field
                                                    type="text"
                                                    as='textarea'
                                                    rows={5}
                                                    maxLength="250"
                                                    name="message"
                                                    placeholder="How can we help?"
                                                    className='form-control text-field'
                                                    autoComplete='off'
                                                />
                                                <ErrorMessage name="message" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className="form-check mb-3 d-flex align-items-center">
                                            <input className="form-check-input" type="checkbox"
                                                checked={values.newsletter}
                                                onChange={() => setFieldValue('newsletter', !values.newsletter)}
                                                id="newsletter" name='newsletter'
                                            />
                                            <label className="form-check-label input-check-box" htmlFor="newsletter">
                                                Receive latest threat advisory and newsletters from Sequretek
                                            </label>
                                        </div>
                                        {loading ?
                                            <button type='submit' className='input-submit-btn btn btn-danger' style={{ backgroundColor: '#1BA431', borderColor: '#1BA431' }} ><img src='/images/tick-white.svg' alt='' /></button> :
                                            <button className='input-submit-btn btn btn-danger' disabled={isSubmitting} type="submit">{isSubmitting ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>
                                        }
                                        {loading ?
                                            <div className='mt-3'>
                                                <p>Thanks for getting in touch. We'll come back to you shortly</p>
                                            </div> :
                                            null
                                        }
                                        <div className='submit-text'>
                                            <p>Your privacy matters. By filling out this form, you consent to the collection, storage, and use of your PII as per our privacy policy. We will protect your data and not share it with third parties without your consent, except as required by law.</p>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                :
                null
            }
            <Modal
                show={isConfirm}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={() => setIsConfirm(false)}
                dialogClassName={"confirm-modal"}
            >
                {loading ? null :
                    <div className='close-sticky'>
                        <img src='/images/close.svg' alt='' onClick={() => setIsConfirm(false)} />
                    </div>}
                <div className="p-4" >
                    <div>
                        <LoadCanvasTemplate reloadText="Reload" />
                    </div>

                    <div className='form-group mt-3'>
                        <input className='form-control' placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text" />
                    </div>
                    <span style={{ color: 'red' }}>{captchaError}</span>
                    <div className="save-btn1">
                        <button disabled={loading} onClick={() => handleCaptchaSubmit()}>{loading ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
