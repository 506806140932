// ############################ Require packages here ############################
import React, { Fragment } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';


// ############################ Require files here ###############################

import MultiSelect from '../../../utils/customSelect';
import DialCodes from '../../../utils/dialCodes.json';
import { Spinner } from 'react-bootstrap';



export default function AddUser({ partnerRoles, roles, setIsAdd, handleSubmit, userDetails, isEdit, countries, partnerDetails, activeTab, handlePartnerSubmit }) {

    const validationSchema = Yup.object({
        firstname: Yup.string()
            .trim('Only alphanumricals allowed')
            .strict(true)
            .matches(/^[a-zA-Z0-9\s]+$/, 'First Name should only contain alphabets, numbers, and spaces')
            .required('First Name is required'),
        lastname: Yup.string()
            .trim('Only alphanumricals allowed')
            .strict(true)
            .matches(/^[a-zA-Z0-9\s]+$/, 'Last Name should only contain alphabets, numbers, and spaces')
            .required('Last Name is required'),
        email: Yup.string()
            .required('Email is required')
            .matches(/^([\w.-]+)@(?!hotmail\.com|gmail\.com|googlemail\.com|yahoo\.com|gmx\.com|ymail\.com|outlook\.com|bluewin\.ch|protonmail\.com|t-online\.de|web\.de|online\.de|aol\.com|live\.com)([a-zA-Z\d-]+\.)+([a-zA-Z]{2,63})$/, 'Enter Only Business email address'),
        dial_code: Yup.string().required('Dial code is required'),
        mobile: Yup.string()
            .matches(/^[0-9]{7,14}$/, 'Phone number is not valid')
            .required('Phone number is required')
            .test('combinedPhone', 'Phone number is not valid with dial code', function (value) {
                const { dial_code } = this.parent;
                return value && dial_code ? (value).length === 10 : false;
            }),
        role: Yup.string()
            .required('Role is required')
    });

    const partnerSchema = Yup.object({
        firstname: Yup.string()
            .matches(/^[a-zA-Z0-9\s\-()&,]+$/, 'First Name should have alphabets, numbers, space, hyphen, brackets, comma and ampersand only')
            .required('First Name is required'),
        lastname: Yup.string()
            .matches(/^[a-zA-Z0-9\s\-()&,]+$/, 'Last Name should have alphabets, numbers, space, hyphen, brackets, comma and ampersand only')
            .required('Last Name is required'),
        email: Yup.string()
            .required('Email is required')
            .matches(/^([\w.-]+)@(?!hotmail\.com|gmail\.com|googlemail\.com|yahoo\.com|gmx\.com|ymail\.com|outlook\.com|bluewin\.ch|protonmail\.com|t-online\.de|web\.de|online\.de|aol\.com|live\.com)([a-zA-Z\d-]+\.)+([a-zA-Z]{2,63})$/, 'Enter Only Business email address'),
        dial_code: Yup.string().required('Dial code is required'),
        mobile: Yup.string()
            .matches(/^[0-9]{7,14}$/, 'Phone number is not valid')
            .required('Phone number is required')
            .test('combinedPhone', 'Phone number is not valid with dial code', function (value) {
                const { dial_code } = this.parent;
                return value && dial_code ? (value).length === 10 : false;
            }),
        country: Yup.string()
            .required('Country is required'),
        joined: Yup.string()
            .required('Joined date is required'),
        city: Yup.string().required('City is required'),
        pincode: Yup.string().matches(/^[0-9]{6}$/, 'Pincode must be 6 numeric digits'),
    });


    return (
        <Fragment>
            {/* ADD USER SCREEN */}
            <div className='add-screen' >
                <Formik initialValues={activeTab === 'partner' ? partnerDetails : userDetails} validationSchema={activeTab === 'partner' ? partnerSchema : validationSchema} onSubmit={activeTab === 'partner' ? handlePartnerSubmit : handleSubmit}>
                    {({ setFieldValue, values, isSubmitting }) => (
                        <Form className="users_form">
                            <div>
                                <h2 className="d-flex align-items-center">
                                    <img src='/images/back.svg' alt='' onClick={() => setIsAdd(false)} className='cursor-pointer' /> &nbsp;&nbsp;&nbsp;{activeTab === 'partner' ? (isEdit ? 'Edit Partner' : 'Add Partner') : (isEdit ? 'Edit User' : 'Add User')}

                                </h2>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-md-6'>
                                    <div className='form-group mt-2'>
                                        <label>First Name</label>
                                        <div className="has-search">
                                            <span className="form-control-feedback">
                                                <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                            </span>
                                            <Field
                                                type="text"
                                                name="firstname"
                                                placeholder="Enter First Name"
                                                className='form-control'
                                            />
                                            <ErrorMessage name="firstname" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mt-2'>
                                        <label>Last Name</label>
                                        <div className="has-search">
                                            <span className="form-control-feedback">
                                                <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                            </span>
                                            <Field
                                                type="text"
                                                name="lastname"
                                                placeholder="Enter Last Name"
                                                className='form-control'
                                            />
                                            <ErrorMessage name="lastname" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mt-2'>
                                        <label>Email Id</label>
                                        <div className="has-search">
                                            <span className="form-control-feedback">
                                                <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                            </span>
                                            <Field
                                                type="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                className='form-control'
                                            />
                                            <ErrorMessage name="email" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mt-2'>
                                        <label>Phone Number</label>
                                        <div className=" d-flex">
                                            <Field
                                                className='select-cur'
                                                name="dial_code"
                                                placeholder=""
                                                isMulti={false}
                                                isCreatable={false}
                                                component={MultiSelect}
                                                options={DialCodes?.map(el => [{ label: el.code, value: el.code }][0])}
                                            />
                                            <Field
                                                type="text"
                                                name="mobile"
                                                placeholder="Enter Phone number"
                                                className='form-control ms-1'
                                            />
                                        </div>
                                        <ErrorMessage name="mobile" component="div" className="error-message" />
                                        <ErrorMessage name="dial_code" component="div" className="error-message" />
                                    </div>
                                </div>
                                {
                                    activeTab === 'partner' ?
                                        <div className='col-md-6'>
                                            <div className='form-group mt-2'>
                                                <label>Company</label>
                                                <div className="has-search">
                                                    <span className="form-control-feedback">
                                                        <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                                    </span>
                                                    <Field
                                                        type="text"
                                                        name="company"
                                                        className='form-control'
                                                        placeholder='Enter company name'
                                                    />
                                                    <ErrorMessage name="company" component="div" className="error-message" />
                                                </div>
                                            </div>
                                        </div> :
                                        null
                                }
                                {
                                    activeTab === 'partner' ?
                                        <div className='col-md-6'>
                                            <div className='form-group mt-2'>
                                                <label>Joined Date</label>
                                                <div className="has-search">
                                                    <span className="form-control-feedback" style={{ zIndex: 1 }}>
                                                        <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                                    </span>
                                                    <Field
                                                        type="date"
                                                        name="joined"
                                                        placeholder=""
                                                        className='picker form-control'
                                                        max={new Date().toISOString().split('T')[0]}
                                                    />
                                                    <ErrorMessage name="joined" component="div" className="error-message" />
                                                </div>
                                            </div>
                                        </div> :
                                        null
                                }
                                <div className='col-md-6'>
                                    {activeTab === 'partner' ?
                                        <div className='form-group mt-2'>
                                            <label>Country</label>
                                            <div className="has-search">
                                                <span className="form-control-feedback">
                                                    <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                                </span>
                                                <Field as="select" name="country" className="form-control">
                                                    <option hidden>Select Country</option>
                                                    {
                                                        countries?.map((el) => {
                                                            return (
                                                                <option value={el.value}>{el.label}</option>
                                                            )
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="country" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        : <div className='form-group mt-2'>
                                            <label>Role</label>
                                            {((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ?
                                                <Field
                                                    name="role"
                                                    id="singleSelectCustom3"
                                                    placeholder="Select Role"
                                                    isMulti={false}
                                                    isCreatable={false}
                                                    component={MultiSelect}
                                                    options={partnerRoles}
                                                /> :
                                                <Field
                                                    name="role"
                                                    id="singleSelectCustom3"
                                                    placeholder="Select Role"
                                                    isMulti={false}
                                                    isCreatable={false}
                                                    component={MultiSelect}
                                                    options={roles}
                                                />
                                            }
                                            <ErrorMessage name="role" component="div" className="error-message" />
                                        </div>
                                    }
                                </div>
                                {activeTab === 'partner' ?
                                    <Fragment>
                                        <div className='col-md-6'>
                                            <div className='form-group mt-2'>
                                                <label>City</label>
                                                <div className="has-search">
                                                    <span className="form-control-feedback">
                                                        <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                                    </span>
                                                    <Field
                                                        type="text"
                                                        name="city"
                                                        placeholder="Enter city"
                                                        className='form-control'
                                                    />
                                                    <ErrorMessage name="city" component="div" className="error-message" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group mt-2'>
                                                <label>Pincode</label>
                                                <div className="has-search">
                                                    <span className="form-control-feedback">
                                                        <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                                    </span>
                                                    <Field
                                                        type="text"
                                                        name="pincode"
                                                        placeholder="Enter pincode"
                                                        className='form-control'
                                                    />
                                                    <ErrorMessage name="pincode" component="div" className="error-message" />
                                                </div>
                                            </div>
                                        </div>

                                    </Fragment> :
                                    null
                                }
                            </div>
                            <div >
                                <button className='invite-btn' disabled={isSubmitting} style={{ width: isSubmitting ? 110 : '' }} type='submit' >{isSubmitting ? <Spinner animation="border" variant="light" /> : 'Send Invite'}</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div >
        </Fragment>
    )
}
