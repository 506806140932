// ############################ Require packages here ############################
import React, { Fragment, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useDispatch, useSelector } from 'react-redux';
import { resetDashboardData, getLeadsDetail, getTicketDetails, getRecentLeads, getRecentDeals, getRecentTickets, getLoginTrend, getResouceTrend, getRecentResource } from '../../../Redux/ActionCreators/dashboardAction';
import Select, { components } from 'react-select';
import { useNavigate } from 'react-router-dom';


// ############################ Require files here ###############################

import './dashboard.scss';
import { getAllPartners } from '../../../Redux/ActionCreators/leadsAction';


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/arrow-down.svg' alt='' width={18} />
        </components.DropdownIndicator>
    );
};
export default function Dashboard() {
    const [partner, setPartner] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, ticketMetrics, leadAdded, leadApproved, months, recentLeads, recentDeals, recentTickets, loginMetric, contentMonth, resourceCount, recentResources } = useSelector(state => state.dashboard);
    const { allPartners } = useSelector(state => state.leads);


    useEffect(() => {

        if ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) {
            const details = JSON.parse(localStorage.getItem('profileDetails'));
            dispatch(getTicketDetails(details?.partner_id));
            dispatch(getLeadsDetail(details?.partner_id));
            dispatch(getLoginTrend(details?.partner_id));
            dispatch(getResouceTrend(details?.partner_id));
            dispatch(getRecentLeads(details?.partner_id));
            dispatch(getRecentTickets(details?.partner_id));
            dispatch(getRecentResource(details?.partner_id));
        } else {
            dispatch(getTicketDetails());
            dispatch(getLeadsDetail());
            dispatch(getLoginTrend());
            dispatch(getResouceTrend());
            dispatch(getRecentLeads());
            dispatch(getRecentTickets());
            dispatch(getRecentResource());
            (localStorage.getItem('role') === 'sales') && dispatch(getRecentDeals());
            dispatch(getAllPartners());
        }
    }, []);

    const selectPartner = async (e) => {
        await dispatch(resetDashboardData())
        setPartner(e ? e : null);
        dispatch(getLoginTrend(e ? e.value : ''));
        dispatch(getResouceTrend(e ? e.value : ''));
        dispatch(getTicketDetails(e ? e.value : ''));
        dispatch(getLeadsDetail(e ? e.value : ''));
        dispatch(getRecentLeads(e ? e.value : ""));
        dispatch(getRecentTickets(e ? e.value : ""));
        dispatch(getRecentResource(e ? e.value : ""));
    }

    const leadsGraph = {
        chart: {
            type: 'column'
        },

        title: {
            text: '',
            align: 'left'
        },
        credits: {
            enabled: false
        },

        xAxis: {
            categories: months
        },

        yAxis: {
            allowDecimals: false,
            enabled: false,
            min: 0,
            title: {
                text: ''
            }
        },

        tooltip: {
            // enabled: false,
            format: '<b>{key}</b><br/>{series.name}: {y}<br/>' +
                'Total: {point.stackTotal}'
        },

        plotOptions: {
            column: {
                stacking: 'normal',

            },
            series: {
                pointWidth: 30
            }
        },

        series: [{
            name: 'Leads Added',
            data: leadAdded,
            stack: 'Leads',
            color: '#0E49B5'
        }, {
            name: 'Leads Approved',
            data: leadApproved,
            stack: 'Leads',
            color: "#2076CD"

        }
        ]
    }

    const loginTrend = {
        chart: {
            type: 'line',
            height: 200
        },
        title: {
            text: ''
        },
        subtitle: {
            // text: '3,456 Students'
        },
        xAxis: {
            categories: loginMetric?.map(el => el._id),
            visible: true
        },
        colors: ['#ECC30B'],
        yAxis: {
            visible: false,
            min: 0,
            // max:100,
            tickInterval: 10,
            title: {
                //   text: 'Temperature (°C)'
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                showInLegend: false,
                enableMouseTracking: false
            }
        },
        series:
            [{
                name: 'Login',
                data: loginMetric?.map(el => el.count)
            },
            ]
    }



    const contentConsumption = {
        chart: {
            type: 'column',
            height: localStorage.getItem('role') === 'sales' ? '' : 218,
        },
        legend: {
            enabled: false
        },

        title: {
            text: ''
        },

        colors: ['#535259'],
        xAxis: {
            categories: contentMonth,
            type: 'Months',
            // visible: false
        },
        yAxis: {
            // type: 'Scores',

            // tickInterval: 30,
            visible: false,
            title: {
                text: ''
            },

        },
        plotOptions: {
            // column: {
            //     borderRadiusTopLeft: 3,
            //     borderRadiusTopRight: 3,
            // },
            series: {
                animation: false,
                pointWidth: 30,
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: false,
                    formatter: function () {
                        // eslint-disable-next-line eqeqeq
                        if (Math.max(this.y)) {
                            return '<tspan style="color:' + this.color + '">' + this.y + '</tspan>';
                        }
                    }

                }
            }
        },
        credits: {
            enabled: false
        },
        series: [
            {
                enableMouseTracking: false,
                showInLegend: false,
                grouping: false,
                data: resourceCount,
            }
        ]
    }
    return (
        <div className='seq-dashboard'>
            {/* DASHBOARD HEADING */}
            <div className='d-flex justify-content-between'>
                <h2>Dashbaord</h2>
                {((localStorage.getItem('role') === 'admin')) ?
                    <div>
                        <Select
                            components={{ DropdownIndicator }}
                            options={allPartners}
                            isClearable
                            onChange={selectPartner}
                            placeholder='By Partner'
                            value={partner}
                        />
                    </div> :
                    null
                }
            </div>
            {//dashboard for supports
                localStorage.getItem('role') === 'support' ?
                    <Fragment>
                        {/* DASHBOARD GRAPH */}
                        <div className='row'>
                            <div className='col-md-12'>

                                <div className='row mt-2'>
                                    <div className='dashboard-item1'>
                                        <h4>
                                            <img src='/images/ticket.svg' alt='' />&nbsp;&nbsp;All Tickets
                                        </h4>
                                        <h1>{ticketMetrics?.total}</h1>

                                    </div>
                                    <div className='dashboard-item1'>
                                        <h4>
                                            <img src='/images/inprogres.svg' alt='' />&nbsp;&nbsp;In-Progress
                                        </h4>
                                        <h1>{ticketMetrics?.open}</h1>

                                    </div>
                                    <div className='dashboard-item1'>
                                        <h4>
                                            <img src='/images/resolved.svg' alt='' />&nbsp;&nbsp;Closed
                                        </h4>
                                        <h1>{ticketMetrics?.closed}</h1>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* DASHBOARD LEADS */}
                        <div className='row mt-3'>
                            {/* DASHBOARD CONTENT LEADS */}
                            <div className='ps-0 col-md-4'>
                                <div className='content-lists'>
                                    <div className='d-flex justify-content-between'>
                                        <h2>Open tickets</h2><span className='cursor-pointer' onClick={() => navigate('/app/supports')}>View All</span>
                                    </div>
                                    {
                                        recentTickets && recentTickets?.map(el => {
                                            return (
                                                <div className='list-item px-0'>
                                                    <div className='d-flex align-items-center' style={{ width: '70%' }}>
                                                        <div style={{ width: '95%' }} >
                                                            <h5 className='elipsis'>{el.question}</h5>
                                                            <p>{el.type}</p>
                                                        </div>
                                                    </div>
                                                    {el.closed ?
                                                        <div className='inprogress resolved'>
                                                            Closed
                                                        </div>
                                                        : <div className='inprogress'>
                                                            In Progress
                                                        </div>}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className='ps-0 col-md-4'>
                                <div className='content-lists'>
                                    <div className='seq-login-graph p-0'>
                                        <div>
                                            <h3 className='d-flex align-items-center'> <img src='/images/consume.svg' alt='' />&nbsp;&nbsp;Content Consumption</h3>
                                        </div>
                                        {
                                            resourceCount?.length > 0 ?
                                                <div className='mt-5'>
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={contentConsumption}

                                                    />
                                                </div> :
                                                <div className='d-flex justify-content-center align-items-center' style={{ height: 250 }}>
                                                    Data will available soon
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* DASHBOARD CONTENT LEADS 2*/}
                            <div className='col-md-4 ps-0'>
                                <div className='content-lists'>
                                    <div className='d-flex justify-content-between'>
                                        <h2>Content Consumed</h2><span className='cursor-pointer' onClick={() => navigate('/app/consumed-resources', { state: { id: partner?.value } })}>View All</span>
                                    </div>
                                    {
                                        recentResources && recentResources.slice(0, 4)?.map(el => {
                                            return (
                                                <div className='list-item'>
                                                    <div className='d-flex align-items-center'>
                                                        {el.type === 'blog' ?
                                                            <img src='/images/blog-icon-color.svg' alt='' /> :
                                                            el.type === 'video' ?
                                                                <img src='/images/video-icon-color.svg' alt='' /> :
                                                                el.type === 'infographic' ?
                                                                    <img src='/images/infographics-color.svg' alt='' /> :
                                                                    el.type === 'report' ?
                                                                        <img src='/images/report-icon-color.svg' alt='' /> :
                                                                        el.type === 'case-study' ?
                                                                            <img src='/images/case-study-icon-color.svg' alt='' /> :
                                                                            el.type === 'whitepaper' ?
                                                                                <img src='/images/whitepaper-icon-color.svg' alt='' /> :
                                                                                <img src='/images/blog-icon-color.svg' alt='' />
                                                        }
                                                        <div className='ms-2'>
                                                            <h5>{el.title}</h5>
                                                            <p>{el.type}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </Fragment> :
                    //dashboard for sales
                    localStorage.getItem('role') === 'sales' ?
                        <Fragment>
                            {/* DASHBOARD GRAPH */}
                            <div className='row'>
                                <div className='col-md-7'>
                                    <div className='row'>
                                        <div className='seq-leads-graph'>
                                            <div>
                                                <h3 className='d-flex align-items-center'> <img src='/images/add-icon.svg' alt='' />&nbsp;&nbsp;Leads added</h3>
                                            </div>
                                            {isLoading ?
                                                '' :
                                                months?.length ?
                                                    <div className='mt-5'>
                                                        <HighchartsReact
                                                            highcharts={Highcharts}
                                                            options={leadsGraph}
                                                        />
                                                    </div> :
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: 250 }}>
                                                        <button className='add-btn' onClick={() => navigate('/app/leads')}>Add Lead</button>
                                                    </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className='col-md-5'>
                                    <div className='seq-login-graph' style={{ height: localStorage.getItem('role') === 'sales' ? 510 : 327 }}>
                                        <div>
                                            <h3 className='d-flex align-items-center'> <img src='/images/consume.svg' alt='' />&nbsp;&nbsp;Content Consumption</h3>
                                        </div>
                                        {
                                            resourceCount?.length > 0 ?
                                                <div className='mt-5'>
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={contentConsumption}

                                                    />
                                                </div> :
                                                <div className='d-flex justify-content-center align-items-center' style={{ height: 250 }}>
                                                    Data will available soon
                                                </div>

                                        }
                                    </div>
                                </div>
                            </div>
                            {/* DASHBOARD LEADS */}
                            <div className='row mt-3'>
                                <div className='ps-0 col-md-4'>
                                    <div className='content-lists'>
                                        <div className='d-flex justify-content-between'>
                                            <h2>Recent Leads</h2><span className='cursor-pointer' onClick={() => navigate('/app/leads')}>View All</span>
                                        </div>
                                        {
                                            recentLeads && recentLeads?.map(el => {
                                                return (
                                                    <div className='list-item'>
                                                        <div className='d-flex align-items-center'>
                                                            {/* <img src='/images/avatar.svg' alt='' className='rounded-circle' /> */}
                                                            <div className='ms-2'>
                                                                <h5>{el.fname} {el.lname}</h5>
                                                                <p>{el.email}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h5>{el.company?.name}</h5>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {/* DASHBOARD Deals */}

                                <div className='ps-0 col-md-4'>
                                    <div className='content-lists'>
                                        <div className='d-flex justify-content-between'>
                                            <h2>Recent Deals</h2><span className='cursor-pointer' onClick={() => navigate('/app/deals')}>View All</span>
                                        </div>
                                        {
                                            recentDeals && recentDeals?.map(el => {
                                                return (
                                                    <div className='list-item'>
                                                        <div className='d-flex align-items-center'>
                                                            {/* <img src='/images/avatar.svg' alt='' className='rounded-circle' /> */}
                                                            <div className='ms-2'>
                                                                <h5>{el.fname} {el.lname}</h5>
                                                                <p>{el.email}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h5>{el.company?.name}</h5>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                {/* DASHBOARD CONTENT Counsumed*/}
                                <div className='col-md-4 ps-0'>
                                    <div className='content-lists'>
                                        <div className='d-flex justify-content-between'>
                                            <h2>Content Consumed</h2><span className='cursor-pointer' onClick={() => navigate('/app/consumed-resources', { state: { id: partner?.value } })}>View All</span>
                                        </div>
                                        {
                                            recentResources && recentResources.slice(0, 4)?.map(el => {
                                                return (
                                                    <div className='list-item'>
                                                        <div className='d-flex align-items-center'>
                                                            {el.type === 'blog' ?
                                                                <img src='/images/blog-icon-color.svg' alt='' /> :
                                                                el.type === 'video' ?
                                                                    <img src='/images/video-icon-color.svg' alt='' /> :
                                                                    el.type === 'infographic' ?
                                                                        <img src='/images/infographics-color.svg' alt='' /> :
                                                                        el.type === 'report' ?
                                                                            <img src='/images/report-icon-color.svg' alt='' /> :
                                                                            el.type === 'case-study' ?
                                                                                <img src='/images/case-study-icon-color.svg' alt='' /> :
                                                                                el.type === 'whitepaper' ?
                                                                                    <img src='/images/whitepaper-icon-color.svg' alt='' /> :
                                                                                    <img src='/images/blog-icon-color.svg' alt='' />
                                                            }
                                                            <div className='ms-2'>
                                                                <h5>{el.title}</h5>
                                                                <p>{el.type}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            {/* DASHBOARD GRAPH */}
                            <div className='row'>
                                <div className='col-md-7'>
                                    <div className='row'>
                                        <div className='seq-leads-graph'>
                                            <div>
                                                <h3 className='d-flex align-items-center'> <img src='/images/add-icon.svg' alt='' />&nbsp;&nbsp;Leads added</h3>
                                            </div>
                                            {isLoading ?
                                                '' :
                                                months?.length ?
                                                    <div className='mt-5'>
                                                        <HighchartsReact
                                                            highcharts={Highcharts}
                                                            options={leadsGraph}
                                                        />
                                                    </div> :
                                                    <div className='d-flex justify-content-center align-items-center' style={{ height: 250 }}>
                                                        <button className='add-btn' onClick={() => navigate('/app/leads')}>Add Lead</button>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='dashboard-item1'>
                                            <h4>
                                                <img src='/images/ticket.svg' alt='' />&nbsp;&nbsp;All Tickets
                                            </h4>
                                            <h1>{ticketMetrics?.total}</h1>

                                        </div>
                                        <div className='dashboard-item1'>
                                            <h4>
                                                <img src='/images/inprogres.svg' alt='' />&nbsp;&nbsp;In-Progress
                                            </h4>
                                            <h1>{ticketMetrics?.open}</h1>

                                        </div>
                                        <div className='dashboard-item1'>
                                            <h4>
                                                <img src='/images/resolved.svg' alt='' />&nbsp;&nbsp;Closed
                                            </h4>
                                            <h1>{ticketMetrics?.closed}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <div className='seq-login-graph' >
                                        <div>
                                            <h3 className='d-flex align-items-center'> <img src='/images/cal-icon.svg' alt='' />&nbsp;&nbsp;Login Trend</h3>
                                        </div>
                                        {
                                            loginMetric?.length > 0 ?
                                                <div className='mt-5'>
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={loginTrend}
                                                    />
                                                </div> :
                                                <div className='d-flex justify-content-center align-items-center' style={{ height: 250 }}>
                                                    Data will available soon
                                                </div>
                                        }
                                    </div>

                                    <div className='seq-login-graph mt-2'>
                                        <div>
                                            <h3 className='d-flex align-items-center'> <img src='/images/consume.svg' alt='' />&nbsp;&nbsp;Content Consumption</h3>
                                        </div>
                                        {
                                            resourceCount?.length > 0 ?
                                                <div className='mt-5'>
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={contentConsumption}

                                                    />
                                                </div> :
                                                <div className='d-flex justify-content-center align-items-center' style={{ height: 250 }}>
                                                    Data will available soon
                                                </div>

                                        }
                                    </div>
                                </div>
                            </div>
                            {/* DASHBOARD LEADS */}
                            <div className='row mt-3'>
                                <div className='ps-0 col-md-4'>
                                    <div className='content-lists'>
                                        <div className='d-flex justify-content-between'>
                                            <h2>Recent Leads</h2><span className='cursor-pointer' onClick={() => navigate('/app/leads')}>View All</span>
                                        </div>
                                        {
                                            recentLeads && recentLeads?.map(el => {
                                                return (
                                                    <div className='list-item'>
                                                        <div className='d-flex align-items-center'>
                                                            {/* <img src='/images/avatar.svg' alt='' className='rounded-circle' /> */}
                                                            <div className='ms-2'>
                                                                <h5>{el.fname} {el.lname}</h5>
                                                                <p>{el.email}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h5>{el.company?.name}</h5>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {/* DASHBOARD CONTENT LEADS */}
                                <div className='ps-0 col-md-4'>
                                    <div className='content-lists'>
                                        <div className='d-flex justify-content-between'>
                                            <h2>Open tickets</h2><span className='cursor-pointer' onClick={() => navigate('/app/supports')}>View All</span>
                                        </div>
                                        {
                                            recentTickets && recentTickets?.map(el => {
                                                return (
                                                    <div className='list-item px-0'>
                                                        <div className='d-flex align-items-center' style={{ width: '70%' }}>
                                                            <div style={{ width: '95%' }} >
                                                                <h5 className='elipsis'>{el.question}</h5>
                                                                <p>{el.type}</p>
                                                            </div>
                                                        </div>
                                                        {el.closed ?
                                                            <div className='inprogress resolved'>
                                                                Closed
                                                            </div>
                                                            : <div className='inprogress'>
                                                                In Progress
                                                            </div>}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {/* DASHBOARD CONTENT LEADS 2*/}
                                <div className='col-md-4 ps-0'>
                                    <div className='content-lists'>
                                        <div className='d-flex justify-content-between'>
                                            <h2>Content Consumed</h2><span className='cursor-pointer' onClick={() => navigate('/app/consumed-resources', { state: { id: partner?.value } })}>View All</span>
                                        </div>
                                        {
                                            recentResources && recentResources.slice(0, 4)?.map(el => {
                                                return (
                                                    <div className='list-item'>
                                                        <div className='d-flex align-items-center'>
                                                            {el.type === 'blog' ?
                                                                <img src='/images/blog-icon-color.svg' alt='' /> :
                                                                el.type === 'video' ?
                                                                    <img src='/images/video-icon-color.svg' alt='' /> :
                                                                    el.type === 'infographic' ?
                                                                        <img src='/images/infographics-color.svg' alt='' /> :
                                                                        el.type === 'report' ?
                                                                            <img src='/images/report-icon-color.svg' alt='' /> :
                                                                            el.type === 'case-study' ?
                                                                                <img src='/images/case-study-icon-color.svg' alt='' /> :
                                                                                el.type === 'whitepaper' ?
                                                                                    <img src='/images/whitepaper-icon-color.svg' alt='' /> :
                                                                                    <img src='/images/blog-icon-color.svg' alt='' />
                                                            }
                                                            <div className='ms-2'>
                                                                <h5>{el.title}</h5>
                                                                <p>{el.type}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Fragment>
            }
        </div >
    )
}
