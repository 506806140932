// ############################ Require packages here ############################
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


// ############################ Require files here ###############################

import './manageResources.scss';
import AddResource from './addResource';
import { getCategoryResources, savedResources } from '../../../../Redux/ActionCreators/resourcesAction';
// import CategoryResources from './categoryResources';
const CategoryResources = React.lazy(() => import('./categoryResources'));

export default function ManageResources() {
    const [detail, setDetail] = useState(null);
    const [isResourceListVisible, setIsResourceListVisible] = useState(false);
    const [isResourceVisible, setIsResourceVisible] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { resourceListForCategory } = useSelector(state => state.resources);

    useEffect(() => {
        if (location?.state?.detail) {
            setDetail(location?.state?.detail);
            dispatch(getCategoryResources(location?.state?.detail?._id));
        }
    }, [])


    const saveResource = async () => {
        let obj = {
            "_id": detail?._id,
            "resource_ids": resourceListForCategory?.map(el => [el._id][0])
        }
        const res = await dispatch(savedResources(obj));
        if (res?.type === 'SAVED_RESOURCES') {
            navigate(-1);
        }
    }

    return (
        <div className='manage-resources'>
            {/* MANAGE RESOURCES */}
            {isResourceVisible ?
                <AddResource
                    backToMain={() => { setIsResourceVisible(false) }}
                    isAddFromCategory={true}
                    isEdit={false}
                /> :
                isResourceListVisible ?
                    <CategoryResources
                        type='select-resource'
                        backToMain={() => setIsResourceListVisible(false)}
                    />
                    :
                    <>
                        <h2 className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <img src='/images/back.svg' className='cursor-pointer' alt='' onClick={() => navigate(-1)} />&nbsp;&nbsp;
                                {detail?.name}
                            </div>
                            <button className='submit-btn' onClick={() => saveResource()}>Save</button>
                        </h2>
                        {/* MANAGE RESOURCES HEADING */}
                        <div className='resources-header'>
                            <div className='row me-0'>
                                <div className='col-md-3'>
                                    {detail?.image ? <img src={detail?.image} width={150} height={112} alt='' />
                                        : <img src='/images/sample1.png' width={150} height={112} alt='' />
                                    }
                                </div>
                                <div className='col-md-6'>
                                    <div>
                                        {/* <p>Category Description</p><br /> */}
                                        <div className='d-flex aling-items-center'>
                                            <img src='/images/article-icon.svg' alt='' />&nbsp;&nbsp;
                                            <div>
                                                <p>Total resources</p>
                                                {detail?.items}
                                            </div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label className="pb-2">Category Resources</label>
                                            <div className="add-btns  d-flex align-items-center">
                                                <button
                                                    className=""
                                                    onClick={() => setIsResourceListVisible(true)}
                                                >
                                                    Select from List
                                                </button> &nbsp; &nbsp;
                                                <button

                                                    onClick={() => setIsResourceVisible(true)}
                                                >
                                                    Add New Resource
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* MANAGE RESOURCES BODY */}
                        <div className='resources-select-body'>
                            <div className="selected-count">
                                <h4>
                                    Selected Resources ({resourceListForCategory?.length})
                                </h4>
                            </div>
                            <CategoryResources />
                        </div>
                    </>
            }
        </div>
    )
}
