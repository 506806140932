import { USER_LOGIN } from '../Types/types';

let intialState = {
    isAuthorized: false
}

// This function was to initially store user section details 

export default function authReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case USER_LOGIN:
            stateCopy.isAuthorized = true;
            localStorage.setItem('adminAuthToken', action.payload.data.token);
            localStorage.setItem('loginEmail', action.payload.data.email);
            localStorage.setItem('fname', action.payload.data.fname);
            localStorage.setItem('lname', action.payload.data.lname);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload.data));
            localStorage.setItem('role', action.payload.data.roles.map(el => el.title));
            stateCopy.user = action.payload.data;
            // window.location.href = action.payload?.role.includes('admin') ? '/manage-content' : '/my-learning';
            window.location.reload();
            return stateCopy;
        default:
            return stateCopy;
    }
}
