// ############################ Require packages here ############################
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################


export default function LinkExpired() {
    const navigate = useNavigate();

    return (
        <div style={{ width: '100%', height: '100vh', background: "linear-gradient(41.44deg, #040C30 37.24%, #06065D 63.08%)" }}>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek Link Expired</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 PAGE NOT FOUND BANNER */}
            <div className='no-found-page '>
                <h1 style={{ color: '#fff' }}>Link Expired Please Try again</h1>
                <div className='text-center mt-3'>
                    <button onClick={() => navigate('/partner-portal-login')}>Go to Login</button>
                </div>

            </div>
        </div>
    )
}
