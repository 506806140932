import { GET_ALL_TICKETS, ADD_TICKET, DELETE_TICKET, GET_REPLIES, ADD_REPLY, CLOSE_TICKET } from "../Types/types";

let intialState = {
    isLoading: false,
    ticketList: [],
    totalPage: 1,
    Statuses: [{ label: 'In Progress', value: 'in-progress' }, { label: 'Closed', value: 'closed' }],
    categories: [{ label: 'Marketing', value: 'marketing' }, { label: 'Sales', value: 'sales' }, { label: 'Products', value: 'product' }, { label: 'Others', value: 'other' }]
}

// This function was to initially store support details based on status and categories

export default function supportReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case GET_ALL_TICKETS:
            stateCopy.ticketList = action.payload.data;
            stateCopy.totalPage = action.payload.pages;
            return stateCopy;
        case ADD_TICKET:
            stateCopy.ticketList = [...stateCopy.ticketList, action.payload];
            return stateCopy;
        case DELETE_TICKET:
            stateCopy.ticketList = stateCopy.ticketList.filter(el => el._id !== action.payload);
            return stateCopy;
        case GET_REPLIES:
            stateCopy.replies = action.payload;
            return stateCopy;
        case ADD_REPLY:
            stateCopy.replies = [...stateCopy.replies, action.payload];
            return stateCopy;
        case CLOSE_TICKET:
            stateCopy.ticketList.forEach(el => {
                if (el._id === action.payload) {
                    el.closed = true
                }
            })
            return stateCopy;
        default:
            return stateCopy;
    }
}
