// ############################ Require packages here ############################
import React, { lazy, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';
import Aos from 'aos';


// ############################ Require files here ###############################

import ScheduleForm from './scheduleForm';
import './Products/product.scss';
// import TestimonialSlider from './testimonialSlider';
import RelatedResources from './relatedResources';
const TestimonialSlider = lazy(() => import("./testimonialSlider"));


const PerceptPlatformBenefits = [
    { image: '/images/ai-chip.svg', title: 'AI-Powered Platform and Products', description: 'AI-powered self-learning engine that detects new attack patterns, automates security tasks, triggers remediation, reducing human error' },
    { image: '/images/chart-connected.svg', title: 'Single Console Visibility Across IT Landscape', description: 'Comprehensive enterprise coverage and product dashboards with complete attack surface visibility, all available within a single console' },
    { image: '/images/dashboard-monitor.svg', title: 'Coexist, Complement, and Consolidate', description: 'Modular architecture enables seamless integration without a “rip & replace” of tech stack while allowing consolidation at your pace' },
    { image: '/images/problem-solving.svg', title: 'Enhanced Detection & Response', description: 'Comprehensive threat exposure management: detection & response, identity governance and administration, endpoint detection and more' },
]




const PerceptPlatform = () => {

    const active = 'perceptplatform';
    const [secNav, setSecNav] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', scrollSecondNav);
        return () => {
            window.removeEventListener('scroll', scrollSecondNav);
        }
    }, [])

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    const scrollSecondNav = () => {
        const secondaryHeader = document.querySelector('.secondary-header');
        const mainHeaderHeight = 72;
        const contentTop = document.querySelector('.seq-Percept-about')?.offsetTop;

        if (window.scrollY > contentTop - mainHeaderHeight) {
            secondaryHeader.classList.add('sticky');
        } else {
            secondaryHeader.classList.remove('sticky');
        }
    }


    const toggleNav = () => {
        setSecNav(!secNav)
    }

    const navigate = useNavigate();

    const setActive = (val) => {
        window.scrollTo(0, 0);
        if (val === 'perceptXdr') {
            navigate('/products/extended-detection-and-response-and-ngsiem')
        } else if (val === 'PerceptIdentity') {
            navigate('/products/identity-and-access-governance')
        } else if (val === 'perceptEdr') {
            navigate('/products/endpoint-detection-and-response')
        } else if (val === 'perceptManager') {
            navigate('/products/compliance-manager')
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }
    const setScroll = (val) => {
        if (val === 'demo') {
            window.innerWidth > 767 ? window.scrollTo(0, 4500) : window.scrollTo(0, 4100);
        }
    }


    return (
        <div className='seq-Percept-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | AI-Powered Continuous Threat Exposure Management </title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Leverage Sequretek's AI-powered platform for comprehensive threat detection, vulnerability management, and exposure management. Gain single-console visibility and seamless integration." />
                <meta name="keywords" content="Cyber threat intelligence, Vulnerability management tools, Exposure management, Threat Exposure Management" />
                <meta property="og:title" content="Sequretek | AI-Powered Continuous Threat Exposure Management " />
                <meta property="og:description" content="Leverage Sequretek's AI-powered platform for comprehensive threat detection, vulnerability management, and exposure management. Gain single-console visibility and seamless integration." />
            </Helmet>

            {/* <HighchartsReact
                highcharts={Highcharts}
                options={doughnut}
            /> */}
            {/* section 1 BANNER */}
            <div className='seq-Percept-heading' style={{ backgroundImage: `url('/images/bg-img18.svg')` }}>
                <div className='container pe-0 ps-0'>
                    <div className='seq-Percept-hero'>
                        <div data-aos="fade-up">
                            <div className='row seq-Percept-hero-content'>
                                <div className='d-flex align-items-center col-2 seq-Percept-hero-content-info'>
                                    <img src='/images/hexagon-check.svg' alt='' />&nbsp;&nbsp;&nbsp;
                                    <p>Protect your business</p>
                                </div>
                                <div className='d-flex align-items-center col-2 seq-Percept-hero-content-info'>
                                    <img src='/images/hexagon-check.svg' alt='' />&nbsp;&nbsp;&nbsp;
                                    <p>Stay compliant</p>
                                </div>
                                <div className='d-flex align-items-center col-2 seq-Percept-hero-content-info'>
                                    <img src='/images/hexagon-check.svg' alt='' />&nbsp;&nbsp;&nbsp;
                                    <p>Drive down costs</p>
                                </div>
                            </div>
                            <h3 className='pb-0'>Stay Ahead of Cyberthreats</h3>
                            <h5>We help you stay ahead of cyberthreats so that you can focus on your business, worry-free</h5>
                            <button onClick={() => setScroll('demo')}><strong>Schedule a demo</strong></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 2 SECOND NAVBAR*/}
            <div className='seq-Percept-tab-pages'>
                {window.innerWidth > 767 ?
                    <div>
                        <div className='secondary-header contact-tabs d-flex justify-content-center align-items-center'>
                            <div onClick={() => setActive('perceptplatform')} className={active === 'perceptplatform' ? 'seq-tab active' : 'seq-tab'}>
                                About Percept Platform
                            </div>
                            <div onClick={() => setActive('perceptXdr')} className={active === 'perceptXdr' ? 'seq-tab active' : 'seq-tab'}>
                                Percept XDR & NG SIEM
                            </div>
                            <div onClick={() => setActive('PerceptIdentity')} className={active === 'PerceptIdentity' ? 'seq-tab active' : 'seq-tab'}>
                                Percept Identity
                            </div>
                            <div onClick={() => setActive('perceptEdr')} className={active === 'perceptEdr' ? 'seq-tab active' : 'seq-tab'}>
                                Percept EDR
                            </div>
                            <div onClick={() => setActive('perceptManager')} className={active === 'perceptManager' ? 'seq-tab active' : 'seq-tab'}>
                                Percept Compliance Manager
                            </div>
                        </div>
                    </div>
                    :
                    <div className='secondary-header'>
                        <div className='seq-sec-nav-mobile d-flex justify-content-evenly'>
                            <div className="seq-active d-flex align-items-center">
                                <h4>About Percept Platform</h4>
                            </div>
                            <div className="dropdown d-flex" onClick={toggleNav} >
                                <img src={secNav === true ? '/images/up-arrow.svg' : '/images/down-arrow.svg'} alt="Menu" />
                            </div>
                        </div>
                        <nav className={`menu ${secNav ? 'open' : ''}`}>
                            <ul className='seq-drop-items'>
                                <li onClick={() => setActive('perceptXdr')}>Percept XDR and NGSIEM</li>
                                <li onClick={() => setActive('PerceptIdentity')}>Percept Identity</li>
                                <li onClick={() => setActive('perceptEdr')}>Percept EDR</li>
                                <li onClick={() => setActive('perceptManager')}>Percept Compliance Manager</li>
                            </ul>
                        </nav>
                    </div>
                }
                {/* section 3 PLAYER */}
                <div className='seq-Percept-about'>
                    {/* section 3-1 PLAYER */}
                    <div className='container pe-0 ps-0' data-aos="fade-up">
                        <div className='d-flex justify-content-center seq-Percept-player' >
                            <ReactPlayer url='https://youtu.be/Az_jcs1qvX4' className='seq-Percept-about-player' />
                        </div>
                    </div>
                    {/* section 3-2 BENEFITS */}
                    <div className='seq-Percept-benefits'>
                        <div className='container pe-0 ps-0'>
                            <p style={{ color: '#2076CD', fontSize: '16px' }}>BENEFITS</p>
                            <div className='row' style={{ marginBottom: '25px', width: '100%' }}>
                                <div className='col-12 col-md-5 col-sm-12'>
                                    <h3>A Comprehensive Solution Built for Ease of Adoption</h3>
                                </div>
                                <div className='col-12 col-md-7 col-sm-12'>
                                    <p>Simplify security, gain AI-powered continuous threat exposure management with complete attack surface visibility, threat detection, response, and remediation</p>
                                </div>
                            </div>
                            <div>
                                {
                                    window.innerWidth > 767 ?
                                        <div className='row me-0 seq-benefits-cards'>
                                            {
                                                PerceptPlatformBenefits?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-Percept-card'>
                                                            <div className='seq-card'>
                                                                <img src={el.image} alt='' />
                                                                <h4>{el.title}</h4>
                                                                <p>{el.description}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className='d-flex overflow-auto flex-nowrap mr-minus'>
                                            {
                                                PerceptPlatformBenefits?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='col-md-3'>
                                                            <div className='seq-card'>
                                                                <img src={el.image} alt='' />
                                                                <h4>{el.title}</h4>
                                                                <p>{el.description}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 3-3 Img Session */}
                <div className='seq-benifits-img seq-benifits-img-products'>
                    {window.innerWidth > 767 ?
                        <div className='seq-benifits-img-products-text'>
                            <div className='d-flex justify-content-center'><h5>With Percept, See Tangible Benefits Across Your Organization</h5></div>
                            <div className='d-flex justify-content-center'><p className='d-flex justify-content-center'>As CXOs navigate a rapidly evolving threat landscape, they face significant challenges trying to balance security investments with business goals.</p></div>
                            <div className='d-flex justify-content-center'><img src='/images/bg-img99.svg' alt='' /></div>
                        </div> :
                        <div>
                            <div className='seq-platform-img-benefits'>
                                <h3>With Percept, See Tangible Benefits Across Your Organization</h3>
                                <p>As CXOs navigate a rapidly evolving threat landscape, they face significant challenges trying to balance security investments with business goals. </p>
                            </div>
                        </div>
                    }
                </div>
                {window.innerWidth > 767 ? null :
                    <div className='seq-platform-img-benefits-card2 d-flex flex-nowrap overflow-auto' style={{ gap: '15px' }}>
                        <div className='seq-platform-img-benefits-card-item'>
                            <div className='d-flex align-items-center'>
                                <img src='/images/lead.svg' alt='' />&nbsp;&nbsp;&nbsp;&nbsp;
                                <h5>CEO</h5>
                            </div>
                            <p>Brand Reputation Business Continuity Stakeholder Value</p>
                        </div>
                        <div className='seq-platform-img-benefits-card-item'>
                            <div className='d-flex align-items-center'>
                                <img src='/images/admin-alt.svg' alt='' />&nbsp;&nbsp;&nbsp;&nbsp;
                                <h5>COO</h5>
                            </div>
                            <p>Business Continuity Regulatory Compliance Operational Disruptions</p>
                        </div>
                        <div className='seq-platform-img-benefits-card-item'>
                            <div className='d-flex align-items-center'>
                                <img src='/images/user-salary.svg' alt='' />&nbsp;&nbsp;&nbsp;&nbsp;
                                <h5>CFO</h5>
                            </div>
                            <p>Financial Risk Business Continuity Cybersecurity Investments</p>
                        </div>
                        <div className='seq-platform-img-benefits-card-item'>
                            <div className='d-flex align-items-center'>
                                <img src='/images/user-shield-wight.svg' alt='' />&nbsp;&nbsp;&nbsp;&nbsp;
                                <h5>CISO</h5>
                            </div>
                            <p>Regulatory Compliance Attack Surface Management Incident Detection Response & Recovery </p>
                        </div>
                        <div className='seq-platform-img-benefits-card-item'>
                            <div className='d-flex align-items-center'>
                                <img src='/images/user-trust-wight.svg' alt='' />&nbsp;&nbsp;&nbsp;&nbsp;
                                <h5>CRO</h5>
                            </div>
                            <p>Regulatory Compliance Risk Exposure Insurance & Liability</p>
                        </div>
                        <div className='seq-platform-img-benefits-card-item'>
                            <div className='d-flex align-items-center'>
                                <img src='/images/user-gear-wight.svg' alt='' />&nbsp;&nbsp;&nbsp;&nbsp;
                                <h5>CIO</h5>
                            </div>
                            <p>Tech Stack Availability Data Integrity Data Privacy</p>
                        </div>
                        <div className='seq-platform-img-benefits-card-item'>
                            <div className='d-flex align-items-center'>
                                <img src='/images/user-gear-wight.svg' alt='' />&nbsp;&nbsp;&nbsp;&nbsp;
                                <h5>CDO</h5>
                            </div>
                            <p>Tech Stack Availability Data Integrity Data Privacy</p>
                        </div>

                    </div>
                }
                {/* section 3-4 Products */}
                <div className='seq-Percept-explore ' style={{ backgroundImage: `linear-gradient(184.89deg, #06065D 10.52%, rgba(4, 12, 48, 0.8) 47.63%, #06065D 90.84%),url('/images/bg-img18.png')` }}>
                    <div className='container pe-0 ps-0'>
                        <div className='seq-Percept-explore-head'>
                            <div className='d-flex'>
                                <div className='seq-Percept-explore-head-info'>
                                    <h5>Explore Our Products</h5>
                                    <p>Enterprise cybersecurity platform and products that help you maintain a proactive and comprehensive security posture against varied threats.</p>
                                </div>
                                {window.innerWidth > 767 ?
                                    <img src='/images/q-icon1.svg' alt='' style={{ width: '300px', height: '300px' }} />
                                    : null}
                            </div>
                        </div>
                        {
                            window.innerWidth > 767 ?
                                <div className='seq-Percept-explore-body'>
                                    <div className='row me-2 seq-Percept-explore-body-cards' style={{ gap: '80px' }}>
                                        <div data-aos="fade-up" className='col-6 col-sm-6 seq-Percept-explore-body-items' >
                                            <h5>PERCEPT XDR & NG SIEM</h5>
                                            <p>Cloud-based AI-powered solution for 24/7 security monitoring, threat detection, and automated incident response for your IT</p>
                                            <button onClick={() => setActive('perceptXdr')}><strong>Explore</strong></button>
                                        </div>
                                        <div data-aos="fade-up" className='col-6 seq-Percept-explore-body-items'>
                                            <h5>PERCEPT IDENTITY</h5>
                                            <p>Zero Trust identity and access orchestration, governance. Meet compliance needs and cover <br />access risks across environments</p>
                                            <button onClick={() => setActive('PerceptIdentity')}><strong>Explore</strong></button>
                                        </div>
                                        <div data-aos="fade-up" className='col-6 seq-Percept-explore-body-items'>
                                            <h5>PERCEPT EDR</h5>
                                            <p>On-agent AI-based detection and response for endpoints, servers, clouds with application whitelisting, vulnerability and patch management</p>
                                            <button onClick={() => setActive('perceptEdr')}><strong>Explore</strong></button>
                                        </div>
                                        <div data-aos="fade-up" className='col-6 seq-Percept-explore-body-items'>
                                            <h5>PERCEPT COMPLIANCE MANAGER</h5>
                                            <p>Continuous governance, risk, compliance across environments with vulnerability assessment and configuration audits on a single console</p>
                                            <button onClick={() => setActive('perceptManager')}><strong>Explore</strong></button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='seq-Percept-explore-body'>
                                    <div data-aos="fade-up" className='seq-Percept-explore-body-items' >
                                        <h5>PERCEPT XDR & NG SIEM</h5>
                                        <p>Cloud-based AI-powered solution for 24/7 security monitoring, threat detection, and automated incident response for your IT</p>
                                        <button onClick={() => setActive('perceptXdr')}>Explore</button>
                                    </div>
                                    <div data-aos="fade-up" className='seq-Percept-explore-body-items'>
                                        <h5>PERCEPT IDENTITY</h5>
                                        <p>Zero Trust identity and access orchestration, governance. Meet compliance needs and cover access risks across environments</p>
                                        <button onClick={() => setActive('PerceptIdentity')}>Explore</button>
                                    </div>
                                    <div data-aos="fade-up" className='seq-Percept-explore-body-items'>
                                        <h5>PERCEPT EDR</h5>
                                        <p>On-agent AI-based detection and response for endpoints, servers, clouds with application whitelisting, vulnerability and patch management</p>
                                        <button onClick={() => setActive('perceptEdr')}>Explore</button>
                                    </div>
                                    <div data-aos="fade-up" className='seq-Percept-explore-body-items'>
                                        <h5>PERCEPT COMPLIANCE MANAGER</h5>
                                        <p>Continuous governance, risk, compliance across environments with vulnerability assessment and configuration audits on a single console</p>
                                        <button onClick={() => setActive('perceptManager')}>Explore</button>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            {/* section 4 TESTIMONIAL SLIDER */}
            <div className="seq-quotes">
                <div className='gredient'> </div>
                <TestimonialSlider
                    page={'platform'}
                />
            </div>
            {/* section 5 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container'>
                    <div className=" insurance-partseven" data-aos="fade-up">
                        {window.innerWidth > 767 ? <h2 className='insurance-partseven-heading'>How can we help improve your security posture? Let’s talk!</h2> : null}
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/light-umbrella.svg'}
                            page='percept-platform'
                            text={'With businesses being targeted by cyberthreats every single day, we urge you not to leave your business vulnerable. Arm your enterprise with robust cybersecurity solutions that not only defend against attacks but also empower your growth.'}
                        />
                    </div>
                </div>
            </div>
            {/* section 6 RELATED RESOURCES */}
            <div className='resources'>
                <div className='container ps-0'>
                    <div className='row' style={{ marginRight: '0' }}>
                        <h4>Related resources</h4>
                        <div className='col-md-12'>
                            <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap overflow-auto ms-2'}>
                                <RelatedResources
                                    page='platform'
                                    type=''
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setActive('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setActive('resources')}>Resource Center</button></div>}
        </div>
    )
}

export default PerceptPlatform
