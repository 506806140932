// ############################ Require packages here ############################
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################

import './resources.scss';


const events = [{ id: 1, image: '/images/sample1.png', type: 'event', title: 'The emergence of GENAI: friend or foe for your company’s security postu...', },
{ id: 2, image: '/images/sample2.png', type: 'webinar', title: 'Percept Compliance Manager', },
{ id: 3, image: '/images/sample3.png', type: 'event', title: 'Percept XDR and NG SIEM', },
{ id: 4, image: '/images/sample4.png', type: 'webinar', title: 'Security Threats and Breaches', },
{ id: 5, image: '/images/sample5.png', type: 'event', title: 'Need for cybersecurity to strengthen digital transformation good retur...', },
{ id: 6, image: '/images/sample6.png', type: 'event', title: 'Attackers exploit VPN vulnerabilities in a frenzy', },
]
const upcomingEvents = [{ id: 1, date: '24-June-2024,2:30PM', image: '/images/sample1.png', type: 'event', title: 'The emergence of GENAI: friend or foe for your company’s security postu...', },
{ id: 2, date: '24-June-2024,2:30PM', image: '/images/sample2.png', type: 'webinar', title: 'Percept Compliance Manager', },
{ id: 3, date: '24-June-2024,2:30PM', image: '/images/sample3.png', type: 'event', title: 'Percept XDR and NG SIEM', }
]
const types = [{ type: 'blog', label: 'Blog' }, { type: 'case-study', label: 'Case Studies' }, { type: 'datasheet', label: 'DataSheets' }, { type: 'demo', label: 'Demos' }, { type: 'event', label: 'Events & Webinars' },
{ type: 'infographics', label: 'Infographics' }, { type: 'podcast', label: 'Podcasts' }, { type: 'report', label: 'Reports' }, { type: 'advisory', label: 'Threat Advisories' }, { type: 'video', label: 'Videos' },
{ type: 'whitepaper', label: 'White Papers' }
]

export default function EventWebinars() {
    const active = 'event';

    const navigate = useNavigate();

    const setActive = (val) => {
        navigate('/resources-hub', { state: { type: val } })
    }
    return (
        <div className='seq-resource-center'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Upcoming Events & Webinars | Join Our Live Sessions</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Explore our upcoming events and webinars to stay updated with the latest trends. Join live sessions, gain insights, and network with industry experts." />
                <meta property="og:title" content="Sequretek | Upcoming Events & Webinars | Join Our Live Sessions" />
                <meta property="og:description" content="Explore our upcoming events and webinars to stay updated with the latest trends. Join live sessions, gain insights, and network with industry experts." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-resource-header'>
                <div style={{ width: window.innerWidth > 767 ? '60%' : '77%' }}>
                    <h2>Resource Center</h2>
                    <p>Our comprehensive Resources section offers a wealth of information on the latest advancements and best practices in cybersecurity.  We invite you to explore, learn, and ensure your security in this increasingly interconnected world.  </p>
                </div>
            </div>
            <div className='container pe-0'>
                <div className='row upcoming-event'>
                    <h4>Upcoming Events & Webinars</h4>
                    <div className='col-md-12'>
                        <div className={window.innerWidth > 767 ? 'row justify-content-center' : 'row flex-nowrap overflow-auto ms-2'}>
                            {
                                upcomingEvents?.map(el => {
                                    return (
                                        <div className={window.innerWidth > 767 ? 'col-md-3 d-flex align-self-stretch cursor-pointer mt-3' : 'col cursor-pointer mt-3 '}>
                                            <div className="card" style={{ width: window.innerWidth > 767 ? '264px' : '280px' }} >
                                                <img className="card-img-top position-relative" src={el.image} alt='' />
                                                <div className='type'>
                                                    <div className='type-1' >
                                                        {el.type === 'report' ? <img src='/images/report-icon.svg' alt='' /> : el.type === 'advisory' ? <img src='/images/advisory-icon.svg' alt='' /> : el.type === 'podcast' ? <img src='/images/podcast-icon.svg' alt='' /> :
                                                            el.type === 'demo' ? <img src='/images/demo-icon.svg' alt='' /> : el.type === 'datasheet' ? <img src='/images/datasheet-icon.svg' alt='' /> : el.type === 'case-study' ? <img src='/images/case-study-icon.svg' alt='' /> :
                                                                el.type === 'blog' ? <img src='/images/blog-icon.svg' alt='' /> : el.type === 'infographics' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'infographics' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'event' ? <img src='/images/event-icon.svg' alt='' /> : el.type === 'video' ? <img src='/images/video-icon.svg' alt='' /> : el.type === 'webinar' ? <img src='/images/webinar-icon.svg' alt='' /> : null
                                                        }&nbsp;&nbsp;
                                                        {el.type}
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="card-title elipsis" style={{ height: 72 }}>{el.title}</h5>
                                                    <div className='card-btn'>
                                                        <button>Register Now</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
                {/* section 2 LAYOUT */}
                <div className='seq-resource-layout'>
                    {active === 'all' ? null :
                        <div className='breadcrumb mb-2'>
                            <ul>
                                <li><span onClick={() => setActive('all')}>Resources</span>
                                    <span>

                                        {
                                            types?.map(el => {
                                                return (<Fragment>

                                                    {active === el.type ? ` >  ${el.label}` : null}
                                                </Fragment>)
                                            })
                                        }</span></li>
                            </ul>
                        </div>
                    }
                    {/* section 3-1 RESOURCE FILTER */}
                    <div className='resource-filter'>
                        <div className="has-search"  >
                            <span className="form-control-feedback" >
                                <img src="/images/search.svg" alt='' width={12} />
                            </span>
                            <input type="search" className="form-control" placeholder='Search for resource' />
                        </div>
                        <div style={{ width: window.innerWidth > 767 ? '15%' : '100%' }}>
                            <select >
                                <option hidden>Select Topic</option>

                            </select>

                        </div>
                        <div style={{ width: window.innerWidth > 767 ? '15%' : '100%' }}>
                            <select>
                                <option hidden>Select Industry</option>

                            </select>
                        </div>
                        {
                            window.innerWidth > 767 ? null :
                                <select className='type-filter' onChange={(e) => setActive(e.target.value)}>
                                    <option value={'all'}>All Resources</option>
                                    {
                                        types?.map(el => {
                                            return (
                                                <option value={el.type} >
                                                    {el.type === active ?
                                                        (el.type === 'report' ? <img src='/images/report-icon.svg' alt='' /> : el.type === 'advisory' ? <img src='/images/advisory-icon.svg' alt='' /> : el.type === 'podcast' ? <img src='/images/podcast-icon.svg' alt='' /> :
                                                            el.type === 'demo' ? <img src='/images/demo-icon.svg' alt='' /> : el.type === 'datasheet' ? <img src='/images/datasheet-icon.svg' alt='' /> : el.type === 'case-study' ? <img src='/images/case-study-icon.svg' alt='' /> :
                                                                el.type === 'blog' ? <img src='/images/blog-icon.svg' alt='' /> : el.type === 'infographics' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'infographics' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'event' ? <img src='/images/event-icon.svg' alt='' /> : el.type === 'video' ? <img src='/images/video-icon.svg' alt='' /> : el.type === 'webinar' ? <img src='/images/webinar-icon.svg' alt='' /> : null) :
                                                        (el.type === 'report' ? <img src='/images/report-icon-color.svg' alt='' /> : el.type === 'advisory' ? <img src='/images/advisory-icon-color.svg' alt='' /> : el.type === 'podcast' ? <img src='/images/podcast-icon-color.svg' alt='' /> :
                                                            el.type === 'demo' ? <img src='/images/demo-icon-color.svg' alt='' /> : el.type === 'datasheet' ? <img src='/images/datasheet-icon-color.svg' alt='' /> : el.type === 'case-study' ? <img src='/images/case-study-icon-color.svg' alt='' /> :
                                                                el.type === 'blog' ? <img src='/images/blog-icon-color.svg' alt='' /> : el.type === 'infographics' ? <img src='/images/infographics-color.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon-color.svg' alt='' /> : el.type === 'event' ? <img src='/images/event-icon-color.svg' alt='' /> : el.type === 'video' ? <img src='/images/video-icon-color.svg' alt='' /> : el.type === 'webinar' ? <img src='/images/webinar-icon-color.svg' alt='' /> : null)
                                                    }&nbsp;&nbsp;
                                                    {el.label}
                                                </option>)
                                        })

                                    }
                                </select>
                        }
                    </div>
                    {/* section 3-2 RESOURCE LIST */}
                    <div className='resources-list'>
                        <div className='row'>
                            <div className='col-md-9'>
                                <div className='row mt-3'>
                                    {
                                        events?.map(el => {
                                            return (
                                                <div className={window.innerWidth > 767 ? 'col-md-4  d-flex align-self-stretch cursor-pointer mt-3' : 'col-md-12 cursor-pointer mt-3'}>
                                                    <div className="card" >
                                                        <img className="card-img-top position-relative" src={el.image} alt='' />
                                                        <div className='type'>
                                                            <div className='type-1' >
                                                                {el.type === 'report' ? <img src='/images/report-icon.svg' alt='' /> : el.type === 'advisory' ? <img src='/images/advisory-icon.svg' alt='' /> : el.type === 'podcast' ? <img src='/images/podcast-icon.svg' alt='' /> :
                                                                    el.type === 'demo' ? <img src='/images/demo-icon.svg' alt='' /> : el.type === 'datasheet' ? <img src='/images/datasheet-icon.svg' alt='' /> : el.type === 'case-study' ? <img src='/images/case-study-icon.svg' alt='' /> :
                                                                        el.type === 'blog' ? <img src='/images/blog-icon.svg' alt='' /> : el.type === 'infographics' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'infographics' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'event' ? <img src='/images/event-icon.svg' alt='' /> : el.type === 'video' ? <img src='/images/video-icon.svg' alt='' /> : el.type === 'webinar' ? <img src='/images/webinar-icon.svg' alt='' /> : null
                                                                }&nbsp;&nbsp;
                                                                {el.type}
                                                            </div>
                                                            <div className='type-1 ms-2' style={{ padding: '4px 10px', background: '#A2DAE0' }}>
                                                                <img src='/images/lock-icon.svg' alt='' />
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <h5 className="card-title elipsis">{el.title}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='ul right-filter '>
                                    <li onClick={() => setActive('all')} className={active === 'all' ? 'active' : ''}>All Resources</li>
                                    {
                                        types?.map(el => {
                                            return (
                                                <li onClick={() => setActive(el.type)} className={el.type === active ? 'active' : ''} >
                                                    {
                                                        el.type === active ?
                                                            (el.type === 'report' ? <img src='/images/report-icon.svg' alt='' /> : el.type === 'advisory' ? <img src='/images/advisory-icon.svg' alt='' /> : el.type === 'podcast' ? <img src='/images/podcast-icon.svg' alt='' /> :
                                                                el.type === 'demo' ? <img src='/images/demo-icon.svg' alt='' /> : el.type === 'datasheet' ? <img src='/images/datasheet-icon.svg' alt='' /> : el.type === 'case-study' ? <img src='/images/case-study-icon.svg' alt='' /> :
                                                                    el.type === 'blog' ? <img src='/images/blog-icon.svg' alt='' /> : el.type === 'infographics' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'infographics' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'event' ? <img src='/images/event-icon.svg' alt='' /> : el.type === 'video' ? <img src='/images/video-icon.svg' alt='' /> : el.type === 'webinar' ? <img src='/images/webinar-icon.svg' alt='' /> : null) :
                                                            (el.type === 'report' ? <img src='/images/report-icon-color.svg' alt='' /> : el.type === 'advisory' ? <img src='/images/advisory-icon-color.svg' alt='' /> : el.type === 'podcast' ? <img src='/images/podcast-icon-color.svg' alt='' /> :
                                                                el.type === 'demo' ? <img src='/images/demo-icon-color.svg' alt='' /> : el.type === 'datasheet' ? <img src='/images/datasheet-icon-color.svg' alt='' /> : el.type === 'case-study' ? <img src='/images/case-study-icon-color.svg' alt='' /> :
                                                                    el.type === 'blog' ? <img src='/images/blog-icon-color.svg' alt='' /> : el.type === 'infographics' ? <img src='/images/infographics-color.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon-color.svg' alt='' /> : el.type === 'event' ? <img src='/images/event-icon-color.svg' alt='' /> : el.type === 'video' ? <img src='/images/video-icon-color.svg' alt='' /> : el.type === 'webinar' ? <img src='/images/webinar-icon-color.svg' alt='' /> : null)
                                                    } &nbsp;&nbsp;
                                                    {el.label}
                                                </li>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}