import './App.scss';
import { Route, Routes, useNavigate } from 'react-router-dom';
// import Login from './Components/App/Login/login';
import Sidebar from './Layouts/Sidebar/sidebar';
import Notfound from './Components/Not-found';
import RouteItems from './Routes/routes';
import PrivateRoute from './Routes/PrivateRoute';
import WebHeader from './Layouts/Website/header';
import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import LinkExpired from './Components/Website/linkExpired';


function App() {
  const isLogged = !!localStorage.getItem('adminAuthToken');
  const navigate = useNavigate();
  const { status } = useSelector(state => state.error);

  useEffect(() => {
    (window.location.pathname === '/app') && (
      isLogged ? navigate('/app/leads') : navigate('/partner-portal')
    )
  }, [])

  if (process.env.NODE_ENV === 'production') {
    console.log = function no_console() { };
  }
  return (
    <div >
      {status === 429 ?
        <div className='request-overlay'>
          <div className='overlay-text '>
            <h3>You have created too many requests</h3>
            <h5>Please Try after sometime</h5>
          </div>
        </div> :
        <Fragment>
          {
            (window.location.pathname === '/app') && (
              isLogged ? navigate('/app/leads') : (window.location.pathname !== '/' && navigate('/'))
            )
          }
          <Routes>
            {/* <Route path='/login' element={<Login />} /> */}
            <Route path='/link-expire' element={<LinkExpired />} />
            {/* ######################################### Used for Partner Portal ########################### */}
            <Route element={<PrivateRoute />}>
              <Route element={isLogged ? <Sidebar /> : ''}>
                {(RouteItems || []).map((item) => {
                  if (item.layout === "mainLayout") {
                    return (
                      <Route
                        key={item.path}
                        path={item.path}
                        element={item.component}
                      />
                    );
                  }
                  return null
                })
                }
              </Route>
            </Route>
            {/* ######################################### Used for Website ########################### */}
            <Route>
              <Route element={<WebHeader />}>
                {(RouteItems || []).map((item) => {
                  if (item.layout === "webLayout") {
                    return (
                      <Route
                        key={item.path}
                        path={item.path}
                        element={
                          <>
                            {item.component}
                          </>
                        }
                      />
                    );
                  }
                  return null
                })
                }
              </Route>
            </Route>
            <Route path='*' element={<Notfound />} />
          </Routes>
        </Fragment>
      }

    </div>
  );
}

export default App;
