/* eslint-disable no-unused-vars */
import { deleteApiCall, getApiCall, postApiCall, putApiCall } from '../../utils/requests';
import { returnErrors } from './errorAction';
import {
    RESET_LIST, GET_ALL_CATEGORY, SEARCH_CATEGORY, ADD_CATEGORY, EDIT_CATEGORY, CHANGE_CATEGORY_STATUS, DELETE_CONTENT,
    GET_INDUSTRIES, GET_TOPICS, GET_SERVICES, GET_TYPE_COUNT, EDIT_TYPE, GET_RESOURCES, GET_NEXT_RESOURCES, GET_CATEGORY_RESOURCES, GET_MIXED_RESOURCE, ADD_RESOURCE, ADD_CATEGORY_RESOURCE, REMOVE_CATEGORY_RESOURCE, ANALYTIC_CAPTURE,
    RESET_RESOURCE_LOADER, GET_EXTERNAL_RESOURCES, GET_EXTERNAL_INDUSTRIES, GET_EXTERNAL_TYPES, GET_EXTERNAL_TOPICS, GET_RELATED_RESOURCES
} from '../Types/types';

// This function is to Reset Lists in resources and users pages

export const resetLists = (data) => async (dispatch) => {
    dispatch({ type: RESET_LIST, payload: data });
};

// This function was an get api call for category in Resources page on partner portal 

export const getCategories = () => async (dispatch) => {
    try {
        const response = await getApiCall(`category`);
        if (response.status === 200) {
            return dispatch({ type: GET_ALL_CATEGORY, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function is to Search for Category in resources pages

export const searchFromCategory = (data) => async (dispatch) => {
    dispatch({ type: SEARCH_CATEGORY, payload: data });
};

// This function was an post api call to add category in Resources page on partner portal 

export const addCatagory = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`category`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Category has been added', response.status);
            return dispatch({ type: ADD_CATEGORY, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an put api call to Update category in Resources page on partner portal

export const updateCategory = (obj) => async (dispatch) => {

    try {
        const response = await putApiCall(`category/edit`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Category has been updated', response.status);
            return dispatch({ type: EDIT_CATEGORY, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an put api call to Handle Status in Resources page on partner portal

export const handleStatus = (id, checked, type) => async (dispatch) => {
    let obj = {
        _id: id
    }
    try {
        const response = type === 'category' ? await putApiCall(`category/toggle`, obj) : await putApiCall(`resource/toggle`, obj);
        if (response.status === 200) {
            return dispatch({ type: CHANGE_CATEGORY_STATUS, payload: { ...obj, checked: checked, type: type } });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an delete api call to Delete content in Resources page on partner portal

export const deletedContent = (id, type) => async (dispatch) => {
    let obj = {
        _id: id
    }
    try {
        const response = type === 'category' ? await deleteApiCall(`category`, obj) : await deleteApiCall(`resource`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, type === 'category' ? 'Category has been deleted' : 'Resource has been deleted', response.status)
            return dispatch({ type: DELETE_CONTENT, payload: { id, type } });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}







//Resource

// This function was an get api call to Get Industries option when adding a resource in Resources page on partner portal

export const getIndustries = () => async (dispatch) => {
    try {
        const response = await getApiCall(`utility/industries`);
        if (response.status === 200) {
            return dispatch({ type: GET_INDUSTRIES, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to Get Topics option when adding a resource in Resources page on partner portal

export const getTopics = () => async (dispatch) => {
    try {
        const response = await getApiCall(`utility/topics`);
        if (response.status === 200) {
            return dispatch({ type: GET_TOPICS, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to Get Services option when adding a resource in Resources page on partner portal

export const getServices = () => async (dispatch) => {
    try {
        const response = await getApiCall(`utility/services`);
        if (response.status === 200) {
            return dispatch({ type: GET_SERVICES, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to Get Count when adding a resource in Resources page on partner portal

export const getTypeCounts = () => async (dispatch) => {
    try {
        const response = await getApiCall(`resource/count`);
        if (response.status === 200) {
            return dispatch({ type: GET_TYPE_COUNT, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an put api call to Edit Types when Editing a resource in Resources page on partner portal

export const editTypes = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`resource/type/edit`, obj);
        if (response.status === 200) {
            return dispatch({ type: EDIT_TYPE, payload: response.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to Get All Resources in Resources page on partner portal

export const getAllResources = (query, type) => async (dispatch) => {
    try {
        const response = await getApiCall(`resource?search=${query ? query : ''}&type=${type ? type : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_RESOURCES, payload: response.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to Get Next Resources in Resources page on partner portal

export const getNextResources = (page, size, query, type) => async (dispatch) => {
    try {
        const response = await getApiCall(`resource?page=${page}&size=${size}&search=${query ? query : ''}&type=${type ? type : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_NEXT_RESOURCES, payload: response.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to Get Category of the Resources in Resources page on partner portal

export const getCategoryResources = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`category/resource/list?category_id=${id}`);
        if (response.status === 200) {
            return dispatch({ type: GET_CATEGORY_RESOURCES, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to Add Resources in Resources page on partner portal

export const addResource = (obj, isAddFromCategory) => async (dispatch) => {
    try {
        const response = await postApiCall(`resource`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Resource added', response?.status);
            if (isAddFromCategory === true) {
                return dispatch({ type: ADD_CATEGORY_RESOURCE, payload: response.data.data });
            } else {
                return dispatch({ type: ADD_RESOURCE, payload: response.data.data });
            }
        } else {
            returnErrors(
                dispatch, response?.data?.message, response?.status
            );
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to Edit Resources in Resources page on partner portal

export const editResource = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`resource/edit`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Resource updated', response?.status);
            return dispatch({ type: 'UPDATE_RESOURCE', payload: response.data.data });
        } else {
            returnErrors(
                dispatch, response?.data?.message, response?.status
            );
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to Get Resources in Resources page on partner portal

export const getResources = (query, type, page) => async (dispatch) => {
    try {
        const response = await getApiCall(`resource?search=${query ? query : ''}&type=${type ? type : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_MIXED_RESOURCE, payload: response.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function is to Add Category Resources in resources and users pages

export const addCategoryResources = (data) => async (dispatch) => {
    dispatch({ type: ADD_CATEGORY_RESOURCE, payload: data });
};

// This function is to Remove Resources in resources and users pages

export const selectOrRemoveResources = (data) => async (dispatch) => {
    dispatch({ type: REMOVE_CATEGORY_RESOURCE, payload: data });
};

// This function was an post api call to Save Resources in Resources page on partner portal

export const savedResources = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`category/resource`, obj);
        if (response.status === 200) {
            return dispatch({ type: 'SAVED_RESOURCES', payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to get Resources in Resources page on partner portal

export const getResource = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`category/resource?_id=${id}`);
        if (response.status === 200) {
            return dispatch({ type: 'GET_RESOURCE', payload: response.data.data[0] });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call for analytic in Resources page on partner portal

export const sentAnalytic = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`resource/analytic`, obj);
        if (response.status === 200) {
            return dispatch({ type: ANALYTIC_CAPTURE, payload: obj });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}


//Used in website

// This function was used in Resources page, Newsroom, Press Releases and Media Coverage pages to reset the resources

export const resetResourceLoader = () => async (dispatch) => {
    dispatch({ type: RESET_RESOURCE_LOADER });
};

// This function was an get api call for External Resources in Resources and Events & Webinars pages

export const getExternalResources = (page, query, type, topic, industry, size) => async (dispatch) => {
    try {
        const response = await getApiCall(`external/resources?page=${page}&search=${query ? query : ''}&type=${type ? type : ''}&topic_id=${topic ? topic : ''}&industry_id=${industry ? industry : ''}&size=${size}`);
        if (response.status === 200) {
            return dispatch({ type: GET_EXTERNAL_RESOURCES, payload: response.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to get Industries base resources in Resources and Events & Webinars pages

export const getExtIndustries = () => async (dispatch) => {
    try {
        const response = await getApiCall(`external/utility/industries`);
        if (response.status === 200) {
            return dispatch({ type: GET_EXTERNAL_INDUSTRIES, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to get topic base resources in Resources and Events & Webinars pages

export const getExtTopics = () => async (dispatch) => {
    try {
        const response = await getApiCall(`external/utility/topics`);
        if (response.status === 200) {
            return dispatch({ type: GET_EXTERNAL_TOPICS, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to get Type base resources in Resources and Events & Webinars pages

export const getTypes = () => async (dispatch) => {
    try {
        const response = await getApiCall(`resource/count`);
        if (response.status === 200) {
            return dispatch({ type: GET_EXTERNAL_TYPES, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to resousces based on the type and industry in RelatedResources section in Home, Platform, Product - NG-SIEM, Product - Identity, Product - EDR
// Product - Compliance Manager, Partners, Industries - SMB, Industries - Banking, Industries - Finance, Industries - Insurance, Industries - Manufacturing, Industries - Healthcare
// Industries - Pharma, Industries - Retail, Industries - Technology, Industries - Government all this pages

export const getRelatedResources = (type, industry) => async (dispatch) => {
    try {
        const response = await getApiCall(`external/resources?page=1&type=${type ? type : ''}&industry_id=${industry !== '' ? industry : ''}&size=20`);
        if (response.status === 200) {
            return dispatch({ type: GET_RELATED_RESOURCES, payload: response.data });
        }
    } catch (error) {
        // returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an get api call to resousces based on ids in RelatedResources section in Home, Platform, Product - NG-SIEM, Product - Identity, Product - EDR
// Product - Compliance Manager, Partners, Industries - SMB, Industries - Banking, Industries - Finance, Industries - Insurance, Industries - Manufacturing, Industries - Healthcare
// Industries - Pharma, Industries - Retail, Industries - Technology, Industries - Government all this pages

export const getRelatedResource = (ids) => async (dispatch) => {
    // let ids = ['66e5182bd62a03d2b74ff2cd', '66e51618d62a03d2b74ff1da', '66e518a9d62a03d2b74ff33e', '66da867e8dae5e2bd4cb3de5']
    try {
        const response = await getApiCall(`resource/data?resource_ids=${JSON.stringify(ids)}`);
        if (response.status === 200) {
            return dispatch({ type: GET_RELATED_RESOURCES, payload: response.data });
        }
    } catch (error) {
        // returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const getOneResource = (ids) => async (dispatch) => {
    try {
        const response = await getApiCall(`resource/data?resource_ids=${JSON.stringify(ids)}`);
        if (response.status === 200) {
            return dispatch({ type: 'GET_ONE_RESOURCES', payload: response.data.data[0] });
        }
    } catch (error) {
        // returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an post api call to Unlock resources in Resources page and Related Resources section in Home, Platform, Product - NG-SIEM, Product - Identity, Product - EDR
// Product - Compliance Manager, Partners, Industries - SMB, Industries - Banking, Industries - Finance, Industries - Insurance, Industries - Manufacturing, Industries - Healthcare
// Industries - Pharma, Industries - Retail, Industries - Technology, Industries - Government all this pages

export const unlockResources = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`external/resources/email`, obj);
        if (response.status === 200) {
            return dispatch({ type: 'UNLOCK_RESOURCES', payload: response.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an post api call to Submit Download Form in Resources

export const submitDownloadForm = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`external/resources/email`, obj);
        if (response.status === 200) {
            return dispatch({ type: 'DOWNLOAD_FORM' });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}



//used in newsroom

// This function was an get api call to get News in Press Releases and Media Coverage pages

export const getNewsroom = (page, query, type, topic, industry, size) => async (dispatch) => {
    try {
        const response = await getApiCall(`external/resources?page=${page}&search=${query ? query : ''}&type=${type ? type : ''}&topic_id=${topic !== '' ? topic : ''}&industry_id=${industry !== '' ? industry : ''}&size=${size}`);
        if (response.status === 200) {
            return dispatch({ type: GET_EXTERNAL_RESOURCES, payload: response.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}


