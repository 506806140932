import {
    GET_ROLES, RESET_LISTS, GET_SEQUERTEK_USERS, ADD_USER, CHANGE_STATUS, EDIT_USER, DELETE_USER, GET_PARTNERS, GET_COUNTRIES, ADD_PARTNER,
    GET_PARTNER_USERS
} from "../Types/types";

let intialState = {
    isLoading: true,
    roles: [],
    partnerRoles: [],
    statusList: [{ value: 'active', label: 'Active' }, { value: 'inactive', label: 'Inactive' }, { value: 'invited', label: 'Invited' }],
    adminPartners: []
}

// This function was to initially store user role details based on title

const getRole = (data, type) => {
    if (type === 'partner') {
        const pRole = data.filter(el => (el.title === 'manager' || el.title === 'partner-admin'));
        return pRole.map(el => [{ ...el, label: el.title === 'manager' ? 'Manager' : 'Partner Admin', value: el._id }][0])
    } else {
        const role = data.filter(el => (el.title !== 'manager' && el.title !== 'partner-admin'));
        return role.map(el => [{ ...el, label: el.title === 'admin' ? 'Admin' : el.title === 'sales' ? 'Sales' : el.title === 'support' ? 'Support' : null, value: el._id }][0])
    }
}

// This function was to initially store user details based on statuslist

export default function userReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_ROLES:
            stateCopy.allRoles = action.payload.map(el => [{ ...el, label: el.title === 'admin' ? 'Admin' : el.title === 'sales' ? 'Sales' : el.title === 'support' ? 'Support' : el.title === 'manager' ? 'Manager' : 'Partner Admin', value: el._id }][0]);
            stateCopy.roles = getRole(action.payload, 'user');
            stateCopy.partnerRoles = getRole(action.payload, 'partner');
            return stateCopy;
        case RESET_LISTS:
            stateCopy.isLoading = true;
            return stateCopy;
        case GET_SEQUERTEK_USERS:
            stateCopy.sequertekUsers = action.payload.data;
            stateCopy.totalPage = action.payload.pages;
            stateCopy.isLoading = false;
            return stateCopy;
        case ADD_USER:
            if (((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager'))) {
                stateCopy.partnerUsers = [...stateCopy.partnerUsers, action.payload];
            } else {
                stateCopy.sequertekUsers = [...stateCopy.sequertekUsers, action.payload];
            }
            return stateCopy;
        case CHANGE_STATUS:
            if (((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager'))) {
                stateCopy.partnerUsers.forEach(el => {
                    if (el._id === action.payload._id) {
                        el.active = action.payload.checked
                    }
                })
            }
            else {
                if (action.payload.type === 'partner') {
                    stateCopy.adminPartners.forEach(el => {
                        if (el._id === action.payload._id) {
                            el.active = action.payload.checked
                        }
                    })
                } else {
                    stateCopy.sequertekUsers.forEach(el => {
                        if (el._id === action.payload._id) {
                            el.active = action.payload.checked
                        }
                    })
                }
            }
            return stateCopy;
        case EDIT_USER:
            return stateCopy;
        case DELETE_USER:
            if (((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager'))) {
                stateCopy.partnerUsers = stateCopy.partnerUsers?.filter(el => el._id !== action.payload.id)
            }
            else {
                if (action.payload.type === 'partner') {
                    stateCopy.adminPartners = stateCopy.adminPartners?.filter(el => el._id !== action.payload.id)
                } else {
                    stateCopy.sequertekUsers = stateCopy.sequertekUsers?.filter(el => el._id !== action.payload.id)
                }
            }
            return stateCopy;
        case GET_PARTNERS:
            stateCopy.adminPartners = action.payload.data;
            stateCopy.totalPage = action.payload.pages;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_COUNTRIES:
            stateCopy.countries = action.payload.map(el => [{ label: el.name, value: el._id }][0]);
            return stateCopy;
        case ADD_PARTNER:
            stateCopy.adminPartners = [...stateCopy.adminPartners, action.payload];
            return stateCopy;
        case GET_PARTNER_USERS:
            stateCopy.partnerUsers = action.payload.data;
            stateCopy.total = action.payload.pages;
            stateCopy.isLoading = false;
            return stateCopy;
        default:
            return stateCopy;
    }
}