// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';


export default function Blogs20() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek Blog-CYBER SECURITY NEEDS A PARADIGM SHIFT</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} CYBER SECURITY NEEDS A PARADIGM SHIFT</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>CYBER SECURITY NEEDS A PARADIGM SHIFT</h3>
                        <p>26-APRIL-2021 | Vivek Kumar and Juee Mudras </p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample62.jpg' width={'100%'} height={318} alt='' />
                            <p>By:- Commander Subhash Dutta, Indian Navy (Retd), Chief Operations Officer and Head of Malware Research at Sequretek</p>
                            <h4>Introduction</h4>
                            <ol>
                                <li><p>After having spent a fair bit of time in the Information Security (InfoSec) industry, I have found that we need to reinvent ourselves on regular intervals. I find that rather defeating as the impression that we create is that we are in a constant tail-chase and the attackers always have an upper hand.  A dispassionate assessment of security technologies and their core functions would lead one to realise that nothing really has changed.  The attackers have always been after data/information that can be monetised, and the defenders have been trying to protect this.</p></li>
                                <li><p>While the technologies underpinning the IT infrastructure have advanced rapidly over the last few years, the underlying feature – the ability to rapidly process and consume information – has remained the same. After their initial attempts of attacking systems, the focus of attackers has always been this information and not the systems that create, store, or process this data.  Therefore, it stands to reason that while our defensive tools and processes may change to keep up with newer systems or technologies, the basic principles must remain the same.</p></li>
                                <li><p>I have had numerous discussions with professionals chartered with keeping their IT infrastructure and users safe – predominantly these are the Chief Information Security Officers (CISOs) – regarding specific threats. From the earlier generations of rootkits[1], we transitioned to Advanced Persistent Threats (APTs) and now the talk is peppered with generous sprinkling of ransomware[2].  If you were to consider the basic functioning of these classes of malware[3], there are remarkable similarities.  Cisco, for example, defines an APT as “processes (that) require a high degree of covertness over a long period of time”[4].  This is markedly similar in capability to a rootkit that hides the existence of malware by hooking and altering operating system Application Programming Interface (API) calls that supply system information.</p></li>
                                <li><p>However, if I tell them that instead of chasing threats, which will be constantly evolving, CISOs must focus on their risks – where is their critical data, who has access to it and where is it moving – I get a polite listen and then the conversation changes back to “but what can your product do against ransomware?”.</p></li>
                            </ol>
                            <h4>A Shift in Thinking</h4>
                            <ol>
                                <li><p><strong>Simplify Security</strong></p></li>
                                <ol type="a">
                                    <li><p> So, what is required to change the mindset?  First, to uncomplicate security.  This is not very hard but has never received the focus that it deserves.  Our security professionals have been deluged with an information overload and suffer greatly from alert-fatigue.  Triaging incidents to take on the most dangerous threat considering the criticality of the system being attacked is virtually impossible when facing up to 900 million events per day (something that most Security Operation Centres face).</p></li>
                                    <li><p> IT administrators and InfoSec professionals are in a daily battle against attackers that are constantly on the lookout for low hanging fruit.  The most common adage in the InfoSec community is that the security professional must ensure that all doors and windows are secured while the attacker has to find just one hole.  It is not a question of “if” but “when” an organisation would be successfully attacked and lose data.</p></li>
                                    <li><p>If we are to provide a degree of succour to our InfoSec professionals the best way is to reduce the number of dashboards/consoles that they need to monitor.  A single endpoint security solution that provides advanced protection against threats emanating from outside while also preventing movement of data to an un-authorised entity would cover almost six different current security products– antimalware, patch management, Data Leakage Prevention (DLP), device-hardening, host-based firewall/Intrusion Detection/Protection System (IDS/IPS), application control, etc.</p></li>
                                </ol>
                                <li><p>The second most important factor is to fight this menace as we would fight a war. Fortunately, (or unfortunately, depending how one looks at it) nations have been fighting wars for centuries and have perfected the basic principles that ensure victory.  Thinkers, such as Sun Tzu and others have outlined these principles that have stood the test of time.  While there are no universally agreed upon principles of was, as they follow from the military doctrines of nations, there is a fair degree of underlying similarity between them.</p></li>
                                <li><p><strong>Adopting the Principles of War to InfoSec.</strong> The British first enunciated the Ten Principles of War after the First World War and were based on the work of the British general and military theorist, JFC Fuller[12].  With minor variations, these principles have been adopted by the British armed forces and, by most Commonwealth nations[13].  We can extrapolate these principles to the domain of Information Security and then devise our strategies and tactics according to these principles.</p></li>
                                <ol type="a">
                                    <li><p><strong>Selection and Maintenance of Aim</strong>[14].  This is the first and pre-eminent principle and guides all other actions.  Organisations must have a singular and unambiguous aim – the protection of critical data to ensure continuation of business operations.  This  must be communicated clearly to the InfoSec team.  Decisions taken in pursuance of this principle will govern all other actions.</p></li>
                                    <li><p><strong>Maintenance of Morale</strong>[15].  Security professionals are deluged with an information overload and increasingly face alert fatigue.  Many times, their actions to protect organisations’ assets go unnoticed and hence unrecognised.  It is of utmost importance that InfoSec warriors are acknowledged and their efforts rewarded.  While monetary rewards do provide some satisfaction, peer recognition and regular feedbacks have been known to give greater gratification.  Simultaneously reposing trust in security partners will provide additional gains.</p></li>
                                    <li><p><strong>Offensive Action</strong>[16].  A series of activities that seize the initiative, gain an upper hand, and maintain momentum over attackers ensures the security of an organisations’ critical data.  Allocation of resources in terms of talented personnel and appropriate security systems is essential and team leaders must be empowered to take actions to fend off attacks with minimal recourse to convoluted chain of command.  While the people on the ground will place demands for more resources, it is the higher authority (CxOs[17]) that have the strategic picture in mind and hence will frame long-term priorities.  They must have absolute clarity on the criticality of individual systems that need higher protection and therefore, provide more allocation of resources.  If these systems fall, everything else will collapse.</p></li>
                                    <li><p><strong>Security</strong>[18].  This provides and maintains an operating environment that gives freedom of action, when and where required, to achieve objectives.  InfoSec teams must be empowered to take urgent protective action when required.  Most security controls are considered an inconvenience and users will most likely try to circumvent them.  Management must send out a message that the security controls in place do not imply mistrust in employees, but rather are there to provide a safe a secure environment for them to be productive.</p></li>
                                    <li><p><strong>Surprise</strong> [19].  This is the consequence of confusion induced by deliberately or incidentally introducing the unexpected.  InfoSec professionals must guard against being surprised by the attackers.  Threat and malware intelligence is essential in developing a picture that provides a holistic view of the hostile environment.  In the absence of this view, the attackers retain the element of surprise and hence initiative.  Any action that the InfoSec team does thereafter would be a reactive one and would cause frustration in the long run.</p></li>
                                    <li><p><strong>Concentration of Force</strong>[20].  In military terms, this involves decisively applying superior fighting power (physical, intellectual, and moral) to realise intended effects, when and where required.  For InfoSec warriors, it is essential that they do not spread themselves too thin and attempt to do everything simultaneously.  Meticulous planning is required by the CISOs to analyse the critical components that would require prioritised attention, align personnel, and equip them with necessary tools to fight localised outbreaks.</p></li>
                                    <li><p><strong>Economy of Effort</strong>[21].  This involves judiciously exploiting resources to achieve the objective.  The activities pursuant to this flow from the previous principal.  Resources will always be short – security systems may not be optimally configured, personnel may lack training, or the time available to respond and recover may not be adequate.  Security leaders need to plan for contingencies and ensure that process delays are ironed out and resources are available to meet the requirement.</p></li>
                                    <li><p><strong>Flexibility</strong>[22].  This is the ability to change readily to meet new circumstances.  Attackers may not always follow a set pattern; they will look for targets of opportunity and a vulnerable internet facing resource will invite unwelcome attention.  Flexibility also demands that greater autonomy be accorded to personnel who need to act quickly to mitigate greater compromise.  Resilient business operations during an ongoing attack will only be possible if responses are not rigid and agility and adaptability is incorporated in security operations.</p></li>
                                    <li><p><strong>Cooperation</strong>[23].  Apart from the teamwork that is a given in an organisation, cooperation also implies sharing of dangers, burdens, risks, and opportunities in every aspect.  HR, corporate communications, and legal departments of an organisation must also be in complete sync with the InfoSec team.  Information flows between various departments must be seamless and systems should be in place to exchange this information.  An example could be an employee who has left the organisation, but accesses to obscure repositories remain and are open to abuse.</p></li>
                                    <li><p><strong>Sustainability</strong>[24].  Just as nations and militaries maintain sustenance to ensure the ability to fight and preserve freedom of action, so must organisations enable their InfoSec teams with adequate resources to fight off determined attackers.  Investments in training and upgrading the skills of InfoSec warriors will pay a handsome dividend when required.  This will also ensure that committed and capable resources remain with the organisation for a longer period – a feature in great demand in an industry with high attrition rates.</p></li>
                                </ol>
                            </ol>
                            <h4>Conclusion</h4>
                            <ol>
                                <li><p>The early day malware activities were largely nuisance attacks, such as defacing or putting graffiti on an organisation’s webpage. Present day malware attacks have become full blown cyberwars (eg, attacks on critical infrastructures) and sophisticated crimes.  An underground eco-system has also emerged to support the full malware lifecycle that includes development, deployment, operations, and monetisation.  In this eco-system, there are actors specialising in key parts of the malware lifecycle, and by providing their services to others they also get a share of the financial gains and rewards.  Such specialisation improves the quality of malware.  For example, an attacker can hire the best exploit researcher to write the part of the malware responsible for remotely compromising a vulnerable computer.</p></li>
                                <li><p>Take the progression of ransomware[25]. It started off as simple data encryption and payment of ransom was required for the decryption key.  Organisations adapted to this by ensuring proper backups of critical data, rebuilt their systems and refused to pay the ransom.  The attackers then changed tack and exfiltrated data prior to encrypting it and then demanded a ransom otherwise the data would be exposed publicly.  Organisations protected themselves with ensuring that protective measures – such as encrypting data – are rigorously enforced while also opting for cyber risk insurance.  The attackers then again shifted their tactics and started informing clients and users whose data has been exposed to name and shame the compromised organisation.</p></li>
                                <li><p>An InfoSec organisation must be agile, keep up with the techniques, tactics, and procedures of attackers by building a robust threat and malware intelligence databank. Organisations must also do their part by supporting their InfoSec warriors by way of resources, capability, and technical advancement.  There must be a concerted effort at reducing the complexity of security operations and reduce the alert fatigue on the part of security operations monitoring</p></li>
                            </ol>
                            <p>Commander Subhash Dutta is a former Indian Naval Officer and Adjunct Fellow at the National Maritime Foundation.  He can be contacted at subhashdutta.nmf@gmail.com</p>
                            <p>References:</p>
                            <p>[1] Dan Rafter, “What is a rootkit? And how to stop them”, NortonLifeLock Inc. <a target='_blank' rel="noreferrer" href='https://us.norton.com/internetsecurity-malware-what-is-a-rootkit-and-how-to-stop-them.html'>https://us.norton.com/internetsecurity-malware-what-is-a-rootkit-and-how-to-stop-them.html</a></p>
                            <p>[2] Josh Fruhlinger, “Ransomware explained: How it works and how to remove it”, CSO Online, 19 Jun 2020. <a target='_blank' rel="noreferrer" href='https://www.csoonline.com/article/3236183/what-is-ransomware-how-it-works-and-how-to-remove-it.html'>https://www.csoonline.com/article/3236183/what-is-ransomware-how-it-works-and-how-to-remove-it.html</a></p>
                            <p>[3] Malware is a portmanteau, a word coined from the combination of the words malicious and software, commonly used within the InfoSec community.</p>
                            <p>[4] “What Is the Difference: Viruses, Worms, Trojans, and Bots?”, Cisco, 14 Jun 2018.  <a target='_blank' rel="noreferrer" href='https://tools.cisco.com/security/center/resources/virus_differences#APT'>https://tools.cisco.com/security/center/resources/virus_differences#APT</a></p>
                            <p>[5] Petr Gazarov, “What is an API”, FreeCodeCamp, 19 Dec 2019. <a target='_blank' rel="noreferrer" href='https://www.freecodecamp.org/news/what-is-an-api-in-english-please-b880a3214a82/'>https://www.freecodecamp.org/news/what-is-an-api-in-english-please-b880a3214a82/</a></p>
                            <p>[6] This is a representative figure.  Sequretek IT Solutions SOC ingests around 800 million events per day from almost 3500 devices spread across its more than 60 clients.</p>
                            <p>[7] <a target='_blank' rel="noreferrer" href='https://sequretek.com/wp-content/uploads/2018/10/EDPR_Datasheet.pdf'>https://sequretek.com/wp-content/uploads/2018/10/EDPR_Datasheet.pdf</a></p>
                            <p>[8] Joshua Mark, “Sun-Tzu”, World History Encyclopaedia, 09 Jul 2020. <a target='_blank' rel="noreferrer" href='https://www.worldhistory.org/Sun-Tzu/'>https://www.worldhistory.org/Sun-Tzu/</a></p>
                            <p>[9] Wikipedia, s.v. Chanakya, last modified 28 Feb 2021, 07:32, <a target='_blank' rel="noreferrer" href='https://en.wikipedia.org/wiki/Chanakya'>https://en.wikipedia.org/wiki/Chanakya</a></p>
                            <p>[10] Cary Nederman, “Niccolò Machiavelli”, Stanford Encyclopaedia of Philosophy, 28 May 2019. <a target='_blank' rel="noreferrer" href='https://plato.stanford.edu/entries/machiavelli/'>https://plato.stanford.edu/entries/machiavelli/</a></p>
                            <p>[11] Jordan Lindell, “Clausewitz: War, Peace and Politics”, E-International Relations, 26 Nov 2009. <a target='_blank' rel="noreferrer" href='https://www.e-ir.info/2009/11/26/clausewitz-war-peace-and-politics/'>https://www.e-ir.info/2009/11/26/clausewitz-war-peace-and-politics/</a>
                                <p>[12] Wikipedia, s.v. J.F.C.Fuller, last modifies 15 Mar 2021, 16:58, <a target='_blank' rel="noreferrer" href='https://en.wikipedia.org/wiki/J._F._C._Fuller'>https://en.wikipedia.org/wiki/J._F._C._Fuller</a></p>
                                <p>[13] UK Ministry of Defence, “UK Defence Doctrine, Annex 2A”, Nov 2014. <a target='_blank' rel="noreferrer" href='https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file /389755/20141208-JDP_0_01_Ed_5_UK_Defence_Doctrine.pdf'>https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file /389755/20141208-JDP_0_01_Ed_5_UK_Defence_Doctrine.pdf</a></p>
                                <p>[14] Ibid</p>
                                <p>[15] Ibid</p>
                                <p>[16] Ibid</p>
                                <p>[17] Titles in an organisation are given to a company’s senior executives, those members that work together to ensure a company stays true its pre-set plans and policies.  These titles, usually starting with the letter C for “Chief” and ending with the letter O for “Officer” are grouped together as C-suite or C-level executives.  And since, today, there are so many variations of the C-level titles, they are collectively referred to as CxOs.</p>
                                <p>[18] UK Ministry of Defence, op cit</p>
                                <p>[19] Ibid</p>
                                <p>[20] Ibid</p>
                                <p>[21] Ibid</p>
                                <p>[22] Ibid</p>
                                <p>[23] Ibid</p>
                                <p>[24] Ibid</p>
                                <p></p>[25] Kevin Savage et al, “The Evolution of Ransomware”, Florida State University, 06 Aug 2015. <a target='_blank' rel="noreferrer" href='https://its.fsu.edu/sites/g/files/imported/storage/images/information-security-and-privacy-office/the-evolution-of-ransomware.pdf'>https://its.fsu.edu/sites/g/files/imported/storage/images/information-security-and-privacy-office/the-evolution-of-ransomware.pdf</a></p>
                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'Sequretek Blog-CYBER SECURITY NEEDS A PARADIGM SHIFT' , url: 'https://www.sequretek.com/resources/blog/cyber-security-needs-a-paradigm-shift'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
