// ############################ Require packages here ############################
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { Modal, Table } from 'react-bootstrap';
import exportFromJSON from "export-from-json";


// ############################ Require files here ###############################

import Paginations from '../../../utils/pagination';
import AddDeal from './addDeal';
import '../Leads/leads.scss';
import { getDealsExportData, getDeals, deletedDeal, updateDeal, addDeal } from '../../../Redux/ActionCreators/dealAction';
import { getCountries } from '../../../Redux/ActionCreators/usersAction';
import { getAllCompanies, getAllPartners, getAllProducts } from '../../../Redux/ActionCreators/leadsAction';
import { TopicLoader } from '../../../Loader/loader';
import { convertDate } from '../../../utils/miscellneous';
import ConfirmModal from '../../../utils/confirmModal';
import { Dialog } from '@mui/material';


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/arrow-down.svg' alt='' width={18} />
        </components.DropdownIndicator>
    );
};

export default function Deals() {
    const [isAdd, setIsAdd] = useState(false);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const size = 10;
    const [partner, setPartner] = useState(null);
    const [product, setProduct] = useState(null);
    const [company, setCompany] = useState(null);
    const [status, setStatus] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isView, setIsView] = useState(false);
    const [loader, setLoader] = useState(false);
    const [dealDetails, setDealDetails] = useState({
        firstname: '',
        lastname: '',
        email: '',
        mobile: "",
        dial_code: "+1",
        company: "",
        website: "",
        country: "",
        city: "",
        partner: '',
        status: "",
        products: [],
        value: "",
        currency: "USD",
        deal_stage: "",
        comment: '',
        attachment: '',
        doc_shared: false
    })

    const { isLoading, deals, totalPages } = useSelector(state => state.deals);
    const { countries } = useSelector(state => state.users);
    const { allPartners, companies, products, leadStatuses } = useSelector(state => state.leads);

    const dispatch = useDispatch();
    const timerRef1 = React.createRef(null);

    useEffect(() => {
        dispatch(getAllPartners());
        dispatch(getAllProducts());
        dispatch(getAllCompanies());
        dispatch(getCountries());
        const details = JSON.parse(localStorage.getItem('profileDetails'));
        ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? setPartner({ value: details?.partner_id, label: `${details.fname} ${details?.lname}` }) : setPartner(null);
        dispatch(getDeals(page, size, query, details?.partner_id, '', '', ''));
    }, [])

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
        dispatch(getDeals(pageNumber, size, query, partner?.value, product?.value, company?.value, status?.value));
    };


    const selectPartner = async (e) => {
        setPartner(e);
        setPage(1);
        setQuery('');
        dispatch(getDeals(1, size, '', e?.value, product?.value, company?.value, status?.value));
    }
    const selectProduct = async (e) => {
        setProduct(e);
        setPage(1);
        setQuery('');
        await dispatch(getDeals(1, size, '', partner?.value, e?.value, company?.value, status?.value));
    }

    const selectCompany = async (e) => {
        setCompany(e);
        setPage(1);
        setQuery('');
        await dispatch(getDeals(1, size, '', partner?.value, product?.value, e?.value, status?.value))
    }

    const selectStatus = async (e) => {
        setStatus(e);
        setPage(1);
        setQuery('');
        await dispatch(getDeals(1, size, '', partner?.value, product?.value, company?.value, e?.value));
    }


    const userAction = (detail, edit) => {
        const details = JSON.parse(localStorage.getItem('profileDetails'));
        setIsEdit(edit);
        if (detail === null) {
            setIsAdd(true);
            setDealDetails({
                firstname: '',
                lastname: '',
                email: '',
                mobile: "",
                dial_code: "+1",
                company: "",
                website: "",
                country: "",
                city: "",
                partner: ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? details?.partner_id : '',
                status: "",
                products: [],
                value: "",
                currency: "USD",
                deal_stage: ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? 'pending' : "",
                comment: '',
                attachment: '',
                doc_shared: false
            })
        } else {
            setIsAdd(true);
            const { fname, lname, email, mobile, dial_code, company, website, country, city, deal_stage, products, comment, partner, value, currency, status, attachments, doc_shared, _id } = detail
            setDealDetails({
                firstname: fname,
                lastname: lname,
                email: email,
                mobile: mobile,
                dial_code: dial_code,
                company: [{ label: company?.name, value: company?._id }],
                website: website,
                country: country?._id,
                city: city,
                status: deal_stage,
                products: ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? products?.map(el => el._id) : products?.map(el => [{ label: el.name, value: el._id }][0]),
                comment: comment,
                deal_stage: status,
                currency: currency,
                value: value,
                partner: partner?._id,
                attachment: attachments[0],
                _id: _id,
                doc_shared: doc_shared
            })
        }
    }

    const getProduct = (value) => {
        const data = products.filter(obj => value.includes(obj._id));
        return data?.map(el => [{ name: el.name, _id: el._id }][0])
    }


    const handleSubmit = async (values) => {
        await setLoader(true);
        const data = new FormData();
        data.append("partner_id", values?.partner);
        data.append("fname", values?.firstname);
        data.append('lname', values.lastname);
        data.append('email', values.email);
        data.append('dial_code', values.dial_code);
        data.append('mobile', values.mobile);
        data.append('company', JSON.stringify(values?.company[0]?.__isNew__ ? { name: values?.company[0]?.label } : { _id: values?.company[0]?.value, name: values?.company[0]?.label }));
        data.append('website', values.website);
        data.append('city', values.city);
        data.append('country_id', values.country);
        data.append('status', values?.deal_stage === undefined ? '' : values?.deal_stage);
        data.append('products',
            ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? JSON.stringify(getProduct(values?.products)) : JSON.stringify(values?.products?.map(el => el.__isNew__ ? [{ name: el.label }][0] : [{ name: el.label, _id: el.value }][0])));
        dealDetails?._id && data.append('_id', dealDetails?._id)
        data.append('comment', values?.comment)
        data.append('value', values?.value);
        data.append('currency', values?.currency);
        values?.attachment && data.append('payload', values?.attachment);
        data.append('doc_shared', values?.doc_shared)
        if (!isEdit) {
            const res = await dispatch(addDeal(data));
            if (res?.type === 'ADD_DEAL') {
                await setIsAdd(false);
                await dispatch(getAllProducts());
                await dispatch(getAllCompanies());
                setLoader(false);
            }
        } else {
            const res = await dispatch(updateDeal(data));
            if (res?.type === 'EDIT_DEAL') {
                await setIsAdd(false);
                dispatch(getDeals(page, size, query, partner?.value, '', '', ''));
                await dispatch(getAllProducts());
                await dispatch(getAllCompanies());
                setLoader(false);
            }
        }
        await setLoader(false);
    }



    const deleteDeal = (id) => {
        setDeleteId(id);
        setIsConfirm(true);
    }


    const removeConfirm = async () => {
        const res = await dispatch(deletedDeal(deleteId));
        if (res?.type === 'DELETE_DEAL') {
            setIsConfirm(false);
            setDeleteId('');
        }
    }

    const downloadData = async () => {
        await setLoading(true);
        const res = await dispatch(getDealsExportData());
        if (res?.type === 'DEAL_EXPORT_DATA') {
            const data = res?.payload?.map(
                (el) => [
                    {
                        'Deal Name': el.name,
                        'Company Name': el.company?.name,
                        'Partner Name': `${el.partner.fname} ${el.partner.lname}`,
                        'Products': el.products.map(el => el.name).join(', '),
                        'Deal Stage': el.status,
                        'Deal value': el.value,
                        "Added-date": convertDate(el.createdAt)
                    },
                ][0]
            );
            const fileName = "deals";
            const exportType = await exportFromJSON.types.csv;
            await exportFromJSON({ data, fileName, exportType });
        }
        await setLoading(false);
    }

    const openView = async (el) => {
        await setLoading(true);
        await setIsView(true);
        await setDealDetails(el);
        await setLoading(false);
    }

    const searchDeal = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPage(1);
        timerRef1.current = setTimeout(() => delaySearch1(val), 800);
    };
    const delaySearch1 = async (val) => {
        dispatch(getDeals(1, size, val, partner?.value, product?.value, company?.value, status?.value));
    }


    return (
        <div className='leads-holder py-2 py-md-2 me-3'>
            {!isAdd ?
                <Fragment>
                    {/* DEALS CODE */}
                    <div>
                        <h2 className="d-flex align-items-center justify-content-between">
                            Deals
                            <div className='leads-btn'>
                                <button style={{ visibility: deals?.length ? 'visible' : 'hidden' }} disabled={loading} onClick={downloadData}>Export </button>
                                <button onClick={() => userAction(null, false)}>Add new Deal&nbsp;&nbsp; <img src='/images/plus-white.svg' alt='' /> </button>
                            </div>
                        </h2>
                    </div>
                    <div className='users-table-holder'>
                        <div className='users-header flex-wrap'>
                            <div className='user-input' style={{ width: '35.8%' }}>
                                <div className="has-search"   >
                                    <span className="form-control-feedback">
                                        <img src="/images/search-icon.svg" alt='' width={15} />
                                    </span>
                                    <input type='search' placeholder='Search By Deal' className='form-control' value={query} onChange={(e) => searchDeal(e.target.value)} />
                                </div>

                            </div>
                            <div className='select-group mt-2 w-100'>
                                {((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? null :
                                    <div>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            options={allPartners}
                                            isClearable
                                            onChange={selectPartner}
                                            placeholder='By Partner'
                                            value={partner}
                                        />
                                    </div>
                                }
                                <div className={((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? "" : 'ms-3'}>
                                    <Select
                                        components={{ DropdownIndicator }}
                                        options={products}
                                        isClearable
                                        onChange={selectProduct}
                                        placeholder='By Product'
                                        value={product}
                                    />
                                </div>
                                <div className='ms-3'>
                                    <Select
                                        components={{ DropdownIndicator }}
                                        options={companies}
                                        isClearable
                                        onChange={selectCompany}
                                        placeholder='By Company'
                                        value={company}
                                    />
                                </div>
                                <div className='ms-3'>
                                    <Select
                                        components={{ DropdownIndicator }}
                                        options={leadStatuses}
                                        isClearable
                                        onChange={selectStatus}
                                        placeholder='By Deal Stage'
                                        value={status}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='users-table'>
                            <Table responsive >
                                <thead>
                                    <tr className='table-header'>
                                        {/* <th className='text-center'><input type='checkbox' style={{ width: 18, height: 18 }} /></th> */}
                                        <th>DEAL NAME</th>
                                        <th >COMPANY NAME   </th>
                                        <th>PARTNER NAME   </th>
                                        <th className='text-center'>PRODUCTS</th>
                                        <th className='text-center'>Deal Value</th>
                                        <th className='text-center'>ADDED ON</th>
                                        <th className='text-center'>STATUS</th>
                                        <th className='text-center'>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <tr>
                                            <td colSpan={7}> <TopicLoader /> </td>
                                        </tr>
                                        :
                                        deals?.length > 0 ?
                                            deals && deals?.map((el, i) => {
                                                return (
                                                    <tr>
                                                        {/* <td className='text-center'><input type='checkbox' style={{ width: 18, height: 18 }} /></td> */}
                                                        <td style={{ width: '12%' }}>
                                                            <div className='d-flex align-items-center'>
                                                                {/* <div className='profile-img'>
                                                                    <img src='/images/avatar.svg' alt='' className='rounded-circle' />
                                                                </div>&nbsp;&nbsp;&nbsp; */}
                                                                {el.fname} {el.lname}
                                                            </div>
                                                        </td>
                                                        <td >{el.company?.name}</td>
                                                        <td >{el.partner?.fname} {el.partner?.lname}</td>
                                                        <td style={{ width: '20%' }}>
                                                            <div className='d-flex justify-content-center align-items-center flex-wrap'>
                                                                {el.products?.map(el1 => {
                                                                    return (
                                                                        <Fragment>
                                                                            <div className='product mt-2'>{el1.name}</div>
                                                                            &nbsp;
                                                                        </Fragment>
                                                                    )
                                                                })}
                                                            </div>
                                                        </td>
                                                        <td className='text-center'>{el.value}</td>
                                                        <td className='text-center'>{convertDate(el.createdAt)}</td>
                                                        <td>
                                                            <div className={el.status === 'approved' ? 'active' : el.status === 'rejected' ? 'reject' : 'pending'}>
                                                                {el.status}
                                                            </div>
                                                        </td>
                                                        <td style={{ width: '13%' }} className='cursor-pointer text-center'>
                                                            <img src='/images/view-icon.svg' alt='' width={20} height={20} onClick={() => openView(el)} />&nbsp;&nbsp;
                                                            <img src='/images/edit-icon.svg' alt='' width={20} height={20} onClick={() => userAction(el, true)} />&nbsp;&nbsp;
                                                            {((localStorage.getItem('role') === 'admin') || (localStorage.getItem('role') === 'partner-admin')) ?
                                                                <img src='/images/delete-icon.svg' alt='' width={20} height={20} onClick={() => deleteDeal(el._id)} /> :
                                                                null
                                                            }


                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className='empty-list'>
                                                        <img src='/images/empty.png' alt='' />
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                            {totalPages > 1 ?
                                <div className='d-flex justify-content-end me-3'>
                                    <Paginations className={'custom-pagination'} totalPages={totalPages} onPageChange={handlePageChange} />
                                </div> :
                                null
                            }
                        </div>
                    </div>
                </Fragment> :
                <Fragment>
                    <Dialog
                        className='screen-loader'
                        open={loader}
                        onClose={() => { }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        Saving...
                    </Dialog>
                    <AddDeal
                        setIsAdd={() => setIsAdd(false)}
                        dealDetails={dealDetails}
                        allPartners={allPartners}
                        companies={companies}
                        countries={countries}
                        products={products}
                        leadStatuses={leadStatuses}
                        handleSubmit={handleSubmit}
                        isEdit={isEdit}
                    />
                </Fragment>
            }

            {/* ################################## Confirmation Modal ################################ */}
            <ConfirmModal
                isConfirm={isConfirm}
                setIsConfirm={() => setIsConfirm(false)}
                type='deal'
                removeConfirm={removeConfirm}
            />
            {/* View Deal Details */}
            <Modal
                show={isView}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"view-modal"}
            >
                <div className='sticky-close'>
                    <img src='/images/close.svg' alt='' onClick={() => setIsView(false)} />
                </div>
                {loading ?
                    'Loading...'
                    :
                    (dealDetails &&
                        <div className='view-details'>
                            <h3>View Details</h3>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='view-detail'>
                                        <h5>Lead Name</h5>
                                        <p>{dealDetails?.fname} {dealDetails?.lname}</p>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='view-detail'>
                                        <h5>Email</h5>
                                        <p>{dealDetails?.email}</p>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='view-detail'>
                                        <h5>Mobile No</h5>
                                        <p>{dealDetails?.mobile}</p>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='view-detail'>
                                        <h5>Email</h5>
                                        <p>{dealDetails?.email}</p>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='view-detail'>
                                        <h5>Company Name</h5>
                                        <p>{dealDetails?.company?.name}</p>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='view-detail'>
                                        <h5>Company Website</h5>
                                        <p>{dealDetails?.website}</p>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='view-detail'>
                                        <h5>Country</h5>
                                        <p>{dealDetails?.country?.name}</p>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='view-detail'>
                                        <h5>City</h5>
                                        <p>{dealDetails?.city}</p>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='view-detail'>
                                        <h5>Lead Status</h5>
                                        <p className='text-capitalize'>{dealDetails?.status}</p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='view-detail'>
                                        <h5>Products</h5>
                                        <p>{dealDetails?.products?.map(el => el.name).join(', ')}</p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='view-detail'>
                                        <h5>Document Shared</h5>
                                        <p>{dealDetails?.doc_shared ? 'Yes' : "No"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>)
                }
            </Modal>
        </div>
    )
}
