// ############################ Require packages here ############################
import React from 'react'
import { Helmet } from 'react-helmet';



export default function VDP() {

    return (
        <div className='seq-cookie-policy'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Vulnerability Disclosure Policy</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Discover how Sequretek’s Vulnerability Disclosure Policy ensures responsible reporting and resolution of security vulnerabilities." />
                <meta property="og:title" content="Sequretek | Vulnerability Disclosure Policy" />
                <meta property="og:description" content="Discover how Sequretek’s Vulnerability Disclosure Policy ensures responsible reporting and resolution of security vulnerabilities." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='cookie-policy-header'>
                <h3>Vulnerability Disclosure Policy</h3>
            </div>
            {/* section 2 DESCRIPTION */}
            <div className='cookie-description'>
                <div className='container'>
                    <div className='mt-4 mb-4'>
                        <h4>Introduction</h4>
                        <p>
                            Sequretek IT Solutions Private Limited is committed to ensuring the security of the
                            public by protecting their information. This policy is intended to give security researchers
                            clear guidelines for conducting vulnerability discovery activities and to convey our
                            preferences in how to submit discovered vulnerabilities to us.
                        </p>
                        <p>
                            This policy describes what systems and types of research are covered under this policy,
                            how to send us vulnerability reports, and how long we ask security researchers to wait
                            before publicly disclosing vulnerabilities.
                        </p>
                        <p>
                            We encourage you to contact us to report potential vulnerabilities in our systems.
                        </p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Authorization
                        </h4>
                        <p>
                            If you make a good faith effort to comply with this policy during your security research,
                            we will consider your research to be authorized, we will work with you to understand
                            and resolve the issue quickly, and Sequretek will not recommend or pursue legal action
                            related to your research. Should legal action be initiated by a third party against you for
                            activities that were conducted in accordance with this policy, we will make this
                            authorization known.
                        </p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Guidelines
                        </h4>
                        <p>Under this policy, “research” means activities in which you:
                            <ul>
                                <li>Notify us as soon as possible after you discover a real or potential security issue.</li>
                                <li>Make every effort to avoid privacy violations, degradation of user experience, disruption to production systems, and destruction or manipulation of data.</li>
                                <li>Only use exploits to the extent necessary to confirm a vulnerability’s presence. Do not
                                    use an exploit to compromise or exfiltrate data, establish persistent command line
                                    access, or use the exploit to pivot to other systems.
                                </li>
                                <li>
                                    Provide us a reasonable amount of time to resolve the issue before you disclose it
                                    publicly.
                                </li>
                                <li>Do not submit a high volume of low-quality reports.</li>
                            </ul>
                        </p>
                        <p>
                            Once you’ve established that a vulnerability exists or encounter any sensitive data
                            (including personally identifiable information, financial information, or proprietary
                            information or trade secrets of any party), you must stop your test, notify us
                            immediately, and not disclose this data to anyone else.
                        </p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Test methods
                        </h4>
                        <p>The following test methods are not authorized:
                            <ul>
                                <li>
                                    Network denial of service (DoS or DDoS) tests or other tests that impair access to or
                                    damage a system or data
                                </li>
                                <li>
                                    Physical testing (e.g. office access, open doors, tailgating), social engineering (e.g.
                                    phishing, vishing), or any other non-technical vulnerability testing
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Scope
                        </h4>
                        <p>This policy applies to the following systems and services: </p>
                        <a href='https://sequretek.com' style={{ color: '#b6b6b6' }}>https://sequretek.com</a>
                        <p>Partner Portal Link</p>
                        <a href='https://threatportal.sequretek.com/'>https://threatportal.sequretek.com/</a>
                        <p>Any services not expressly listed above, such as any connected services, are excluded
                            from scope and are not authorized for testing. Additionally, vulnerabilities found in
                            systems from our vendors fall outside of this policy’s scope and should be reported
                            directly to the vendor according to their disclosure policy (if any). If you aren’t sure
                            whether a system is in scope or not, contact us at vul.disclosure@sequretek.com
                        </p>
                        <p>
                            Though we develop and maintain other internet-accessible systems or services, we ask
                            that active research and testing only be conducted on the systems and services
                            covered by the scope of this document. If there is a particular system not in scope that
                            you think merits testing, please contact us to discuss it first. We will increase the scope
                            of this policy over time.
                        </p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Reporting a vulnerability
                        </h4>
                        <p>
                            Information submitted under this policy will be used for defensive purposes only – to
                            mitigate or remediate vulnerabilities. If your findings include newly discovered vulnerabilities that affect all users of a product or service and not solely Sequretek, we
                            may share your report with the required regulatory body, where it will be handled under
                            their coordinated vulnerability disclosure process. We will not share your name or
                            contact information without express permission.
                        </p>
                        <p>
                            We accept vulnerability reports via vul.disclosure@sequretek.com. Reports may be
                            submitted anonymously. If you share contact information, we will acknowledge receipt
                            of your report within 3 business days.
                        </p>
                        <p>
                            We do not support PGP-encrypted emails. For particularly sensitive information, submit
                            it through our HTTPS web form.
                        </p>
                        <p>What we would like to see from you</p>
                        <p>In order to help us triage and prioritize submissions, we recommend that your reports:</p>
                        <p>
                            <ul>
                                <li>Describe the location the vulnerability was discovered and the potential impact of
                                    exploitation.
                                </li>
                                <li>
                                    Offer a detailed description of the steps needed to reproduce the vulnerability (proof of
                                    concept scripts or screenshots are helpful).
                                </li>
                                <li>
                                    Be in English, if possible.
                                </li>
                            </ul>
                        </p>
                        <p>
                            What you can expect from us
                        </p>
                        <p>
                            When you choose to share your contact information with us, we commit to coordinating
                            with you as openly and as quickly as possible.
                            <ul>
                                <li>
                                    Within 3 business days, we will acknowledge that your report has been received.
                                </li>
                                <li>
                                    To the best of our ability, we will confirm the existence of the vulnerability to you and
                                    be as transparent as possible about what steps we are taking during the remediation
                                    process, including on issues or challenges that may delay resolution.
                                </li>
                                <li>We will maintain an open dialogue to discuss issues.</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
