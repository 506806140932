// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';


export default function Blogs17() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek Blog-Kutaki Stealer – Analysis</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Kutaki Stealer – Analysis</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Kutaki Stealer – Analysis</h3>
                        <p>02-JUNE-2023 | Sanket Wagh </p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample62.jpg' width={'100%'} height={318} alt='' />
                            <h4>Overview</h4>
                            <p>Kutaki stealer is designed to stealthily infiltrate the victim’s computer. Kutaki stealer uses old techniques to detect VM, sandboxes and debugging. However, it works quite well against unhardened virtual machines and other analysis devices. Kutaki is a typical key logger.
                                In most cases, attackers infect their victims with malware through spam email campaigns, Trojans, fake (third party) software or software updates and software ‘cracking’ tools (unofficial activators).</p>
                            <p>Upon execution, activity of the malware is as follows: –</p>
                            <br />
                            <ul>
                                <li><p>VB compiler P-code executable.</p></li>
                                <li><p>Uses the anti-VM, anti-sandbox and, anti-debugging technique for process termination.</p></li>
                                <li><p>Steal the user credentials.</p></li>
                                <li><p>Capture key stokes or mouse movements.</p></li>
                                <li><p>Send the data to the attacker server.</p></li>
                            </ul>
                            <h4>Delivery Method</h4>
                            <br />
                            <p>While monitoring a client on our XDR solution, the Security Operations team noticed an anomalous communication that included an IP address that was listed in our Threat Intel feed as a malicious IP. This indicator was present in an email received by the client.
                                The client shared that email with Sequretek’s Malware Research Team for detailed analysis.</p>
                            <br />
                            <h4>Investigation Process</h4>
                            <br />
                            <ul>
                                <li><p>Email Header analysis</p></li>
                                <li><p>Email Body content analysis</p></li>
                            </ul>
                            <h4>Email Header Analysis</h4>
                            <br />
                            <p>SPF : neutral with IP Unknown!</p>
                            <p>DMARC : none</p>
                            <p>MTA Encryption : Poor</p>
                            <p>Email travel Path : 6 hops by email server</p>
                            <p>Sender Validation : Good</p>
                            <p>Sender IP address : 119[.]252[.]152[.]37</p>
                            <br />
                            <p>The lack of authenticating indicators (SPF and DMARC) of the email indicated a phishing email. Further the originator IP address has been reported multiple times from distinct sources with the first report on 14 Aug 2021, and the most recent being 2 weeks ago.</p>
                            <br />
                            <h4>Email Body Content Analysis</h4>
                            <br />
                            <p>The attachment, Payment Confirmation.pdf, sent by the attacker had an icon representing a PDF document (Figure 1). <strong> The text of the email asks the user to open the attached document to confirm payment received.</strong></p>
                            <p>On clicking the attachment, the file downloaded is ICICI_Payment.zip (Figure 2).</p>
                            <img src='/images/blog-images/sample148.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 3: daanjzfk running payload</p>
                            <br />
                            <p>This dropped file name changes on every execution of ICICI_Payment.cmd</p>
                            <h4>File Details</h4>
                            <Table responsive>
                                <tr>
                                    <th><p>MD5</p></th>
                                    <th><p>FC294C2DB2DE7C6270DB5EA3F70125EB</p></th>
                                </tr>
                                <tr>
                                    <th><p>SHA-1</p></th>
                                    <th><p>SHA-1</p></th>
                                </tr>
                                <tr>
                                    <th><p>SHA-256</p></th>
                                    <th><p>C360385A482C6030CD6201D89F59322F06F5381A848B0ED840700BD4FA6E3D2A</p></th>
                                </tr>
                                <tr>
                                    <th><p>File type</p></th>
                                    <th><p>Win32 EXE</p></th>
                                </tr>
                                <tr>
                                    <th><p>Target Machine</p></th>
                                    <th><p>x32</p></th>
                                </tr>
                                <tr>
                                    <th><p>File size</p></th>
                                    <th><p>2.33 MB (2445312 bytes)</p></th>
                                </tr>
                                <tr>
                                    <th><p>Compiler</p></th>
                                    <th><p>P-code Microsoft Visual Basic v5.0/v6.0</p></th>
                                </tr>
                                <tr>
                                    <th><p>Signature</p></th>
                                    <th><p>Not signed</p></th>
                                </tr>
                                <tr>
                                    <th><p>Legal Copy right</p></th>
                                    <th><p>Copyrighted/2000 Tristan Development</p></th>
                                </tr>
                                <tr>
                                    <th><p>Internal Name</p></th>
                                    <th><p>4</p></th>
                                </tr>
                                <tr>
                                    <th><p>File Version</p></th>
                                    <th><p>1.02.0010</p></th>
                                </tr>
                                <tr>
                                    <th><p>Company Name</p></th>
                                    <th><p>Tristan Development</p></th>
                                </tr>
                                <tr>
                                    <th><p>Product Name</p></th>
                                    <th><p>PPServer</p></th>
                                </tr>
                                <tr>
                                    <th><p>Product Version</p></th>
                                    <th><p>1.02.0010</p></th>
                                </tr>
                                <tr>
                                    <th><p>Original Filename</p></th>
                                    <th><p>4.exe</p></th>
                                </tr>
                                <tr>
                                    <th><p>File Description</p></th>
                                    <th><p>Personal Proxy Server Professional</p></th>
                                </tr>
                            </Table>
                            <h4>Static Analysis of the Payload</h4>
                            <br />
                            <p>Sequretek Malware Analysis Team analysed the dropped sample to determine the infection methodology.</p>
                            <p>It is a P-code Microsoft Visual Basic v5.0/v6.0 executable file with a compiled date of 15 Mar 2023. (Figure4)</p>
                            <img src='/images/blog-images/sample150.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 4: Microsoft Visual Basic (P-code) Executable file</p>
                            <br />
                            <p>The sample uses anti virtualization techniques to detect if it is running in an analysis environment (Figure 5).</p>
                            <img src='/images/blog-images/sample151.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 5: Use of anti-virtualization techniques</p>
                            <br />
                            <p>Information Stealer.  The downloaded file (“Daanjzfk.exe”) is a remote access trojan. The malware provides remote access to infected machines besides offering many functionalities such as access to the victim’s stolen credentials and mouse events or keystrokes (Figure 6).</p>
                            <br />
                            <img src='/images/blog-images/sample152.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure6: Keylogger functionality</p>
                            <br />
                            <p>Collects credentials from cache storage, cookies stored from the browsers. Also collects information of installed application in the system and running services (Figure 7).</p>
                            <img src='/images/blog-images/sample153.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 7: User credentials information</p>
                            <br />
                            <p>The malware also retrieves some data from the control’s columns. The retrieved data includes IP addresses, host names, and a “Stat_Connect_Count” and connect to C2 server (Figure 8).</p>
                            <img src='/images/blog-images/sample154.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 8: System details collected</p>
                            <br />
                            <p>The subroutine appears to handle an unknown event on the server by counting the number of valid dispatch identifiers between clients and server either accepting or rejecting a connection request based on a maximum connection (Figure 9).</p>
                            <img src='/images/blog-images/sample155.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 9: Send and accept request</p>
                            <br />
                            <h4>Dynamic Analysis</h4>
                            <br />
                            <p>We executed the payload in our sandbox environment to observe its behaviour.  We observed the following activities:-</p>
                            <p>After execute the payload It copy itself to the below location with a random name</p>
                            <p>“/AppData/Roaming/Microsoft/Windows/Start Menu/Programs/Startup/”</p>
                            <p>By copying itself into the start-up folder, the malware enables persistence across reboots (Figure 10).</p>
                            <img src='/images/blog-images/sample156.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 10: Process Tree</p>
                            <br />
                            <p>The malware established a connection to 94[.]242[.]61[.]186 to transfer encrypted data (Figure 11).</p>
                            <p>The attacker received the data with the help of a PHP script, squared.php.</p>
                            <img src='/images/blog-images/sample157.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure11 – Encoded Captured Key Stokes</p>
                            <br />
                            <p>Collected information about victims, such as mouse movements or keystrokes with an encoded form.</p>
                            <p>The malware uses urlencoded method to encode the collected data.</p>
                            <h4>IoCs:</h4>
                            <ul>
                                <li><p>119[.]252[.]152[.]37 (Email Sender IP)</p></li>
                                <li><p>http [:]//lagersteel[.]in/assets/fonts/kawyhg (Zip File Downloaded URL)</p></li>
                                <li><p>FC294C2DB2DE7C6270DB5EA3F70125EB (Hash of a key logger)</p></li>
                                <li><p>94[.]242[.]61[.]186 (Attacker Server IP)</p></li>
                                <li><p>http[:]//treysbeatend[.]com/laptop/squared[.]php (Location of a PHP file which help to receive the data of victim PC)</p></li>
                                <li><p>treysbeatend[.]com (Domain)</p></li>
                            </ul>
                            <h4>MITRE ATT&CK® Tactics and Techniques:</h4>
                            <ul>
                                <li>
                                    <p>Credential Access TA0006</p>
                                    <ul>
                                        <li>
                                            <p>Input Capture T1056</p>
                                            <ul>
                                                <li><p>Creates a DirectInput object (often for capturing keystrokes)</p></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <p>Discovery TA0007</p>
                                            <ul>
                                                <li>
                                                    <p>System Information Discovery T1082</p>
                                                    <ul>
                                                        <li><p>Reads software policies</p></li>
                                                        <li><p>Queries the volume information (name, serial number etc.) of a device</p></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p>File and Directory Discovery T1083</p>
                                                    <ul>
                                                        <li><p>Reads ini files</p></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p>Security Software Discovery T1518.001</p>
                                                    <ul>
                                                        <li><p>May try to detect the virtual machine to hinder analysis (VM artifact strings found in memory)</p></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p>Collection TA0009</p>
                                                    <ul>
                                                        <li>
                                                            <p>Input Capture T1056</p>
                                                            <ul>
                                                                <li><p>Creates a DirectInput object (often for capturing keystrokes)</p></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <h4>Conclusion:</h4>
                            <p>The analyzed malware sample (FC294C2DB2DE7C6270DB5EA3F70125EB) is a keylogger. It connects to the attacker’s server and shares the victim’s keystrokes continually. To prevent monitoring and analysis, the file has anti-VM and anti-sandbox technology. When executed, it copies itself to the startup folder so that it can be executed when the computer is restarted.</p>
                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'Sequretek Blog-Kutaki Stealer – Analysis' , url: 'https://www.sequretek.com/resources/blog/kutaki-stealer-analysis'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
