import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import sidebarReducer from "./sidebarReducer";
import authReducer from "./authReducer";
import leadsReducer from "./leadsReducer";
import dealReducer from "./dealReducer";
import userReducer from "./userReducer";
import resourceReducer from "./resourcesReducer";
import supportReducer from "./supportReducer";
import dashboardReducer from "./dashboardReducer";

// This function was to initially store all the other reducers details

export const rootReducer = combineReducers({
    auth: authReducer,
    sidebar: sidebarReducer,
    leads: leadsReducer,
    deals: dealReducer,
    users: userReducer,
    resources: resourceReducer,
    supports: supportReducer,
    dashboard: dashboardReducer,
    error: errorReducer,
});