// ############################ Require packages here ############################
import React, { useEffect, useState , useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import Slider from "react-slick";

// ############################ Require files here ###############################
import { SampleNextArrow, SamplePrevArrow } from '../../utils/miscellneous';


const CareersCard = [
  { id: 1, image: '/images/career.svg', title: 'SeQure Career', text: `Advance your career through diverse challenges, international exposure, and continuous professional development.` },
  { id: 3, image: '/images/interactive.svg', title: 'Cutting-Edge Technology', text: `Engineer cybersecurity solutions that safeguard tomorrow’s digital landscape, collaborating with industry pioneers and leveraging cutting-edge technologies.` },
  { id: 4, image: '/images/learning-icon.svg', title: 'Learning & Development', text: `Immerse yourself in a culture of continuous learning that thrives on innovations driving paradigm shifts in the cybersecurity landscape alongside fulfilling careers` },
  { id: 2, image: '/images/culture.svg', title: 'Culture', text: `Work in a supportive environment aiding professional growth, where benefits and flexible working arrangements enable you to give your best.` },
]

const CareersPosition = [
  { id: 1, image: '/images/ind-flag.svg', title: 'VAPT', text: `3+ yrs, Mumbai`, btn: 'VIEW AND APPLY' , link: 'https://www.naukri.com/job-listings-Vulnerability-Assessment-Penetration-Testing-VAPT--Sequretek-It-Solutions--4-to-9-years-240724017563' },
  { id: 2, image: '/images/ind-flag.svg', title: 'SOC Project Manager', text: `6+ yrs, Mumbai`, btn: 'VIEW AND APPLY' , link : 'https://www.naukri.com/job-listings-040624005976'},
  { id: 3, image: '/images/ind-flag.svg', title: 'Malware Analyst', text: `3+ yrs, Mumbai`, btn: 'VIEW AND APPLY' , link:'http://nauk.in/beFe6b7' },
  { id: 4, image: '/images/ind-flag.svg', title: 'Network/Proxy/DLP', text: `2+ yrs, Mumbai`, btn: 'VIEW AND APPLY' , link:'http://nauk.in/beFe6qL' },
  { id: 5, image: '/images/ind-flag.svg', title: 'SOC Admin', text: `3+ yrs, Mumbai`, btn: 'VIEW AND APPLY' , link:'http://nauk.in/beFe6tQ' },
  { id: 6, image: '/images/ind-flag.svg', title: 'SOC Analyst', text: `3+ yrs , Mumbai`, btn: 'VIEW AND APPLY' , link:'https://www.naukri.com/job-listings-security-engineer-soc-sequretek-it-solutions-mumbai-4-to-7-years-040624004855?src=jddesktop&sid=17256014100889198&xp=6&px=1' },
  { id: 7, image: '/images/usa-flag.svg', title: 'US Sales', text: `3+ yrs, Dallas`, btn: 'VIEW AND APPLY' , link:'https://wellfound.com/l/2AnEnM' },
  { id: 8, image: '/images/usa-flag.svg', title: 'US Sales', text: `3+ yrs, San Francisco`, btn: 'VIEW AND APPLY' , link:'https://wellfound.com/l/2AnEnM' },
  { id: 9, image: '/images/usa-flag.svg', title: 'US Project Mananger', text: `6+ yrs, New Jersey`, btn: 'VIEW AND APPLY' , link:'https://wellfound.com/l/2Aq5UU' },
  { id: 10, image: '/images/ind-flag.svg', title: '.Net Developer', text: `2+ yrs, Mumbai`, btn: 'VIEW AND APPLY' , link:'https://www.naukri.com/job-listings-Net-Developer-Sequretek-It-Solutions-Mumbai-All-Areas-1-to-4-years-050924017606' },
  { id: 11, image: '/images/ind-flag.svg', title: 'QA Automation Lead', text: `8+ yrs, Mumbai`, btn: 'VIEW AND APPLY' , link:'https://www.naukri.com/job-listings-qa-automation-lead-sequretek-it-solutions-mumbai-all-areas-8-to-13-years-050924019211?src=jddesktop&sid=17256014100889198&xp=3&px=1' },
  { id: 12, image: '/images/ind-flag.svg', title: 'Java Developer', text: `2+ yrs, Mumbai`, btn: 'VIEW AND APPLY' , link:'https://www.naukri.com/job-listings-Java-Developer-Sequretek-It-Solutions-Mumbai-All-Areas-2-to-5-years-050924017367' },
  { id: 13, image: '/images/ind-flag.svg', title: 'Log Parsing Archictect', text: `8-9 yrs, Mumbai`, btn: 'VIEW AND APPLY' , link:'https://www.naukri.com/job-listings-Log-Parsing-Architect-Technical-Architect-Mumbai-Sequretek-It-Solutions-Mumbai-All-Areas-8-to-12-years-050924015992' },
]
const CareersImages = [
  { id: 1, src: '/images/bg-img32.svg', alt: 'First Slide' },
  { id: 1, src: '/images/bg-img85.svg', alt: 'First Slide' },
  { id: 1, src: '/images/bg-img89.svg', alt: 'First Slide' },
  { id: 1, src: '/images/bg-img93.svg', alt: 'First Slide' },
  
];
const CareersImages2 = [
  { id: 2, src: '/images/bg-img33.svg', alt: 'Second Slide' },
  { id: 2, src: '/images/bg-img86.svg', alt: 'Second Slide' },
  { id: 2, src: '/images/bg-img90.svg', alt: 'Second Slide' },
  { id: 2, src: '/images/bg-img94.svg', alt: 'Second Slide' },
  
];
const CareersImages3 = [
  { id: 3, src: '/images/bg-img34.svg', alt: 'Third Slide' },
  { id: 3, src: '/images/bg-img87.svg', alt: 'Third Slide' },
  { id: 3, src: '/images/bg-img91.svg', alt: 'Third Slide' },
  { id: 3, src: '/images/bg-img95.svg', alt: 'Third Slide' },
  
];
const CareersImages4 = [
  { id: 4, src: '/images/bg-img35.svg', alt: 'fourth Slide' },
  { id: 4, src: '/images/bg-img88.svg', alt: 'fourth Slide' },
  { id: 4, src: '/images/bg-img92.svg', alt: 'fourth Slide' },
  { id: 4, src: '/images/bg-img96.svg', alt: 'fourth Slide' },

];

const Careers = () => {
  const [index, setIndex] = useState(0);
  const location = useLocation();
  const jobRef = useRef(null);
  const valuesRef = useRef(null);
  const [showAll, setShowAll] = useState(false);

  const visiblePositions = showAll ? CareersPosition : CareersPosition.slice(0, 9);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  useEffect(() => {
    window.scrollTo(0, location?.state?.scroll || 0)
  }, [location]);

  useEffect(() => {
    if (location.state?.scroll) {
      const { scroll } = location.state;
      if (scroll === 'job') {
        jobRef.current?.scrollIntoView({ behavior: 'smooth' });
      } else if (scroll === 'values') {
        valuesRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.state]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SampleNextArrow className={"next-arrow"} />,
    prevArrow: <SamplePrevArrow className={"prev-arrow"} />,
    responsive: [
        {
            breakpoint: 1320,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                initialSlide: 0,
            }
        },
        {
          breakpoint: 900,
          settings: {
              slidesToShow: 2.35,
              slidesToScroll: 2,
              initialSlide: 0,
          }
      },
    ]
};

  return (
    <div className='seq-Careers-page'>
      {/* section 0 PAGE HEADING */}
      <Helmet>
        <title>Sequretek Careers | Join Our Team</title>
        <meta http-equiv="X-Frame-Options" content="DENY" />
        <meta name="description" content="Join our dynamic team and fuel your career with endless possibilities! Explore opportunities today!" />
        <meta property="og:title" content="Sequretek Careers | Join Our Team" />
        <meta property="og:description" content="Join our dynamic team and fuel your career with endless possibilities! Explore opportunities today!" />
      </Helmet>
      {/* section 1 BANNER */}
      <div className='seq-Careers-heading' style={{ backgroundImage: `url('/images/bg-img84.svg')` }}>
        <div className='container pe-0 ps-0'>
          <div className='seq-Careers-hero'>
            <div className='seq-Careers-hero-text' data-aos="fade-up">
              <h3 className='d-flex align-items-center justify-content-center'>Sequretek Careers for a SeQure Future</h3>
              <h5 className='d-flex align-items-center justify-content-center'>Join our talent community where you can learn and grow alongside dynamic thought leaders and domain experts, and work on our core products that create a positive impact on the world. Explore our career opportunities in cybersecurity.</h5>
            </div>
          </div>
        </div>
      </div>
      <div className='seq-Careers-body' >
        {/* section 2 MAIN IMAGE*/}
        <div className='seq-Careers-bodytop'>
          <div className='seq-Careers-body1' data-aos="fade-up">
            <div className='container pe-0 ps-0' >
              <img className='seq-Careers-body1-frontimg' src={window.innerWidth > 767 ? '/images/bg-img31.svg' : '/images/bg-img46.svg'} alt='' />
              {window.innerWidth > 767 ? <img className='seq-Careers-body1-backimg d-flex d-none d-lg-flex' src='/images/color-dots.svg' alt='' /> : null}
            </div>
          </div>
          {/* section 2-1 CAREERS CARDS */}
          <div className='seq-Careers-body2'>
            <div className='container pe-0 ps-0'>
              {window.innerWidth > 767 ?
                <div className="slider-container">
                  <Slider {...settings}>
                    {
                    CareersCard?.map(el => {
                      return (
                        <div data-aos="fade-up" className='seq-Careers-card'>
                          <div className='d-flex align-items-center justify-content-center'>
                            <img className='' src={el.image} alt='' />
                          </div>
                          <h4 className='d-flex align-items-center justify-content-center'>{el.title}</h4>
                          <p className='d-flex align-items-center justify-content-center'>{el.text}</p>

                        </div>
                      )
                    })
                  }
                </Slider>
              </div>
                :
                <div className='d-flex overflow-auto flex-nowrap' style={{ gap: '14px', paddingRight: '40px' }}>
                  {
                    CareersCard?.map(el => {
                      return (
                        <div className='seq-Careers-card'>
                          {/* <div className=''> */}
                          <div className='d-flex align-items-center justify-content-center'>
                            <img className='' src={el.image} alt='' />
                          </div>
                          <h4 className='d-flex align-items-center justify-content-center'>{el.title}</h4>
                          <p className='d-flex align-items-center justify-content-center'>{el.text}</p>
                          {/* </div> */}

                        </div>
                      )
                    })
                  }
                </div>
              }
            </div>
          </div>
        </div>
        {/* section 3 OPEN POSITIONS*/}
        <div className='seq-Careers-body3' ref={jobRef}>
          <div className='container pe-0 ps-0'>
            <h3 className='d-flex align-items-center justify-content-center'>Open positions</h3>
            {window.innerWidth > 767 ?
              <div className='row seq-Careers-position d-flex justify-content-center'>
                {visiblePositions?.map(el => (
                  <div className='col-4 col-sm-6 seq-Careers-position-card d-flex justify-content-center' key={el.id}>
                    <div className='seq-Careers-position-cardind'>
                      <div className='d-flex align-items-center justify-content-center'><img src={el.image} alt='' /></div>
                      <h5 className='d-flex align-items-center justify-content-center'>{el.title}</h5>
                      <p className='d-flex align-items-center justify-content-center'>{el.text}</p>
                      <div className='d-flex justify-content-center align-items-baseline'><button><a href={el.link} target='_blank' rel="noreferrer" style={{ textDecoration: 'none', color: 'inherit' }} >{el.btn}</a></button></div>
                    </div>
                  </div>
                ))}
                {CareersPosition.length > 6 && (
                  <div className='d-flex align-items-center justify-content-center'>
                    <button onClick={() => setShowAll(!showAll)}>
                      <strong>{showAll ? 'View less positions' : 'View all positions'}</strong>
                    </button>
                  </div>
                )}
              </div> :
              <div>
                <div className='seq-Careers-position d-flex overflow-auto flex-nowrap'>
                {visiblePositions?.map(el => (
                  <div className='col-4 col-sm-6 seq-Careers-position-card d-flex justify-content-center' key={el.id}>
                    <div className='seq-Careers-position-cardind'>
                      <div className='d-flex align-items-center justify-content-center'><img src={el.image} alt='' /></div>
                      <h5 className='d-flex align-items-center justify-content-center'>{el.title}</h5>
                      <p className='d-flex align-items-center justify-content-center'>{el.text}</p>
                      <div className='d-flex justify-content-center align-items-baseline'><button><a href={el.link} target='_blank' rel="noreferrer" style={{ textDecoration: 'none', color: 'inherit' }} >{el.btn}</a></button></div>
                    </div>
                  </div>
                ))}
                </div>
                {CareersPosition.length > 6 && (
                  <div className='d-flex align-items-center justify-content-center'>
                    <button onClick={() => setShowAll(!showAll)}>
                      <strong>{showAll ? 'View less positions' : 'View all positions'}</strong>
                    </button>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
        {/* section 4 VALUES */}
        <div className='seq-Careers-body4' ref={valuesRef}>
          <div className='container pe-0 ps-0'>
            <div className='seq-Careers-body4-head' data-aos="fade-up">
              <h3 className='d-flex align-items-center justify-content-center'>Values and Culture</h3>
              <p className='d-flex align-items-center justify-content-center'>We strive to create positive experiences through the core values of:</p>
            </div>
            <div className='seq-Careers-body4-body' data-aos="fade-up">
              {window.innerWidth > 767 ? null : <div><img src='/images/pie-img.svg' alt='' /></div>}
              {window.innerWidth > 767 ? null : <h3>Integrity</h3>}
              <div className='d-flex align-items-center justify-content-center seq-Careers-body4-text'>
                <p>We uphold the highest ethical standards, in everything we do. We ensure complete transparency in our operations including service delivery and contracts.</p>
              </div>
              {window.innerWidth > 767 ? <div className='seq-careers-body4-img'>
                <img src='/images/pie-img.svg' alt='' />
              </div> : null}
              <div>
                {window.innerWidth > 767 ? null : <h3>Customer Delight</h3>}
                <div className='seq-Careers-body4-text'>
                  <p>We are a trusted partner to our customers with an outcome-based engagement approach. We focus on consistently delivering value and excellence.</p>
                </div>
                <div className='seq-Careers-body4-text'>
                  {window.innerWidth > 767 ? null : <h3>Continuous Innovation</h3>}
                  <p>We prioritize technological advancement, thrive on healthy competition, and accelerate time-to-market.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section 5 IMAGES */}
        <div className='seq-Careers-body5'>
          <div className='container pe-0 ps-0'>
            <img className='seq-Careers-body5-dotsimg' src='/images/color-dots.svg' alt='' />
            {window.innerWidth > 767 ?
              <div class="gallery-container" style={{ overflow: 'hidden' }}>
                <div className='row justify-content-center'>
                  <Carousel className=' col-md-7 col-sm-12' activeIndex={index} onSelect={handleSelect} controls={false} indicators={false} interval={2500} fade>
                    {CareersImages.map((image, i) => (
                      <Carousel.Item className='tz-gallery' key={i}>
                        <img style={{ width: '648px', height: '334px' }} src={image.src} alt={image.alt} />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <Carousel className='col-md-5 col-sm-6 seq-gallery-small' activeIndex={index} onSelect={handleSelect} controls={false} indicators={false} interval={2500} fade>
                    {CareersImages2.map((image, i) => (
                      <Carousel.Item className='tz-gallery' key={i}>
                        <img style={{ width: '455px', height: '334px' }} src={image.src} alt={image.alt} />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <Carousel className=' col-md-5 col-sm-6 seq-gallery-small' activeIndex={index} onSelect={handleSelect} controls={false} indicators={false} interval={2500} fade>
                    {CareersImages3.map((image, i) => (
                      <Carousel.Item className='tz-gallery' key={i}>
                        <img style={{ width: '455px', height: '334px' }} src={image.src} alt={image.alt} />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <Carousel className='col-md-7 col-sm-12' activeIndex={index} onSelect={handleSelect} controls={false} indicators={false} interval={2500} fade>
                    {CareersImages4.map((image, i) => (
                      <Carousel.Item className='tz-gallery' key={i}>
                        <img style={{ width: '648px', height: '334px' }} src={image.src} alt={image.alt} />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div> :
              <div className="gallery-container" style={{ overflow: 'hidden' }}>
                <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false} interval={2500} fade>
                  {CareersImages.map((image, i) => (
                    <Carousel.Item className='tz-gallery' key={i}>
                      <img src={image.src} style={{ height: '166px' }} alt={image.alt} />
                    </Carousel.Item>
                  ))}
                </Carousel>
                <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false} interval={2500} fade>
                  {CareersImages2.map((image, i) => (
                    <Carousel.Item className='tz-gallery' key={i}>
                      <img src={image.src} style={{ height: '238px' }} alt={image.alt} />
                    </Carousel.Item>
                  ))}
                </Carousel>
                <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false} interval={2500} fade>
                  {CareersImages3.map((image, i) => (
                    <Carousel.Item className='tz-gallery' key={i}>
                      <img src={image.src} style={{ height: '238px' }} alt={image.alt} />
                    </Carousel.Item>
                  ))}
                </Carousel>
                <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false} interval={2500} fade>
                  {CareersImages4.map((image, i) => (
                    <Carousel.Item className='tz-gallery' key={i}>
                      <img src={image.src} style={{ height: '166px' }} alt={image.alt} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default Careers
