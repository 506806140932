// ############################ Require packages here ############################
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################

import ScheduleForm from './scheduleForm';
import './styles.scss'


const RelatedResources = [{ id: 1, image: '/images/Evil-man.svg', type: 'DEMO', title: 'Percept Compliance Manager', },
{ id: 2, image: '/images/podcastwithman.svg', type: 'PODCAST', title: `The emergence of GENAI: friend or foe for your company's security postu...`, },
{ id: 3, image: '/images/womenwithpc.svg', type: 'WHITEPAPER', title: 'Application whitelisting - getting it right', },
{ id: 4, image: '/images/interviewingperson.svg', type: 'VIDEO', title: 'Need for cybersecurity to strengthen digital transformation good retur...', }
]

const ServicesCards = [{ id: 1, image: '/images/shieldcheck.svg', title: 'Proactive Protection', text: 'Our proactive approach ensures threats are identified and neutralized before they can cause harm.' },
{ id: 2, image: '/images/badge-dollar.svg', title: 'Cost Efficiency', text: 'Save on the costs associated with in-house security teams and potential financial losses from breaches.' },
{ id: 3, image: '/images/user-shield.svg', title: 'Expertise and Experience', text: 'Benefit from the knowledge and skills of our seasoned security professionals who are dedicated to protecting your business.' },
{ id: 4, image: '/images/chat-arrow-grow.svg', title: 'Scalability and Flexibility', text: 'Our services are designed to scale with your business, providing the right level of protection as your needs evolve. ' },
{ id: 5, image: '/images/memo-circle-check.svg', title: 'Regulatory Assurance', text: 'Stay compliant with industry regulations and standards, avoiding potential fines and legal issues.' },
{ id: 6, image: '/images/shield-trust.svg', title: 'Focus on Core Business', text: 'With Sequretek managing your security, you can focus on what you do best -   growing your business.' },
]

const SequretekCards = [{ id: 1, image: '/images/trophy-star.svg', title: 'Industry Leaders', text: 'With years of experience and a proven track record, Sequretek is a trusted name in cybersecurity.' },
{ id: 2, image: '/images/lightbulb-on-blue.svg', title: 'Innovative Technology', text: 'We use the latest technologies and methodologies to provide unparalleled protection.' },
{ id: 3, image: '/images/customer-care.svg', title: 'Client-Focused', text: 'Our services are designed with your unique challenges and requirements in mind.' },
{ id: 4, image: '/images/badge.svg', title: 'Certified Professionals', text: 'Our team comprises certified experts dedicated to delivering exceptional security services.' },
{ id: 5, image: '/images/shieldcheck.svg', title: 'Holistic Approach', text: 'We offer end-to-end security solutions that cover every aspect of your IT environment.' },
]


const Services = () => {

    const [active, setActive] = useState('governancerisk');
    const [activeTab, setActiveTab] = useState('policy');
    const [openAccordion, setOpenAccordion] = useState('collapseOne');
    const formRef = useRef(null);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location?.state?.active) {
            setActive(location.state.active || 'governancerisk')
        }
    }, [location.state?.active])

    useEffect(() => {
        if (location?.state?.openAccordion) {
            setOpenAccordion(location.state.openAccordion || 'collapseOne');
        }
    }, [location.state?.openAccordion]);

    const handleAccordionToggle = (accordionId) => {
        setOpenAccordion(prev => (prev === accordionId ? '' : accordionId));
    };

    const setScroll = (val) => {
        const yOffset = window.innerWidth > 767 ? -150 : -50;
        const y = formRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    const setPage = (val) => {
        window.scrollTo(0, 0);
        if (val === 'partner') {
            navigate('/cybersecurity-solution-provider-partner')
        } else if (val === 'Why-Sequretek') {
            navigate('/sequretek-benefits')
        }
    }

    return (
        <div className='seq-services-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek - Services</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-Percept-heading' style={{ backgroundImage: `linear-gradient(274.85deg, rgba(4, 12, 48, 0.75) 23.83%, rgba(6, 6, 93, 0.75) 61.65%),url('/images/bg-img27.png')` }}>
                <div className='container pe-0 ps-0'>
                    <div className='seq-Percept-hero d-flex align-items-center justify-content-center'>
                        <div>
                            <h3 className='pb-0'>Services</h3>
                            <h5>Sequretek offers a wide range of Managed Security Services designed to protect every aspect of your IT environment</h5>
                            <div className='d-flex align-items-center justify-content-center'>
                                <button onClick={() => setScroll('demo')}><strong>Get Started</strong></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='seq-services-body'>
                {/* section 2 INFO TABS */}
                {window.innerWidth > 767 ?
                    <div className='seq-service' style={{ backgroundImage: 'linear-gradient(161.44deg, #040C30 37.24%, #06065D 63.08%)' }}>
                        <div className='container pe-0 ps-0'>
                            <div className='seq-service-cards d-flex justify-content-center'>
                                <div onClick={() => setActive('governancerisk')} className={active === 'governancerisk' ? 'seq-service-card active' : 'seq-service-card'}>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <img src='/images/government-flag.svg' alt='' />
                                    </div>
                                    <h5>Governance, Risk, and Compliance</h5>
                                </div>
                                <div onClick={() => setActive('securitymanage')} className={active === 'securitymanage' ? 'seq-service-card active' : 'seq-service-card'}>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <img src='/images/sensor-alert-wight.svg' alt='' />
                                    </div>
                                    <h5>Security Operations and Management</h5>
                                </div>
                                <div onClick={() => setActive('incidentmanage')} className={active === 'incidentmanage' ? 'seq-service-card active' : 'seq-service-card'}>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <img src='/images/brake-warning-wight.svg' alt='' />
                                    </div>
                                    <h5>Incident Response and Management</h5>
                                </div>
                                <div onClick={() => setActive('pathmanage')} className={active === 'pathmanage' ? 'seq-service-card active' : 'seq-service-card'}>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <img src='/images/cloud-wight.svg' alt='' />
                                    </div>
                                    <h5>Patch Management as a Service</h5>
                                </div>
                            </div>
                            <div>
                                {active === 'governancerisk' ?
                                    <div className='seq-service-cards-content-tab'>
                                        <div className='d-flex align-items-center justify-content-center seq-service-cards-content'>
                                            <h4 onClick={() => setActiveTab('policy')} className={activeTab === 'policy' ? 'active' : ''}>
                                                Policy and Framework
                                            </h4>
                                            <h4 onClick={() => setActiveTab('manageprogram')} className={activeTab === 'manageprogram' ? 'active' : ''}>
                                                Vulnerability Management Program
                                            </h4>
                                        </div>
                                        {activeTab === 'policy' ?
                                            <div className='seq-service-cards-content-text'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <div className='seq-service-cards-content-section'>
                                                        <p>Defining security policies as per the industry standards and conducting various audits and gap assessments to hunt for any vulnerabilities or gaps in the policies, procedures, controls, etc., for the organization.</p>
                                                    </div>
                                                    <div className='seq-service-cards-content-section'>
                                                        <div className='d-flex seq-service-cards-content-section-head'>
                                                            <img src='/images/diploma.svg' alt='' />
                                                            <h5>Defining Security Policies</h5>
                                                        </div>
                                                        <p>Based on standards like ISO 27001, NIST, CIS or domain specific requirements like PCI-DSS, HIPAA, RBI, IRDAI, NY-DFS, FFIEC, TISAX, GDPR etc.</p>
                                                    </div>
                                                    <div className='seq-service-cards-content-section'>
                                                        <div className='d-flex seq-service-cards-content-section-head'>
                                                            <img src='/images/memo-circle-check.svg' alt='' />
                                                            <h5>Audits and Gap Assessments</h5>
                                                        </div>
                                                        <p>Audit of security policies and procedures, technical controls (firewalls, access controls, etc.), physical security, training and awareness, incidents and DR.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='seq-service-cards-content-tabs'>
                                                <p className='seq-service-cards-content-tabs-text'>Regular scanning and assessment to identify and remediate vulnerabilities in your systems, ensuring a fortified security posture.</p>
                                                <div className='seq-service-cards-content-text'>
                                                    <div id="carouselExample" class="carousel slide">
                                                        <div class="carousel-inner">
                                                            <div class="carousel-item active">
                                                                <div className='card-wrapper d-flex'>
                                                                    <div className='seq-service-cards-content-section'>
                                                                        <div className='d-flex seq-service-cards-content-section-head'>
                                                                            <img src='/images/warning-search.svg' alt='' />
                                                                            <h5>Vulnerability Assessments (VA)</h5>
                                                                        </div>
                                                                        <p>Vulnerability scans of networks, servers, endpoints, AD, and database vulnerability management program.</p>
                                                                    </div>
                                                                    <div className='seq-service-cards-content-section'>
                                                                        <div className='d-flex seq-service-cards-content-section-head'>
                                                                            <img src='/images/Cube-gear.svg' alt='' />
                                                                            <h5>Configuration Audits</h5>
                                                                        </div>
                                                                        <p>Configuration audits for OS servers (Windows and Linux/Unix), network devices (managed and unmanaged), web servers (IIS, Apache, Drupal), databases (SQL, Oracle, MySQL), virtualization platforms, hardening document review and update.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="carousel-item">
                                                                <div className='card-wrapper d-flex'>
                                                                    <div className='seq-service-cards-content-section'>
                                                                        <div className='d-flex seq-service-cards-content-section-head'>
                                                                            <img src='/images/web-site-test.svg' alt='' />
                                                                            <h5>Penetration Testing (PT)</h5>
                                                                        </div>
                                                                        <p>
                                                                            Penetration testing (PT) of networks, systems, web applications, APIs, wireless networks. External PT and revalidation of VAPT findings after fixes by the respective application owners.</p>
                                                                    </div>
                                                                    <div className='seq-service-cards-content-section'>
                                                                        <div className='d-flex seq-service-cards-content-section-head'>
                                                                            <img src='/images/mobile-notch.svg' alt='' />
                                                                            <h5>Application Security</h5>
                                                                        </div>
                                                                        <p>Mobile application assessment for Android & iOS (SAST & DAST), Flutter, intranet and internet/ extranet facing web applications, binary applications (.exe based application, thick client & thin client), Wi-Fi /network infrastructure, </p>
                                                                        <p>API security testing/ web services (payment/non-payment), black box/ white box/ grey box testing vulnerability management.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="carousel-item">
                                                                <div className='card-wrapper d-flex'>
                                                                    <div className='seq-service-cards-content-section'>
                                                                        <div className='d-flex seq-service-cards-content-section-head'>
                                                                            <img src='/images/puzzle-piece.svg' alt='' />
                                                                            <h5>Red / Blue / Purple Teaming</h5>
                                                                        </div>
                                                                        <p>Real-world attacks to simulate the actions of those that are malicious or adversarial. The goal is to breach or compromise an organization's digital security and cover the gaps of the vulnerability management program.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Previous</span>
                                                        </button>
                                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Next</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    : null}
                                {active === 'securitymanage' ?
                                    <div className='seq-service-cards-content-tab'>
                                        <div className='d-flex align-items-center justify-content-center seq-service-cards-content'>
                                            <h4>Deployment, configuration and ongoing management of your complete security infrastructure.</h4>
                                        </div>
                                        <div className='seq-service-cards-content-tabs'>
                                            <div className='seq-service-cards-content-text'>
                                                <div className='d-flex justify-content-center'>
                                                    <div className='seq-service-cards-content-section'>
                                                        <div className='d-flex seq-service-cards-content-section-head'>
                                                            <img src='/images/lock-blue.svg' alt='' />
                                                            <h5>Endpoint Security</h5>
                                                        </div>
                                                        <p>Protection for all endpoints, including desktops, laptops, and mobile devices, ensuring no entry points are left unguarded.</p>
                                                    </div>
                                                    <div className='seq-service-cards-content-section'>
                                                        <div className='d-flex seq-service-cards-content-section-head'>
                                                            <img src='/images/folder-tree.svg' alt='' />
                                                            <h5>Data Centre Security</h5>
                                                        </div>
                                                        <p>Data center IT infrastructure security including server hardening, patching, server AV management, email security, network security, virtual patching period.</p>
                                                    </div>
                                                    <div className='seq-service-cards-content-section'>
                                                        <div className='d-flex seq-service-cards-content-section-head'>
                                                            <img src='/images/network-cloud.svg' alt='' />
                                                            <h5>Cloud Security</h5>
                                                        </div>
                                                        <p>Cloud governance, risk and compliance management, cloud infrastructure security operations, cloud security incident management period.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                                {active === 'incidentmanage' ?
                                    <div className='seq-service-cards-content-tab'>
                                        <div className='d-flex align-items-center justify-content-center seq-service-cards-content'>
                                            <div className='d-flex align-items-center justify-content-center'><h4 className='seq-service-cards-content-head'>Our expert team swiftly addresses security incidents, minimizing damage and restoring normal operations with minimal downtime.</h4></div>
                                        </div>
                                        <div className='seq-service-cards-content-tabs'>
                                            <div className='seq-service-cards-content-text'>
                                                <div className='d-flex justify-content-center'>
                                                    <div className='seq-service-cards-content-section'>
                                                        <div className='d-flex seq-service-cards-content-section-head'>
                                                            <img src='/images/monitor-pulse.svg' alt='' />
                                                            <h5>Advanced Threat Intelligence & Malware Analysis</h5>
                                                        </div>
                                                        <p>We leverage cutting-edge threat intelligence to anticipate, analyse and mitigate potential threats before they impact your business. We have an automated system for deep analysis. The results of this analyses  are integrated with XDR and provide augmentation to the detection capability of the ML models of Percept EDR.</p>
                                                    </div>
                                                    <div className='seq-service-cards-content-section'>
                                                        <div className='d-flex seq-service-cards-content-section-head'>
                                                            <img src='/images/warning-search.svg' alt='' />
                                                            <h5>Malware Forensics and Root Cause Analysis</h5>
                                                        </div>
                                                        <p>Meticulous investigation and examination of malicious software (malware) are carried out to understand its origin, behavior, and impact on computer systems. Guidance on proactively identifying cyberthreats, mitigating damage, and enhancing overall cybersecurity resilience are provided.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                                {active === 'pathmanage' ?
                                    <div className='seq-service-cards-content-tab'>
                                        <div className='d-flex align-items-center justify-content-center seq-service-cards-content'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <h4 className='seq-service-cards-content-head'> Efficient and automated deployment of patches for operating systems, databases, and endpoints, ensuring minimal disruption while maintaining compliance with industry regulations and standards through systematic patch management.</h4>
                                            </div>
                                        </div>
                                        <div className='seq-service-cards-content-tabs'>
                                            <div className='seq-service-cards-content-text '>
                                                <div className='d-flex justify-content-center'>
                                                    <div className='seq-service-cards-manage'>
                                                        <div className=''>
                                                            <div className='d-flex justify-content-center'><img className='' src='/images/database.svg' alt='' /></div>
                                                            <h5 className=''>Server OS Patch Management</h5>
                                                        </div>
                                                        <p>Windows, Linux, UNIX, Ubuntu</p>
                                                    </div>
                                                    <div className='seq-service-cards-manage'>
                                                        <div className=''>
                                                            <div className='d-flex justify-content-center'><img className='' src='/images/lock-blue.svg' alt='' /></div>
                                                            <h5>Endpoint Patch Management</h5>
                                                        </div>
                                                        <p>Windows</p>
                                                    </div>
                                                    <div className='seq-service-cards-manage'>
                                                        <div className=''>
                                                            <div className='d-flex justify-content-center'><img className='' src='/images/table-list.svg' alt='' /></div>
                                                            <h5>DB Patch Management</h5>
                                                        </div>
                                                        <p>Oracle, SQL, and Open-source Databases</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div> :
                    <div className='seq-service' style={{ backgroundImage: 'linear-gradient(161.44deg, #040C30 37.24%, #06065D 63.08%)' }}>
                        <div class="accordion seq-service-dropdown" id="accordionExample">
                            <div class="accordion-item seq-service-drop-items">
                                <h2 class="accordion-header" id="headingOne">
                                    <button
                                        className={`accordion-button ${openAccordion === 'collapseOne' ? '' : 'collapsed'}`}
                                        type="button"
                                        onClick={() => handleAccordionToggle('collapseOne')}
                                    >
                                        <img src='/images/government-flag.svg' alt='' />Governance Risk and Compliance
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    className={`accordion-collapse collapse ${openAccordion === 'collapseOne' ? 'show' : ''}`}
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <div className='accordion seq-service-drop-bodyitems' id="nestedAccordionOne">
                                            <div class="accordion-item seq-service-drop-bodyitems">
                                                <h2 class="accordion-header" id="bodyOne">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsebodyOne" aria-expanded="false" aria-controls="collapsebodyOne">
                                                        Policy and Framework
                                                    </button>
                                                </h2>
                                                <div id="collapsebodyOne" class="accordion-collapse collapse" aria-labelledby="bodyOne" data-bs-parent="#nestedAccordionOne">
                                                    <div class="">
                                                        <div className='seq-service-drop-bodytext'>
                                                            <p>Defining security policies as per the industry standards and conducting various audits and gap assessments to hunt for any vulnerabilities or gaps in the policies, procedures, controls, etc., for the organization.</p>
                                                        </div>
                                                        <div className='seq-service-drop-bodytext'>
                                                            <div className='d-flex'>
                                                                <img src='/images/diploma.svg' alt='' />
                                                                <h4>Defining Security Policies</h4>
                                                            </div>
                                                            <p>Based on standards like ISO 27001, NIST, CIS or domain specific requirements like PCI-DSS, HIPAA, RBI, IRDAI, NY-DFS, FFIEC, TISAX, GDPR etc.</p>
                                                        </div>
                                                        <div className='seq-service-drop-bodytext'>
                                                            <div className='d-flex'>
                                                                <img src='/images/memo-circle-check.svg' alt='' />
                                                                <h4>Audits and Gap Assessments</h4>
                                                            </div>
                                                            <p>Audit of security policies and procedures, technical controls (firewalls, access controls, etc.), physical security, training and awareness, incidents and DR.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item seq-service-drop-bodyitems">
                                                <h2 class="accordion-header" id="bodytwo">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsebodytwo" aria-expanded="false" aria-controls="collapsebodytwo">
                                                        Vulnerability Management Program
                                                    </button>
                                                </h2>
                                                <div id="collapsebodytwo" class="accordion-collapse collapse" aria-labelledby="bodytwo" data-bs-parent="#nestedAccordionOne">
                                                    <div class="">
                                                        <div className='seq-service-drop-bodytext'>
                                                            <p>Regular scanning and assessment to identify and remediate vulnerabilities in your systems, ensuring a fortified security posture.</p>
                                                        </div>
                                                        <div className='seq-service-drop-bodytext'>
                                                            <div className='d-flex'>
                                                                <img src='/images/warning-search.svg' alt='' />
                                                                <h4>Vulnerability Assessments (VA)</h4>
                                                            </div>
                                                            <p>VA scans of networks, servers, endpoints, AD, and Database</p>
                                                        </div>
                                                        <div className='seq-service-drop-bodytext'>
                                                            <div className='d-flex'>
                                                                <img src='/images/Cube-gear.svg' alt='' />
                                                                <h4>Configuration Audits</h4>
                                                            </div>
                                                            <p>Configuration audits for OS Servers (Windows and Linux/Unix), Network Devices (Managed and Unmanaged), Web Servers (IIS, Apache, Drupal), Database (SQL, Oracle, MySQL), Virtualization platforms, Hardening Document review and update.</p>
                                                        </div>
                                                        <div className='seq-service-drop-bodytext'>
                                                            <div className='d-flex'>
                                                                <img src='/images/web-site-test.svg' alt='' />
                                                                <h4>Penetration Testing (PT)</h4>
                                                            </div>
                                                            <p>PT of Networks, Systems, Web Applications, API, Wireless Networks. External PT and Revalidation of VAPT findings after they are fixed by respective application owners.</p>
                                                        </div>
                                                        <div className='seq-service-drop-bodytext'>
                                                            <div className='d-flex'>
                                                                <img src='/images/mobile-notch.svg' alt='' />
                                                                <h4>Application Security</h4>
                                                            </div>
                                                            <p>Mobile Application Assessment for Android & iOS (SAST & DAST), Flutter, Intranet and Internet / Extranet facing Web Applications, Binary Applications (.exe based application, thick client & thin client), Wi-Fi /Network Infrastructure,</p>
                                                            <p>API Security Testing / Web Services (Payment/Non-Payment), Black box / White Box / Grey box testing </p>
                                                        </div>
                                                        <div className='seq-service-drop-bodytext'>
                                                            <div className='d-flex'>
                                                                <img src='/images/puzzle-piece.svg' alt='' />
                                                                <h4>Red / Blue / Purple Teaming</h4>
                                                            </div>
                                                            <p>Real world attacks to simulate the actions of those who are malicious or adversarial. The goal is to breach or compromise a company's digital security, and cover the gaps </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item seq-service-drop-items">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button
                                        className={`accordion-button ${openAccordion === 'collapseTwo' ? '' : 'collapsed'}`}
                                        type="button"
                                        onClick={() => handleAccordionToggle('collapseTwo')}
                                    >
                                        <img src='/images/sensor-alert-wight.svg' alt='' />Security Operations and Management
                                    </button>
                                </h2>
                                <div
                                    id="collapseTwo"
                                    className={`accordion-collapse collapse ${openAccordion === 'collapseTwo' ? 'show' : ''}`}
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <div class="accordion-item seq-service-drop-bodyitems">
                                            <div id="" class="accordion-collapse collapse show" aria-labelledby="" data-bs-parent="#accordionExample">
                                                <div class="">
                                                    <div className='seq-service-drop-bodytext'>
                                                        <p>Deployment, configuration and ongoing management of your complete security infrastructure. </p>
                                                    </div>
                                                    <div className='seq-service-drop-bodytext'>
                                                        <div className='d-flex'>
                                                            <img src='/images/lock-blue.svg' alt='' />
                                                            <h4>Endpoint Security</h4>
                                                        </div>
                                                        <p>Protection for all endpoints, including desktops, laptops, and mobile devices, ensuring no entry points are left unguarded.</p>
                                                    </div>
                                                    <div className='seq-service-drop-bodytext'>
                                                        <div className='d-flex'>
                                                            <img src='/images/folder-tree.svg' alt='' />
                                                            <h4>Data Centre Security</h4>
                                                        </div>
                                                        <p>Data center IT Infrastructure security including Server Hardening, Patching, Server AV Management, Email Security, Network Security, Virtual Patching</p>
                                                    </div>
                                                </div>
                                                <div className='seq-service-drop-bodytext'>
                                                    <div className='d-flex'>
                                                        <img src='/images/network-cloud.svg' alt='' />
                                                        <h4>Cloud Security</h4>
                                                    </div>
                                                    <p>Cloud Governance, Risk and Compliance Management, Cloud Infrastructure Security Operations, Cloud Security Incident Management</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item seq-service-drop-items">
                                <h2 class="accordion-header" id="headingThree">
                                    <button
                                        className={`accordion-button ${openAccordion === 'collapseThree' ? '' : 'collapsed'}`}
                                        type="button"
                                        onClick={() => handleAccordionToggle('collapseThree')}
                                    >
                                        <img src='/images/brake-warning-wight.svg' alt='' />Incident Response and Management
                                    </button>
                                </h2>
                                <div
                                    id="collapseThree"
                                    className={`accordion-collapse collapse ${openAccordion === 'collapseThree' ? 'show' : ''}`}
                                    aria-labelledby="headingthree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <div class="accordion-item seq-service-drop-bodyitems">
                                            <div id="" class="accordion-collapse collapse show" aria-labelledby="" data-bs-parent="#accordionExample">
                                                <div class="">
                                                    <div className='seq-service-drop-bodytext'>
                                                        <p>Our expert team swiftly addresses security incidents, minimizing damage and restoring normal operations with minimal downtime. </p>
                                                    </div>
                                                    <div className='seq-service-drop-bodytext'>
                                                        <div className='d-flex'>
                                                            <img src='/images/monitor-pulse.svg' alt='' />
                                                            <h4>Advanced Threat Intelligence & Malware Analysis</h4>
                                                        </div>
                                                        <p>Cutting-edge malware analyses and threat intelligence are leveraged to anticipate, analyze, and mitigate potential threats before businesses are impacted. An automated system for deep analysis is employed, and the results can be ingested into Percept platform and products to augment detection capabilities.</p>
                                                    </div>
                                                    <div className='seq-service-drop-bodytext'>
                                                        <div className='d-flex'>
                                                            <img src='/images/warning-search.svg' alt='' />
                                                            <h4>Malware Forensics and Root Cause Analysis</h4>
                                                        </div>
                                                        <p>Meticulous investigation and examination of malicious software (malware) are carried out to understand its origin, behavior, and impact on computer systems. Guidance on proactively identifying cyberthreats, mitigating damage, and enhancing overall cybersecurity resilience are provided.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item seq-service-drop-items">
                                <h2 class="accordion-header" id="headingfour">
                                    <button
                                        className={`accordion-button ${openAccordion === 'collapseFour' ? '' : 'collapsed'}`}
                                        type="button"
                                        onClick={() => handleAccordionToggle('collapseFour')}
                                    >
                                        <img src='/images/cloud-wight.svg' alt='' />Patch Management as a Service
                                    </button>
                                </h2>
                                <div
                                    id="collapseFour"
                                    className={`accordion-collapse collapse ${openAccordion === 'collapseFour' ? 'show' : ''}`}
                                    aria-labelledby="headingfour"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="accordion-body">
                                        <div class="accordion-item seq-service-drop-bodyitems">
                                            <div id="" class="accordion-collapse collapse show" aria-labelledby="" data-bs-parent="#accordionExample">
                                                <div class="">
                                                    <div className='seq-service-drop-bodytext'>
                                                        <p>Efficient and automated deployment of patches for operating systems, databases, and endpoints, ensuring minimal disruption while maintaining compliance with industry regulations and standards through systematic patch management.</p>
                                                    </div>
                                                    <div className='seq-service-drop-bodytext'>
                                                        <div className='d-flex'>
                                                            <img src='/images/database.svg' alt='' />
                                                            <h4>Server OS Patch Management</h4>
                                                        </div>
                                                        <p>Windows, Linux, UNIX, Ubuntu</p>
                                                    </div>
                                                    <div className='seq-service-drop-bodytext'>
                                                        <div className='d-flex'>
                                                            <img src='/images/lock-blue.svg' alt='' />
                                                            <h4>Endpoint Patch Management</h4>
                                                        </div>
                                                        <p>Windows</p>
                                                    </div>
                                                    <div className='seq-service-drop-bodytext'>
                                                        <div className='d-flex'>
                                                            <img src='/images/table-list.svg' alt='' />
                                                            <h4>DB Patch Management</h4>
                                                        </div>
                                                        <p>Oracle, SQL, and Open-source Databases</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* section 3 PARTNERING WITH SEQURETEK */}
                <div className='container pe-0 ps-0'>
                    <div className='seq-services-body2' style={{ backgroundImage: `url('/images/bg-img28.svg')` }}>
                        <div className='seq-services-body2-explore'>
                            <div className='d-flex' style={{ gap: '20px' }}>
                                <div className='seq-services-body2-explore-text'>
                                    <p>Benefits </p>
                                    <h5>Elevate your organization's cybersecurity and compliance with Sequretek's Managed Security Services</h5>
                                    {window.innerWidth > 747 ? <button onClick={() => setScroll('demo')}>Learn more</button> : null}
                                </div>
                                {window.innerWidth > 767 ?
                                    <img src='/images/q-icon1.svg' alt='' />
                                    : null}
                            </div>
                        </div>
                    </div>
                    {window.innerWidth > 767 ?
                        <div className='seq-services-body2-card'>
                            <div className='row'>
                                {ServicesCards?.map(el => {
                                    return (
                                        <div className='col-4 col-sm-6 d-flex align-items-center justify-content-center  seq-services-body2-cards'>
                                            <div>
                                                <div className='d-flex align-items-center seq-services-cards-head'>
                                                    <img src={el.image} alt='' />
                                                    <h4>{el.title}</h4>
                                                </div>
                                                <p>{el.text}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div> :
                        <div className='seq-services-body2-card'>
                            <Accordion defaultActiveKey={SequretekCards[0]?.id}>
                                {ServicesCards?.map(el => {
                                    return (
                                        <Accordion.Item eventKey={el.id}>
                                            <Accordion.Header >
                                                <div className='d-flex'>
                                                    <h2 className='d-flex align-items-center'><img src={el.image} alt='' />{el.title}</h2>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>{el.text}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })}
                            </Accordion>
                            {window.innerWidth > 747 ? null : <button className='seq-services-body2-card-btn' onClick={() => setScroll('demo')}>Learn more</button>}
                        </div>
                    }
                </div>
                {/* section 4 HOW WE WORK */}
                <div className='container'>
                    <div className='seq-services-body3'>
                        {window.innerWidth > 747 ?
                            <div className='row'>
                                <h3 className='col-md-6 seq-services-body3-head-text'>How We Work</h3>
                                <p className='seq-services-body3-text col-md-6 col-sm-12 d-flex align-items-end justify-content-end'>Our process is designed to seamlessly integrate with your existing infrastructure and provide comprehensive security</p>
                            </div> :
                            <div className=''>
                                <h3>How We Work</h3>
                                <p className='seq-services-body3-text'>Our process is designed to seamlessly integrate with your existing infrastructure and provide comprehensive security</p>
                            </div>
                        }
                        <div class="accordion">
                            <div class="accordion-item seq-services-body3-card">
                                <h2 class="accordion-header" id="headingeleven">
                                    <h1>1</h1>
                                    <h3>Initial Assessment</h3>
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeleven" aria-expanded="false" aria-controls="collapseeleven">
                                        <img src='/images/anglerblue-circle-up.svg' alt='' />
                                    </button>
                                </h2>
                                <div id="collapseeleven" class="accordion-collapse collapse" aria-labelledby="headingeleven">
                                    <div class="accordion-body">
                                        <p>We begin with a thorough assessment of your current security landscape, identifying strengths and vulnerabilities.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item seq-services-body3-card">
                                <h2 class="accordion-header" id="headingtwelve">
                                    <h1>2</h1>
                                    <h3>Customized Solutions</h3>
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwelve" aria-expanded="false" aria-controls="collapsetwelve">
                                        <img src='/images/anglerblue-circle-up.svg' alt='' />
                                    </button>
                                </h2>
                                <div id="collapsetwelve" class="accordion-collapse collapse" aria-labelledby="headingtwelve">
                                    <div class="accordion-body" style={{ paddingBottom: 0, paddingTop: '12px' }}>
                                        <p>Based on the assessment, we design a tailored security plan that meets your specific needs and addresses potential threats.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item seq-services-body3-card">
                                <h2 class="accordion-header" id="headingthirteen">
                                    <h1>3</h1>
                                    <h3>Implementation</h3>
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethirteen" aria-expanded="false" aria-controls="collapsethirteen">
                                        <img src='/images/anglerblue-circle-up.svg' alt='' />
                                    </button>
                                </h2>
                                <div id="collapsethirteen" class="accordion-collapse collapse" aria-labelledby="headingthirteen">
                                    <div class="accordion-body">
                                        <p>We implement the necessary security measures, ensuring minimal disruption to your operations.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item seq-services-body3-card">
                                <h2 class="accordion-header" id="headingfourteen">
                                    <h1>4</h1>
                                    <h3>Continuous Monitoring</h3>
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefourteen" aria-expanded="false" aria-controls="collapsefourteen">
                                        <img src='/images/anglerblue-circle-up.svg' alt='' />
                                    </button>
                                </h2>
                                <div id="collapsefourteen" class="accordion-collapse collapse" aria-labelledby="headingfourteen">
                                    <div class="accordion-body">
                                        <p>We provide ongoing monitoring and management with real-time threat detection and response.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item seq-services-body3-card">
                                <h2 class="accordion-header" id="headingfifteen">
                                    <h1>5</h1>
                                    <h3>Regular Reporting</h3>
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefifteen" aria-expanded="false" aria-controls="collapsefifteen">
                                        <img src='/images/anglerblue-circle-up.svg' alt='' />
                                    </button>
                                </h2>
                                <div id="collapsefifteen" class="accordion-collapse collapse" aria-labelledby="headingfifteen">
                                    <div class="accordion-body">
                                        <p>We keep you informed with detailed reports on your security status and the actions taken to protect your business.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 5 WHY SEQURETEK */}
                <div className='container pe-0 ps-0'>
                    <div className='seq-services-body4' style={{ backgroundImage: `url('/images/bg-img29.svg')`, border: '1px solid #FFFFFF1A' }}>
                        <div className='seq-services-body4-explore'>
                            <div className='d-flex'>
                                <div className='seq-services-body4-explore-text'>
                                    <div className='seq-services-body4-explore-text1'>
                                        <h5>Why Sequretek</h5>
                                        <p>By choosing Sequretek for your Managed Security Services, you gain access to end-to-end cybersecurity solutions</p>
                                        {window.innerWidth > 767 ? <button onClick={() => setPage('Why-Sequretek')}>Learn more</button> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {window.innerWidth > 767 ?
                        <div className='seq-services-body4-card'>
                            <div className='row'>
                                {SequretekCards?.map(el => {
                                    return (
                                        <div className='col-4 col-sm-6 d-flex justify-content-center  seq-services-body4-cards'>
                                            <div>
                                                <div className='d-flex align-items-center seq-services-cards-head'>
                                                    <img src={el.image} alt='' />
                                                    <h4>{el.title}</h4>
                                                </div>
                                                <p>{el.text}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        :
                        <div className='seq-services-body4-card'>
                            <Accordion defaultActiveKey={SequretekCards[0]?.id}>
                                {SequretekCards?.map(el => {
                                    return (
                                        <Accordion.Item eventKey={el.id}>
                                            <Accordion.Header >
                                                <div className='d-flex'>
                                                    <h2 className='d-flex align-items-center'><img src={el.image} alt='' />{el.title}</h2>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>{el.text}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })}
                            </Accordion>
                            {window.innerWidth > 747 ? null : <button className='seq-services-body4-card-btn' onClick={() => setPage('Why-Sequretek')}>Learn more</button>}
                        </div>
                    }
                </div>
                {/* section 6 SCHEDULE DEMO FORM */}
                <div className='seq-forms' ref={formRef}>
                    <div className='container'>
                        <div className="insurance-partseven">
                            {window.innerWidth > 767 ? <h2 className='insurance-partseven-heading d-flex align-items-center justify-content-center'>Get Started Today</h2> : null}
                            <ScheduleForm
                                bg={'/images/bg-img22.svg'}
                                mobilebg={'/images/light-umbrella.svg'}
                                page='Sequretek Services'
                                text={`We understand that every enterprise is unique. Our team of cybersecurity professionals bring years of experience in safeguarding businesses of all sizes, tailoring services keeping your enterprise's specific requirements in mind.`}
                            />
                        </div>
                    </div>
                </div>
                {/* section 7 RELATED RESOURCES */}
                {window.innerWidth > 767 ? null :
                    <div className='row resources'>
                        <h4>Related resources</h4>
                        <div className='col-md-8'>
                            <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap overflow-auto ms-2'}>
                                {
                                    RelatedResources?.map(el => {
                                        return (
                                            <div className={window.innerWidth > 767 ? 'col-md-3 d-flex align-self-stretch cursor-pointer mt-3' : 'col cursor-pointer mt-3 '}>
                                                <div className="card" style={{ width: window.innerWidth > 767 ? '' : '280px', height: window.innerWidth > 767 ? '260px' : '280px' }} >
                                                    <img className="card-img-top position-relative" src={el.image} alt='' />
                                                    <div className='type'>
                                                        <div className='type-1' >
                                                            {el.type === 'DEMO' ? <img src='/images/manage-btn-icon.svg' alt='' /> : el.type === 'PODCAST' ? <img src='/images/podcast-icon.svg' alt='' /> : el.type === 'WHITEPAPER' ? <img src='/images/folded-sheet.svg' alt='' /> :
                                                                el.type === 'VIDEO' ? <img src='/images/calendar-pen-icon.svg' alt='' /> : null
                                                            }&nbsp;&nbsp;
                                                            {el.type}
                                                        </div>
                                                        {el.type === 'WHITEPAPER' ? <img src='/images/lock-icon.svg' alt='' className='type-2' /> : null}
                                                    </div>
                                                    <div className="card-body">
                                                        <h5 className="card-title elipsis">{el.title}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Services;