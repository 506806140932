// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


// ############################ Require packages here ############################

import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';


export default function Blogs7() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sandworm Cyberattacks: GRU's APT Threatening Global Infrastructure</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Explore the shadowy world of Sandworm, a Russian military-linked cyber group. From the infamous NotPetya attack to recent exploits in Ukraine, uncover how this group has shaped global cyber warfare." />
                <meta name="keywords" content="Advanced Persistent Threat, Cyber attacks Russia" />
                <meta property="og:title" content="Sandworm Cyberattacks: GRU's APT Threatening Global Infrastructure" />
                <meta property="og:description" content="Explore the shadowy world of Sandworm, a Russian military-linked cyber group. From the infamous NotPetya attack to recent exploits in Ukraine, uncover how this group has shaped global cyber warfare." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} What do sand dunes foretell Sandworm?</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>What do sand dunes foretell Sandworm?</h3>
                        <p>21-MAY-2024 | Sanket Wagh </p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample14.jpg' width={'100%'} height={318} alt='' loading="lazy" />
                            <h4>Overview</h4>
                            <p>Sandworm Team is a destructive threat group that has been attributed to Russia’s General Staff Main Intelligence Directorate (GRU) Main Center for Special Technologies (GTsST) military unit 74455. This group has been active since at least 2009.[i]</p>
                            <p>Mandiant, an American cybersecurity firm and a subsidiary of Google, named the group APT44 due to its association with the Main Centre for Special Technologies (GTsST) within the Main Directorate of the General Staff of the Armed Forces of the Russian Federation (GU), commonly known as the Main Intelligence Directorate (GRU). This naming convention highlights its affiliation with the Russian military intelligence service and its specialized cyber capabilities.</p>
                            <h4>Under the Sandworm Umbrella</h4>
                            <p>“Sandworm” is indeed a broad term used to refer to multiple Advanced Persistent Threat (APT) groups believed to be affiliated with the GRU, Russia’s military intelligence agency. These groups share common tactics, techniques, and procedures (TTPs), suggesting a coordinated effort or centralized direction. However, each Sandworm subgroup may have its own specific objectives, targets, and operational methodologies within the larger framework of Russian cyber operations.</p>
                            <p>Sandworm is primarily associated with APT28 (also known as Fancy Bear) and APT29 (also known as Cozy Bear), which are two of the most well-known and prolific APT groups linked to Russian intelligence. Additionally, other groups with strong ties to Russia like Fin7 and Berserk, have been involved in cybercriminal activities targeting various sectors. These groups collectively demonstrate the multifaceted and sophisticated nature of Russian state-sponsored cyber operations.</p>
                            <p>While these are the primary groups associated with the term “Sandworm,” it’s important to note that the landscape of cyberthreats is constantly evolving, and there may be additional groups or actors operating under the broader umbrella of Sandworm that are not as widely recognized or publicly attributed.[ii]</p>
                            <p>Sandworm’s operations are global in scope in key political, military and economic hotspots for Russia. Here’s a deep dive into their origins, activities, techniques, and infamous attacks.</p>
                            <h4>Origins:  The discovery of Sandworm (2009 or Earlier)</h4>
                            <p>Regressing from 2022 attack on Kyiv deploying destructive wiper malware, the cyberattack on the 2018 Olympic Winter Games in South Korea, various interference efforts in the 2017 French presidential election, the 2017 NotPetya attack against companies worldwide, Ukraine power grid hack, its intrusions dating back to Russia’s illegal annexation of Crimea in 2014 to how researchers who discovered the group were alarmed by its reach, let’s go back to where it all started.</p>
                            <p>In his new book, Sandworm, Andy Greenberg[iii] delves into the chilling discovery made by researchers in 2014. They unraveled the existence of a group behind a series of attacks, tracing their activity back to at least 2009, if not earlier. This revelation underscored the group’s long history and the looming danger they posed. In 2014, just over a year before the outbreak of Ukraine’s cyber-war, a file had been passed from iSight’s Ukraine office was a PowerPoint attachment. The PowerPoint attachment used a zero-day exploit, allowing hackers to execute code silently on millions of computers.</p>
                            <p>The BlackEnergy sample, dropped by a Ukrainian PowerPoint, was recognized by its campaign code “arrakis02,” referencing the desert planet in the novel Dune. Another BlackEnergy sample, “houseatreides94,” found in a Word document discussing oil and gas prices, was linked to Dune as well. The hackers continued to use Dune references in their campaign, with names like “BasharoftheSardaukars” and “SalusaSecundus2.”</p>
                            <p>Sandworm embedded Dune references in their lure documents, tailoring them to the specific interests of their targets, Sandworm cleverly adapted their tactics. References to Europe’s conflict with Russia over Ukraine served as bait for attendees of Ukraine-focused summits and NATO events, tying together a series of seemingly disparate attacks. Sifting through the murky underworld of cybersecurity, security researchers stumbled upon a trail of Dune references hidden within malware. This cryptic clue unraveled a much larger story.  While the initial attack using a zero-day exploit in PowerPoint seemed recent, further investigation revealed a campaign spanning years. The first traces of these “Dune hackers” led back to cleverly disguised lures used in 2009. Like detectives piecing together fragments of evidence, security experts realized these attackers had been operating in the shadows for over half a decade.</p>
                            <p>Sandworm evolved into an advanced persistent threat under Military Unit 74455, a cyberwarfare unit of Russia’s GRU military intelligence. Since late 2021, it has primarily targeted Ukraine, aiding in the groundwork for Moscow’s February 2022 attack on Kyiv with destructive wiper malware. Following this, the unit continued launching multiple attacks against Ukrainian targets.[iv]</p>
                            <h4>Sandworm’s activities in support of Russia</h4>
                            <p>Sandworm is responsible for some of the most disruptive cyberattacks in recent history.  Here are a few notable examples[v]:</p>
                            <ul>
                                <li><p><strong>2014:</strong>On September 3, 2014, iSIGHT Partners uncovered a spear-phishing operation leveraging a zero-day flaw through weaponized Microsoft Office files. Dubbed CVE-2014-4114, the vulnerability targeted Windows Vista to 8.1, granting attackers remote code execution capabilities. Attributed to the Sandworm group, the campaign targeted the Ukrainian government, notably coinciding with a NATO summit focused on Ukraine in Wales.</p></li>
                                <li><p><strong>2015 Ukraine power grid hack:</strong>On December 23, 2015, Ukrainian power companies experienced unscheduled power outages, with reports indicating the presence of BlackEnergy malware in critical infrastructure sectors. The attack, meticulously planned, began eight months earlier with the initial intrusion via spear phishing. Intelligence gathering ensued, facilitated by the adaptable BlackEnergy malware, which scanned networks, identified vulnerabilities, and installed additional malware and backdoors. The actual attack lasted a mere 10 minutes on December 23, highlighting the need to understand the extended timeframe for proper detection and prevention measures.[vi]</p></li>
                                <li><p><strong>2016 Ukraine power grid hack:</strong>On December 17, 2016, hackers targeted the Ukrainian power grid with the Industroyer or CRASHOVERRIDE malware, causing a blackout in about one fifth of Kyiv for an hour. This sophisticated attack aimed to exploit vulnerabilities in protective relays, potentially leading to the destruction of physical electrical equipment. The attack’s intent was to cause extensive damage to transformers and power lines, which could have resulted in a prolonged outage and endangered utility workers if successful.</p></li>
                                <li><p><strong>2017 NotPetya Ransomware Attack: </strong>The NotPetya cyber attack, originating in 2017, emerged as a far more aggressive and destructive variant of the Petya ransomware. Unlike traditional ransomware, which encrypts specific files, Petya encrypted the master boot record and master file table, rendering the entire filesystem inaccessible. NotPetya, distinct from its predecessor, spread autonomously through multiple infection vectors like EternalBlue and EternalRomance, targeting computers running older Windows versions. Notably, NotPetya, masquerading as ransomware, irreparably damaged data instead of offering decryption, underscoring the need for robust cybersecurity measures and regular system updates to mitigate such threats.[vii]</p></li>
                                <li><p><strong>2018 Winter Olympics:</strong></p> During the 2018 Winter Olympics opening ceremony in Pyeongchang, a cyberattack targeted critical IT infrastructure, disrupting WiFi, stadium TVs, security gates, and the official Olympics app. This attack resulted in the loss of internet access and telecasts, grounded broadcasters’ drones, shut down the Pyeongchang 2018 website, and hindered spectators from attending, leading to numerous empty seats. Although not definitively confirmed, digital evidence implicated Fancy Bear, a Russian hacking group with ties to Russian intelligence services, known for its brazen cyber operations, including the attack on the Democratic National Committee prior to the 2016 presidential election.[viii]</li>
                                <li><p><strong>Exim Exploitation (2020): </strong>Sandworm has been capitalizing on a vulnerability within the Exim mail transfer agent (MTA) software, known as CVE-2019-10149, since at least last August. Exim, widely utilized across Unix-based and some Linux systems, is susceptible to remote code execution by exploiting this vulnerability. These actors, affiliated with the GRU’s Main Center for Special Technologies (GTsST), leverage this exploit to gain extensive access privileges, including the ability to add privileged users, disable network security settings, and execute additional scripts for further network exploitation. However, their success relies on the target network’s utilization of an unpatched version of Exim MTA.[ix]</p></li>
                                <li><p><strong>Cyclops Blink (2022):</strong>In February 2022, a joint report by US and UK cybersecurity and law enforcement agencies unveiled Cyclops Blink, a state-sponsored botnet targeting routers and firewall appliances manufactured by WatchGuard and Asus. Attributed to Sandworm, a notorious hacker group associated with the Russian government, Cyclops Blink’s victims range from government entities to critical infrastructure, spanning across regions like Ukraine, Europe, and the US, indicating the group’s widespread and impactful cyber operations.[x]</p></li>
                                <li><p><strong>Ukrainian Power Grid Attack (April 2022): </strong>In April 2022, Sandworm attempted a blackout in Ukraine. It is said to be the first attack in five years to use an Industroyer malware variant called Industroyer2. The March 22 attack, identified as one of the most severe in Russia’s springtime air campaign, caused significant disruption, leaving 1.9 million people without power, according to analysts. DTEK, Ukraine’s largest private energy company, reported an 80% loss of power generation capacity due to attacks on March 22 and 29, resulting in widespread destruction of plants across the country. Russia’s deliberate targeting of transmission networks exacerbated the impact, highlighting the severity and strategic nature of the assault on civilian infrastructure.[xi]</p></li>
                                <li><p><strong>Infamous Chisel (August 2023): </strong>On August 31, 2023, cybersecurity agencies from Five Eyes published a report attributing a new malware campaign, “Infamous Chisel,” to Sandworm. The malware targeted Ukrainian military Android devices, establishing persistent access for data exfiltration. It also collected open ports and service banners, while creating an SSH server configured as a Tor hidden service, allowing remote access without revealing the attacker’s IP. It specifically seeks out files matching predefined extensions like .jpeg and .txt while also gathering system information, scanning local networks for active hosts and open ports, and searching for unidentified Ukrainian military applications, the specifics of which remain undisclosed in the report.[xii]</p></li>
                                <li><p><strong>Attacked water and hydroelectric utilities (March 2024): </strong>As reported by Wired[xiii], Sandworm directly carried out the attacks on Texas water utilities in US and Polish waste water plant treatment, French watermill hydroelectric water dam. Sandworm posted videos on social media that show screen recordings of their manipulation of human-machine interfaces software that control physical equipment inside those target networks.</p></li>
                            </ul>
                            <img src='/images/blog-images/sample25.png' width={'100%'} height={'100%'} alt='' loading="lazy" />
                            <p>Figure 1: Timeline of consequential pre-war APT44 operations</p>
                            <p>Source: h**ps[:]//cloud[.]google[.]com/blog/topics/threat-intelligence/apt44-unearthing-sandworm</p>
                            <h4>Sandworm’s Tactics: GRU Playbook Insights</h4>
                            <p>Most State-backed threat groups tend to specialize in specific missions such as collecting intelligence, sabotaging networks or conducting information operations. However, Sandworm stands apart where its capabilities are sought to integrate into a unified playbook. Sandworm’s full spectrum operations encompass a multifaceted approach:</p>
                            <ul>
                                <li><p><strong>Espionage (KRIKS):</strong> This involves conducting cryptographic reconnaissance of Information and Communication Systems (KRIKS), aiming to gather intelligence through covert means. This may include activities such as intercepting communications or compromising encryption systems to access sensitive information.</p></li>
                                <li><p><strong>Attack (ITV):</strong> Under this phase, Sandworm engages in Information-Technical Influence/Effects (ITV), targeting systems and networks to disrupt, manipulate, or extract data. This could involve deploying malware, conducting distributed denial-of-service (DDoS) attacks, or exploiting vulnerabilities to achieve their objectives.</p></li>
                                <li><p><strong>Influence (IIPV): </strong>Sandworm employs Information-Psychological Influence/Effects (IIPV) to shape perceptions, opinions, and behaviors. This could involve spreading propaganda, misinformation, or conducting psychological operations aimed at influencing individuals, groups, or even entire populations through various online and offline channels.</p></li>
                            </ul>
                            <p>Sandworm has shifted focus from disruption to intelligence gathering, aiding Russian ground forces in collecting battlefield data, indicating a significant evolution in their support tactics over the war’s duration.</p>
                            <p>UNC3810-related activity is now attributed to APT44. UNC3810 is a threat actor was originally tracked by Mandiant as a Russia-backed hacking group. Mandiant merged the cluster with Sandworm. Since February 2022 invasion on Ukraine, Mandiant has tracked Russian military adhering to a standard 5-phase playbook:</p>
                            <img src='/images/blog-images/sample26.png' width={'100%'} height={'100%'} alt='' loading="lazy" />
                            <p>Figure 2: The 5 phases of GRU Playbook</p>
                            <h4>A Highly Adaptive Adversary</h4>
                            <p>Sandworm is a sophisticated and adaptable threat group utilizing diverse access methods, including supply chain compromises, while prioritizing operational security and leveraging both Russian companies and criminal marketplaces for offensive capabilities.</p>
                            <ul>
                                <li><p>Sandworm is an adaptive adversary proficient in various initial access methods such as phishing, credential harvesting, and supply chain compromises.</p></li>
                                <li><p>They often exploit edge infrastructure like routers and VPN appliances for initial access, conducting missions like reconnaissance, data theft, phishing, and deploying wiper malware.</p></li>
                                <li><p>Sandworm follows NotPetya’s footsteps, targeting software supply chains to compromise critical infrastructure networks.</p></li>
                                <li><p>They employ unconventional methods like trojanized software installers distributed via torrents for opportunistic access.</p></li>
                                <li><p>Once inside a network, Sandworm uses living-off-the-land techniques and prioritizes open-source or criminally sourced tools over custom implants.</p></li>
                                <li><p>They operate with high operational security, adhering to a playbook to scale operations, limit forensic evidence, and make post-exploitation activity hard to detect.</p></li>
                                <li><p>Sandworm judiciously deploys advanced tools, relying on diverse Russian companies and criminal marketplaces for offensive capabilities.</p></li>
                                <li><p>Leaked documents from Russian companies detail support for Sandworm ‘s operations, and they increasingly use tools from criminal marketplaces since Russia’s re-invasion of Ukraine in 2022.[xiv]</p></li>
                            </ul>
                            <h4>Malware commonly used in Sandworm attacks</h4>
                            <p>The notorious Sandworm group wields a vast arsenal of malware, constantly evolving to target a wide range of industries.  These malicious tools disrupt critical systems and leverage cutting-edge techniques to evade detection. Here are some of the specific types of malware that Sandworm has been known to use:</p>
                            <ul>
                                <li><p><strong>Olympic Destroyer (2018): </strong>Olympic Destroyer is malware that was used by Sandworm Team against the 2018 Winter Olympics, held in Pyeongchang, South Korea. The main purpose of the malware was to render infected computer systems inoperable. The malware leverages various native Windows utilities and API calls to carry out its destructive tasks. Olympic Destroyer has worm-like features to spread itself across a computer network in order to maximize its destructive impact. 2015 and 2022 Ukraine Power Grid Attacks: Sandworm launched cyberattacks against Ukraine’s power grid in both 2015 and 2022, causing widespread blackouts.</p></li>
                                <li><p><strong>BlackEnergy (2015):</strong>The BlackEnergy malware, which has been used in attacks dating back to 2007, was originally thought to be focused on cyber espionage. But in 2014, hackers updated the toolset to include malicious code targeting SCADA ICS, known-to-be-vulnerable kit used to control power stations and other critical infrastructure.[xv] This malware framework can be used for a variety of purposes, including conducting DDoS attacks, espionage, data theft, and sabotage. It is well known for being used during the confrontation between Georgia and Russia in 2008, as well as in targeting Ukrainian institutions. Variants include BlackEnergy 2 and BlackEnergy 3.[xvi] In 2014 (approximately) a specific user group of BlackEnergy attackers began deploying SCADA-related plugins to victims in the ICS (Industrial Control Systems) and energy markets around the world. This indicated a unique skillset, well above the average DDoS botnet master.[xvii]</p></li>
                                <li><p><strong>KillDisk (2015 & 2022):</strong> This is a disk-wiping malware that can be used to destroy data on a target system. Sandworm used KillDisk in the 2015 and 2022 Ukraine power grid attacks. KillDisk is a disk-wiping tool designed to overwrite files with random data to render the OS unbootable. It was first observed as a component of BlackEnergy malware during cyberattacks against Ukraine in 2015. KillDisk has since evolved into stand-alone malware used by a variety of threat actors against additional targets in Europe and Latin America.[xviii] It contains 32-bit and 64-bit driver files. Once run, the wiper will damage the Master Boot Record (MBR) of the infected computer, rendering it inoperable. The wiper does not appear to have any additional functionality beyond its destructive capabilities.[xix]</p></li>
                                <li><p><strong>Industroyer (2016 & 2022): </strong>This malware is specifically designed to target industrial control systems (ICS) used to operate critical infrastructure such as power grids. Sandworm used a variant of Industroyer called Industroyer2 in the 2022 Ukraine power grid attack. As reported by ESET once planted, Industroyer spread throughout the substation’s network looking for specific industrial control devices whose communication protocols it could speak. Then, like a time bomb going off, it apparently opened every circuit breaker at once, while defying any attempts of the substation operators to regain easy control: if an operator tried to close a breaker, the malware opened it back up.[xx]</p></li>
                                <li><p><strong>Cyclops Blink (2019):</strong> Cyclops Blink is a modular malicious framework developed to remotely compromise targeted networks. The expert Cyclops Blink analysis from federal U.S. and national-level intelligence agencies including FBI, CISA, NSA, and UK NCSC has linked this malware with a large-scale botnet primarily affecting small office/home office (SOHO) routers and network devices.[xxi]</p></li>
                                <li><p><strong>Wipers (2022):</strong>In 2022, the use of wipers surged dramatically. During the Russian invasion of Ukraine, numerous wipers were used to disrupt the Ukrainian government, critical infrastructure, and business. Over the course of the year, at least nine wipers were deployed against the country. A wiper’s purpose is to render data inaccessible and unusable. However, unlike ransomware, the intent is not to restore access after a ransom has been paid. Wiper destroys data forever, and this can be accomplished in a couple of ways: The contents of the files can be encrypted or overwritten, or the attacker could render them impossible to access by attacking the operating system itself.[xxii]</p></li>
                            </ul>
                            <h4>Conclusion</h4>
                            <p>Sandworm, represented by APT44, persists due to its exceptional capabilities, risk tolerance, and mandate to support Russia’s foreign policy interests, making it a formidable and persistent threat globally. The group’s history of pioneering cyberattack activities, such as influencing elections and retaliating against international sporting bodies, demonstrates its nationalist impulses and unwavering commitment to furthering Russia’s strategic goals. Despite its bias for psychological effects, Sandworm is patient, resourceful, and adept at remaining undetected in victim environments, utilizing tailored intrusion techniques and leveraging both open-source and criminally-sourced malware to evade detection.</p>
                            <p>Sandworm APT appears to be gaining momentum as it aligns with Russia’s objectives, presenting a formidable force. However, amidst Russia’s increasing global tensions, the risks of international scrutiny loom large. While Sandworm achieves short-term victories, the question remains: will their actions lead to long-term success? As Russia’s geopolitical landscape evolves, Sandworm’s operations are anticipated to adapt, with Ukraine remaining a primary focus but with potential for global reach to serve the Kremlin’s strategic interests. Sandworm’s sensitivity to counterintelligence efforts underscores the need for organizations to prioritize detection of its techniques and carefully investigate potential APT44 activity to effectively respond to this persistent and adaptive threat.</p>
                            <h4>Protecting the Community</h4>
                            <p>Here are some more specific recommendations tailored to mitigate the risk of Sandworm group attacks:</p>
                            <ul>
                                <li><p><strong>Patch Management:</strong> Prioritize patching vulnerabilities known to be exploited by the Sandworm group, such as those targeting common software like Microsoft Windows, Adobe products, and networking devices.</p></li>
                                <li><p><strong>Email Security:</strong>Implement robust email security measures, including spam filters, email authentication (such as SPF, DKIM, and DMARC), and user awareness training to detect and prevent phishing attempts used by Sandworm for initial access.</p></li>
                                <li><p><strong>Network Segmentation:</strong> Segment networks to limit lateral movement and contain potential breaches, preventing the spread of malware and reducing the impact of Sandworm’s activities.</p></li>
                                <li><p><strong>Endpoint Protection:</strong> Deploy advanced endpoint protection solutions with features like behavior monitoring, sandboxing, and machine learning to detect and block malware payloads delivered through Sandworm’s attack vectors.</p></li>
                                <li><p><strong>Threat Intelligence Sharing:</strong> Participate in threat intelligence sharing programs and information-sharing communities to stay informed about Sandworm’s tactics, techniques, and procedures (TTPs) and proactively defend against their evolving tactics.</p></li>
                                <li><p><strong>Access Control: </strong> Enforce the principle of least privilege to restrict access to sensitive systems and data, minimizing the potential impact of compromised accounts or devices exploited by Sandworm.</p></li>
                                <li><p><strong>Incident Response Preparedness: </strong>Develop and regularly test incident response plans specifically tailored to address Sandworm-style attacks, outlining steps for detection, containment, eradication, and recovery.</p></li>
                                <li><p><strong>Forensic Readiness: </strong> Implement logging and monitoring solutions to capture detailed information about network and endpoint activities, enabling effective forensic analysis and investigation in the event of a Sandworm attack.</p></li>
                                <li><p><strong>Continuous Monitoring:</strong> Establish continuous monitoring processes to detect anomalous behavior indicative of Sandworm activity, allowing for rapid response and mitigation of potential threats.</p></li>
                            </ul>
                            <p>By incorporating these specific recommendations into their cybersecurity posture, organizations can better defend against the sophisticated and evolving threats posed by the Sandworm group and minimize the risk of successful attacks.</p>
                            <hr />
                            <p>References:</p>
                            <p>[i] <a target='_blank' rel="noreferrer" href="https://attack.mitre.org/groups/G0034/">https://attack.mitre.org/groups/G0034/</a></p>
                            <p>[ii] <a target='_blank' rel="noreferrer" href="https://flashpoint.io/blog/vulnerabilities-exploited-by-russian-apts-ransomware-groups/">https://flashpoint.io/blog/vulnerabilities-exploited-by-russian-apts-ransomware-groups/</a></p>
                            <p>[iii] <a target='_blank' rel="noreferrer" href="https://books.google.co.in/books/about/Sandworm.html?id=ujxrDwAAQBAJ&redir_esc=y">https://books.google.co.in/books/about/Sandworm.html?id=ujxrDwAAQBAJ&redir_esc=y</a></p>
                            <p>[iv] <a target='_blank' rel="noreferrer" href="https://www.vanityfair.com/news/2019/10/the-discovery-of-sandworm-the-worlds-most-dangerous-hackers">https://www.vanityfair.com/news/2019/10/the-discovery-of-sandworm-the-worlds-most-dangerous-hackers</a></p>
                            <p>[v] <a target='_blank' rel="noreferrer" href="https://en.wikipedia.org/wiki/Sandworm_(hacker_group)">https://en.wikipedia.org/wiki/Sandworm_(hacker_group)</a></p>
                            <p>[vi] <a target='_blank' rel="noreferrer" href="https://www.isa.org/intech-home/2017/march-april/features/ukrainian-power-grids-cyberattack">https://www.isa.org/intech-home/2017/march-april/features/ukrainian-power-grids-cyberattack</a></p>
                            <p>[vii] <a target='_blank' rel="noreferrer" href="https://www.awencollective.com/blog/2021/5/14/quick-overview-of-the-2017-notpetya-cyber-attack">https://www.awencollective.com/blog/2021/5/14/quick-overview-of-the-2017-notpetya-cyber-attack</a></p>
                            <p>[viii] <a target='_blank' rel="noreferrer" href="https://www.nytimes.com/2018/02/12/technology/winter-olympic-games-hack.html">https://www.nytimes.com/2018/02/12/technology/winter-olympic-games-hack.html</a></p>
                            <p>[ix] <a target='_blank' rel="noreferrer" href="https://www.nsa.gov/Press-Room/News-Highlights/Article/Article/2196511/exim-mail-transfer-agent-actively-exploited-by-russian-gru-cyber-actors/">https://www.nsa.gov/Press-Room/News-Highlights/Article/Article/2196511/exim-mail-transfer-agent-actively-exploited-by-russian-gru-cyber-actors/</a></p>
                            <p>[x] <a target='_blank' rel="noreferrer" href="https://nordvpn.com/blog/cyclops-blink/">https://nordvpn.com/blog/cyclops-blink/</a></p>
                            <p>[xi] <a target='_blank' rel="noreferrer" href="https://apnews.com/article/russia-ukraine-war-power-plant-missiles-drones-94692e19900f60c2c3641c9352a416ed">https://apnews.com/article/russia-ukraine-war-power-plant-missiles-drones-94692e19900f60c2c3641c9352a416ed</a></p>
                            <p>[xii] <a target='_blank' rel="noreferrer" href="https://cyberscoop.com/sandworm-ukraine-infamous-chisel/">https://cyberscoop.com/sandworm-ukraine-infamous-chisel/</a></p>
                            <p>[xiii] <a target='_blank' rel="noreferrer" href="https://www.wired.com/story/cyber-army-of-russia-reborn-sandworm-us-cyberattacks/">https://www.wired.com/story/cyber-army-of-russia-reborn-sandworm-us-cyberattacks/</a></p>
                            <p>[xiv] <a target='_blank' rel="noreferrer" href="https://services.google.com/fh/files/misc/apt44-unearthing-sandworm.pdf">https://services.google.com/fh/files/misc/apt44-unearthing-sandworm.pdf</a></p>
                            <p>[xv] <a target='_blank' rel="noreferrer" href="https://www.forbes.com/sites/thomasbrewster/2016/01/04/ukraine-power-out-cyber-attack/?sh=65979d0f6fa8">https://www.forbes.com/sites/thomasbrewster/2016/01/04/ukraine-power-out-cyber-attack/?sh=65979d0f6fa8</a></p>
                            <p>[xvi] <a target='_blank' rel="noreferrer" href="https://attack.mitre.org/software/S0089/">https://attack.mitre.org/software/S0089/</a></p>
                            <p>[xvii] <a target='_blank' rel="noreferrer" href="https://www.kaspersky.com/resource-center/threats/blackenergy">https://www.kaspersky.com/resource-center/threats/blackenergy</a></p>
                            <p>[xviii] <a target='_blank' rel="noreferrer" href="https://attack.mitre.org/software/S0607/">https://attack.mitre.org/software/S0607/</a></p>
                            <p>[xix] <a target='_blank' rel="noreferrer" href="https://symantec-enterprise-blogs.security.com/blogs/threat-intelligence/ukraine-wiper-malware-russia">https://symantec-enterprise-blogs.security.com/blogs/threat-intelligence/ukraine-wiper-malware-russia</a></p>
                            <p>[xx] <a target='_blank' rel="noreferrer" href="https://www.welivesecurity.com/2022/06/13/industroyer-cyber-weapon-brought-down-power-grid/">https://www.welivesecurity.com/2022/06/13/industroyer-cyber-weapon-brought-down-power-grid/</a></p>
                            <p>[xxi] <a target='_blank' rel="noreferrer" href="https://socprime.com/blog/cyclops-blink-malware-used-by-sandworm-apt-group-replaces-vpnfilter-as-reported-by-cisa/">https://socprime.com/blog/cyclops-blink-malware-used-by-sandworm-apt-group-replaces-vpnfilter-as-reported-by-cisa/</a></p>
                            <p>[xxii] <a target='_blank' rel="noreferrer" href="https://www.checkpoint.com/cyber-hub/threat-prevention/what-is-malware/what-is-wiper-malware/">https://www.checkpoint.com/cyber-hub/threat-prevention/what-is-malware/what-is-wiper-malware/</a></p>
                        </div>
                    </div>
                </div>
                <ShareUrl
                    details={{ title: `Sandworm Cyberattacks: GRU's APT Threatening Global Infrastructure`, url: 'https://www.sequretek.com/resources/blog/sandworm-cyberattacks' }}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                        title={'What do sand dunes foretell Sandworm?'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
