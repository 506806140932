// ############################ Require packages here ############################
import React, { useEffect, Fragment, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################

import { resetResourceLoader, getExternalResources, getExtIndustries, getExtTopics, getTypes } from '../../../Redux/ActionCreators/resourcesAction';
import './resources.scss';
import { convertDate } from '../../../utils/miscellneous';


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/arrow-down-white.svg' width={20} alt='' />
        </components.DropdownIndicator>
    );
};


const upcomingEvents = [{ id: 1, date: '24-June-2024,2:30PM', image: '/images/sample1.png', type: 'event', title: 'The emergence of GENAI: friend or foe for your company’s security postu...', },
{ id: 2, date: '24-June-2024,2:30PM', image: '/images/sample2.png', type: 'webinar', title: 'Percept Compliance Manager', },
{ id: 3, date: '24-June-2024,2:30PM', image: '/images/sample3.png', type: 'event', title: 'Percept XDR and NG SIEM', }
]
const types = [{ type: 'blog', label: 'Blog' }, { type: 'case-study', label: 'Case Studies' }, { type: 'datasheet', label: 'DataSheets' }, { type: 'demo', label: 'Demos' }, { type: 'event', label: 'Events & Webinars' },
{ type: 'infographics', label: 'Infographics' }, { type: 'podcast', label: 'Podcasts' }, { type: 'report', label: 'Reports' }, { type: 'advisory', label: 'Threat Advisories' }, { type: 'video', label: 'Videos' },
{ type: 'whitepaper', label: 'White Papers' }
]

export default function EventWebinars() {
    const active = 'event';
    const [toggle, setToggle] = useState(false);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [topic, setTopic] = useState(null);
    const [industry, setIndustry] = useState(null);
    const { isLoadingResource, externalResources, extIndustries, extTopics, extTypes } = useSelector(state => state.resources);
    const dispatch = useDispatch();
    const location = useLocation();
    const timerRef1 = React.useRef(null);
    const size = 10;
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(resetResourceLoader());
        dispatch(getExternalResources(1, '', active, '', '', size));
        dispatch(getExtIndustries());
        dispatch(getExtTopics());
        dispatch(getTypes());
    }, [])


    const setActive = (val) => {
        navigate('/resources-hub', { state: { type: val } })
    }
    useEffect(() => {
        const firstRender = async () => {
            if (location?.state?.type) {
                await dispatch(resetResourceLoader());
                await dispatch(getExternalResources(1, '', location?.state?.type, '', '', size));
            }
        }

        firstRender();
    }, [location.state?.type])

    const searchResource = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPage(1);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    }

    const delaySearch = async (val) => {
        await dispatch(resetResourceLoader());
        await dispatch(getExternalResources(1, val, '', '', '', size));
    }

    const selectTypeFilter = async (val) => {
        if (val !== 'event') {
            navigate('/resources-hub', { state: { type: val } });
        }
        else {
            setActive(val);
            setPage(1);
            setQuery('');
            await dispatch(resetResourceLoader());
            await dispatch(getExternalResources(1, '', val, '', '', size));
        }
    }

    const selectTopic = async (e) => {
        setTopic(e);
        setPage(1);
        setQuery('');
        await dispatch(resetResourceLoader());
        await dispatch(getExternalResources(1, '', '', e?.value, '', size));
    }

    const selectIndustries = async (e) => {
        setIndustry(e);
        setPage(1);
        setQuery('');
        await dispatch(resetResourceLoader());
        await dispatch(getExternalResources(1, '', '', '', e.value, size));
    }

    const selectTypes = async (e) => {
        setActive(e);
        setPage(1);
        setQuery('');
        await dispatch(resetResourceLoader());
        await dispatch(getExternalResources(1, '', e.value, '', '', size));
    }

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#0e49b5'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'lightblue' : '#0e49b5', // Change background color on selection
            color: 'white', // Ensure text is visible
            ':hover': {
                backgroundColor: 'lightgrey' // Change background color on hover
            }
        })
    };

    const filteredData = externalResources.filter(item => {
        const itemDate = new Date(item.publish); // Assuming 'date' is a string in the item object
        return itemDate > new Date(); // Filter only items with a date after or equal to today
    });
    const filteredDataLessThanToday = externalResources.filter(item => {
        const itemDate = new Date(item.publish);
        return itemDate < new Date();
    });
    return (
        <div className='seq-resource-center'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Upcoming Events & Webinars | Join Our Live Sessions</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Explore our upcoming events and webinars to stay updated with the latest trends. Join live sessions, gain insights, and network with industry experts." />
                <meta property="og:title" content="Sequretek | Upcoming Events & Webinars | Join Our Live Sessions" />
                <meta property="og:description" content="Explore our upcoming events and webinars to stay updated with the latest trends. Join live sessions, gain insights, and network with industry experts." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-resource-header'>
                <div style={{ width: window.innerWidth > 767 ? '60%' : '77%' }}>
                    <h2>Resource Center</h2>
                    <p>Our comprehensive Resources section offers a wealth of information on the latest advancements and best practices in cybersecurity.  We invite you to explore, learn, and ensure your security in this increasingly interconnected world.  </p>
                </div>
            </div>
            {/* section 2 EVENTS */}
            <div className='container pe-0'>
                {filteredData?.length > 0 ?
                    <div className='row upcoming-event'>
                        <h4>Upcoming Events & Webinars</h4>
                        <div className='col-md-12'>
                            <div className={window.innerWidth > 767 ? 'row justify-content-center' : 'row flex-nowrap overflow-auto ms-2'}>
                                {
                                    filteredData?.slice(0, 3)?.map(el => {
                                        return (
                                            <div className={window.innerWidth > 767 ? 'col-md-3 d-flex align-self-stretch cursor-pointer mt-3' : 'col cursor-pointer mt-3 '} loading="lazy">
                                                <div className="card w-100" style={{ width: window.innerWidth > 767 ? '264px' : '280px' }} >
                                                    <img className="card-img-top position-relative" src={el.image ? el.image : '/images/sample2.png'} alt='' loading="lazy" />
                                                    <div className='type'>
                                                        <div className='type-1' >
                                                            {el.type === 'report' ? <img src='/images/report-icon.svg' alt='' /> : el.type === 'advisory' ? <img src='/images/advisory-icon.svg' alt='' /> : el.type === 'podcast' ? <img src='/images/podcast-icon.svg' alt='' /> :
                                                                el.type === 'demo' ? <img src='/images/demo-icon.svg' alt='' /> : el.type === 'datasheet' ? <img src='/images/datasheet-icon.svg' alt='' /> : el.type === 'case-study' ? <img src='/images/case-study-icon.svg' alt='' /> :
                                                                    el.type === 'blog' ? <img src='/images/blog-icon.svg' alt='' /> : el.type === 'infographics' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'infographics' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'event' ? <img src='/images/event-icon.svg' alt='' /> : el.type === 'video' ? <img src='/images/video-icon.svg' alt='' /> : el.type === 'webinar' ? <img src='/images/webinar-icon.svg' alt='' /> : null
                                                            }&nbsp;&nbsp;
                                                            {el.type}
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <h6 style={{ marginTop: 5, fontSize: 12, color: "#fff" }}>{convertDate(el.publish)}</h6>
                                                        <h5 className="card-title elipsis" style={{ height: 72 }}>{el.title}</h5>
                                                        <div className='card-btn'>
                                                            <button onClick={() => window.open(el.url, '_blank')}>Register Now</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div> :
                    null
                }
                {/* section 3 LAYOUT */}
                <div className='seq-resource-layout'>
                    <div className='container'>
                        {active === '' ? null :
                            <div className='breadcrumb'>
                                <ul>
                                    <li><span onClick={() => setActive('')}>Resources</span>
                                        <span>
                                            {
                                                types?.map(el => {
                                                    return (<Fragment>
                                                        {active === el.type ? ` >  ${el.label}` : null}
                                                    </Fragment>)
                                                })
                                            }</span></li>
                                </ul>
                            </div>
                        }
                        {window.innerWidth > 767 ?
                            <div className='resource-filter mt-2'>
                                <div className="has-search"  >
                                    <span className="form-control-feedback" >
                                        <img src="/images/search.svg" alt='' width={12} />
                                    </span>
                                    <input type="search" className="form-control" placeholder='Search for resource' value={query} onChange={(e) => searchResource(e.target.value)} />
                                </div>
                                {/* <div> */}

                                <div style={{ width: '20%' }}>
                                    <Select
                                        components={{ DropdownIndicator }}
                                        options={extTopics}
                                        onChange={selectTopic}
                                        placeholder='Select Topic'
                                        value={topic}
                                        styles={customStyles}
                                        isClearable
                                    />
                                </div>
                                <div style={{ width: '20%' }}>
                                    <Select
                                        components={{ DropdownIndicator }}
                                        options={extIndustries}
                                        onChange={selectIndustries}
                                        placeholder='Select Industry'
                                        value={industry}
                                        styles={customStyles}
                                        isClearable
                                    />
                                </div>
                                {/* </div> */}
                            </div> :
                            <div className='resource-filter'>
                                {/* section 3-1 FILTER */}
                                <div className='d-flex justify-content-between align-items-center w-100'>
                                    <div className="has-search"  >
                                        <span className="form-control-feedback" >
                                            <img src="/images/search.svg" alt='' width={12} />
                                        </span>
                                        <input type="search" className="form-control" placeholder='Search for resource' />
                                    </div>&nbsp;&nbsp;
                                    <button className='filter-btn' style={{ backgroundColor: toggle ? '#0e49b5' : '' }}><img src='/images/filter.svg' alt='' onClick={() => setToggle(!toggle)} /></button>
                                </div>
                                {toggle ?
                                    <Fragment>
                                        <div className='mt-2' style={{ width: '100%' }}>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                options={extTopics}
                                                onChange={selectTopic}
                                                placeholder='Select Topic'
                                                value={topic}
                                                styles={customStyles}
                                                isClearable
                                            />

                                        </div>
                                        <div className='mt-2' style={{ width: '100%' }}>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                options={extIndustries}
                                                onChange={selectIndustries}
                                                placeholder='Select Industry'
                                                value={industry}
                                                styles={customStyles}
                                                isClearable
                                            />
                                        </div>
                                    </Fragment>
                                    : null
                                }
                                <div className='type-filter mt-2 w-100'>
                                    <Select
                                        components={{ DropdownIndicator }}
                                        options={extTypes}
                                        isClearable
                                        onChange={selectTypes}
                                        placeholder='Select Type'
                                        value={active}
                                        styles={customStyles}
                                    />
                                </div>

                            </div>
                        }
                        {/* section 3-3 RESOURCES LIST */}
                        <div className='resources-list'>
                            <div className='row'>
                                <div className='col-md-9'>
                                    <div className='row mt-3'>
                                        {isLoadingResource ?
                                            ''
                                            :
                                            filteredDataLessThanToday?.length > 0 ?
                                                filteredDataLessThanToday?.map(el => {
                                                    return (
                                                        <div className={window.innerWidth > 767 ? 'col-md-4  d-flex align-self-stretch cursor-pointer mt-3' : 'col-md-12 cursor-pointer mt-3'} onClick={() => window.open(el.url, '_blank')}>
                                                            <div className="card w-100" >
                                                                <img className="card-img-top position-relative" src={el.image ? el.image : '/images/sample2.png'} alt='' />
                                                                <div className='type'>
                                                                    <div className='type-1' >
                                                                        {el.type === 'report' ? <img src='/images/report-icon.svg' alt='' /> : el.type === 'advisory' ? <img src='/images/advisory-icon.svg' alt='' /> : el.type === 'podcast' ? <img src='/images/podcast-icon.svg' alt='' /> :
                                                                            el.type === 'demo' ? <img src='/images/demo-icon.svg' alt='' /> : el.type === 'datasheet' ? <img src='/images/datasheet-icon.svg' alt='' /> : el.type === 'case-study' ? <img src='/images/case-study-icon.svg' alt='' /> :
                                                                                el.type === 'blog' ? <img src='/images/blog-icon.svg' alt='' /> : el.type === 'infographic' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'infographics' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'event' ? <img src='/images/event-icon.svg' alt='' /> : el.type === 'video' ? <img src='/images/video-icon.svg' alt='' /> : el.type === 'webinar' ? <img src='/images/webinar-icon.svg' alt='' /> : null
                                                                        }&nbsp;&nbsp;
                                                                        {el.type}
                                                                    </div>
                                                                    {el.gated ?
                                                                        <div className='type-1 ms-2' style={{ padding: '4px 10px', background: '#A2DAE0' }}>
                                                                            <img src='/images/lock-icon.svg' alt='' />
                                                                        </div> :
                                                                        null
                                                                    }
                                                                </div>
                                                                <div className="card-body" style={{ height: 127 }}>
                                                                    <h6 style={{ marginTop: 5, fontSize: 12, color: "#fff" }}>{convertDate(el.publish)}</h6>
                                                                    <h5 className="card-title elipsis">{el.title}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) :
                                                <p className='text-center text-white mt-5'>
                                                    No Resources Available
                                                </p>
                                        }

                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='ul right-filter '>
                                        <li onClick={() => selectTypeFilter('')} className={active === '' ? 'active' : ''}>All Resources</li>
                                        {
                                            extTypes?.map(el => {
                                                return (
                                                    <li onClick={() => selectTypeFilter(el.value)} className={el.value === active ? 'active text-capitalize' : 'text-capitalize'} >
                                                        {
                                                            el.value === active ?
                                                                (el.value === 'report' ? <img src='/images/report-icon.svg' alt='' /> : el.value === 'advisory' ? <img src='/images/advisory-icon.svg' alt='' /> : el.value === 'podcast' ? <img src='/images/podcast-icon.svg' alt='' /> :
                                                                    el.value === 'demo' ? <img src='/images/demo-icon.svg' alt='' /> : el.value === 'datasheet' ? <img src='/images/datasheet-icon.svg' alt='' /> : el.value === 'case-study' ? <img src='/images/case-study-icon.svg' alt='' /> :
                                                                        el.value === 'blog' ? <img src='/images/blog-icon.svg' alt='' /> : el.value === 'infographic' ? <img src='/images/infographics.svg' alt='' /> : el.value === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.value === 'infographic' ? <img src='/images/infographics.svg' alt='' /> : el.value === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.value === 'event' ? <img src='/images/event-icon.svg' alt='' /> : el.value === 'video' ? <img src='/images/video-icon.svg' alt='' /> : el.value === 'webinar' ? <img src='/images/webinar-icon.svg' alt='' /> : null) :
                                                                (el.value === 'report' ? <img src='/images/report-icon-color.svg' alt='' /> : el.value === 'advisory' ? <img src='/images/advisory-icon-color.svg' alt='' /> : el.value === 'podcast' ? <img src='/images/podcast-icon-color.svg' alt='' /> :
                                                                    el.value === 'demo' ? <img src='/images/demo-icon-color.svg' alt='' /> : el.value === 'datasheet' ? <img src='/images/datasheet-icon-color.svg' alt='' /> : el.value === 'case-study' ? <img src='/images/case-study-icon-color.svg' alt='' /> :
                                                                        el.value === 'blog' ? <img src='/images/blog-icon-color.svg' alt='' /> : el.value === 'infographic' ? <img src='/images/infographics-color.svg' alt='' /> : el.value === 'whitepaper' ? <img src='/images/whitepaper-icon-color.svg' alt='' /> : el.value === 'event' ? <img src='/images/event-icon-color.svg' alt='' /> : el.value === 'video' ? <img src='/images/video-icon-color.svg' alt='' /> : el.value === 'webinar' ? <img src='/images/webinar-icon-color.svg' alt='' /> : null)
                                                        } &nbsp;&nbsp;
                                                        {el.displayname}
                                                    </li>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}