// ############################ Require packages here ############################
import React, { useEffect, useRef } from 'react'
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import Aos from 'aos';

// ############################ Require files here ###############################
import { SampleNextArrow, SamplePrevArrow } from '../../utils/miscellneous';


const TeamCard = [
    { id: 1, img: '/images/an.svg', lnUrl: 'https://www.linkedin.com/in/anand-naik-4726bb15/?originalSubdomain=in', name: 'Anand Naik', position: 'CEO and Co-Founder' },
    { id: 2, img: '/images/pd.svg', lnUrl: 'https://www.linkedin.com/in/pankit/', name: 'Pankit Desai', position: 'CEO and Co-Founder' },
    { id: 3, img: '/images/yf.svg', lnUrl: 'https://www.linkedin.com/in/yair-frankel-4a052a1/', name: 'Yair Frankel', position: 'SVP & Head Operations - Americas' },
    { id: 4, img: '/images/uv.svg', lnUrl: 'https://www.linkedin.com/in/udayanathanvettikkat/', name: 'Udayanathan Vettikkat', position: 'SVP & Head - Global Marketing' },
    { id: 5, img: '/images/ab.svg', lnUrl: 'https://www.linkedin.com/in/arvind-boggarapu-3877a95/', name: 'Arvind Boggarapu', position: 'Chief Technology Officer' },
    { id: 6, img: '/images/sd.svg', lnUrl: 'https://www.linkedin.com/in/subhashdutta/', name: 'Cdr. Subhash Dutta (Retd.)', position: 'SVP & Head - Sequretek Research Labs' },
    { id: 7, img: '/images/ls.svg', lnUrl: 'https://www.linkedin.com/in/lshinde/', name: 'Lalit Shinde', position: 'SVP & Chief Revenue Officer - Americas' },
    // { id: 8, img: '/images/sk.svg', lnUrl: '/images/linkedin-icon-blue.svg', name: 'Shrirang Kane', position: 'SVP & Chief Revenue Officer - India' },
    { id: 9, img: '/images/sp.svg', lnUrl: null, name: 'Swati Pande', position: 'SVP & Head Operations - India' },
    { id: 10, img: '/images/dt.svg', lnUrl: 'https://www.linkedin.com/in/dwijtrivedi/', name: 'Dwij Trivedi', position: 'VP & Head Sales Engineering - Americas' },
    { id: 11, img: '/images/rg.svg', lnUrl: 'https://www.linkedin.com/in/rajat-goel-b66455129/', name: 'Rajat Goel', position: 'Head - Customer Success' },
    { id: 12, img: '/images/js.svg', lnUrl: 'https://www.linkedin.com/in/jhanvi-shah-18272ab3/', name: 'Jhanvi Shah', position: 'Company Secretary & Legal Officer' },
]

const OurInvestors = [
    { id: '1', img: '/images/Investor1.svg' },
    { id: '2', img: '/images/Investor2.svg' },
    { id: '3', img: '/images/Investor3.svg' },
    { id: '4', img: '/images/Investor4.svg' },
    { id: '5', img: '/images/Investor5.svg' },
    { id: '6', img: '/images/Investor6.svg' },
    { id: '7', img: '/images/Investor7.svg' },
    { id: '8', img: '/images/Investor8.svg' },
]

const Ourassociate = [
    { id: 1, img: '/images/associate1.svg', text: 'Apprenticeship Program' },
    { id: 2, img: '/images/associate2.svg', text: 'Member - ESC India, selected for ESC Incubator program for US regions' },
    { id: 3, img: '/images/associate3.svg', text: 'Member - ICBA (Independent Community Bankers of America)' },
    { id: 4, img: '/images/associate4.svg', text: 'One of the ICE71 companies.' },
    { id: 5, img: '/images/associate5.svg', text: 'A CERT-In Empaneled Vendor' },
    { id: 6, img: '/images/associate6.svg', text: 'Our offerings are available for Government organizations' },
    { id: 7, img: '/images/associate7.svg', text: 'Designed cybersecurity curriculum for Train-the-trainer program.' },
    { id: 8, img: '/images/associate8.svg', text: 'Member: NASSCOM. One of the NASSCOM DeepTech Club companies.' },
]

const DiversityCard = [
    { id: 1, img: '/images/user-add.svg', title: 'Inclusive Hiring Practices', text: 'We actively recruit from a varied candidate pool to ensure a balanced workforce. Our hiring processes prevent biases, creating equal opportunities for all.' },
    { id: 2, img: '/images/plant-care.svg', title: 'Supportive Work Environment', text: 'We foster an inclusive culture where every individual feels valued and respected, aided by policies like zero-tolerance for discrimination, flexi-working and more.' },
    { id: 3, img: '/images/career-growth.svg', title: 'Continuous Learning and Development', text: 'We regularly provide DEI sensitisation training to all employees. Our leadership development programs aim to ensure a diverse pipeline of future leaders.' },
    { id: 4, img: '/images/bullseye-arrow.svg', title: 'Transparent Goals and Accountability', text: 'We set clear diversity goals and track our progress regularly. We periodically share updates on our DEI initiatives and their impact with our stakeholders.' },
]

var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SampleNextArrow className={"next-arrow"} />,
    prevArrow: <SamplePrevArrow className={"prev-arrow"} />,
    responsive: [
        {
            breakpoint: 1320,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                initialSlide: 0,
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2.35,
                slidesToScroll: 2,
                initialSlide: 0,
            }
        },
    ]
};


const Story = () => {
    const leadershipRef = useRef(null);
    const investorRef = useRef(null);
    const diversityRef = useRef(null);

    const location = useLocation();

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    useEffect(() => {
        if (location.state?.scroll) {
            const { scroll } = location.state;
            if (scroll === 'leadership') {
                leadershipRef.current?.scrollIntoView({ behavior: 'smooth' });
            } else if (scroll === 'investor') {
                investorRef.current?.scrollIntoView({ behavior: 'smooth' });
            } else if (scroll === 'diversity') {
                diversityRef.current?.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.state]);


    return (
        <div className='seq-Story-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>About Sequretek | Our Story, Mission and Team </title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Explore our journey. Discover our leadership, mission, vision, and career opportunities." />
                <meta name="keywords" content="Diversity and inclusion, diversity and inclusion in the workplace" />
                <meta property="og:title" content="About Sequretek | Our Story, Mission and Team" />
                <meta property="og:description" content="Explore our journey. Discover our leadership, mission, vision, and career opportunities." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-Story-heading' style={{ backgroundImage: `url('/images/bg-img38.svg')` }}>
                <div className='container'>
                    <div className='seq-Story-hero'>
                        <div className='seq-Story-hero-text' data-aos="fade-up">
                            <h3 className='d-flex align-items-center justify-content-center'>VISION</h3>
                            <h5 className='d-flex align-items-center justify-content-center'>To simplify security by consolidating the technology landscape</h5>
                        </div>
                        {/* section 1-1 PLAYER */}
                        <ReactPlayer url='https://www.youtube.com/watch?v=iWSLMdub0fg' data-aos="fade-up" className='seq-Our-Story-player' />
                    </div>
                </div>
            </div>
            <div className='seq-Story-body'>
                {/* Session 2 MISSION CARDS*/}
                <div className='container pe-0 ps-0'>
                    <div className='seq-Story-body1'>
                        <div style={{ marginRight: '40px' }} data-aos="fade-up">
                            <div className='d-flex align-items-center justify-content-center'><img src='images/q-icon1.svg' alt='' /></div>
                            <h5 className='d-flex align-items-center justify-content-center'>MISSION</h5>
                            <h3 className='d-flex align-items-center justify-content-center'>To empower your growth without fear as your trusted partner by simplifying security</h3>
                        </div>
                        <div className='seq-Story-carousel' data-aos="fade-up">
                            {window.innerWidth > 767 ?
                                <div className="slider-container">
                                    <Slider {...settings}>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/genesis.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>The Genesis</h5>
                                            <p className='d-flex align-items-center justify-content-center'>Born out of a shared vision of enterprise leaders Anand Naik and Pankit Desai, to tackle rising cyberthreats and compliance challenges of digitalization</p>
                                        </div>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/quest.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>Our Quest</h5>
                                            <p className='d-flex align-items-center justify-content-center'>Simplify security by integrating people, processes, and technologies with cost-effective offerings to improve security  posture and grow fearlessly</p>
                                        </div>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/road.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>The Journey So Far</h5>
                                            <p className='d-flex align-items-center justify-content-center'>Created AI-powered proprietary platform, products, and services that enhance visibility, protect against cyberattacks, and comply with regulations</p>
                                        </div>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/intent.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>The Intent</h5>
                                            <p className='d-flex align-items-center justify-content-center'>Leverage AI and ML to provide robust cyberprotection with solutions that coexist with, complement, and consolidate IT investments, delivering better ROI</p>
                                        </div>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/investment.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>The Latest Funding</h5>
                                            <p className='d-flex align-items-center justify-content-center'>Raised USD 8 million in 2023 in a Series A round led by Omidyar Network India, with participation from Narotam Sekhsaria Family Office and Alteria Capital</p>
                                        </div>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/creds.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>The Credentials</h5>
                                            <p className='d-flex align-items-center justify-content-center'>CERT-In empaneled vendor, ISO 27001:2013 and SOC 2 certified, NASSCOM DeepTech company, ICBA ThinkTech,Tampa Bay Wave, and FIS Financial Accelerator Programs</p>
                                        </div>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/proud.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>Key Mentions</h5>
                                            <p className='d-flex align-items-center justify-content-center'>Recognized by top global research & analyst firms, listed among the FT High Growth Asia Pacific Companies, ET India's Growth Champions, MSSP Alert’s Top 250</p>
                                        </div>
                                    </Slider>
                                </div>
                                :
                                <div className='d-flex overflow-auto flex-nowrap' style={{ gap: '20px' }}>
                                    <div className=''>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/genesis.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>The Genesis</h5>
                                            <p className='d-flex align-items-center justify-content-center'>Born out of a shared vision of enterprise leaders Anand Naik and Pankit Desai, to tackle rising cyberthreats and compliance challenges of digitalization</p>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/quest.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>Our Quest</h5>
                                            <p className='d-flex align-items-center justify-content-center'>Simplify security by integrating people, processes, and technologies with cost-effective offerings to improve security  posture and grow fearlessly</p>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/road.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>The Journey So Far</h5>
                                            <p className='d-flex align-items-center justify-content-center'>Created AI-powered proprietary platform, products, and services that enhance visibility, protect against cyberattacks, and comply with regulations</p>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/intent.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>The Intent</h5>
                                            <p className='d-flex align-items-center justify-content-center'>Leverage AI and ML to provide robust cyberprotection with solutions that coexist with, complement, and consolidate IT investments, delivering better ROI</p>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/investment.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>The Latest Funding</h5>
                                            <p className='d-flex align-items-center justify-content-center'>Raised USD 8 million in 2023 in a Series A round led by Omidyar Network India, with participation from Narotam Sekhsaria Family Office and Alteria Capital</p>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/creds.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>The Credentials</h5>
                                            <p className='d-flex align-items-center justify-content-center'>CERT-In empaneled vendor, ISO 27001:2013 and SOC 2 certified, NASSCOM DeepTech company, ICBA ThinkTech,Tampa Bay Wave, and FIS Financial Accelerator Programs
                                            </p>

                                        </div>
                                    </div>
                                    <div className=''>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/proud.svg' alt='' /></div>
                                            <h5 className='d-flex align-items-center justify-content-center'>Key Mentions</h5>
                                            <p className='d-flex align-items-center justify-content-center'>Recognized by top global research & analyst firms, listed among the FT High Growth Asia Pacific Companies, ET India's Growth Champions, MSSP Alert’s Top 250</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/* Session 3 LEADERSHIP TEAM*/}
                <div className='seq-Story-body2' ref={leadershipRef} style={{ background: 'linear-gradient(157.92deg, #040c30 55.85%, #06065d 82.93%)' }}>
                    <div className='container pe-0 ps-0'>
                        <h3 className='d-flex align-items-center justify-content-center' data-aos="fade-up">Leadership Team</h3>
                        <p className='d-flex align-items-center justify-content-center' data-aos="fade-up">Our leadership team is a unique blend of experts with pedigree in management and technology, passionate about simplifying cybersecurity. We're always pushing the boundaries to stay ahead in the ever-changing and challenging world of cybersecurity.</p>
                        <div className='seq-Story-team' >
                            {window.innerWidth > 767 ?
                                <div className='row'>
                                    {TeamCard?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='col-lg-3  col-sm-4'>
                                                <div className='seq-Story-team-card'>
                                                    <div className='seq-Story-team-card-img'>
                                                        <img src={el.img} alt='' />
                                                        {el.lnUrl !== null ? <a href={el.lnUrl} target='_blank' rel="noreferrer" ><img className='seq-story-lnimg' src={'/images/linkedin-icon-blue.svg'} alt='' /></a> : null}
                                                    </div>
                                                    <h2 className='d-flex align-items-center justify-content-center'>{el.name}</h2>
                                                    <h6 className='d-flex align-items-center justify-content-center'>{el.position}</h6>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <div className='d-flex overflow-auto flex-nowrap'>
                                    {TeamCard?.map(el => {
                                        return (
                                            <div className=''>
                                                <div className='seq-Story-team-card'>
                                                    <div style={{ height: '264px' }}>
                                                        <img src={el.img} alt='' />
                                                        <a href={el.lnUrl} target='_blank' rel="noreferrer" ><img className='seq-story-lnimg' src={'/images/linkedin-icon-blue.svg'} alt='' /></a>
                                                    </div>
                                                    <h2 className='d-flex align-items-center justify-content-center'>{el.name}</h2>
                                                    <h6 className='d-flex align-items-center justify-content-center'>{el.position}</h6>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/* Session 4 INVESTORS */}
                <div className='seq-Story-body3' ref={investorRef} style={{ background: 'linear-gradient(41.44deg, #040c30 37.24%, #06065d 63.08%)' }}>
                    <div className=' container pe-0 ps-0'>
                        <h3 className='d-flex align-items-center justify-content-center' data-aos="fade-up">Our Investors</h3>
                        <p className='d-flex align-items-center justify-content-center' data-aos="fade-up">Our genuine intent of simplifying and democratizing cybersecurity solutions helped us win the trust of our investors.</p>
                        <div className='seq-Story-investor'>
                            {window.innerWidth > 767 ?
                                <div className='row'>
                                    {OurInvestors?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='col-lg-3 col-sm-4 col-3 '>
                                                <div className='d-flex align-items-center justify-content-center seq-Story-investor-card'><img src={el.img} alt='' /></div>
                                            </div>
                                        )
                                    })}

                                </div>
                                :
                                <div className='row'>
                                    {OurInvestors?.map(el => {
                                        return (
                                            <div className='col-6'>
                                                <div className='d-flex align-items-center justify-content-center seq-Story-investor-card'><img src={el.img} alt='' /></div>
                                            </div>
                                        )
                                    })}

                                </div>

                            }

                        </div>
                    </div>
                </div>
                {/* Session 5 INITIATIVES */}
                <div className='seq-Story-body4' style={{ background: 'linear-gradient(157.92deg, #040c30 55.85%, #06065d 82.93%)' }}>
                    <div className='container pe-0 ps-0'>
                        <h3 className='d-flex align-items-center justify-content-center' data-aos="fade-up">Initiatives With the Government, Industry Associations and Academia</h3>
                        <p className='d-flex align-items-center justify-content-center' data-aos="fade-up">Sequretek is proud to be associated with multiple government initiatives, both Indian and global. We actively collaborate with various industry bodies and academia to help strengthen the AI cybersecurity landscape for a safer tomorrow.</p>
                        <div className='seq-Story-associate'>
                            {window.innerWidth > 767 ?
                                <div className='row'>
                                    {Ourassociate?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='col-lg-3  col-sm-4 '>
                                                <div className='seq-Story-associate-card'>
                                                    <div className='d-flex align-items-center justify-content-center'><img src={el.img} alt='' /></div>
                                                    <p className='d-flex align-items-center justify-content-center'>{el.text}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div> :
                                <div className='d-flex overflow-auto flex-nowrap' style={{ gap: '20px' }}>
                                    {Ourassociate?.map(el => {
                                        return (
                                            <div className=''>
                                                <div className='seq-Story-associate-card'>
                                                    <div className='d-flex align-items-center justify-content-center'><img src={el.img} alt='' /></div>
                                                    <p className='d-flex align-items-center justify-content-center'>{el.text}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                            }

                        </div>
                    </div>
                </div>
                {/* Session 6 DIVERSITY */}
                <div className='seq-Story-body5' ref={diversityRef}>
                    <div className='container pe-0 ps-0'>
                        <div className='seq-Story-body5-hero' data-aos="fade-up">
                            <h3 className='d-flex align-items-center justify-content-center'>Diversity, Equity & Inclusion</h3>
                            <p className='d-flex align-items-center justify-content-center'>At Sequretek, diversity and inclusion is woven into our DNA, forming the foundation of our strength and innovation. We’re committed to creating an inclusive environment where everyone feels valued, respected, and empowered, with an equal chance to succeed. To us, diversity means embracing and celebrating differences in age, background, experience, abilities, and viewpoints.</p>
                        </div>
                        <div className='seq-Story-body5-body'>
                            <div className='seq-Story-body5-body1'>
                                {window.innerWidth > 767 ?
                                    <div className='row'>
                                        {DiversityCard?.map(el => {
                                            return (
                                                <div data-aos="fade-up" className='col-6'>
                                                    <div className='seq-Story-Diversity-card'>
                                                        <div className='d-flex align-items-center justify-content-center'><img src={el.img} alt='' /></div>
                                                        <h3 className='d-flex align-items-center justify-content-center'>{el.title}</h3>
                                                        <p className='d-flex align-items-center justify-content-center'>{el.text}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div> :
                                    <div className=''>
                                        {DiversityCard?.map(el => {
                                            return (
                                                <div data-aos="fade-up" className='seq-Story-Diversity-card'>
                                                    <div className='d-flex align-items-center justify-content-center'><img src={el.img} alt='' /></div>
                                                    <h3 className='d-flex align-items-center justify-content-center'>{el.title}</h3>
                                                    <p className='d-flex align-items-center justify-content-center'>{el.text}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                            <div className='seq-Story-body5-body2'>
                                <h4 className='d-flex align-items-center justify-content-center'>At Sequretek, embracing DEI is not just a policy but a core value that propels us toward innovation and excellence. </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Story;
