// ############################ Require packages here ############################
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Typical from 'react-typical';
import { Helmet } from 'react-helmet';
import Aos from 'aos';

// ############################ Require files here ###############################
import ScheduleForm from './scheduleForm';



const SequretekCard = [
    { id: 1, text: `In today's digitally connected world, businesses encounter a growing range of cyberthreats that are becoming more advanced and complex.` },
    { id: 2, text1: `Enterprises are now dealing with a multitude of devices and applications that generate various forms of security data, making it difficult to get clear visibility into this intricate threat landscape. Analyzing and correlating these massive amounts of data to identify genuine incidents is a tedious task. Timely incident response is paramount in mitigating potential cyberattacks.` },
]

const AwardsCard = [
    { id: 1, img: '/images/awards1.svg', text: `Featured in Avasant's Cybersecurity RadarView (2nd time in 2023, and 3rd time in 2024). ` },
    { id: 2, img: '/images/awards2.svg', text: `Winner of TiE50 Awards 2023 at Santa Clara` },
    { id: 3, img: '/images/awards3.svg', text: `Featured in Top 250 MSSPs across the globe, by MSSP Alert` },
    { id: 4, img: '/images/awards4.svg', text: `One of the High-Growth Asia-Pacific Companies 2024` },
    { id: 5, img: '/images/awards5.svg', text: `Member - ESC India, selected for ESC Incubator program for US regions` },
    { id: 6, img: '/images/awards6.svg', text: `Featured in CyberTech100 companies across the world` },
    { id: 7, img: '/images/awards7.svg', text: `Best Cybersecurity Products Company of the Year 2023, by Wealth & Finance International` },
    { id: 8, img: '/images/awards8.svg', text: `Winner: Defence Challenge floated by iDEX DIO under DISC 9 Category` },
    { id: 9, img: '/images/awards9.svg', text: `National e-Governance Award 2023, DARPG, GoI` },
    { id: 10, img: '/images/awards10.svg', text: `Winner: National Startup Award 2021, Startup India, DPIIT, GoI` },
    { id: 11, img: '/images/awards11.svg', text: `One of the India's Growth Champions 2023` },
    { id: 12, img: '/images/awards12.svg', text: `One of Chunauti 2.0 Challenge winners, MEITY and Software Technology Parks of India (STPI).` },
    { id: 13, img: '/images/awards13.svg', text: `Announced technology integration with Intel Corporation in the usage and integration of Accelerated ` },
    { id: 14, img: '/images/awards14.svg', text: `Sequretek was one of the 11 companies shortlisted for the ICBA ThinkTech Accelerator Program` },
    { id: 15, img: '/images/awards15.svg', text: `Shortlisted for the FIS Fintech Accelerator Program.and also announced as the Most Valuable Participant (MVP)` },
    { id: 16, img: '/images/awards16.svg', text: `Winner of the NASSCOM Emerge 50 Awards and the Data Security Council of India (DSCI)’s Security Product Company of the Year` },
    { id: 17, img: '/images/awards17.svg', text: `Winner of the State and the National Conclaves by ESC India and STPI` },
    { id: 18, img: '/images/awards18.svg', text: `Engaged with Tampa Bay Wave and was a part of their Accelerator program in the US` },
    { id: 19, img: '/images/awards19.svg', text: `Ingram Micro announced the addition of Sequretek to its Cloud portfolio in US, Canada and India regions` },
    { id: 20, img: '/images/awards20.svg', text: `Sequretek products are covered by Zero Trust Demo Forum in their maps and solution charts` },
]

const AccordianData = [
    { id: 1, img: '/images/shield-trust.svg', title: 'Effortless Security', subtitle: 'With Sequretek, security is simplified and effortless.', text: 'With Sequretek, security is simplified and effortless.  AI-powered threat detection, response, and remediation are harnessed, ensuring that your systems are safeguarded 24/7 against even the most sophisticated cyberthreats across attack surfaces. The state-of-the-art technology used is cloud-native, open, and modular, seamlessly adapting to your unique infrastructure. User-friendly and affordable solutions are tailored to meet your business needs, so you can focus confidently on your core operations. The efficient governance and high-performance solutions from Sequretek simplify your security journey.' },
    { id: 2, img: '/images/shieldcheck.svg', title: 'Empowering Protection', subtitle: 'With Sequretek, security is simplified and effortless.', text: `A dedication to empowering your organization with expert insights, aligned processes, and continuous threat exposure management solutions is the driving force for Sequretek. Regular assessments and recommended enhancements ensure that threats and vulnerabilities are always neutralized. Everything needed for end-to-end visibility, detection, response, and regulatory compliance is available on a platform with single-console visibility. You can experience security that coexists with, complements, and consolidates technologies at your pace. Sequretek's solutions, created with in-house AI/ML expertise and supported by research from Sequretek Labs, eliminate complexity allowing you to operate fearlessly.` },
    { id: 3, img: '/images/handshake.svg', title: 'Unwavering Commitment', subtitle: 'With Sequretek, security is simplified and effortless.', text: 'Experience unwavering security and exceptional value through cutting-edge processes and a dedicated team of Sequretek’s cybersecurity professionals who stand by your side through every challenge, without hiding behind contracts and loopholes. Should roadblocks arise during implementation, the security journey is charted together. In situations where your security skills or resources are limited, Sequretek functions as the bridge to ensure that you understand the implications and overcome the challenges. Assistance is provided with navigating changes in the security landscape, ensuring protection and compliance every step of the way. The proactive approach taken includes the sharing of regular global threat advisories, and the implementation of product enhancements that shield your organization from unforeseen threats. Sequretek stands by your side at every stage – before, during, and after any cyber incident – working tirelessly to protect and assist you. With Sequretek, you invest in a trustworthy partner committed to safeguarding your enterprise while driving business growth. ' },
]



const Whysequretek = () => {
    const navigate = useNavigate();
    const [active, setActive] = useState(1);
    const accordionRefs = useRef([]);
    const awardsRef = useRef(null);

    const location = useLocation();

    const setPage = (val) => {
        if (val === 'demo') {
            navigate('/schedule-a-demo')
        }
    }

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    useEffect((val) => {
        if (location.state?.accordionId) {
            setActive(location.state.accordionId);
            const ref = accordionRefs.current[location.state.accordionId];
            if (ref) {
                ref.scrollIntoView({ behavior: 'smooth' });
            }
        } else if (location.state?.scrollTo === 'awards') {
            awardsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location.state]);

    return (
        <div className='seq-Sequretek-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Why Sequretek - Secure Your Business with AI-Powered Cybersecurity</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Choose Sequretek for AI-powered cybersecurity solutions that protect your digital assets, ensure compliance, and foster business growth with confidence." />
                <meta name="keywords" content="Cyber security solutions, AI driven cyber security, Cloud based Cybersecurity" />
                <meta property="og:title" content="Why Sequretek - Secure Your Business with AI-Powered Cybersecurity" />
                <meta property="og:description" content="Choose Sequretek for AI-powered cybersecurity solutions that protect your digital assets, ensure compliance, and foster business growth with confidence." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-Sequretek-heading'>
                <div className='container pe-0 ps-0'>
                    <div className='seq-Sequretek-hero'>
                        <div className='seq-Sequretek-hero-text'>
                            <h3 className="d-flex align-items-center justify-content-center marquee-item">
                                <Typical
                                    steps={[
                                        'Effortless Security...',
                                        2000,
                                        'Empowering Protection...',
                                        2000,
                                        'Unwavering Commitment...',
                                        2000,
                                    ]}
                                    loop={Infinity}
                                    wrapper="span"
                                />
                            </h3>
                            <h5 className='d-flex align-items-center justify-content-center'>Sequretek’s cybersecurity experience, backed by cloud-based cybersecurity solutions with real-time threat detection and responses powered by AI, empowers you to take control and grow your business with confidence, all while keeping your digital assets secure and meeting regulatory compliance.</h5>
                            <div className='d-flex align-items-center justify-content-center' onClick={() => setPage('demo')}><button><strong>Schedule a demo</strong></button> </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='seq-Sequretek-body'>
                {/* section 2  GLOBE*/}
                <div className='container pe-0 ps-0'>
                    <div className='seq-Sequretek-body-line' >
                        {/* <hr className='seq-Sequretek-body-line' /> */}
                        <img src='/images/box.png' alt='' />
                    </div>
                    <div className='seq-Sequretek-body-globe'>
                        <div className='seq-Sequretek-body-globe-text overflow-auto'>
                            {SequretekCard?.map((el) => {
                                return (
                                    <div className='seq-Sequretek-body-globe-para'>
                                        <h5 className='d-flex align-items-center justify-content-center'>{el.text}</h5>
                                        <p className='d-flex align-items-center justify-content-center'>{el.text1}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {/* section 3 ACCORDION*/}
                <div className='container pe-0 ps-0'>
                    <div className='seq-Sequretek-body3'>
                        {window.innerWidth > 767 ?
                            <div class="accordion">
                                {AccordianData.map(el => {
                                    return (
                                        <div key={el.id} class="accordion-item seq-services-body3-card" ref={(element) => accordionRefs.current[el.id] = element}>
                                            <h2 onClick={() => setActive(el.id)} className={active === el.id ? 'accordion-header d-flex active' : 'accordion-header d-flex'}>
                                                <div className='d-flex'>
                                                    {active === el.id ?
                                                        <div className='accordopen'>
                                                            <div className='d-flex align-items-center'><img src={el.img} alt='' /></div>
                                                            <h3 className='d-flex align-items-center'>{el.title}</h3>
                                                            <div>
                                                                <div className='acc-border'></div>
                                                                {/* <h6>{el.subtitle}</h6> */}
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='d-flex seq-collapsed'>
                                                            <div className='d-flex align-items-center'><img src={el.img} alt='' /></div>&nbsp;
                                                            <h3 className='d-flex align-items-center'>{el.title}</h3>
                                                        </div>
                                                    }
                                                    <p className={active === el.id ? 'p-expanded' : 'p-collapsed'}>{el.text}</p>
                                                </div>
                                                {active === el.id ? null : <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeleven" aria-expanded="false" aria-controls="collapseeleven">
                                                </button>}
                                            </h2>
                                            <div id="collapseeleven" class="accordion-collapse collapse" aria-labelledby="headingeleven">
                                                <div class="accordion-body">
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div> :
                            <div class="accordion">
                                {AccordianData.map(el => {
                                    return (
                                        <div class="accordion-item seq-services-body3-card">
                                            <h2 id={el.id} onClick={() => setActive(el.id)} className={active === el.id ? 'accordion-header d-flex active' : 'accordion-header d-flex'}>
                                                <div className='d-flex'>
                                                    <div className='d-flex align-items-center'><img src='/images/lightbulb-on-blue.svg' alt='' /></div>&nbsp;&nbsp;&nbsp;
                                                    <h3 className='d-flex align-items-center'>{el.title}</h3>
                                                    {active === el.id ? null : <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeleven" aria-expanded="false" aria-controls="collapseeleven">
                                                    </button>}
                                                </div>
                                            </h2>
                                            {active === el.id ?
                                                <div class="accordion-body">
                                                    <div className='acc-border'></div>
                                                    <h6>{el.subtitle}</h6>
                                                    <p>{el.text}</p>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
                {/* section 4 AWARDS*/}
                <div className='seq-Sequretek-body4' ref={awardsRef} style={{ background: 'linear-gradient(221.44deg, #040C30 37.24%, #06065D 63.08%)' }}>
                    <div className='container pe-0 ps-0'>
                        <h3>Awards & Recognitions</h3>
                        {window.innerWidth > 767 ?
                            <div className='row me-0'>
                                {AwardsCard?.map(el => {
                                    return (
                                        <div className='col-3 pe-0 align-self-stretch' style={{ flexGrow: 'unset' }}>
                                            <div className='seq-Sequretek-body-award'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <img src={el.img} alt='' />
                                                </div>
                                                <p>{el.text}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div> :
                            <div className='d-flex overflow-auto' style={{ gap: '25px' }}>
                                {AwardsCard?.map(el => {
                                    return (
                                        <div>
                                            <div className='seq-Sequretek-body-award'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <img src={el.img} alt='' />
                                                </div>
                                                <p>{el.text}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
                {/* section 5 SCHEDULE FORM*/}
                <div className='seq-Sequretek-body5' style={{ background: 'linear-gradient(331.44deg, #040C30 37.24%, #06065D 63.08%)' }}>
                    <div className='seq-forms'>
                        <div className='container'>
                            <div className="insurance-partseven" data-aos="fade-up">
                                <ScheduleForm
                                    bg={'/images/bg-img22.svg'}
                                    mobilebg={'/images/bg-img22.svg'}
                                    page='why-sequretek'
                                    text={`We understand that every enterprise is unique. Our team of cybersecurity professionals bring years of experience in safeguarding businesses of all sizes, tailoring services keeping your enterprise's specific requirements in mind.`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whysequretek
