import { deleteApiCall, getApiCall, postApiCall } from "../../utils/requests";
import { GET_ALL_TICKETS, ADD_TICKET, DELETE_TICKET, GET_REPLIES, ADD_REPLY, CLOSE_TICKET } from "../Types/types";
import { returnErrors } from "./errorAction";


// This function was a get api call to get Ticket List in support page in Partner portal

export const getTicketList = (page, query, category, status) => async (dispatch) => {
    try {
        const response = await getApiCall(`ticket?size=10&page=${page}&search=${query}&type=${category ? category : ''}&status=${status ? status : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_ALL_TICKETS, payload: response.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a post api call to add Tickets in support page in Partner portal

export const addTicket = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`ticket`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Ticket  has been added', response.status);
            return dispatch({ type: ADD_TICKET, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a delete api call to delete Tickets in support page in Partner portal

export const deleteTicket = (id) => async (dispatch) => {
    let obj = {
        _id: id
    }
    try {
        const response = await deleteApiCall(`ticket`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Ticket has been deleted', response.status)
            return dispatch({ type: DELETE_TICKET, payload: id });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}


// This function was a get api call to get replay for the tickets in support page in Partner portal

export const getReplies = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`ticket/reply?_id=${id}`);
        if (response.status === 200) {
            return dispatch({ type: GET_REPLIES, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a post api call to send replays in support page in Partner portal

export const replySent = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`ticket/reply`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Reply has been sent', response.status);
            return dispatch({ type: ADD_REPLY, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was a post api call to Close a Ticket in support page in Partner portal

export const ticketClosed = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`ticket/close`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Ticket has been closed', response.status);
            return dispatch({ type: CLOSE_TICKET, payload: obj?._id });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}