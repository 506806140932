// ############################ Require packages here ############################
import React, { lazy, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import { useDispatch } from 'react-redux';

// ############################ Require files here ###############################

import './product.scss';
import ScheduleForm from '../scheduleForm';
// import TestimonialSlider from '../testimonialSlider';
import RelatedResources from '../relatedResources';
import { getOneResource } from '../../../Redux/ActionCreators/resourcesAction';
const TestimonialSlider = lazy(() => import("../testimonialSlider"));


const PerceptIdentitycards = [
    { image: '/images/layer-plus.svg', title: 'Consolidate Technology Landscape', description: 'Coexist with, complement, and consolidate IT landscape. Ensure comprehensive security without needing to rip and replace.' },
    { image: '/images/ai-chip.svg', title: 'AI-Powered Threat Detection & Response', description: 'Precise threat detection and continuous threat hunting capability. Integrated incident response with CSIR and inbuilt SOAR remediation.' },
    { image: '/images/Lock-check.svg', title: 'End-to-End Security Ownership', description: 'Round-the-clock SOC monitoring, detection, response, and remediation aid complete risk management with Percept XDR & NG SIEM.' },
    { image: '/images/eye-square.svg', title: 'Complete Visibility of the Attack Surface', description: `Extensive enterprise coverage and product dashboards enable visibility across attack surfaces on a single console through secure access.` },
    { image: '/images/sensor-alert.svg', title: 'SOAR-Based Incident Response', description: 'Built-in MITRE ATT&CK evaluation map. It reduces manual intervention through orchestration and automated response.' },
    { image: '/images/chart-histogram.svg', title: 'Enterprise Security Reports', description: 'Actionable intelligence, insightful analytics, bespoke dashboards, playbook access, and designer reports for real-time updates.' },
    { image: '/images/globe-pointer.svg', title: 'Real-Time Global Threat Intelligence', description: 'Percept CTEM data lake ingests feed in real-time from GTI enrichment sources, threat & malware content, and Sequretek Global Threat Detection Network.' },
    { image: '/images/dashboard-monitor.svg', title: 'Integrated Ticketing and SLA Management', description: 'In-built ticketing and SLA management tool that has the capability to integrate fully with your pre-existing ticketing tool.' },
    { image: '/images/apps-add.svg', title: 'Seamless Onboarding and Migration', description: 'Convenient package to get started quickly without a fuss. Onboarding and migration activities need minimal intervention from your IT team.' },
    { image: '/images/memo-circle-check.svg', title: 'Hassle-Free Compliance Reports', description: 'Adhere to OCC, SEC, HIPAA, PCI-DSS, RBI, SEBI, and others. Out-of-the-box reports for compliance and proactive security analyses.' },
    { image: '/images/badge-dollar.svg', title: 'Reduced Total Cost of Ownership', description: 'Accurate detection and automated response reduce your incidents, lowering your TCO, resolution time, and cyber insurance costs.' },
    { image: '/images/problem-solving.svg', title: 'Proactive Detection', description: 'Trained with 2000+ test scenarios covering ITDR, NTDR, attacks and threats. Self-learns to identify new test scenarios and anomalies.' },
]


const Graphitems = [
    { image: '/images/shieldplus.svg', title: 'Deep Learning AI-Powered Detection Engine' },
    { image: '/images/shieldplus.svg', title: 'Security Orchestration and Automated Response (SOAR)' },
    { image: '/images/shieldplus.svg', title: 'Agentless Enterprise Logs & Sensor Telemetry Aggregation' },
    { image: '/images/shieldplus.svg', title: 'Custom and Out-of-the-Box Connectors' },
    { image: '/images/shieldplus.svg', title: '24/7 SOC Monitoring and Real-time Threat Hunting ' },
    { image: '/images/shieldplus.svg', title: 'Identity Threat Detection & Response (ITDR) ' },
    { image: '/images/shieldplus.svg', title: 'Network Threat Detection & Response (NTDR) ' },
    { image: '/images/shieldplus.svg', title: 'Network Behavior Anomaly Detection (NBAD) ' },
    { image: '/images/shieldplus.svg', title: 'User & Entity Behavior Analytics' },
    { image: '/images/shieldplus.svg', title: 'Proactive & Predictive Security Modeling' },
    { image: '/images/shieldplus.svg', title: 'MITRE ATT&CK Framework Mapping' },
    { image: '/images/shieldplus.svg', title: 'Case & Incident Management Tools' },
    { image: '/images/shieldplus.svg', title: 'Big Data-Based Security Analytics' },
    { image: '/images/shieldplus.svg', title: 'Custom Dashboards and Reports' },
    { image: '/images/shieldplus.svg', title: 'Threat & Malware Intel and Honeypot Data Ingestion' },

]

const PerceptXdr = () => {

    const active = 'perceptXdr';
    const [secNav, setSecNav] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener('scroll', scrollSecondNav);
        return () => {
            window.removeEventListener('scroll', scrollSecondNav);
        }
    }, [])

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    const scrollSecondNav = () => {
        const secondaryHeader = document.querySelector('.secondary-header');
        const mainHeaderHeight = 72;
        const contentTop = document.querySelector('.seq-Percept-about')?.offsetTop;

        if (window.scrollY > contentTop - mainHeaderHeight) {
            secondaryHeader.classList.add('sticky');
        } else {
            secondaryHeader.classList.remove('sticky');
        }
    }

    const toggleNav = () => {
        setSecNav(!secNav);
    }

    const navigate = useNavigate();

    const setActive = (val) => {
        window.scrollTo(0, 0);
        if (val === 'PerceptIdentity') {
            navigate('/products/identity-and-access-governance')
        } else if (val === 'perceptplatform') {
            navigate('/sequretek-percept-platform')
        } else if (val === 'perceptEdr') {
            navigate('/products/endpoint-detection-and-response')
        } else if (val === 'perceptManager') {
            navigate('/products/compliance-manager')
        } else if (val === 'products') {
            window.innerWidth > 767 ? window.scrollTo(0, 6000) : window.scrollTo(0, 4800);
        } else if (val === 'datasheet') {
            navigate('/resources-hub', { state: { type: 'datasheet' } })
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }

    const downloadDatasheet = async () => {
        const res = await dispatch(getOneResource(['66e2ed2e5d02763332e0f9ff']))
        if (res?.type === 'GET_ONE_RESOURCES') {
            navigate(`/resources/datasheet/${encodeURIComponent(res.payload?.title)}`, { state: { data: res?.payload } })
        }
    }

    return (
        <div className='seq-Percept-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek: AI-powered XDR for comprehensive security</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Enhance your security posture with Percept XDR & NG SIEM. Our AI-powered platform detects and responds to threats across your IT environment, reducing alert fatigue and improving your overall security. Experience comprehensive threat management made easy." />
                <meta name="keywords" content="Security Management, Threat detection, Managed detection and response, Malware Analysis" />
                <meta property="og:title" content="Sequretek: AI-powered XDR for comprehensive security" />
                <meta property="og:description" content="Enhance your security posture with Percept XDR & NG SIEM. Our AI-powered platform detects and responds to threats across your IT environment, reducing alert fatigue and improving your overall security. Experience comprehensive threat management made easy." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-Percept-heading' style={{ backgroundImage: `linear-gradient(41.44deg, rgba(4, 12, 48, 0.93) 37.24%, rgba(6, 6, 93, 0.93) 63.08%),url('/images/bg-img25.png')` }}>
                <div className='container pe-0 ps-0'>
                    <div className='seq-Percept-hero d-flex justify-content-center'>
                        <div className='seq-Percept-hero-identity' data-aos="fade-up">
                            <h3 className='pb-0 seq-Percept-hero-identity-head d-flex justify-content-center'>Simplify Security Management</h3>
                            <p className='seq-Percept-hero-identity-text'>AI-powered SaaS for comprehensive threat detection and response across all your IT assets</p>
                            {window.innerWidth > 767 ?
                                <div className='d-flex  justify-content-center'>
                                    <button onClick={() => setActive('products')}><strong>Get Percept XDR & NG SIEM</strong></button>
                                    <button className='seq-Percept-hero-identity-btn' onClick={() => downloadDatasheet()}><strong>Download Datasheet</strong></button>
                                </div> :
                                <div>
                                    <button style={{ fontSize: '15px' }} onClick={() => setActive('products')}><strong>Get Percept XDR & NG SIEM</strong></button>
                                    <button className='seq-Percept-hero-identity-btn' onClick={() => downloadDatasheet()}><strong>Download Datasheet</strong></button>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
            {/* section 2 SECOND NAVBAR*/}
            <div className='seq-Percept-tab-pages'>
                {window.innerWidth > 767 ?
                    <div>
                        <div className='secondary-header contact-tabs d-flex justify-content-center align-items-center'>
                            <div onClick={() => setActive('perceptplatform')} className={active === 'perceptplatform' ? 'seq-tab active' : 'seq-tab'}>
                                About Percept Platform
                            </div>
                            <div onClick={() => setActive('perceptXdr')} className={active === 'perceptXdr' ? 'seq-tab active' : 'seq-tab'}>
                                Percept XDR & NG SIEM
                            </div>
                            <div onClick={() => setActive('PerceptIdentity')} className={active === 'PerceptIdentity' ? 'seq-tab active' : 'seq-tab'}>
                                Percept Identity
                            </div>
                            <div onClick={() => setActive('perceptEdr')} className={active === 'perceptEdr' ? 'seq-tab active' : 'seq-tab'}>
                                Percept EDR
                            </div>
                            <div onClick={() => setActive('perceptManager')} className={active === 'perceptManager' ? 'seq-tab active' : 'seq-tab'}>
                                Percept Compliance Manager
                            </div>
                        </div>
                    </div>
                    :
                    <div className='secondary-header'>
                        <div className='seq-sec-nav-mobile d-flex justify-content-evenly'>
                            <div className="seq-active d-flex align-items-center">
                                <h4>Percept XDR and NGSIEM</h4>
                            </div>
                            <div className="dropdown d-flex" onClick={toggleNav} >
                                <img src={secNav === true ? '/images/up-arrow.svg' : '/images/down-arrow.svg'} alt="Menu" loading="lazy" />
                            </div>
                        </div>
                        <nav className={`menu ${secNav ? 'open' : ''}`}>
                            <ul className='seq-drop-items'>
                                <li onClick={() => setActive('perceptplatform')}>About Percept Platform</li>
                                <li onClick={() => setActive('PerceptIdentity')}>Percept Identity</li>
                                <li onClick={() => setActive('perceptEdr')}>Percept EDR</li>
                                <li onClick={() => setActive('perceptManager')}>Percept Compliance Manager</li>
                            </ul>
                        </nav>
                    </div>
                }
                {/* section 3 PLAYER */}
                <div className='seq-Percept-about'>
                    {/* PLAYER */}
                    <div className='container pe-0 ps-0' data-aos="fade-up">
                        <div className='d-flex justify-content-center seq-Percept-player' >
                            <ReactPlayer url='https://youtu.be/TS6uWzYUdn8?si=WuzzWhv-MHA9io0y' className='seq-Percept-about-player' width='1128px' height='595px' />
                        </div>
                    </div>
                    {/* Img Session */}
                    <div className='seq-benifits-img'>
                        <div className='container pe-0 ps-0'>
                            {window.innerWidth > 767 ?
                                <div>
                                    <div className='d-flex justify-content-center'><h4>PERCEPT XDR & NG SIEM </h4></div>
                                    {/* <div className='d-flex justify-content-center'><h5>End-to-End Enterprise Security and Visibility</h5></div> */}
                                    <img className='seq-percept-main-img' src='/images/PXDR Diag.svg' alt='' loading="lazy" />
                                </div>

                                :
                                <img className='seq-percept-main-img' src='/images/bg-img80.svg' data-aos="fade-up" alt='' loading="lazy" />}
                        </div>
                    </div>
                    {/* section 3-2 BENEFITS & FEATURES */}
                    <div className='seq-Percept-benefits'>
                        {/* section 4-1 BENEFITS */}
                        <div className='container pe-0 ps-0'>
                            {window.innerWidth > 767 ?
                                <div>
                                    <p style={{ color: '#2076CD' }}>BENEFITS</p>
                                    <div className='row' style={{ margin: '30px 0' }}>
                                        <div className='col-12 col-md-5 col-sm-12 px-0'>
                                            <h3>Alert fatigued? Filter out the noise and deal with real incidents</h3>
                                        </div>
                                        <div className='col-12 col-md-7 col-sm-12 px-0'>
                                            <p>Alert fatigue. Missing critical incidents. Combating complex attacks. Lack of skilled resources. If this is your SOC challenge, we get you. Solve this and enhance your security posture with Percept XDR & NG SIEM: reliable threat management that works effortlessly.</p>
                                        </div>
                                    </div>
                                </div> :
                                <div>
                                    <div className='row' style={{ width: '100%' }}>
                                        <div className='col-12 col-md-6'>
                                            <p style={{ color: '#2076CD' }}>BENEFITS</p>
                                            <h3>Identity is the New Security Perimeter. Have you Taken Control of it Yet?</h3>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <p>Identity risks due to lack of access visibility can be a real bugbear, specially in multi-cloud environments. Percept Identity lets you take control - across uneven user lifecycles, access requests from multiple sources, privilege users or compliance measures. Identity management need not be complex. Let’s help you fix it.</p>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div>
                                {
                                    window.innerWidth > 767 ?
                                        <div className='row me-0 seq-benefits-cards'>
                                            {
                                                PerceptIdentitycards?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-Percept-card seq-percept-card-4'>
                                                            <div className='seq-card'>
                                                                <img src={el.image} alt='' loading="lazy" />
                                                                <h4>{el.title}</h4>
                                                                <p>{el.description}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className='d-flex overflow-auto flex-nowrap'>
                                            {
                                                PerceptIdentitycards?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='col-md-3' style={{ gap: '24px', paddingTop: '35px' }}>
                                                            <div className='seq-card'>
                                                                <img src={el.image} alt='' style={{ width: '48px', height: '48px', marginBottom: '12px' }} loading="lazy" />
                                                                <h4>{el.title}</h4>
                                                                <p>{el.description}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                        {/* section 4-2 FEATURES */}
                        <div className='container pe-0 ps-0'>
                            <div className='seq-Percept-graph'>
                                <p className='d-flex justify-content-center align-items-center' data-aos="fade-up">FEATURES</p>
                                <h3 className='d-flex justify-content-center align-items-center' data-aos="fade-up">Harnessing the Power of AI: Accurate Detection & Automated Response</h3>
                                <img className='seq-Percept-graph-img2' src='/images/screenshort-xdr.svg' data-aos="fade-up" alt='' loading="lazy" />
                                <div>
                                </div>
                                {
                                    window.innerWidth > 767 ?
                                        <div className='row d-flex justify-content-center align-items-center'>
                                            {
                                                Graphitems?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='seq-Percept-graph-extra col-md-2' style={{ height: '160px' }} >
                                                            <div>
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    <img src={el.image} alt='' className='' loading="lazy" />
                                                                </div>
                                                                <h4>{el.title}</h4>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className='d-flex overflow-auto flex-nowrap '>
                                            {
                                                Graphitems?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='d-flex justify-content-center align-items-center'>
                                                            <div className='seq-Percept-graph-extra' style={{ gap: '10px' }}>
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    <img src={el.image} alt='' className='' style={{ width: '24px', height: '24px', marginBottom: '12px' }} loading="lazy" />
                                                                </div>
                                                                <h4>{el.title}</h4>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 4 TESTIMONIAL SLIDER */}
                <div className="seq-quotes">
                    <div className='gredient'> </div>
                    <TestimonialSlider
                        page={'product-NG-SIEM'}
                    />
                </div>
                {/* section 5 SCHEDULE DEMO FORM */}
                <div className='seq-forms'>
                    <div className='container'>
                        <div className="insurance-partseven" data-aos="fade-up">
                            {window.innerWidth > 767 ? <h2 className='insurance-partseven-heading'>Interested in learning how Percept XDR & NG SIEM will integrate with your environment? Contact us to find out.</h2> : null}
                            <ScheduleForm
                                bg={'/images/bg-img22.svg'}
                                mobilebg={'/images/light-umbrella.svg'}
                                page='percept-xdr'
                                text='Without a strong cyber defense, your enterprise could be the next target. Fortify your network against intrusions with real-time and comprehensive threat detection and response with the help of the Percept XDR & NG SIEM.'
                            />
                        </div>
                    </div>
                </div>
                {/* section 6 RELATED RESOURCES */}
                <div className='resources'>
                    <div className='container ps-0'>
                        <div className='row '>
                            <h4>Related resources</h4>
                            <div className='col-md-12'>
                                <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap overflow-auto ms-2'}>
                                    <RelatedResources
                                        page='perceptxdr'
                                        type=''
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setActive('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setActive('resources')}>Resource Center</button></div>}
            </div>
        </div>
    )
}

export default PerceptXdr;
