import React, { useState } from 'react'

export default function PreviewImage({ file }) {

    const [preview, setPreview] = useState(null);
    if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setPreview(reader.result);
        }
    }

    return (
        <div>
            {(file && preview) ?
                <img src={preview} width={150} height={80} alt='' /> :
                null}
        </div>
    )
}
