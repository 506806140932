// ############################ Require packages here ############################
import React, { lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// ############################ Require files here ###############################

import './style.scss';
import ScheduleForm from '../scheduleForm';
// import TestimonialSlider from '../testimonialSlider';
import RelatedResources from '../relatedResources';
import { getOneResource } from '../../../Redux/ActionCreators/resourcesAction';
const TestimonialSlider = lazy(() => import("../testimonialSlider"));


const risks = [
    { image: '/images/disk.svg', title: 'Legacy Technologies', description: 'Government agencies built on outdated systems are no match for modern cyberthreats. Upgrading these systems is complex and time-consuming.' },
    { image: '/images/magnifying-glass-eye.svg', title: 'Lack of Single-Pane Visibility ', description: 'IT infrastructure assets having security risks must be viewed in real-time on a single dashboard for mitigation.' },
    { image: '/images/hourglass.svg', title: 'Reactive Approach', description: 'Many government agencies are late to detect a cybersecurity attack and lack automated response, making remediation more complex and longer.' },
    { image: '/images/customer-care.svg', title: 'Buying Process', description: `Government RFP processes, prioritizing cost over technology, often leave agencies vulnerable while awaiting lengthy procurement cycles.` },
    { image: '/images/usd-circle.svg', title: 'Limited Budgets', description: 'Budget constraints compromise the scope and scale of cybersecurity measures. Underfunded cybersecurity programs lead to increased vulnerabilities.' },
    { image: '/images/flag.svg', title: 'State-Sponsored Attacks', description: 'Government systems are increasingly targeted by state-sponsored attacks that impact national security, disrupt state services, and steal classified information.' },
    { image: '/images/information.svg', title: 'High Citizen Touchpoints', description: `Citizens’ sensitive data such as social security numbers, Aadhar, and healthcare records, must have robust data protection measures at all times.` },
    { image: '/images/queue.svg', title: 'Unaware Workforce', description: `Government agencies with overly curious, under-trained, or unaware employees are vulnerable to exploitation by cyber adversaries.` },
]

const helpList = [{ image: '/images/pulse.svg', title: 'Enhanced Security Monitoring', description: `Sequretek's Percept Continuous Threat Exposure Management (CTEM) continuously monitors IT infrastructure for threats and mitigates potential breaches.` },
{ image: '/images/alert.svg', title: 'Threat Detection and Response', description: 'Advanced cyber risk management with our EDR, XDR & NG SIEM, SOAR products and services help monitor, detect, and respond to cyberattacks in real-time. ' },
{ image: '/images/user-key.svg', title: 'User Access Management', description: 'Our identity products and services prevent data breaches by continuously monitoring user activity and limiting access to authorized personnel only.' },
{ image: '/images/shield.svg', title: 'Conformance to Compliance Mandates', description: `Sequretek's CTEM platform’s governance and tailored compliance reports help meet security regulations by FFIEC, SEC, RBI and other bodies.` },
{ image: '/images/house-lock.svg', title: 'Vulnerability Management', description: 'Sequretek strengthens defenses, fixes vulnerabilities, and ensures system stability with regular audits, patching, and critical infrastructure update deployment.' },
{ image: '/images/customer-care-white.svg', title: 'Managed Security Services', description: `Sequretek MSS offers 24/7 onsite/remote services, including audit and advisory, risk and governance, incident response, server, network and perimeter security.` },
]


export default function Government() {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setPage = (val) => {
        window.scrollTo(0, 0);
        if (val === 'contact') {
            window.scrollTo(0, 3800);
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }


    const downloadFactsheet = async () => {
        const res = await dispatch(getOneResource(['66dacf3c1e5c52d1577f9158']))
        if (res?.type === 'GET_ONE_RESOURCES') {
            navigate(`/resources/factsheet/${encodeURIComponent(res.payload?.title)}`, { state: { data: res?.payload } })
        }
    }


    return (
        <div className='seq-content-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sequretek | Secure Government Data with Strategic Cybersecurity Solutions</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="" />
                <meta name="keywords" content="Tackle cybersecurity challenges with Sequretek's reliable solutions. Safeguard sensitive government data, mitigate risks from advanced cyberattacks, and secure home-grown applications and government data centers." />
                <meta property="og:title" content="Sequretek | Secure Government Data with Strategic Cybersecurity Solutions" />
                <meta property="og:description" content="Tackle cybersecurity challenges with Sequretek's reliable solutions. Safeguard sensitive government data, mitigate risks from advanced cyberattacks, and secure home-grown applications and government data centers." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-content-heading' style={{ backgroundImage: `linear-gradient(4.09deg, rgba(6, 6, 93, 0.7) 4%, rgba(4, 12, 48, 0.9) 18.36%, rgba(4, 12, 48, 0.9) 55.89%, rgba(6, 6, 93, 0.7) 96.66%),url('/images/bg-img12.png')` }}>
                <div className='seq-description tab-menu'>
                    <h5 data-aos="fade-up">Industries{' > '}Government </h5>
                    <h2 data-aos="fade-up">Strengthening Government Cybersecurity:
                        <p> Innovative, Reliable Solutions and Strategic Approaches</p>
                    </h2>
                    <div data-aos="fade-up" className='text-center mt-3'>
                        <button onClick={() => downloadFactsheet()}>Download Factsheet</button>
                    </div>
                </div>
            </div>
            {/* section 2 INFO */}
            <div className='seq-content-1'>
                <div className='container pe-0'>
                    <div className='row me-0'>
                        <div className='col-md-5 col-sm-12 col-12'>
                            <h4>
                                Government Data Protection: <br />Addressing Cybersecurity Challenges

                            </h4>
                        </div>
                        <div className='col-md-6 col-sm-12 offset-lg-1 col-12 seq-info-text'>
                            <p>Cybersecurity for the government continues to be a significant concern due to the rising volume, frequency, and sophistication of cyberattacks. Government organizations often store and process sensitive information of the citizens. This data is collected through multiple channels–online, offline, direct, and indirect–and must be protected. Their IT infrastructure–home-grown applications built on open source technologies and hosted in government data centers–are prone to next-gen attacks.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 3 CHALLENGES */}
            <div className='seq-challenge'>
                <p>Risks Radar</p>
                <div className='container pe-0'>
                    {
                        window.innerWidth > 767 ?
                            <div className='row me-0 seq-card-items-row'>
                                {
                                    risks?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-card-items' style={{ flexGrow: 'unset' }}>
                                                <div className='seq-card' style={{ height: 325 }}>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <p>{el.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className='d-flex overflow-auto flex-nowrap'>
                                {
                                    risks?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='seq-card'>
                                                <img src={el.image} alt='' loading="lazy" />
                                                <h4>{el.title}</h4>
                                                <p>{el.description}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
            {/* section 4 HOW SEQURETEK HELPS */}
            <div className='seq-how-to-helps'>
                <div className='container'>
                    <div className='seq-help-bg ' style={{ backgroundImage: `url('/images/bg-img55.svg')` }}>
                        <div className='row '>
                            <div data-aos="fade-up" className=' col-md-5 col-12 seq-help-left seq-help-sm'>
                                <p>How Sequretek Helps</p>
                                <h3>Sequretek’s Comprehensive Cybersecurity Solutions for the Government
                                </h3>
                                <button onClick={() => setPage('contact')}>Get started</button>
                            </div>
                            {window.innerWidth > 767 ?
                                <div className='col-md-4 offset-md-1 seq-ind-qimg'>
                                    <img src='/images/q-icon1.svg' alt='' height={300} loading="lazy" />
                                </div> :
                                null
                            }
                        </div>
                    </div>
                    <div className='seq-help-list'>
                        <div className='row'>
                            {
                                helpList?.map(el => {
                                    return (
                                        <div data-aos="fade-up" className='col-md-4 col-sm-12'>
                                            {window.innerWidth > 767 ?
                                                <div className='seq-card'>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div> :
                                                <div className='seq-card'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex justify-content-center align-items-center'><img src={el.image} alt='' loading="lazy" /></div>
                                                        <h4>{el.title}</h4>
                                                    </div>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* section 5 TESTIMONIAL SLIDER*/}
            <div className="seq-quotes">
                <div className='gredient'> </div>
                <TestimonialSlider
                    page={'government'}
                />
            </div>
            {/* section 6 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container ps-4 pe-4 '>
                    <div className="insurance-partseven" data-aos="fade-up">
                        <ScheduleForm
                            text={'The security of your data and infrastructure is more critical than ever. Secure your sensitive data with Sequretek’s enhanced security monitoring and threat detection and ensure that your critical information remains confidential and protected.'}
                            bg={'/images/bg-img56.svg'}
                            mobilebg={'/images/bg-img57.svg'}
                            page='banking'
                        />
                    </div>
                </div>
            </div>
            {/* section 7 RELATED RESOURCES*/}
            <div className='seq-resources'>
                <div className='container'>
                    <h4>Related resources</h4>
                    <div className='row ms-0 me-0'>
                        <div className='col-md-12 ps-0'>
                            <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto'}>
                                <RelatedResources
                                    page='government'
                                    type=''
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Resource Center</button></div>}
        </div>
    )
}
