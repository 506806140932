// ############################ Require packages here ############################
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

// ############################ Require files here ###############################

import './resources.scss';
import ScheduleForm from '../scheduleForm';
import PdfReader from '../../../utils/pdfReader';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';
import FactsheetModal from '../Industries/factsheetModal';

export default function Factsheet() {
    const [details, setDetails] = useState(null);
    const [isOpenThreat, setIsOpenThreat] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const { relatedResources } = useSelector(state => state.resources);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (location?.state?.data) {
            setDetails(location?.state?.data)
        }
    }, [location?.state?.data])

    useEffect(() => {
        window.scrollTo(0, 0);
        const firstRender = async () => {
            if (!location?.state?.data) {
                const resourceData = relatedResources?.filter(el => el.title === decodeURIComponent(window.location.pathname.split('/')[3]))[0];
                if (resourceData) {
                    await setDetails(resourceData);
                }
            }
        }
        firstRender();
    }, [relatedResources])

    return (
        <div className='seq-reports-view'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Factsheets</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Check out visually engaging factsheets that simplify information and present cybersecurity data effectively." />
                <meta property="og:title" content="Sequretek | Factsheet" />
                <meta property="og:description" content="Check out visually engaging factsheets that simplify information and present cybersecurity data effectively." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'factsheet' } })}>{' > '} Factsheets </span>
                            <span>{' > '} {details?.title}</span></li>
                    </ul>
                </div>
                <div className='seq-report-view'>
                    {/* <h3>{details?.title}</h3> */}
                    <div className='d-flex justify-content-between align-items-start cursor-pointer' style={{ width: window.innerWidth > 767 ? '85%' : '98%' }}>
                        <h3 style={{ width: '90%' }} >{details?.title}</h3>
                        <img src='/images/download-icon-white.svg' onClick={() => setIsOpenThreat(true)} width={32} height={32} alt='' />
                    </div>
                    <div className='content-middle mt-2'>
                        <PdfReader url={details?.url} />
                    </div>
                </div>
                <ShareUrl details={details} />
                {/* section 2 */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-2'}>
                                    <RelatedResources
                                        page='resources'
                                        type='factsheet'
                                        title={details?.title}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* section 2 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
            <FactsheetModal
                isOpen={isOpenThreat}
                setIsOpen={() => setIsOpenThreat(false)}
                type='factsheet'
                pdfUrl={details?.url}
                data={details}
            />
        </div>
    )
}
