const resourceIds = [
    { pagename: 'home', ids: ['66d74083b5414584b2c333a7', '66e289e697387a4bdaf751aa', '66d7438eb75fd76cbc0ab2e9', '66e28a2845ea48ff47db21cf'] },
    { pagename: 'platform', ids: ['66d74083b5414584b2c333a7', '66e28a8d45ea48ff47db21f1', '66e289e697387a4bdaf751aa', '66d744b577d151c0cc823481'] },
    { pagename: 'perceptxdr', ids: ['66e2ed2e5d02763332e0f9ff', '66d74083b5414584b2c333a7', '66d7438eb75fd76cbc0ab2e9', '66d744b577d151c0cc823481'] },
    { pagename: 'perceptidentity', ids: ['66e2ed93ab9b5744e2b16d92', '66eaa8622e8b4b3b8e52ea65', '66d74083b5414584b2c333a7', '66e2ed2e5d02763332e0f9ff'] },
    { pagename: 'perceptedr', ids: ['66e2ec29c514ec4d6801f9bf', '66d74083b5414584b2c333a7', '66d7438eb75fd76cbc0ab2e9', '66e2ed2e5d02763332e0f9ff'] },
    { pagename: 'perceptcompliancemanager', ids: ['66d74083b5414584b2c333a7', '66e2ed2e5d02763332e0f9ff', '66e289e697387a4bdaf751aa', '66d7438eb75fd76cbc0ab2e9'] },
    { pagename: 'partners', ids: ['66d74083b5414584b2c333a7', '66e2ed2e5d02763332e0f9ff', '66d744b577d151c0cc823481', '66d7438eb75fd76cbc0ab2e9'] },
    { pagename: 'smb', ids: ['66d74083b5414584b2c333a7', '66e2ed2e5d02763332e0f9ff', '66d744b577d151c0cc823481', '66d74521006fc33038b8434a'] },
    { pagename: 'financial-services', ids: ['66d744b577d151c0cc823481', '66e289e697387a4bdaf751aa', '66e2865045ea48ff47db20cc', '66e2ed2e5d02763332e0f9ff'] },
    { pagename: 'insurance', ids: ['66d744b577d151c0cc823481', '66e289e697387a4bdaf751aa', '66e286cf97387a4bdaf750e5', '66e2ed93ab9b5744e2b16d92'] },
    { pagename: 'manufacturing', ids: ['66d744b577d151c0cc823481', '66e28a8d45ea48ff47db21f1', '66e28a8d45ea48ff47db21f1', '66d74083b5414584b2c333a7'] },
    { pagename: 'healthcare', ids: ['66d74083b5414584b2c333a7', '66e28a2845ea48ff47db21cf', '66e2868f45ea48ff47db20d3', '66e2ed2e5d02763332e0f9ff'] },
    { pagename: 'pharma', ids: ['66d745e877d151c0cc8234a1', '66d74083b5414584b2c333a7', '66e2873597387a4bdaf750f3', '66e2ed2e5d02763332e0f9ff'] },
    { pagename: 'retails', ids: ['66d745e877d151c0cc8234a1', '66e28abf45ea48ff47db220c', '66d74083b5414584b2c333a7', '66e2ed2e5d02763332e0f9ff'] },
    { pagename: 'services', ids: ['66d745e877d151c0cc8234a1', '66d74083b5414584b2c333a7', '66e289e697387a4bdaf751aa', '66e2ed2e5d02763332e0f9ff'] },
    { pagename: 'government', ids: ['66e2893db533ed74f8265a18', '66d74083b5414584b2c333a7', '66d745e877d151c0cc8234a1', '66e2ed2e5d02763332e0f9ff'] },

]

// This function is used for to show the related resources with the use of resources id's with different pages has different resources id's

export const getId = (page) => {
    if (page) {
        return resourceIds?.filter(el => el.pagename === page)[0]?.ids
    }
}