// ############################ Require packages here ############################
import React, { Fragment, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FileUploader } from 'react-drag-drop-files';


// ############################ Require files here ###############################

import MultiSelect from '../../../utils/customSelect';
import CreatableSelects from '../../../utils/creatableSelect';
import DialCodes from '../../../utils/dialCodes.json';
import { Spinner } from 'react-bootstrap';

export default function Addlead({ setIsAdd, isEdit, leadDetails, leadStatuses, allPartners, products, companies, countries, handleSubmit }) {
    const fileTypes = ["JPEG", "PNG", "PDF"];
    const [err, setErr] = useState('');

    const validationSchema = Yup.object({
        firstname: Yup.string()
            .trim('Remove Extra spaces')
            .strict(true)
            .matches(/^[a-zA-Z0-9\s]+$/, 'First Name should only contain alphabets, numbers, and spaces')
            .required('First Name is required'),
        lastname: Yup.string()
            .trim('Remove Extra spaces')
            .strict(true)
            .matches(/^[a-zA-Z0-9\s]+$/, 'Last Name should only contain alphabets, numbers, and spaces')
            .required('Last Name is required'),
        email: Yup.string()
            .required('Email is required')
            .matches(/^([\w.-]+)@(?!hotmail\.com|gmail\.com|googlemail\.com|yahoo\.com|gmx\.com|ymail\.com|outlook\.com|bluewin\.ch|protonmail\.com|t-online\.de|web\.de|online\.de|aol\.com|live\.com)([a-zA-Z\d-]+\.)+([a-zA-Z]{2,63})$/, 'Enter Only Business email address'),
        dial_code: Yup.string().required('Dial code is required'),
        mobile: Yup.string()
            .matches(/^[0-9]{7,14}$/, 'Phone number is not valid')
            .required('Phone number is required')
            .test('combinedPhone', 'Phone number is not valid with dial code', function (value) {
                const { dial_code } = this.parent;
                return value && dial_code ? (value).length === 10 : false;
            }),
        company: Yup.array()
            .required('Select or create at least one option')
            .max(1, 'Select or create at least one company'),
        products: Yup.array()
            .required('Select or create at least one option')
            .min(1, 'Select or create at least one product'),
        partner: Yup.string().required('Partner is required'),
        country: Yup.string().required('Country is required'),
        city: Yup.string().required('City is required'),
        status: Yup.string().required('Status is required'),
        attachment: Yup.mixed()
            .notRequired()
            .test('fileSize', 'File size too large', (value) => {
                if (typeof value !== 'object') {
                    return true
                }
                else if (value) {
                    return value.size <= 2097152; // 2 MB
                }
                return true;
            })
    });

    const onSizeError = (err) => {
        setErr(err);
    }

    return (
        <Fragment>
            {/* ADDLEAD HEADING */}
            <div className='add-screen' >
                <Formik initialValues={leadDetails} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ setFieldValue, values, isSubmitting }) => (
                        <Form className="users_form">
                            <div>
                                <h2 className="d-flex align-items-center justify-content-between">
                                    {isEdit ? 'Edit Lead' : ' Add Lead'}
                                    <div className='leads-btn'>
                                        <button onClick={() => setIsAdd(false)}>Cancel </button>
                                        <button type='submit' style={{ width: isSubmitting ? 102 : "" }} disabled={isSubmitting}  >{isSubmitting ? <Spinner animation="border" variant="light" /> : 'Save Lead'}</button>
                                    </div>
                                </h2>
                            </div>
                            <div className='row'>
                                {((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? null :
                                    <div className='col-md-6'>
                                        <div className='form-group mt-2'>
                                            <label>Partner Name</label>
                                            <div className="has-search">
                                                <Field
                                                    name="partner"
                                                    id="singleSelectCustom3"
                                                    placeholder="Select Partner"
                                                    isMulti={false}
                                                    isCreatable={false}
                                                    component={MultiSelect}
                                                    options={allPartners}
                                                />
                                                <ErrorMessage name="partner" component="div" className="error-message" />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {/* LEADS FORM */}
                            <div className='row mt-2'>
                                <div className='col-md-6'>
                                    <div className='form-group mt-2'>
                                        <label>First Name</label>
                                        <div className="has-search">
                                            <span className="form-control-feedback">
                                                <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                            </span>
                                            <Field
                                                type="text"
                                                name="firstname"
                                                placeholder="Enter First Name"
                                                className='form-control'
                                            />
                                            <ErrorMessage name="firstname" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mt-2'>
                                        <label>Last Name</label>
                                        <div className="has-search">
                                            <span className="form-control-feedback">
                                                <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                            </span>
                                            <Field
                                                type="text"
                                                name="lastname"
                                                placeholder="Enter Last Name"
                                                className='form-control'
                                            />
                                            <ErrorMessage name="lastname" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mt-2'>
                                        <label>Email Id</label>
                                        <div className="has-search">
                                            <span className="form-control-feedback">
                                                <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                            </span>
                                            <Field
                                                type="email"
                                                name="email"
                                                placeholder="Enter Email"
                                                className='form-control'
                                            />
                                            <ErrorMessage name="email" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mt-2'>
                                        <label>Phone Number</label>
                                        <div className=" d-flex">
                                            <Field
                                                className='select-cur'
                                                name="dial_code"
                                                placeholder=""
                                                isMulti={false}
                                                isCreatable={false}
                                                component={MultiSelect}
                                                options={DialCodes?.map(el => [{ label: el.code, value: el.code }][0])}
                                            />
                                            <Field
                                                type="text"
                                                name="mobile"
                                                placeholder="Enter Phone number"
                                                className='form-control ms-1'
                                            />
                                        </div>
                                        <ErrorMessage name="mobile" component="div" className="error-message" />
                                        <ErrorMessage name="dial_code" component="div" className="error-message" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mt-2'>
                                        <label>Company Name</label>
                                        <div className="has-search">
                                            <CreatableSelects
                                                name="company"
                                                options={companies}
                                                isMulti
                                                placeholder="Select Company"
                                            />
                                            <ErrorMessage name="company" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mt-2'>
                                        <label>Company Website</label>
                                        <div className="has-search">
                                            <span className="form-control-feedback">
                                                <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                            </span>
                                            <Field
                                                type="text"
                                                name="website"
                                                placeholder="Enter Website"
                                                className='form-control'
                                            />
                                            <ErrorMessage name="website" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mt-2'>
                                        <label>Country</label>
                                        <div className="has-search">
                                            <Field
                                                name="country"
                                                id="singleSelectCustom"
                                                placeholder="Select Country"
                                                isMulti={false}
                                                isCreatable={false}
                                                component={MultiSelect}
                                                options={countries}
                                            />
                                            <ErrorMessage name="country" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mt-2'>
                                        <label>City</label>
                                        <div className="has-search">
                                            <span className="form-control-feedback">
                                                <img src="/images/user-icon.svg" alt='' width={15} height={15} />
                                            </span>
                                            <Field
                                                type="text"
                                                name="city"
                                                placeholder="Enter city"
                                                className='form-control'
                                            />
                                            <ErrorMessage name="city" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>
                                {((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? null :
                                    <div className='col-md-6'>
                                        <div className='form-group mt-2'>
                                            <label>Lead Status</label>
                                            <div className="has-search">
                                                <Field
                                                    name="status"
                                                    id="singleSelectCustom1"
                                                    placeholder="Select Lead Status"
                                                    isMulti={false}
                                                    isCreatable={false}
                                                    component={MultiSelect}
                                                    options={leadStatuses}
                                                />
                                                <ErrorMessage name="status" component="div" className="error-message" />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='col-md-12 col-12'>
                                    <div className='form-group mt-2'>
                                        <label>Products</label>
                                        {((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ?
                                            <div className="has-search">
                                                <Field
                                                    name="products"
                                                    id="singleSelectCustom"
                                                    placeholder="Select muliple products"
                                                    isMulti={true}
                                                    component={MultiSelect}
                                                    options={products}
                                                />
                                                <ErrorMessage name="products" component="div" className="error-message" />
                                            </div>
                                            :
                                            <div className="has-search">
                                                <CreatableSelects
                                                    name="products"
                                                    options={products}
                                                    isMulti
                                                    placeholder="Select muliple products"

                                                />
                                                <ErrorMessage name="products" component="div" className="error-message" />
                                            </div>}
                                    </div>
                                </div>
                                <div className='col-md-12 col-12'>
                                    <div className='form-group mt-2'>
                                        <label>Comment</label>
                                        <Field
                                            as="textarea"
                                            type='text'
                                            name="comment"
                                            placeholder="Enter Comment"
                                            className='form-control'
                                            rows='4'
                                        />
                                        <ErrorMessage name="comment" component="div" className="error-message" />

                                    </div>
                                </div>
                                <div className="col-12 col-md-12 mt-2">
                                    <div className='form-group'>
                                        <div className="form-check form-check-inline">
                                            <Field
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkbox"
                                                name='doc_shared'
                                            // value={false}

                                            />
                                            <label className="form-check-label" htmlFor="checkbox">
                                                I wish to share RFP/Proposal or Bid related document
                                            </label>
                                        </div>
                                    </div>
                                    {values?.doc_shared ?
                                        <Fragment>
                                            In case of any lead / deal related documents, please email them to <strong> partnersupport@sequretek.com</strong>
                                        </Fragment>
                                        : null}
                                </div>
                                {/* <div className='col-md-12 col-12'>
                                    <div className='form-group mt-2'>
                                        <label>Upload Attachment</label><br />
                                        {isEdit && <a href={values.attachment} target='_blank' without rel="noreferrer">{values?.attachment}</a>}
                                        <br /><br />
                                        <div>
                                            <FileUploader
                                                multiple={false}
                                                name="attachment"
                                                types={fileTypes}
                                                label={'Upload a file or Drag and Drop file'}
                                                maxSize={2}
                                                handleChange={(file) => {
                                                    setFieldValue("attachment", file);
                                                    setErr('')
                                                }}
                                                onSizeError={onSizeError}
                                            />
                                            {values?.attachment?.name ? <p>{values?.attachment ? `File name: ${values?.attachment?.name}` : "No file uploaded yet"}</p> : null}
                                            <span style={{ color: 'red' }}>{err}</span>
                                            <ErrorMessage name="attachment" component="div" className="error-message" />
                                        </div>

                                    </div>
                                </div> */}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div >
        </Fragment>
    )
}

