// ############################ Require packages here ############################
import React from 'react';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################


export default function LegalPolicy() {

    return (
        <div className='seq-cookie-policy'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Legal Policy</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Read Sequretek's Legal Policy for a comprehensive overview of our terms of service, user rights, and adherence to legal requirements." />
                <meta property="og:title" content="Sequretek | Legal Policy" />
                <meta property="og:description" content="Read Sequretek's Legal Policy for a comprehensive overview of our terms of service, user rights, and adherence to legal requirements." />
            </Helmet>

            {/* section 1 BANNER */}
            <div className='cookie-policy-header'>
                <h3>Legal Policy</h3>
                {window.innerWidth > 767 ?
                    <p className='legalpolicy-text'>Cookie policy should clearly explain to users how your website <br />uses cookies, which are small data files stored on their <br />browser.  Here's a breakdown of the key things to include:</p> :
                    <p>Cookie policy should clearly explain to users how your website uses cookies, which are small data files stored on their browser.  Here's a breakdown of the key things to include:</p>
                }
            </div>
            {/* section 2 DESCRIPTION */}
            <div className='cookie-description'>
                <div className='container'>
                    <div>
                        <h4>What are Cookies?</h4>
                        <p>Cookies, in the context of web browsing, are small pieces of data that websites store on your device. They act like memories for the website, keeping track of things you've done there in the past.
                            <ul>
                                <li> On Visiting this Website: When you land on this website, our web server might send your browser a cookie.</li>
                                <li> Your Browser Stores the Cookie: Your web browser will typically accept the cookie and store it on your device. This storage can be on your computer, phone, or tablet, depending on what you're using to browse the web.</li>
                                <li> Cookies Remember Information: The cookie itself contains information related to your visit, like what pages you looked at.</li>
                                <li>Cookies are Sent Back on Return Visits: When you revisit this website, your browser will send the cookie back to the web server. This way, the website can recognise you and potentially tailor your experience based on the information stored in the cookie.</li>
                            </ul>
                        </p>
                    </div>
                    <div className='mt-4 mb-2'>
                        <h4>
                            What Cookies Does This Site Use?
                        </h4>
                        <p>
                            <ul>
                                <li> Essential Cookies: These cookies are necessary for the website's basic functionality.</li>
                                <li>Performance Cookies: These cookies collect data on how users interact with the site, such as which pages they visit and for how long. This helps improve the website's performance and user experience.</li>
                                <li>Functionality Cookies: These cookies remember user preferences, like language settings or font size, to personalize their experience.</li>
                                <li>Targeting or Advertising Cookies: These cookies track users across different websites to deliver targeted advertising.</li>
                            </ul>
                        </p>
                    </div>
                    <div className='mt-4 mb-2'>
                        <h4>
                            Why Does Our Site Use Cookies?
                        </h4>
                        <p>Explain the purposes for using each type of cookie. This will depend on the types of cookies that are set in our website. Specify about how the information collected benefits both Sequretek and the user (e.g., improve user experience, personalize content, or deliver relevant ads).
                        </p>
                        <p>User Control and Opting Out<br />
                            This section contains information to users about their options for managing cookies. This could include:<br />
                            ·  A link to instructions on how to disable cookies through their browser settings.<br />
                            ·  How to opt-out of specific types of cookies (e.g., advertising cookies).<br />
                            ·  If our website uses a cookie consent banner, explain how users' choices regarding cookies will be reflected.
                        </p>
                    </div>
                    <div className='mt-4 mb-2'>
                        <h4>
                            Data Sharing
                        </h4>
                        <p>In case we share any data collected through cookies with third parties (e.g., analytics providers or advertisers), this needs to be disclosed clearly. Briefly explain who they are and for what purposes the data is used.
                        </p>
                    </div>
                    <div className='mt-4 mb-2'>
                        <h4>
                            Updates to the Cookie Policy
                        </h4>
                        <p>We need to state how often we will update the cookie policy and under what circumstances (e.g., if you start using new cookies).
                        </p>
                    </div>
                    <div className='mt-4 mb-2'>
                        <h4>
                            Contact Information
                        </h4>
                        <p>This section includes email address of the contact person for users to contact Sequretek with any questions about the cookie policy.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
