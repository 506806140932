// ############################ Require packages here ############################
import React, { lazy, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import Aos from 'aos';
// ############################ Require files here ###############################

import { getRelatedResources } from '../../Redux/ActionCreators/resourcesAction';
// import TestimonialSlider from './testimonialSlider';
import RelatedResources from './relatedResources';
const TestimonialSlider = lazy(() => import("./testimonialSlider"));



const ServicesCards = [{ id: 1, image: '/images/handshake.svg', title: 'Rewarding Partnerships', text: 'The entire SPP program focuses on growing revenue streams and putting more money in your pocket by increasing your margins.' },
{ id: 2, image: '/images/usd-circle.svg', title: 'Collaborative Selling', text: 'The Sequretek compensation models encourage our sales teams to jointly develop customer offerings through our partners.' },
{ id: 3, image: '/images/growth-chart.svg', title: 'Market Development', text: 'Investments in programs, tools, people, and MDF broaden market coverage to generate demand and leads jointly with partners.' },
{ id: 4, image: '/images/arrow-right-circle.svg', title: 'Sequretek Partner Portal', text: 'The SPP is a dynamic, secure hub for partners to access Percept solutions and resources, register leads & deals, and more.' },
{ id: 5, image: '/images/badge.svg', title: 'Training and Certification', text: 'Learning tools and training boost technical skills to sell, demo, implement and support Sequretek solutions successfully.' },
{ id: 6, image: '/images/calendar-clock.svg', title: 'Service Provider Partner Business Manager (PBM)', text: 'Each eligible partner is assigned a PBM to advocate their needs within Sequretek, serving as a central point of contact.' },
{ id: 7, image: '/images/chart-up.svg', title: 'Joint Business Planning and Quarterly Business Review', text: 'Your PBM can assist in developing the annual business plan to maximize your revenue potential and participate in the reviews.' },
{ id: 8, image: '/images/license.svg', title: 'Not-for-Resale (NFR) Licenses', text: 'Partners can use NFR licenses to test Sequretek products and create centers of excellence in their environment for client demos.' },
{ id: 9, image: '/images/customer-service.svg', title: 'Support', text: 'Sequretek provides 24/7/365 multichannel support to its customers and partners through email, web, social media, and calls.' },

]

const PartnerCard = [
    { id: 1, img: '/images/partner1.svg', },
    { id: 2, img: '/images/partner2.svg', },
    { id: 3, img: '/images/partner3.svg', },
    { id: 4, img: '/images/partner4.svg', },
    { id: 5, img: '/images/partner5.svg', },
    { id: 6, img: '/images/partner6.svg', },
    { id: 7, img: '/images/partner7.svg', },
    { id: 8, img: '/images/partner8.svg', },
    { id: 9, img: '/images/partner9.svg', },
    { id: 10, img: '/images/partner10.svg', },
    { id: 11, img: '/images/partner11.svg', },
    { id: 12, img: '/images/partner12.svg', },
    { id: 13, img: '/images/partner13.svg', },
    { id: 14, img: '/images/partner14.svg', },
    { id: 15, img: '/images/partner15.svg', },
    { id: 16, img: '/images/partner16.svg', },
    { id: 17, img: '/images/partner17.svg', },
    { id: 18, img: '/images/partner18.svg', },
    { id: 19, img: '/images/partner19.svg', },

]



const Partners = () => {
    const location = useLocation();
    const [active, setActive] = useState('overview');
    const [mspActive, setMspActive] = useState('');
    const [secNav, setSecNav] = useState(false);
    const overviewRef = useRef(null);
    const banefitRef = useRef(null);

    const msspRef = useRef(null);
    const mspRef = useRef(null);
    const otherRef = useRef(null);
    const currnetRef = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getRelatedResources("", ""))
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', scrollSecondNav);
        return () => {
            window.removeEventListener('scroll', scrollSecondNav);
        }
    }, [])

    const scrollSecondNav = () => {
        const secondaryHeader = document.querySelector('.secondary-header');
        const mainHeaderHeight = 72;
        const contentTop = document.querySelector('.seq-Partners-body2')?.offsetTop;

        if (window.scrollY > contentTop - mainHeaderHeight) {
            secondaryHeader.classList.add('sticky');
        } else {
            secondaryHeader.classList.remove('sticky');
        }
    }

    const toggleNav = () => {
        setSecNav(!secNav)
    }

    useEffect(() => {
        selectActive(location?.state?.active)
    }, [location?.state?.active])

    const navigate = useNavigate();


    const selectActive = (val) => {
        setSecNav(false);
        if (val === 'overview') {
            setActive(val);
            const yOffset = window.innerWidth > 767 ? -150 : -450;
            const y = overviewRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        else if (val === 'benifits') {
            setActive(val);
            const yOffset = window.innerWidth > 767 ? -150 : -400;
            const y = banefitRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        else if (val === 'mssp') {
            setActive(val);
            const yOffset = window.innerWidth > 767 ? -150 : -500;
            const y = msspRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        else if (val === 'msp') {
            setActive(val);
            const yOffset = window.innerWidth > 767 ? -150 : -450;
            const y = mspRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        else if (val === 'other') {
            setActive(val);
            const yOffset = window.innerWidth > 767 ? -150 : -450;
            const y = otherRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        else if (val === 'current') {
            setActive(val);
            const yOffset = window.innerWidth > 767 ? -150 : -320;
            const y = currnetRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        } else if (val === 'registration') {
            navigate('/partner-registration')
            window.scrollTo(0, 0);
        } else if (val === 'contact') {
            navigate('/contactus')
            window.scrollTo(0, 0);
        } else if (val === 'resources') {
            navigate('/resources-hub')
            window.scrollTo(0, 0);
        }
    }

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: false,
        prevArrow: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 0,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 0,
                }
            },
        ]
    };

    const handleClick = (type) => {
        setMspActive(type);
    }

    return (
        <div className='seq-Partners-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Join Sequretek's Partner Program - Boost Your IT Services</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Partner with us to enhance your security portfolio and grow your business. Join now to access exclusive benefits!" />
                <meta name="keywords" content="Managed Security Service Providers, MSP portal" />
                <meta property="og:title" content="Join Sequretek's Partner Program - Boost Your IT Services" />
                <meta property="og:description" content="Partner with us to enhance your security portfolio and grow your business. Join now to access exclusive benefits!" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-Partners-heading' style={{ backgroundImage: `url('/images/bg-img42.svg')` }}>
                <div className='container pe-0 ps-0'>
                    <div className='seq-Partners-hero d-flex justify-content-center' data-aos="fade-up">
                        <div>
                            <h5 className='d-flex justify-content-center align-items-center'>PARTNERSHIPS</h5>
                            <h3 className='d-flex justify-content-center align-items-center'>Empower Protection for Your Customers by Simplifying Security</h3>
                            {window.innerWidth > 767 ?
                                <div className='d-flex justify-content-center align-items-center'><button onClick={() => selectActive('registration')}>Become a Sequretek Partner&nbsp;&nbsp;&nbsp;<img src='/images/arrow-wight.svg' alt='' /></button></div>
                                :
                                <div className='d-flex justify-content-center align-items-center'><button onClick={() => selectActive('registration')}>Become a partner&nbsp;&nbsp;&nbsp;<img src='/images/arrow-wight.svg' alt='' /></button></div>
                            }
                            <p className='d-flex justify-content-center align-items-center'>Register at the Sequretek Partner Portal</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='seq-Partners-body'>
                {/* section 2  SECOND NAVBAR*/}
                <div className='seq-Partners-body1'>
                    {window.innerWidth > 767 ?
                        <div>
                            <div className='secondary-header contact-tabs d-flex justify-content-center align-items-center'>
                                <div onClick={() => selectActive('overview')} className={active === 'overview' ? 'seq-tab active' : 'seq-tab'}>
                                    Program Overview
                                </div>
                                <div onClick={() => selectActive('benifits')} className={active === 'benifits' ? 'seq-tab active' : 'seq-tab'}>
                                    Program Benefits
                                </div>
                                <div onClick={() => selectActive('mssp')} className={active === 'mssp' ? 'seq-tab active' : 'seq-tab'}>
                                    MSSP
                                </div>
                                <div onClick={() => selectActive('msp')} className={active === 'msp' ? 'seq-tab active' : 'seq-tab'}>
                                    MSP
                                </div>
                                <div onClick={() => selectActive('other')} className={active === 'other' ? 'seq-tab active' : 'seq-tab'}>
                                    Other Partners
                                </div>
                                <div onClick={() => selectActive('current')} className={active === 'current' ? 'seq-tab active' : 'seq-tab'}>
                                    Current Partners
                                </div>
                            </div>
                        </div>
                        :
                        <div className='secondary-header'>
                            <div className='seq-sec-nav-mobile d-flex justify-content-evenly'>
                                <div className="seq-active d-flex align-items-center">
                                    <h4>{active === 'overview' ? 'Program Overview' : active === 'benifits' ? 'Program Benefits' : active === 'mssp' ? 'MSSP' : active === 'msp' ? 'MSP' : active === 'other' ? 'Other Partners' : 'Current Partners'}</h4>
                                </div>
                                <div className="dropdown d-flex" onClick={toggleNav} >
                                    <img src={secNav === true ? '/images/up-arrow.svg' : '/images/down-arrow.svg'} alt="Menu" />
                                </div>
                            </div>
                            <nav className={`menu ${secNav ? 'open' : ''}`}>
                                <ul className='seq-drop-items'>
                                    <li onClick={() => selectActive('overview')}> Program Overview</li>
                                    <li onClick={() => selectActive('benifits')}>Program Benefits</li>
                                    <li onClick={() => selectActive('mssp')}>MSSP</li>
                                    <li onClick={() => selectActive('msp')}>MSP</li>
                                    <li onClick={() => selectActive('other')}>Other Partners</li>
                                    <li onClick={() => selectActive('current')}>Current Partners</li>
                                </ul>
                            </nav>
                        </div>
                    }
                </div>
                {/* section 3 INFO */}
                <div className='seq-Partners-body2'>
                    <div className='container pe-0 ps-0'>
                        <p>Sequretek recognizes that the Service Provider marketplace is evolving rapidly to keep pace with secure digital transformation strategies adopted by customers; partners are aligning their respective strategies to expand their business to leverage this new opportunity, profitably.</p>
                    </div>
                </div>
                {/* section 4 PARTNER CARD*/}
                <div className='seq-Partners-body3' ref={overviewRef}>
                    <div className='container pe-0 ps-0'>
                        <h3 className='d-flex justify-content-center align-items-center' data-aos="fade-up">Sequretek Partner Program</h3>
                        <h5 className='d-flex justify-content-center align-items-center' data-aos="fade-up">The Sequretek Partner Program (SPP) is a comprehensive program enabling partners to provide proven, premier cloud-based security SaaS solutions. </h5>
                        <div className='seq-Partners-body3-card'>
                            {window.innerWidth > 767 ?
                                <div className="slider-container">
                                    <Slider {...settings}>
                                        <div className='seq-Story-carousel-card' data-aos="fade-up">
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/partner-img1.svg' alt='' /></div>
                                            <h4>Improve your Profitability</h4>
                                            <p>Better cash flows, improve business performance with customers, and realize mutual profitability with our AI-powered platform and products.</p>
                                        </div>
                                        <div className='seq-Story-carousel-card' data-aos="fade-up">
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/partner-img2.svg' alt='' /></div>
                                            <h4>Build Customer Loyalty</h4>
                                            <p>Become a trusted advisor for our Percept SaaS offerings. Enable migration from capex to opex for your customers’ software needs at their pace.</p>
                                        </div>
                                        <div className='seq-Story-carousel-card' data-aos="fade-up">
                                            <div className='d-flex align-items-center justify-content-center'><img src='images/partner-img3.svg' alt='' /></div>
                                            <h4>Expert Insights</h4>
                                            <p>Sequretek Labs’ detection engineering facilitates deep insights on all attacks to detect, respond, and mitigate your customers’ cyber risks.</p>
                                        </div>
                                    </Slider>
                                </div>
                                :
                                <div className='d-flex overflow-auto flex-nowrap' style={{ gap: '20px' }}>
                                    <div className=''>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/partner-img1.svg' alt='' /></div>
                                            <h4>Improve your Profitability</h4>
                                            <p>Better cash flows, improve business performance with customers, and realize mutual profitability with our AI-powered platform and products.</p>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/partner-img1.svg' alt='' /></div>
                                            <h4>Build Customer Loyalty</h4>
                                            <p>Become a trusted advisor for our Percept SaaS offerings. Enable migration from capex to opex for your customers’ software needs at their pace.</p>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div className='seq-Story-carousel-card'>
                                            <div className='d-flex align-items-center justify-content-center'><img src='/images/partner-img1.svg' alt='' /></div>
                                            <h4>Expert Insights</h4>
                                            <p>Sequretek Labs’ detection engineering facilitates deep insights on all attacks to detect, respond, and mitigate your customers’ cyber risks.</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/* section 5 BECOME PARTNER*/}
                <div className='seq-Partners-body4' ref={banefitRef}>
                    <div className='container pe-0 ps-0'>
                        <div className='seq-Partners-body2' style={{ backgroundImage: `url('/images/bg-img40.svg')` }}>
                            <div className='seq-Partners-body2-explore'>
                                <div className='d-flex'>
                                    <div className='seq-Partners-body2-explore-text' data-aos="fade-up">
                                        <h5>Why become a Partner?</h5>
                                        <h3>Sequretek’s Partner Progam is comprehensive, rewarding, and inclusive, with simplified and flexible licensing options, which helps you grow your business.</h3>
                                        {window.innerWidth > 767 ? <div className='d-flex'><button onClick={() => selectActive('registration')}>Become a Sequretek Partner&nbsp;&nbsp;&nbsp;<img src='/images/arrow-wight.svg' alt='' /></button></div> : <div className='d-flex justify-content-start align-items-center'><button>Sequretek partner&nbsp;&nbsp;&nbsp;&nbsp;<img src='/images/arrow-wight.svg' alt='' /></button></div>}
                                        <p>Register at the Sequretek Partner Portal</p>
                                    </div>
                                    {window.innerWidth > 767 ?
                                        <img className='seq-q-img' src='/images/q-icon1.svg' alt='' style={{ width: '294px', height: '298px' }} />
                                        : null}
                                </div>
                            </div>
                        </div>
                        {window.innerWidth > 767 ?
                            <div className=' pe-0 ps-0'>
                                <div className='seq-Partners-body2-card'>
                                    <div className='row'>
                                        {ServicesCards?.map(el => {
                                            return (
                                                <div data-aos="fade-up" className='col-4 col-sm-12 d-flex align-items-top justify-content-center seq-Partners-body2-cards'>
                                                    <div>
                                                        <div className='d-flex'>
                                                            <img src={el.image} alt='' />&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <h4 className='d-flex justify-content-center align-items-center'>{el.title}</h4>
                                                        </div>
                                                        <p>{el.text}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div> :
                            <div>
                                <div class="accordion seq-Partners-body2-card" id="accordionExample">
                                    <div class="accordion-item seq-Partners-body2-item">
                                        <h2 class="accordion-header" id="headingfive">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="true" aria-controls="collapsefive">
                                                <img src='/images/handshake.svg' alt='' />Rewarding Partnerships
                                            </button>
                                        </h2>
                                        <div id="collapsefive" class="accordion-collapse collapse show" aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="accordion-item ">
                                                    <div id="" class="accordion-collapse collapse show" aria-labelledby="" data-bs-parent="#accordionExample">
                                                        <div class="">
                                                            <div className=''>
                                                                <p>The entire SPP program focuses on growing revenue streams and putting more money in your pocket by increasing your margins.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item seq-Partners-body2-item">
                                        <h2 class="accordion-header" id="headingsix">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="true" aria-controls="collapsesix">
                                                <img src='/images/usd-circle.svg' alt='' />Collaborative Selling
                                            </button>
                                        </h2>
                                        <div id="collapsesix" class="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="accordion-item ">
                                                    <div id="" class="accordion-collapse collapse show" aria-labelledby="" data-bs-parent="#accordionExample">
                                                        <div class="">
                                                            <div className=''>
                                                                <p>The Sequretek compensation models encourage our sales teams to jointly develop customer offerings through our partners.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item seq-Partners-body2-item">
                                        <h2 class="accordion-header" id="headingseven">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="true" aria-controls="collapseseven">
                                                <img src='/images/growth-chart.svg' alt='' />Market development
                                            </button>
                                        </h2>
                                        <div id="collapseseven" class="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="accordion-item ">
                                                    <div id="" class="accordion-collapse collapse show" aria-labelledby="" data-bs-parent="#accordionExample">
                                                        <div class="">
                                                            <div className=''>
                                                                <p>Investments in programs, tools, people, and MDF broaden market coverage to generate demand and leads jointly with partners.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item seq-Partners-body2-item">
                                        <h2 class="accordion-header" id="headingeight">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="true" aria-controls="collapseeight">
                                                <img src='/images/arrow-right-circle.svg' alt='' />Sequretek Partner Portal
                                            </button>
                                        </h2>
                                        <div id="collapseeight" class="accordion-collapse collapse" aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="accordion-item ">
                                                    <div id="" class="accordion-collapse collapse show" aria-labelledby="" data-bs-parent="#accordionExample">
                                                        <div class="">
                                                            <div className=''>
                                                                <p>The SPP is a dynamic, secure hub for partners to access Percept solutions and resources, register leads & deals, and more.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item seq-Partners-body2-item">
                                        <h2 class="accordion-header" id="headingnine">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="true" aria-controls="collapsenine">
                                                <img src='/images/badge.svg' alt='' />Training and certification
                                            </button>
                                        </h2>
                                        <div id="collapsenine" class="accordion-collapse collapse" aria-labelledby="headingnine" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="accordion-item ">
                                                    <div id="" class="accordion-collapse collapse show" aria-labelledby="" data-bs-parent="#accordionExample">
                                                        <div class="">
                                                            <div className=''>
                                                                <p>Learning tools and training boost technical skills to sell, demo, implement and support Sequretek solutions successfully.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item seq-Partners-body2-item">
                                        <h2 class="accordion-header" id="headingten">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseten" aria-expanded="true" aria-controls="collapseten">
                                                <img src='/images/calendar-clock.svg' alt='' />Service Provider Partner Business Manager (PBM)
                                            </button>
                                        </h2>
                                        <div id="collapseten" class="accordion-collapse collapse" aria-labelledby="headingten" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="accordion-item ">
                                                    <div id="" class="accordion-collapse collapse show" aria-labelledby="" data-bs-parent="#accordionExample">
                                                        <div class="">
                                                            <div className=''>
                                                                <p>Each eligible partner is assigned a PBM to advocate their needs within Sequretek, serving as a central point of contact.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item seq-Partners-body2-item">
                                        <h2 class="accordion-header" id="headingeleven">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeleven" aria-expanded="true" aria-controls="collapseeleven">
                                                <img src='/images/chart-up.svg' alt='' />Joint Business Planning and Quarterly Business Review
                                            </button>
                                        </h2>
                                        <div id="collapseeleven" class="accordion-collapse collapse" aria-labelledby="headingeleven" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="accordion-item ">
                                                    <div id="" class="accordion-collapse collapse show" aria-labelledby="" data-bs-parent="#accordionExample">
                                                        <div class="">
                                                            <div className=''>
                                                                <p>Your PBM can assist in developing the annual business plan to maximize your revenue potential and participate in the reviews.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item seq-Partners-body2-item">
                                        <h2 class="accordion-header" id="headingtwelve">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwelve" aria-expanded="true" aria-controls="collapsetwelve">
                                                <img src='/images/license.svg' alt='' />Not-for-Resale Licenses (NFR) Licenses
                                            </button>
                                        </h2>
                                        <div id="collapsetwelve" class="accordion-collapse collapse" aria-labelledby="headingtwelve" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="accordion-item ">
                                                    <div id="" class="accordion-collapse collapse show" aria-labelledby="" data-bs-parent="#accordionExample">
                                                        <div class="">
                                                            <div className=''>
                                                                <p>Partners can use NFR licenses to test Sequretek products and create centers of excellence in their environment for client demos.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item seq-Partners-body2-item">
                                        <h2 class="accordion-header" id="headingthirteen">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethirteen" aria-expanded="true" aria-controls="collapsethirteen">
                                                <img src='/images/customer-service.svg' alt='' />Support
                                            </button>
                                        </h2>
                                        <div id="collapsethirteen" class="accordion-collapse collapse" aria-labelledby="headingthirteen" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="accordion-item ">
                                                    <div id="" class="accordion-collapse collapse show" aria-labelledby="" data-bs-parent="#accordionExample">
                                                        <div class="">
                                                            <div className=''>
                                                                <p>Sequretek provides 24/7/365 multichannel support to its customers and partners through email, web, social media, and calls.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {/* section 6 MSSP PARTNER*/}
                <div className='seq-Partners-body5' ref={msspRef} style={{ background: 'linear-gradient(172.92deg, #040C30 55.85%, #06065D 82.93%)' }}>
                    <div className='container pe-0 ps-0'>
                        <div className='seq-Partners-body5-head' data-aos="fade-up">
                            <h6 className='d-flex justify-content-center align-items-center'>MSSP</h6>
                            <h5 className='d-flex justify-content-center align-items-center'>Help Your Customers Secure Their Business with The Right Solutions.</h5>
                            <p className='d-flex justify-content-center align-items-center'>Explore the comprehensive range of Sequretek cloud-based security SaaS offerings and the collaborative model of partner operations designed to maximize your margins. Become an MSSP partner!</p>
                        </div>
                        <div className='seq-Partners-body5-body' data-aos="fade-up">
                            {window.innerWidth > 767 ?
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12 img-container'><img className='adkar-img' src='/images/bg-img98.svg' alt='' /></div>
                                    <div className='col-md-6 col-sm-12'>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Do you want to benefit from a partnership that combines advanced cybersecurity solutions and expert resources to keep enterprises safe?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Are you facing challenges in helping your customers scale their operations due to platform, resource, or margin challenges?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Do you want to be a quick enabler in creating and managing safer digital spaces that allow enterprises to focus their energies on growing and innovating?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Do you want the benefits of a subscription-based security solutions, tailored to your customers’ growing digital needs, use cases, and technologies?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Do you want to simplify your offerings and significantly reduce your operating costs?  </h6>
                                        </div>
                                        <div className='d-flex align-items-center'><button onClick={() => selectActive('registration')}>Become a Sequretek Partner&nbsp;&nbsp;&nbsp;<img src='/images/arrow-wight.svg' alt='' /></button></div>
                                        <p>Register at the Sequretek Partner Portal</p>
                                    </div>
                                </div> :
                                <div>
                                    <div className=''><img className='adkar-img' src='/images/bg-img98.svg' alt='' /></div>
                                    <div className='mssp-item-card'>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Do you want to benefit from a partnership that combines advanced cybersecurity solutions and expert resources to keep enterprises safe?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Are you facing challenges in helping your customers scale their operations due to platform, resource, or margin challenges?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Do you want to be a quick enabler in creating and managing safer digital spaces that allow enterprises to focus their energies on growing and innovating?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Do you want the benefits of a subscription-based security solutions, tailored to your customers’ growing digital needs, use cases, and technologies?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Do you want to simplify your offerings and significantly reduce your operating costs?  </h6>
                                        </div>
                                        <div className='d-flex align-items-center'><button onClick={() => selectActive('registration')}>Become a partner&nbsp;&nbsp;&nbsp;<img src='/images/arrow-wight.svg' alt='' /></button></div>
                                        <p>Register at the Sequretek Partner Portal</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/* section 7 MSP PARTNER*/}
                <div className='seq-Partners-body5' ref={mspRef} style={{ background: 'linear-gradient(3.92deg, #040C30 55.85%, #06065D 82.93%)' }}>
                    <div className='container pe-0 ps-0'>
                        <div className='seq-Partners-body5-head' data-aos="fade-up" >
                            <h6 className='d-flex justify-content-center align-items-center'>MSP</h6>
                            <h5 className='d-flex justify-content-center align-items-center'>Empower and Elevate Your Managed Services with Cutting-Edge Cybersecurity Solutions, Profitably</h5>
                            <p className='d-flex justify-content-center align-items-center'>Experience the unique journey of becoming a trusted cybersecurity advisor to your customers powered by the Sequretek minimum risk and investment partnership approach. Discover MSP benefits!</p>
                        </div>
                        <div className='seq-Partners-body5-body' data-aos="fade-up">
                            <div className='row mx-0'>
                                <div className='col-md-6 col-sm-12 px-0'>
                                    <div>
                                        <div className='img-container'>
                                            {window.innerWidth > 767 ? null : <div className='d-flex justify-content-center align-items-center seq-img-container-head-text'><h5>Select an option to view details</h5></div>}
                                            {window.innerWidth > 767 ? <img className={mspActive === '' ? 'adkar-img' : "adkar-img pos"} src='/images/msp-img.svg' alt='' /> : <img className={mspActive === '' ? 'adkar-img' : "adkar-img pos"} src='/images/msp-img-mobile.svg' alt='' />}
                                            <div className="clickable top-left" onClick={() => handleClick('products')}></div>
                                            <div className="clickable top-right" onClick={() => handleClick('services')}></div>
                                            <div className="clickable bottom-left" onClick={() => handleClick('integrations')}></div>
                                            <div className="clickable bottom-right" onClick={() => handleClick('response')}></div>
                                            {mspActive === 'products' ?
                                                <div className='partner-desc'>
                                                    <div className='close-sticky'>
                                                        <img src='/images/close-white.svg' alt='' onClick={() => setMspActive('')} />
                                                    </div>
                                                    <div className='d-flex justify-content-center align-items-center'><img src='/images/apps-add-white.svg' alt='' /></div>
                                                    <h6 className='d-flex justify-content-center align-items-center'>PRODUCTS</h6>
                                                    <hr style={{ border: '1px solid #FFFFFF1A' }} />
                                                    <div className='partner-desc-text'>
                                                        <h6 className='d-flex justify-content-center align-items-center'>Percept XDR & NG SIEM SaaS subscription</h6>
                                                        <h6 className='d-flex justify-content-center align-items-center'>Percept SOAR SaaS subscription</h6>
                                                        <h6 className='d-flex justify-content-center align-items-center'>Percept Collector & Agent Server SaaS subscription</h6>
                                                    </div>
                                                </div> : null}
                                            {mspActive === 'services' ?
                                                <div className='partner-desc'>
                                                    <div className='close-sticky'>
                                                        <img src='/images/close-white.svg' alt='' onClick={() => setMspActive('')} />
                                                    </div>
                                                    <div className='d-flex' style={{ gap: '35px' }}>
                                                        <img src='/images/optional-for-mssp.svg' alt='' />
                                                        <div>
                                                            <div className='d-flex justify-content-center align-items-center'><img src='/images/analyse-white.svg' alt='' /></div>
                                                            <h6 className='d-flex justify-content-center align-items-center'>SERVICES</h6>
                                                        </div>
                                                    </div>
                                                    <hr style={{ border: '1px solid #FFFFFF1A' }} />
                                                    <div className='partner-desc-text'>
                                                        <div className='d-flex justify-content-center align-items-center'><h6>24/7 Security monitoring from Sequretek NG SOC</h6></div>
                                                        <div className='d-flex justify-content-center align-items-center'><h6>Continuous threat hunting </h6></div>
                                                        <div className='d-flex justify-content-center align-items-center'><h6>90 days online data <span>(extendable with contract)</span></h6></div>
                                                        <div className='d-flex justify-content-center align-items-center'><h6>365 days offline data <span>(extendable with contract)</span></h6></div>
                                                        <div className='d-flex justify-content-center align-items-center'><h6 className='d-flex justify-content-center align-items-center'>Device onboarding, parsing, and connector development</h6></div>
                                                    </div>
                                                </div> : null}
                                            {mspActive === 'integrations' ?
                                                <div className='partner-desc'>
                                                    <div className='close-sticky'>
                                                        <img src='/images/close-white.svg' alt='' onClick={() => setMspActive('')} />
                                                    </div>
                                                    <div className='d-flex justify-content-center align-items-center'><img src='/images/system-cloud-white.svg' alt='' /></div>
                                                    <h6 className='d-flex justify-content-center align-items-center'>INTEGRATIONS</h6>
                                                    <hr style={{ border: '1px solid #FFFFFF1A' }} />

                                                    <div className='partner-desc-text'>
                                                        <div className='d-flex justify-content-center align-items-center'><h6>Connector Studio and Agent Server access <span>(all devices)</span></h6></div>
                                                        <div className='d-flex justify-content-center align-items-center'><h6>SOAR Connector Studio and Playbook access</h6></div>
                                                        <div className='d-flex justify-content-center align-items-center'><h6>Inbuilt ticket management system access <span>(Single user access)</span></h6></div>
                                                        <div className='d-flex justify-content-center align-items-center'><h6>Integration to any third-party ITSM system <span>(API or manual integration)</span></h6></div>
                                                        <div className='d-flex justify-content-center align-items-center'><h6>Built-in MITRE ATT&CK® evaluation map</h6></div>
                                                    </div>
                                                </div> : null}
                                            {mspActive === 'response' ?
                                                <div className='partner-desc'>
                                                    <div className='close-sticky'>
                                                        <img src='/images/close-white.svg' alt='' onClick={() => setMspActive('')} />
                                                    </div>
                                                    <div className='d-flex' style={{ gap: '35px' }}>
                                                        <img src='/images/optional-for-mssp.svg' alt='' />
                                                        <div>
                                                            <div className='d-flex justify-content-center align-items-center'><img src='/images/time-twenty-four-white.svg' alt='' /></div>
                                                            <h6 className='d-flex justify-content-center align-items-center'>RESPONSE</h6>
                                                        </div>
                                                    </div>
                                                    <hr style={{ border: '1px solid #FFFFFF1A' }} />
                                                    <div className='partner-desc-text'>
                                                        <div className='d-flex justify-content-center align-items-center'><h6>24/7 Incident Response (shared)</h6></div>
                                                        <div className='d-flex justify-content-center align-items-center'><h6>24/7 Incident Remediation (shared) <br /><span>(can extend to dedicated response & remediation with contracts)</span></h6></div>
                                                        <div className='d-flex justify-content-center align-items-center'><h6>Continuous SLA management</h6></div>
                                                        <div className='d-flex justify-content-center align-items-center'><h6>Reports, Dashboards, and SOP/ Playbook access for response </h6></div>
                                                    </div>
                                                </div> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-12 mssp-item-card'>
                                    <div className='d-flex mssp-item'>
                                        <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                        <h6>Are you an MSP planning to expand your security services portfolio with minimum business risk and investment?</h6>
                                    </div>
                                    <div className='d-flex mssp-item'>
                                        <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                        <h6>Would you like to partner with a leader in cybersecurity platforms and solutions to retain your customers, stay relevant, and grow?</h6>
                                    </div>
                                    <div className='d-flex mssp-item'>
                                        <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                        <h6>Looking to acquire new customers across sectors by offering cutting-edge AI-powered security platforms and products?</h6>
                                    </div>
                                    <div className='d-flex mssp-item'>
                                        <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                        <h6>Want to help your customers plug their challenges and become an extended service arm of a reliable cybersecurity solution provider?</h6>
                                    </div>
                                    <div className='d-flex mssp-item'>
                                        <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                        <h6>Do you wish to add value to your MSP offerings to your customers from a cybersecurity services partnership that can coexist with and complement your existing tech stack?</h6>
                                    </div>
                                    <div className='d-flex align-items-center'>{window.innerWidth > 767 ? <button onClick={() => selectActive('registration')}>Become a Sequretek Partner&nbsp;&nbsp;&nbsp;<img src='/images/arrow-wight.svg' alt='' /></button> : <button onClick={() => selectActive('registration')}>Become a partner&nbsp;&nbsp;&nbsp;<img src='/images/arrow-wight.svg' alt='' /></button>}</div>
                                    <p>Register at the Sequretek Partner Portal</p>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
                {/* section 8 OTHER PARTNER*/}
                < div className='seq-Partners-body5' ref={otherRef} style={{ background: 'linear-gradient(172.92deg, #040C30 55.85%, #06065D 82.93%)' }}>
                    <div className='container pe-0 ps-0'>
                        <div className='seq-Partners-body5-head' data-aos="fade-up" >
                            <h5 className='d-flex justify-content-center align-items-center'>Other Partners</h5>
                            <p className='d-flex justify-content-center align-items-center'>Explore the benefits of Sequretek’s tailored, diverse partnership programs for distributors, resellers, OEM/strategic partnerships and independent consultants.</p>
                        </div>
                        <div className='seq-Partners-body5-body' data-aos="fade-up">
                            {window.innerWidth > 767 ?
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12 col-12 img-container'><img className='adkar-img' src='/images/bg-img100.svg' alt='' /></div>
                                    <div className='col-md-6 col-sm-12 col-12'>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Looking to tap new market segments with innovative security risk and compliance solutions that make a visible impact in the digital world?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Interested in leveraging Sequretek’s Percept AI-powered platform and products, to enhance your IT security solutions’ value proposition to your customers?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Are you excited about the prospect of collaborating with a leader in cybersecurity solutions to drive exceptional customer outcomes?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Would you like to gain access to exclusive cybersecurity resources and support that can differentiate you in a hyper competitive market?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Would you like to upsell and cross-sell next-gen platform and products to your existing customers and increase your bottomline?</h6>
                                        </div>
                                        <div className='d-flex align-items-center'><button onClick={() => selectActive('registration')}>Become a Sequretek Partner&nbsp;&nbsp;&nbsp;<img src='/images/arrow-wight.svg' alt='' /></button></div>

                                        <p>Register at the Sequretek Partner Portal</p>
                                    </div>
                                </div> :
                                <div>
                                    <div className=''><img className='adkar-img' src='/images/bg-img100.svg' alt='' /></div>
                                    <div className='mssp-item-card'>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Looking to tap new market segments with innovative security risk and compliance solutions that make a visible impact in the digital world?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Interested in leveraging Sequretek’s Percept AI-powered platform and products, to enhance your IT security solutions’ value proposition to your customers?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Are you excited about the prospect of collaborating with a leader in cybersecurity solutions to drive exceptional customer outcomes?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Would you like to gain access to exclusive cybersecurity resources and support that can differentiate you in a hyper competitive market?</h6>
                                        </div>
                                        <div className='d-flex mssp-item'>
                                            <div className='d-flex justify-content-center'><img src='/images/question-marker.svg' alt='' /></div>
                                            <h6>Would you like to upsell and cross-sell next-gen platform and products to your existing customers and increase your bottomline?</h6>
                                        </div>
                                        <div className='d-flex align-items-center'><button onClick={() => selectActive('registration')}>Become a partner&nbsp;&nbsp;&nbsp;<img src='/images/arrow-wight.svg' alt='' /></button></div>
                                        <p>Register at the Sequretek Partner Portal</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div >
                {/* section 9 TESTIMONIAL SLIDER*/}
                < div className='seq-Partners-body8' >
                    <TestimonialSlider
                        page={'partners'}
                    />
                    <div className='seq-resources' data-aos="fade-up" data-aos-delay='50'>
                        <div className='container ps-0'>
                            <h4>Related resources</h4>
                            <div className='row ms-0 me-0'>
                                <div className='col-md-12 ps-0'>
                                    <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto mx-0'}>
                                        <RelatedResources
                                            page='partners'
                                            type=''
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => selectActive('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => selectActive('resources')}>Resource Center</button></div>}
                </div >
                {/* section 10 CURRENT PARTNERS*/}
                < div className='seq-Partners-body9' ref={currnetRef} >
                    <div className='container pe-0 ps-0'>
                        <h3 className='d-flex justify-content-center align-items-center'>Current Partners</h3>
                        {window.innerWidth > 767 ?
                            <div className='seq-partner-card'>
                                <div className='seq-partner-items d-flex flex-wrap justify-content-center' data-aos="fade-up">
                                    {PartnerCard.map(el => {
                                        return (
                                            <div className='seq-partner-item'>
                                                <div className='d-flex justify-content-center align-items-center'><img src={el.img} alt='' /></div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div> :

                            <div className='seq-partner-card d-flex overflow-auto flex-nowrap'>
                                <div className='seq-partner-items d-flex'>
                                    {PartnerCard?.map(el => {
                                        return (
                                            <div className='seq-partner-item'>
                                                <div className='d-flex justify-content-center align-items-center'><img src={el.img} alt='' /></div>
                                            </div>
                                        )
                                    })}

                                </div>

                            </div>
                        }

                        <div>
                            {window.innerWidth > 767 ?
                                <div className='d-flex justify-content-center align-items-center'><button onClick={() => selectActive('registration')}>Become a Sequretek Partner&nbsp;&nbsp;&nbsp;<img src='/images/arrow-wight.svg' alt='' /></button></div>
                                :
                                <div className='d-flex align-items-center'><button onClick={() => selectActive('registration')}>Become a partner&nbsp;&nbsp;&nbsp;<img src='/images/arrow-wight.svg' alt='' /></button></div>
                            }
                            <p className='d-flex align-items-center'>Register at the Sequretek Partner Portal</p>
                        </div>
                        <div className='d-flex justify-content-center'><h6><span className='cursor-pointer' onClick={() => selectActive('contact')} style={{ color: '#ED0101' }}>Talk to us</span> to join our Partner Community and know more on the Sequretek Partnership Program.</h6></div>
                    </div>
                </div >
            </div >
        </div >
    )
}

export default Partners;
