/* eslint-disable jsx-a11y/anchor-is-valid */
// ############################ Require packages here ############################
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Alert } from '@mui/material';
import { Helmet } from 'react-helmet';
import Snackbar from '@mui/material/Snackbar';
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha,
} from "react-simple-captcha";
// ############################ Require files here ###############################

import './styles.scss';
import { generateOTP, userLogin, acceptInvite, forgotPassword, resetPasswords, verifyLink } from '../../Redux/ActionCreators/authAction';
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
import { Modal, Spinner } from 'react-bootstrap';


function PartnerPortal() {
    const [active, setActive] = useState('login');
    const [defaultShow, setDefaultShow] = useState(true);
    const [resetValue, setResetValue] = useState({ otp: '', password: '' });
    const [otpValue, setOtpValue] = useState({ otp: '', email: "" });
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [isResetPassword, setISResetPassword] = useState(false);
    const [resetPasswordComplete, setResetPasswordComplete] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [otpEmail, setOtpEmail] = useState('');
    const [otpScreen, setOtpScreen] = useState(false);
    const [isOtpEmailLoading, setIsOtpEmailLoading] = useState(false);
    const [otpEnable, setOtpEnable] = useState(false);
    const [emailOtpScreen, setEmailOtpScreen] = useState(true);
    const [timer, setTimer] = useState(60);
    const [isRunning, setIsRunning] = useState(false);
    const [initialValues, setInitialValues] = useState({
        email: '',
        password: ''
    })
    const [type, setType] = useState('');
    const [formType, setFormType] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [captchaError, setCaptchaError] = useState('');
    const formRef = useRef(null);
    const formRef1 = useRef(null);
    const otpFormRef = useRef(null);
    const resetPasswordRef = useRef(null);

    const [registerValues, setRegisterValues] = useState({ fname: '', lname: '', mobile: '', email: "", organization: "", website: "", linkedin_url: "", country: "", job: "", partner: "", products: [], });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const error = useSelector(state => state.error);
    const location = useLocation();

    const loginSchema = Yup.object({
        email: Yup.string()
            .required('Email is required')
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                'Invalid email address'
            ),
        password: Yup.string().required('Password is required'),
    })

    const otpSchema = Yup.object({
        otp: Yup.string().matches(/^[a-zA-Z0-9]+$/, "Invalid Otp")
            .min(6, 'Invalid Otp')
            .max(6, 'Invalid Otp')
            .required('OTP is required'),
    })

    const resetPasswordSchema = Yup.object({
        otp: Yup.string().matches(/^[a-zA-Z0-9]+$/, "Invalid Otp")
            .min(6, 'Invalid Otp')
            .max(6, 'Invalid Otp')
            .required('OTP is required'),
        password: Yup.string()
            .required('Password is required')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/, 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one special character')
            .max(15, 'Password cannot be longer than 15 characters'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    })

    useEffect(() => {
        let interval;

        if (isRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
        } else if (timer === 0) {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer, isRunning]);


    // Check if the user is already logged in
    useEffect(() => {
        const userIsLoggedIn = localStorage.getItem('adminAuthToken') !== null
        if (userIsLoggedIn) {
            // Redirect to the dashboard page
            if (window.location.pathname === '/partner-portal-login') {
                if (localStorage.getItem('role') === 'support') {
                    navigate('/app/supports');
                }
                else {
                    navigate('/app/leads');
                }
            }
        }
    }, [location]);


    useEffect(() => {
        const firstRender = async () => {
            if (window.location.href.includes("otp")) {
                const token = window.location.search.split('=')[2];
                const otp = window.location.search.split("=")[1].split('&')[0];
                const res = await dispatch(verifyLink({ token: token, otp: otp }));
                localStorage.clear();
                if (res?.type === 'VERIFY_CODE') {
                    setDefaultShow(false);
                    setResetValue({ otp: window.location.search.split("=")[1].split('&')[0], password: '' })
                } else {
                    navigate('/link-expire');
                }
            }
            else if (window.location.href.includes("resetcode")) {
                const token = window.location.search.split('=')[2];
                const otp = window.location.search.split("=")[1].split('&')[0];
                const res = await dispatch(verifyLink({ token: token, resetcode: otp }));
                if (res?.type === 'VERIFY_CODE') {
                    setDefaultShow(true);
                    setIsOtpEmailLoading(false);
                    setOtpEnable(true);
                    setEmailOtpScreen(false);
                    setISResetPassword(true);
                    setResetPasswordComplete(false);
                    setResetValue({ otp: window.location.search.split("=")[1].split('&')[0], password: '' })
                } else {
                    navigate('/link-expire');
                }
            } else if (window.location.href.includes("code")) {
                const token = window.location.search.split('=')[2];
                const otp = window.location.search.split("=")[1].split('&')[0];
                const res = await dispatch(verifyLink({ token: token, code: otp }));
                if (res?.type === 'VERIFY_CODE') {
                    setOtpScreen(true);
                    setISResetPassword(false);
                    setOtpValue({ otp: window.location.search.split("=")[1].split('&')[0], email: '' })
                } else {
                    navigate('/link-expire');
                }
            }
        }
        firstRender();
    }, [])


    const selectTab = (val) => {
        if (val === 'register') {
            navigate('/partner-registration')
        } else {
            setActive(val);
            setDefaultShow(true);
            setISResetPassword(false);
            setRegisterValues({ fname: '', lname: '', mobile: '', email: "", organization: "", website: "", linkedin_url: "", country: "", job: "", partner: "", products: [], });
            setInitialValues({ email: "", password: "" })
        }
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        setFormType('login');
        let login = {
            email: values.email,
            password: values.password
        }
        localStorage.clear();
        setInitialValues(login);
        setIsConfirm(true);
        setSubmitting(false);
        setTimeout(() => {
            loadCaptchaEnginge(6);
        }, 500);

    }

    const handleOtpSubmit = async (values, { setSubmitting }) => {
        setFormType('otp')
        let obj = {
            email: localStorage.getItem('email'),
            otp: values.otp
        };
        setOtpValue(obj);
        setIsConfirm(true);
        setSubmitting(false);
        setTimeout(() => {
            loadCaptchaEnginge(6);
        }, 500);
    };

    const handleCaptchaSubmit = async () => {
        let user_captcha_value = document.getElementById('user_captcha_input').value;
        if (validateCaptcha(user_captcha_value, false) === true) {
            setCaptchaError('');
            await setIsLoading(true);
            if (formType === 'login') {
                const isLogin = await dispatch(generateOTP(initialValues));
                if (isLogin?.type === 'GENERATE_OTP') {
                    localStorage.setItem('email', initialValues?.email);
                    if (formRef1.current) {
                        setInitialValues({ email: '', password: "" })
                        formRef1.current.resetForm();
                        setIsConfirm(false);
                    }
                }
            } else if (formType === 'otp') {
                const res = await dispatch(userLogin(otpValue));
                if (res?.type === 'USER_LOGIN') {
                    navigate('/app/leads');
                    if (otpFormRef.current) {
                        setOtpValue({ otp: "", email: "" })
                        otpFormRef.current.resetForm();
                        setIsConfirm(false);
                    }

                } else {
                    navigate('/partner-portal-login')
                }
            } else if (formType === 'password') {
                if (type === 'invite') {
                    const res = await dispatch(acceptInvite(resetValue))
                    if (res?.type === 'USER_LOGIN') {
                        navigate('/app/leads');
                        if (resetPasswordRef.current) {
                            setResetValue({ otp: "", password: "" })
                            resetPasswordRef.current.resetForm();
                            setIsConfirm(false);
                        }

                    } else {
                        navigate('/partner-portal-login')
                    }
                } else {
                    const res = await dispatch(resetPasswords(resetValue));
                    if (res?.type === 'RESET_PASSWORD') {
                        setResetPasswordComplete(true);
                        if (resetPasswordRef.current) {
                            setResetValue({ otp: "", password: "" })
                            resetPasswordRef.current.resetForm();
                            setIsConfirm(false);
                        }
                    } else {
                        navigate('/partner-portal-login')
                    }
                }
            }

            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        } else {
            setCaptchaError('Captcha Does Not Match');
        }
    };


    const resetPassword = () => {
        setISResetPassword(true);
        setOtpEnable(false);
        setEmailOtpScreen(true);
        setOtpEmail('');
        setEmailError('');
    }

    const onOtpEmailChange = (e) => {
        setEmailError('');
        setOtpEmail(e.target.value);
    }

    const handleOtpEmail = async () => {
        // eslint-disable-next-line no-useless-escape
        let emailrex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!otpEmail) {
            setEmailError(`Email is required`);
            return false;
        }
        else if (!emailrex.test(String(otpEmail))) {
            setEmailError("Email is not valid");
            return false;
        }
        else {
            setIsOtpEmailLoading(true);
            let obj = {
                "email": otpEmail
            }
            const res = await dispatch(forgotPassword(obj));
            if (res?.type === 'FORGET_PASSWORD') {
                setEmailSuccess(true);
                // await setIsOtpEmailLoading(false);
                // await setOtpEnable(true);
                // await setEmailOtpScreen(false);
                // await setResetPasswordComplete(false);
                // await setIsRunning(true);
            } else {
                await setIsOtpEmailLoading(false);
            }
        }
    }

    const onOtpEmailChangeKeyPress = async (e) => {
        // eslint-disable-next-line no-restricted-globals
        if (e.which === 13 || event.keyCode === 13) {
            // eslint-disable-next-line no-useless-escape
            let emailrex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!otpEmail) {
                setEmailError(`Email can't be blank`);
                return false;
            }
            else if (!emailrex.test(String(otpEmail))) {
                setEmailError("Email is not valid");
                return false;
            }
            else {
                let obj = {
                    "email": otpEmail
                }
                const res = await dispatch(forgotPassword(obj));
                if (res?.type === 'FORGET_PASSWORD') {
                    setEmailSuccess(true);
                } else {
                    await setIsOtpEmailLoading(false);
                }
            }
        }
    }



    const handleResetSubmit = async (values, type) => {
        let obj = {
            password: values.password,
            otp: values.otp,
        };
        setType(type);
        setFormType('password');
        setResetValue(obj);
        setIsConfirm(true);
        setTimeout(() => {
            loadCaptchaEnginge(6);
        }, 500);
    };

    const resetTimer = () => {
        setTimer(60);
        setIsRunning(false);
    };


    const redirectTologin = () => {
        setDefaultShow(true);
        setISResetPassword(false);
        setEmailSuccess(false)
        setIsOtpEmailLoading(false);
        resetTimer();
    }

    const snackClosed = () => {
        dispatch(clearErrors());
    }

    let content

    if (resetPasswordComplete === false)
        content = (
            <Fragment>
                <h1 className='login-box-head'>Sequretek Partner Portal</h1>
                <h2 className="login-box-head text-left mt-2">Create leads and deals and explore content</h2>
                <div className="otp-fields d-flex flex-column align-items-center">

                    <h2 className="text-center mt-4">Verify OTP to reset password</h2>
                    <Formik initialValues={resetValue} validationSchema={resetPasswordSchema} innerRef={resetPasswordRef} onSubmit={(e) => handleResetSubmit(e, 'reset')}>
                        {(formik) => (
                            <Form className="text-left">
                                <div className="row mt-4">
                                    <div className="col-12 col-md-12">
                                        <div className="form-group">
                                            <Field type="text" name="otp" className="form-control" placeholder="Enter OTP" />
                                            <ErrorMessage name="otp" component="div" className="error-message text-left" />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-12">
                                        <div className="form-group">
                                            <Field type="password" name="password" className="form-control" placeholder="Password" />
                                            <ErrorMessage name="password" component="div" className="error-message text-left" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12">
                                        <div className="form-group">
                                            <Field type="password" name="confirm_password" className="form-control" placeholder="Enter Confirm Password" />
                                            <ErrorMessage name="confirm_password" component="div" className="error-message text-left" />
                                        </div>
                                    </div>

                                </div>
                                <button type="submit" disabled={formik.isSubmitting} className='mt-3 button-color form-control'>Submit</button>
                            </Form>
                        )}
                    </Formik>
                    <h3
                        className="mt-2 cursor-pointer" style={{ fontSize: 15 }}
                        onClick={() => redirectTologin()}
                    >
                        Back to Login
                    </h3>
                </div>
            </Fragment>
        )
    else {
        content = (
            <Fragment>
                <h1 className='login-box-head'>Sequretek Partner Portal</h1>
                <h2 className="login-box-head text-left mt-2">Create leads and deals and explore content</h2>
                <div className="success-pasword-reset pb-3 mt-5">
                    <p className='text-left' style={{ fontSize: 18 }}>Password has been successfully changed</p>
                    <button
                        onClick={() => redirectTologin()}
                        className="button-color form-control mt-4"
                    >
                        Login
                    </button>
                </div>
            </Fragment>

        )
    }


    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={error.successMessage}
                onClose={() => snackClosed()}
                autoHideDuration={3000}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {error.messageText}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={error.successEnable}
                onClose={() => snackClosed()}
                autoHideDuration={3000}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {error.messageText}
                </Alert>
            </Snackbar>
            <div className={'login-module'} >
                {/* section 0 PAGE HEADING */}
                <Helmet>
                    <title>Sequretek | Partner Portal Login | Access Your Account Securely</title>
                    <meta http-equiv="X-Frame-Options" content="DENY" />
                    <meta name="description" content="Login to our Partner Portal to unlock key resources and enhance your collaboration. Access resources effortlessly with our user-friendly login" />
                    <meta property="og:title" content="Sequretek | Partner Portal Login | Access Your Account Securely" />
                    <meta property="og:description" content="Login to our Partner Portal to unlock key resources and enhance your collaboration. Access resources effortlessly with our user-friendly login" />
                </Helmet>
                {/* section 1 LOGIN */}
                <div className='container pe-0 ps-0'>
                    <div className="login-holder">
                        {
                            defaultShow ? (
                                <div className="login-box text-center" style={{ width: '362px' }}>
                                    {!isResetPassword ? (
                                        !otpScreen ?
                                            <Fragment>
                                                <h1 className='login-box-head'>Sequretek Partner Portal</h1>
                                                <h2 className="mt-2 login-box-head1" style={{ height: 42 }}>Create leads and deals and explore content</h2> :
                                                <div className='portal-tabs'>
                                                    <div onClick={() => selectTab('login')} className={active === 'login' ? 'active portal-tab' : 'portal-tab'}>Login</div>
                                                    <div onClick={() => selectTab('register')} className={active === 'register' ? 'active portal-tab' : 'portal-tab'}>Register</div>
                                                </div>
                                                <Formik enableReinitialize initialValues={initialValues} innerRef={formRef1} validationSchema={loginSchema} onSubmit={handleSubmit}>
                                                    {({ isSubmitting }) => (
                                                        <Form id='login'>
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <div className="has-search">

                                                                            <Field type="email" name="email" autoComplete='off' className="form-control" placeholder="Email *" />
                                                                        </div>
                                                                        <ErrorMessage name="email" component="div" className="error-message" />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <div className="has-search">
                                                                            {/* <span
                                                                                className="form-control-feedback"
                                                                                style={{ marginTop: 0 }}
                                                                            >
                                                                                <img src="./images/password-icon.svg" alt="" />
                                                                            </span> */}
                                                                            <Field type="password" autoComplete='off' name="password" className="form-control" placeholder="Password *" />
                                                                        </div>
                                                                        <ErrorMessage name="password" component="div" className="error-message" />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <button type="submit" disabled={isSubmitting} className='button-color form-control'>{isSubmitting ? <Spinner animation="border" variant="light" style={{ marginTop: 0 }} /> : 'Submit'}</button>
                                                            <h3
                                                                className="mt-2 cursor-pointer text-center"
                                                                onClick={() => resetPassword()}
                                                            >
                                                                Forgot Password?
                                                            </h3>
                                                        </Form>
                                                    )}

                                                </Formik>

                                            </Fragment> :
                                            <div className='otp-holder '>
                                                <div className="otp-fields">
                                                    <h1>Sequretek Partner Portal</h1>
                                                    <h2 className="mt-2">Create leads and deals and explore content</h2>
                                                    <h2 className="text-center mt-2">Verify OTP to Login</h2>
                                                    <Formik initialValues={otpValue} validationSchema={otpSchema} innerRef={otpFormRef} onSubmit={handleOtpSubmit}>
                                                        {({ isSubmitting }) => (
                                                            <Form className="text-left">
                                                                <div className="row mt-4">
                                                                    <div className="col-12 col-md-12">
                                                                        <div className="form-group">
                                                                            <Field type="text" name="otp" className="form-control" placeholder="Enter OTP" />
                                                                            <ErrorMessage name="otp" component="div" className="error-message text-left" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button type="submit" disabled={isSubmitting} className='mt-3 button-color form-control'>{isSubmitting ? <Spinner animation="border" variant="light" style={{ marginTop: 0 }} /> : 'Submit'}</button>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                    ) : (
                                        <div className="otp-holder">
                                            {(otpEnable === false &&
                                                emailOtpScreen) ? (
                                                <div className="otp-fields">
                                                    <h1 className='login-box-head'>Sequretek Partner Portal</h1>
                                                    <h2 className="login-box-head mt-2">Create leads and deals and explore content</h2>
                                                    {!emailSuccess ?
                                                        <Fragment>
                                                            <h2 className="text-center mt-4">Enter email to reset password</h2>
                                                            <div className="form-group mt-3 w-100">
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Email"
                                                                    onChange={(e) => onOtpEmailChange(e)}
                                                                    onKeyPress={(e) =>
                                                                        onOtpEmailChangeKeyPress(e)
                                                                    }
                                                                    value={otpEmail}
                                                                />
                                                                <div className='error'>{emailError}</div>
                                                            </div>
                                                            <button
                                                                onClick={() => handleOtpEmail()}
                                                                className="button-color form-control position-relative mb-2"
                                                            >
                                                                {isOtpEmailLoading ? (
                                                                    'Loading...'
                                                                ) : (
                                                                    "Continue"
                                                                )}
                                                            </button>
                                                            <h3
                                                                className="mt-2 cursor-pointer" style={{ fontSize: 15 }}
                                                                onClick={() => redirectTologin()}
                                                            >
                                                                Back to Login
                                                            </h3>
                                                        </Fragment> :
                                                        <div className="success-pasword-reset pb-3 mt-5">
                                                            <p className='text-left' style={{ fontSize: 18 }}>Reset Link sent to your registered email</p>
                                                            <button
                                                                onClick={() => redirectTologin()}
                                                                className="button-color form-control mt-4"
                                                            >
                                                                Login
                                                            </button>
                                                        </div>
                                                    }
                                                </div>

                                            ) :
                                                content
                                            }
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="login-box text-center" style={{ width: active === 'login' ? '362px' : '650px' }}>
                                    <div className="otp-holder">
                                        <h2>Set your password.</h2>
                                        {
                                            <div className="otp-fields">
                                                <p>Please enter the code that is sent to your mail.</p>

                                                <Formik initialValues={resetValue} innerRef={resetPasswordRef} validationSchema={resetPasswordSchema} onSubmit={(e) => handleResetSubmit(e, 'invite')}>
                                                    {({ isSubmitting }) => (
                                                        <Form className="text-left">
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <Field type="text" name="otp" className="form-control" placeholder="Enter Otp" />
                                                                        <ErrorMessage name="otp" component="div" className="error-message text-left" />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <Field type="password" name="password" className="form-control" placeholder="Password" />
                                                                        <ErrorMessage name="password" component="div" className="error-message text-left" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <Field type="password" name="confirm_password" className="form-control" placeholder="Enter Confirm Password" />
                                                                        <ErrorMessage name="confirm_password" component="div" className="error-message text-left" />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <button type="submit" disabled={isSubmitting} className='button-color form-control '>{isSubmitting ? <Spinner animation="border" variant="light" style={{ marginTop: 0 }} /> : 'Submit'}</button>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            <Modal
                show={isConfirm}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={() => setIsConfirm(false)}
                dialogClassName={"confirm-modal"}
            >
                {
                    isLoading ?
                        null :
                        <div className='close-sticky'>
                            <img src='/images/close.svg' alt='' onClick={() => setIsConfirm(false)} />
                        </div>
                }
                <div className="p-4" >
                    <div className=''>
                        <LoadCanvasTemplate reloadText="Reload" />
                    </div>

                    <div className='form-group mt-3'>
                        <input className='form-control' placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text" />
                    </div>
                    <span style={{ color: 'red' }}>{captchaError}</span>
                    <div className="save-btn1">
                        <button disabled={isLoading} onClick={() => handleCaptchaSubmit()}>{isLoading ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>&nbsp;&nbsp;
                    </div>
                </div>
            </Modal>
        </Fragment >
    )
}


export default PartnerPortal;
