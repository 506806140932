// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################

import RelatedResources from '../relatedResources';
import ScheduleForm from '../scheduleForm';
import ShareUrl from './shareUrl';


export default function Blogs19() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek Blog-Fake Tax-filing App Analysis</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Fake Tax-filing App Analysis</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Fake Tax-filing App Analysis</h3>
                        <p>10-FEB-2022 | Vivek Kumar and Juee Mudras </p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample62.jpg' width={'100%'} height={318} alt='' />
                            <h4>Overview</h4>
                            <p>This app poses as a tax-filing app which is targeting Indian tax payers. Its method of distribution is via SMS messages which contains a phishing link and this link requires the user to download an app displayed on the link. After downloading and installing the app the user can fill details like PAN, mobile number, Aadhaar card number, etc. These details are then sent to a server in plain text.</p>
                            <h4>Technical Analysis</h4>
                            <p>The MD5 of the app that is analyzed in this post is e3848d2712e5847050aa2138b1e17a42 and its package name is ‘love.ble.iApp’.</p>
                            <img src='/images/blog-images/sample169.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <br />
                            <p>The app asks the user to enable ‘Google Install Assistant’ to proceed.</p>
                            <img src='/images/blog-images/sample170.jpg' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 1: App requests enabling of ‘ Google Install Assistant ‘</p>
                            <br />
                            <p>Enabling the ‘ Google Install Assistant ‘ shows that it enables the app to receive notifications, retrieve window content and perform gestures.</p>
                            <img src='/images/blog-images/sample171.jpg' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 2: Enabling the ‘ Google Install Assistant ‘</p>
                            <br />
                            <p>On clicking ‘OK’ the app first asks the user to login with their PAN and mobile numbers.</p>
                            <img src='/images/blog-images/sample172.jpg' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 3: App requesting login with PAN and mobile numbers</p>
                            <br />
                            <p>PCAPdroid and Wireshark were used to capture and analyze the network traffic respectively. The following figure shows the login details like PAN and mobile numbers being sent to http[:]//198.12.107.13/iaserver.php via POST method.</p>
                            <img src='/images/blog-images/sample173.jpg' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 4: PAN and mobile numbers sent to http[:]//198.12.107.13/iaserver.php</p>
                            <br />
                            <p>If the user fails to login then the app requests the user to register which requires the user to enter details like full name, PAN, Aadhaar number, Date of birth, mobile number, Address, state, pincode, account number.</p>
                            <img src='/images/blog-images/sample174.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 5: App asks for details like full name, PAN, Aadhaar number, Date of birth, mobile number, Address, state, pincode, account number</p>
                            <br />
                            <img src='/images/blog-images/sample175.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 6: The app asks for account number, IFS Code, Credit / Debit Card number, Expiry Date, Cvv, Pin</p>
                            <br />
                            <img src='/images/blog-images/sample176.jpg' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 7: The app sends full name, PAN, Aadhaar number, Date of birth, mobile number, Address, state, pincode, account number, IFS Code, Credit / Debit Card number, Expiry Date, Cvv, Pin to http[:]//198.12.107.13/iaserver.php</p>
                            <br />
                            <img src='/images/blog-images/sample177.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 8: IP Details http[:]//198.12.107.13/iaserver.php</p>
                            <br />
                            <p>The app is also capable of gathering SMS details using functions getMessageBody() and getDisplayOriginatingAddress().</p>
                            <img src='/images/blog-images/sample178.jpg' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 9: App stealing SMS details</p>
                            <br />
                            <p>The app can access the External Storage Directory.</p>
                            <img src='/images/blog-images/sample179.jpg' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 10: The app can access the External Storage Directory.</p>
                            <br />
                            <p>The app can take screenshots using the accessibility service.</p>
                            <img src='/images/blog-images/sample180.jpg' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 11: The app can take screenshots using the accessibility service</p>
                            <br />
                            <h4>Conclusion</h4>
                            <p>This is a fake app impersonating Income Tax department of India. The app steals vital information from the users in the guise of providing them with a refund apart from capturing other information present on the mobile device of the user.</p>
                            <h4>Recommendations to prevent infection</h4>
                            <ul>
                                <li><p>Check if the SMS messages and links are received from a legitimate source.</p></li>
                                <li><p>Install an antivirus to be alerted of fake apps.</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'Sequretek Blog-Fake Tax-filing App Analysis' , url: 'https://www.sequretek.com/resources/blog/fake-tax-filing-app-analysis'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
